import { useState } from "react";


export function useStateLSString<S extends string>(key:string, defaultValue:S): [S, (newValue:S) => void] {
    const [value, setValue_StateOnly] = useState(() => {
        const cachedValueString = localStorage.getItem(key) as S;
        if (!cachedValueString) {
            return defaultValue;
        } else {
            return cachedValueString;
        }
    });
    function setValue(newValue:S) {
        localStorage.setItem(key,newValue);
        setValue_StateOnly(newValue);
    }
    return [value, setValue];
}

/***********
 * Slightly better than raw JSON because it can handle some array functions (push) in a way that doesn't have any poor interactions with the react state.
 */
export function useStateLSArray(key:string, defaultValue:any) {
    function getFreshValue() {
        const cachedValueString = localStorage.getItem(key);
        if (!cachedValueString) {
            return defaultValue;
        } else {
            try {
                return JSON.parse(cachedValueString);
            } catch (e) {
                return defaultValue;
            }
        }
    }
    const [value, setValue_StateOnly] = useState(getFreshValue);
    function setValue(newValue:any) {
        localStorage.setItem(key,JSON.stringify(newValue));
        setValue_StateOnly(newValue);
    }
    function push(newValue:any) {
        // Get the freshest version of the array:
        const arr = getFreshValue();
        arr.push(newValue);
        setValue(arr);
    }
    return {value, setValue, push, getFreshValue};
}

export function useStateLSJSON(key:string, defaultValue:any): [any, (newValue:any) => void] {
    const [value, setValue_StateOnly] = useState(() => {
        const cachedValueString = localStorage.getItem(key);
        let cachedValueJSON;
        if (!cachedValueString) {
            cachedValueJSON = defaultValue;
        } else {
            try {
                cachedValueJSON = JSON.parse(cachedValueString);
            } catch (e) {
                cachedValueJSON = defaultValue;
            }
        }
        return cachedValueJSON;
    });
    function setValue(newValue:any) {
        localStorage.setItem(key,JSON.stringify(newValue));
        setValue_StateOnly(newValue);
    }
    return [value, setValue];
}

export function useStateLSNumber(key:string, defaultValue:number): [number, (newValue:number) => void] {
    const [value, setValue_StateOnly] = useState(() => {
        const cachedValueString = localStorage.getItem(key);
        if (!cachedValueString || Number.isNaN(parseInt(cachedValueString))) {
            return defaultValue;
        } else {
            return parseInt(cachedValueString);
        }
    });
    function setValue(newValue:number) {
        localStorage.setItem(key,newValue.toString());
        setValue_StateOnly(newValue);
    }
    return [value, setValue];
}

export function useStateLSBoolean(key:string, defaultValue:boolean): [boolean, (newValue:boolean) => void] {
    const [value, setValue_StateOnly] = useState(() => {
        const cachedValueString = localStorage.getItem(key);
        if (!cachedValueString) {
            return defaultValue;
        } else {
            return cachedValueString === "true";
        }
    });
    function setValue(newValue:boolean) {
        localStorage.setItem(key,newValue.toString());
        setValue_StateOnly(newValue);
    }
    return [value, setValue];
}