import { Switch } from "antd";
import { EditorState } from "draft-js";

import createToolbarPlugin, { Separator } from '@draft-js-plugins/static-toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createUndoPlugin from '@draft-js-plugins/undo';

import { insertAMusicLinkEntityIntoFocusedBlock, insertAVoiceLinkEntityIntoFocusedBlock, useMusicLinkPlugin, useNoteLinkPlugin, useSynthesizedLinePlugin, useVoiceLinkPlugin } from "../BlockEditing/LinkToNoteBlockUtils";
import { useInsertNote_ToolbarButton, useLinkToNote_ToolbarButton, useLinkToVoice_ToolbarButton } from "./Toolbar/LinkToNote_ToolbarButton";
import { TodoButtonDraftJSPlugins } from "./Toolbar/TodoEditorButton";
import { CommentButton_DraftJSPlugins } from "../BlockEditing/CommentBlock";


import {
    ItalicButton,
    BoldButton,
    UnderlineButton,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    // CodeBlockButton,
} from '@draft-js-plugins/buttons';
import { ReplicaPitch } from "../../../../DecisionGraph/Utilities/Sound/ReplicaAPI";
  

const linkifyPlugin = createLinkifyPlugin();
export const linkPlugin = createLinkPlugin();

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;

const undoPlugin = createUndoPlugin();
const { UndoButton, RedoButton } = undoPlugin;

const PLUGINS_STATIC = [toolbarPlugin, linkPlugin, linkifyPlugin, undoPlugin];



export function useExtensions(onEditorChange: (newEditorState: EditorState) => void, editorState: EditorState, editable: boolean, setEditable?: (e: boolean) => void) {
  const noteLinkPlugin = useNoteLinkPlugin();
  const voiceLinkPlugin = useVoiceLinkPlugin();
  const musicLinkPlugin = useMusicLinkPlugin();
  const synthesizedLinePlugin = useSynthesizedLinePlugin();

  const { ToolbarButton: AdvancedLinkToNoteButton, SearchModal: insertNoteSearchModal, openSearchModal: openInsertNoteSearchModal } = useInsertNote_ToolbarButton(editorState, onEditorChange);
  const { ToolbarButton: InsertLinkToNoteButton, SearchModal: insertLinkSearchModal, openSearchModal: openInsertLinkSearchModal } = useLinkToNote_ToolbarButton(editorState, onEditorChange);
  const { ToolbarButton: InsertVoiceButton, SearchModal: insertVoiceSearchModal, openSearchModal: openInsertVoiceSearchModal } = useLinkToVoice_ToolbarButton(editorState, onEditorChange);

  function insertAVoiceLinkIntoNote(voiceID: string, voiceName: string, pitch: ReplicaPitch) {
    // TODO We only need one Voice per note. Check the note, see if there's already a voice, and if so, offer to update it instead of inserting a new one.
    const newEditorState = insertAVoiceLinkEntityIntoFocusedBlock(editorState, voiceID, voiceName, pitch);
    onEditorChange(newEditorState);
  }
  function insertAMusicLinkIntoNote(tags: string[]) {
    const newEditorState = insertAMusicLinkEntityIntoFocusedBlock(editorState, tags);
    onEditorChange(newEditorState);
  }

  const insertFunctions = { insertAVoiceLinkIntoNote, insertAMusicLinkIntoNote };
  const headerComponents = [insertNoteSearchModal, insertLinkSearchModal, insertVoiceSearchModal, <Toolbar>
    {
      // may be use React.Fragment instead of div to improve perfomance after React 16
      (externalProps) => (
        <div>
          {setEditable && <span className="draftJSButtonWrapper" style={{ verticalAlign: "top", height: "34px", paddingTop: "5px", paddingLeft: "5px" }}><Switch checked={editable} onChange={e => setEditable(e)} size="small" /> {editable ? "Editable" : "(Read-only)"}</span>}
          {editable && <>
            {/* @ts-ignore */}
            <UndoButton {...externalProps} />
            {/* @ts-ignore */}
            <RedoButton {...externalProps} />
            {/* @ts-ignore */}
            <Separator {...externalProps} />
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            {/* <CodeButton {...externalProps} /> */}
            {/* <HeadlinesButton {...externalProps} /> */}
            {/* @ts-ignore */}
            <Separator {...externalProps} />
            <HeadlineOneButton {...externalProps} />
            <HeadlineTwoButton {...externalProps} />
            <HeadlineThreeButton {...externalProps} />
            {/* @ts-ignore */}
            <Separator {...externalProps} />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            <TodoButtonDraftJSPlugins {...externalProps} />
            {/* @ts-ignore */}
            <Separator {...externalProps} />
            <BlockquoteButton {...externalProps} />
            <CommentButton_DraftJSPlugins {...externalProps} />
            {/* <CodeBlockButton {...externalProps} /> */}
            <InsertLinkToNoteButton {...externalProps} />
            <AdvancedLinkToNoteButton {...externalProps} />
            <InsertVoiceButton {...externalProps} />
            <linkPlugin.LinkButton {...externalProps} /> {/* Insert a regular HTML URL. It does not have a tooltip.  */}
          </>}
        </div>
      )}
  </Toolbar>];
  const plugins = [...PLUGINS_STATIC, noteLinkPlugin, voiceLinkPlugin, musicLinkPlugin, synthesizedLinePlugin];


  return { insertFunctions, headerComponents, plugins, openInsertLinkSearchModal, openInsertNoteSearchModal, openInsertVoiceSearchModal };
}
