import { Tooltip } from "antd";
import { toggleFocusedBlockIsCheckable } from "../../BlockEditing/EditCheckableItemUtils";
import {CheckSquareOutlined} from '@ant-design/icons';


export function TodoButtonDraftJSPlugins(props:any) {
    const { setEditorState, getEditorState} = props;
    /*****
     * We've found an interesting bug that appears to be deep in the static toolbar, and hopefully inconsequential in production.
     * When we change something in Node.js, the getEditorState and setEditorState start to come back in as undefined.
     * Once we refresh the page or navigate away from an editor and back to this one it starts coming in as normal.
     * 
     * It appears that old code is still being held by DraftJS even after editing. To prove this, you could:
     * 1. Uncomment the warning below
     * 2. Refresh the app
     * 3. Change the warning below (or comment it out)
     * 4. Notice that the warning below is printed in its original state, not the new state
     */
    // if (!setEditorState && !getEditorState) {
    //     console.warn("Warning: You are probably in Node.js developer mode and changed something. Refresh this page before using the Editor. Otherwise, you will get an error when you click on the Todo toolbar button.");
    // }
    function onClick() {
        if (!setEditorState || !getEditorState) {
            alert("Please refresh the page before using the toolbar -- this is a Node.js & DraftJS compatibility issue.");
        }
        // console.log("useTodoButton_DraftJSPlugins: onClick: editorState=",getEditorState());
        setEditorState(toggleFocusedBlockIsCheckable(getEditorState()));
    }
    // console.log("TodoButton_DraftJS: props=",props);
    return <div className="draftJSButtonWrapper" onMouseDown={(e)=>e.preventDefault()}>
        <Tooltip title = "TODO: A checkbox that can be used to track your work.">
            <button onClick={onClick} className="draftJSButton">
            <b><CheckSquareOutlined/></b>
            </button>
        </Tooltip>
    </div>;
}
