import { EditorState, RichUtils } from "draft-js";
import { changeBlockTo } from "./EditCheckableItemUtils";

export function toggleFocusedBlockIsType(editorState: EditorState, blockType:string) {
    const block = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getFocusKey());
    if (block.getType()===blockType) {
        // Just turn it off:
        return RichUtils.toggleBlockType(editorState, blockType);
    }
    // Make it checkable:
    const newBlock = block.set("data",block.getData()).set("type",blockType);
    const newEditorState = changeBlockTo(editorState,block,newBlock,"toggle-is-"+blockType);
    return newEditorState;
}