import { useContext } from "react";
import { ContentBlock, DefaultDraftBlockRenderMap, EditorState } from "draft-js";
import { CHECKABLE_LIST_ITEM, CheckableListItem, checkableListItemBlockRenderMap } from "./CheckableListItem";
import { changeBlockPropertyValue, toggleChecked } from "./EditCheckableItemUtils";
import { LINK_TO_NOTE_BLOCK_TYPE, LinkToNoteBlock, linkToNoteBlockRenderMap } from "./LinkToNoteBlockUtils";
import { COMMENT_BLOCK, CommentBlock, commentBlockRenderMap } from "./CommentBlock";
import { BLOCKQUOTE_BLOCK, BlockquoteBlock } from "./BlockquoteBlock";
import { NotesContext } from "../../../Data/NotesContext";
import { SelectedJSONFormsContext } from "../../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { getNoteEmoji_withLoadedNotesOnly } from "../../../NotesTree/TreeUtilities/CreateTagTreeNodes";
import { useNavigateToNote } from "../../../../DecisionGraph/Utilities/NavigateTo";

export const BLOCK_RENDER_MAP =  DefaultDraftBlockRenderMap.merge(checkableListItemBlockRenderMap).merge(linkToNoteBlockRenderMap).merge(commentBlockRenderMap);

export function useBlockRendererFn(onEditorChange:(newEditorState:any)=>void, editorState:EditorState, editable:boolean) {
    const navigateToNote = useNavigateToNote();
    const notesContext = useContext(NotesContext);
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);

    function blockRendererFn (block: ContentBlock)  {
      if (block.getType() === CHECKABLE_LIST_ITEM) {
        return {
          component: CheckableListItem,
          props: {
            onChangeChecked: () => onEditorChange(toggleChecked(editorState, block)),
            checked: !!block.getData().get('checked'),
          },
        }
      } else if (block.getType() === COMMENT_BLOCK) {
        return {
          component: CommentBlock
        }
      } else if (block.getType() === BLOCKQUOTE_BLOCK) {
        return {
          component: BlockquoteBlock,
          props: {
            onClick: () => {
              if (selectedJsonFormsContext.soundtrackRef.current) {
                // selectedJsonFormsContext.soundtrackRef.current?.playBlockquoteMusic(["tension"]);
                selectedJsonFormsContext.soundtrackRef.current?.playBlockquoteNarration(block, editorState.getCurrentContent());
              } else {
                alert("Bug: Sound track system has not been loaded. Please refresh the page and try again.");
              }
            }
          }
        }
      } else if (block.getType() === LINK_TO_NOTE_BLOCK_TYPE) {
        // console.log("blockRendererFn for "+LINK_TO_NOTE_BLOCK_TYPE+": block=",block, "block.getData()=",block.getData());
        let doc_name = block.getData().get('doc_name');
        // Refresh the latest doc name:
        const note = notesContext.getLoadedNote(block.getData().get('doc_id'), true);
        if (note && note.doc_name)
          doc_name = note.doc_name;
        const emoji = getNoteEmoji_withLoadedNotesOnly(note, notesContext);
  
        return {
          component: LinkToNoteBlock,
          props: {
            onClick: (doc_id:string, doc_name:string) => {
              // alert("Clicked on a link to '"+doc_name+"', id="+doc_id);
              navigateToNote(doc_id);
            },
            onChangeChecked: () => onEditorChange(toggleChecked(editorState, block)),
            onChangeInclusion: (inclusion:string) => onEditorChange(changeBlockPropertyValue(editorState, block, "inclusion", inclusion)),
            doc_name,
            doc_id: block.getData().get('doc_id'),
            emoji,
            editable,
            checked: !!block.getData().get('checked'),
            inclusion: block.getData().get('inclusion'),
          },
        }
      }
    }
    return blockRendererFn;
}

// https://draftjs.org/docs/advanced-topics-block-styling/
//@ts-ignore The spec is returns "string" but all doc examples have it returning nothing if we don't render this type.
export function blockStyleFn(block: ContentBlock): string {
  if (block.getType() === CHECKABLE_LIST_ITEM) {
    return CHECKABLE_LIST_ITEM;
  } else if (block.getType() === LINK_TO_NOTE_BLOCK_TYPE) {
    return LINK_TO_NOTE_BLOCK_TYPE;
  } else if (block.getType() === COMMENT_BLOCK) {
    return COMMENT_BLOCK;
  }
}