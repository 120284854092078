import { NavigateToNoteFunction } from "../../DecisionGraph/Utilities/NavigateTo";
import { JSONFormsObjectContextType } from "../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { Note } from "../../Notes/Data/NoteType";
import { Extension } from "./ExtensionsList";


export const OPERATION_SELECT = "Select";
export const OPERATION_COMPLETE = "Complete";

export type CoreOperation = typeof OPERATION_SELECT | typeof OPERATION_COMPLETE;
export type OperationRenderable = {
    operation: CoreOperation;
    text: string;
    add: boolean;
};

/*********
 * Called by the extension to save after the user selects something.
 */
export function basicSaveOperation(noteContext:JSONFormsObjectContextType, note:Note, operation:OperationRenderable, removeFromOthers:CoreOperation[]) {
    const { jsonFormsObject } = noteContext;    
    const slotContents = [] as string[];
    if (jsonFormsObject[operation.operation])
        slotContents.push(...(jsonFormsObject[operation.operation]));
    let newJsonFormsObject = {...jsonFormsObject};
    // Check if it's already present, if so, don't save it.
    if (slotContents.indexOf(note.id)>=0) {
        if (operation.add)
            return; // already complete
        slotContents.splice(slotContents.indexOf(note.id),1);
        newJsonFormsObject[operation.operation] = slotContents;
    } else {
        if (!operation.add)
            return; // already complete
        slotContents.push(note.id);
        newJsonFormsObject[operation.operation] = slotContents;
        // Check if it's in others, and remove it from there.
        if (removeFromOthers)
            removeFromOthers.forEach((otherOperation:CoreOperation) => {
                if (!jsonFormsObject[otherOperation])
                    return;
                const otherSlotContents = [...jsonFormsObject[otherOperation]] as string[];
                if (otherSlotContents.indexOf(note.id)>=0) {
                    otherSlotContents.splice(otherSlotContents.indexOf(note.id),1);
                    newJsonFormsObject[otherOperation] = otherSlotContents;
                }
            });
    }
    // Save it:
    noteContext.saveJSONFormsObject(newJsonFormsObject);
}


export function getSaveOperationsForNote(extensions:Extension[], noteContext:JSONFormsObjectContextType, note:Note) {
    const operations = [] as OperationRenderable[];
    for (const extension of extensions) {
        extension.getOperationsForNote(noteContext, note).forEach((operation:OperationRenderable) => {
            operations.push(operation);
        });
    }
    return operations;
}
export function saveOperation(extensions:Extension[], noteContext:JSONFormsObjectContextType,note:Note,operation:OperationRenderable, navigateToNote:NavigateToNoteFunction):void {
    for (const extension of extensions) {
        const saveOperations = extension.getOperationsForNote(noteContext, note);
        // First extension that matches gets it.
        if (saveOperations.indexOf(operation)>=0) {
            extension.saveOperation(noteContext, note, operation, navigateToNote);
            return;
        }
    }
    throw new Error("No extension found for operation: '"+operation.text+"'. This is a bug, not a user error, this should have been pre-checked.");
}
