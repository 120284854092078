import { ContentBlock, EditorBlock } from "draft-js";
import React from "react";
import Immutable from "immutable";
import { toggleFocusedBlockIsCheckable } from "./EditCheckableItemUtils";



/******
 * For the toolbar
 */
export function TodoButton({onChange, editorState}:any) {
    function addTodo() {
      onChange(toggleFocusedBlockIsCheckable(editorState));
      /*const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        '⭐',
        editorState.getCurrentInlineStyle(),
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'));*/
    }
    return (
      <div className="rdw-custom-button-wrapper">
        <div onClick={addTodo} className="rdw-option-wrapper">#Todo</div>
      </div>
    );
}
  

/*******************
 * This checkable list item works very similarly to CheckableListItem in draft-js-checkable-list-item at 
 * https://github.com/sugarshin/draft-js-checkable-list-item
 * 
 * That is no longer being maintained, with the last update in 2018, so we could no longer use it.
 */
export const CHECKABLE_LIST_ITEM="checkable-list-item";

export class CheckableListItem extends React.Component {
  render() {
    //@ts-ignore
    const { offsetKey, blockProps: { onChangeChecked, checked } } = this.props
    return (
      <div
        className={`checkable-list-item-block${checked ? ' is-checked' : ''}`}
        data-offset-key={offsetKey}
      >
        <div
          className='checkable-list-item-block__checkbox'
          contentEditable={false}
          suppressContentEditableWarning
        >
          <input type='checkbox' checked={checked} onChange={onChangeChecked} />
        </div>
        <div className='checkable-list-item-block__text'>
          <EditorBlock {...this.props} />
        </div>
      </div>
    )
  }
}

const WRAPPER = <ul className='public-DraftStyleDefault-ul' />;
export const checkableListItemBlockRenderMap = Immutable.Map({
  [CHECKABLE_LIST_ITEM]: {
    element: 'li',
    wrapper: WRAPPER,
  },
})


export function isEmptyListItem(block:ContentBlock) {
  const text = block.getText();
  const hasEmptyText = text.length === 0;
  const blockType = block.getType();
  const isListItemBlock = blockType === 'unordered-list-item' || blockType === 'ordered-list-item' || blockType===CHECKABLE_LIST_ITEM;
  return isListItemBlock && hasEmptyText;
}

export function isCheckableItem(block:ContentBlock) {
  const blockType = block.getType();
  return blockType===CHECKABLE_LIST_ITEM;
}
export function isIndentableBlock(block:ContentBlock) {
  return isCheckableItem(block) || block.getType()==="unordered-list-item" || block.getType()==="ordered-list-item";
}
