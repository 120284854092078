import { JSONSchemaType } from "ajv";
import { GLOBAL_DEBUG_INLINE_TESTS } from "../../../GlobalDebug";

export type Dice = {
    diceType:number,
    numDice:number,
    plus:number
};

export const DiceSchema:JSONSchemaType<Dice> = {
    type: "object",
    properties: {
        diceType: {type: "number"},
        numDice: {type: "number"},
        plus: {type: "number"},
    },
    required: ["diceType","numDice","plus"],
};

export function getAvgRollD(diceType:number,numDice:number,plus:number):number {
    return (diceType*((numDice+1)/2))+plus;
}
export function getAvgRoll(dice:Dice):number {
    return getAvgRollD(dice.diceType,dice.numDice,dice.plus);
}

/****
 * Handle simple dice notation, such as 1d6+2, 1d4-1, 2d20+2.
 */
export function parseDice(dNotation:string):Dice {
    const [firstNumStr,rest] = dNotation.toLowerCase().split("d");
    const firstNum = parseInt(firstNumStr);
    let plus = null as number|null;
    let diceType = null as number|null;

    if (!rest) {
        // This means there was no "d".
        // But there was still a first part, numDice.
        return {diceType:0,numDice:0,plus:firstNum} as Dice;
    } else /*if (rest)*/ {
        // Both a d and a rest.
        if (rest.includes("+")) {
            const [diceTypeStr,plusStr] = rest.split("+");
            diceType = parseInt(diceTypeStr);
            plus = parseInt(plusStr);
        } else if (rest.includes("-")) {
            const [diceTypeStr,plusStr] = rest.split("-");
            diceType = parseInt(diceTypeStr);
            plus = -parseInt(plusStr);
        } else {
            diceType = parseInt(rest);
            plus = 0;
        }    
    }
    return {diceType, numDice: firstNum, plus} as Dice;
}
export function addDice(d1:Dice,d2:Dice):Dice {
    if ((!d1.diceType && !d1.numDice) || (!d2.diceType && !d2.numDice)) {
        // Just add the bonus since there's only one dice on the side.
        return {
            diceType:d1.diceType+d2.diceType, // one is 0
            numDice:d1.numDice+d2.numDice, // one is 0
            plus:d1.plus+d2.plus
        } as Dice;
    }
    if (d1.diceType!==d2.diceType)
        throw new Error("Dice types must match to add them.");
    return {
        diceType:d1.diceType,
        numDice:d1.numDice+d2.numDice,
        plus:d1.plus+d2.plus
    };
}
export function getDiceStr(dice:Dice):string {
    if (!dice.numDice || !dice.diceType) {
        // Error if there's only one missing:
        if (!dice.numDice && dice.diceType)
            throw new Error("numDice is missing.");
        if (dice.numDice && !dice.diceType)
            throw new Error("diceType is missing.");
        return dice.plus.toString();
    }
    return dice.numDice+"d"+dice.diceType+(dice.plus>0?"+"+dice.plus:dice.plus<0?dice.plus:"");
}

function testParseSimpleDNotation() {
    const d1d2p2 = parseDice("1d6+2");
    console.assert(d1d2p2.diceType===6,"Expected 6, got "+d1d2p2.diceType);
    console.assert(d1d2p2.numDice===1,"Expected 1, got "+d1d2p2.numDice);
    console.assert(d1d2p2.plus===2,"Expected 2, got "+d1d2p2.plus);

    const d1d4m1 = parseDice("1d4-1");
    console.assert(d1d4m1.diceType===4,"Expected 4, got "+d1d4m1.diceType);
    console.assert(d1d4m1.numDice===1,"Expected 1, got "+d1d4m1.numDice);
    console.assert(d1d4m1.plus===-1,"Expected -1, got "+d1d4m1.plus);

    const d2d20p2 = parseDice("2d20+2");
    console.assert(d2d20p2.diceType===20,"Expected 20, got "+d2d20p2.diceType);
    console.assert(d2d20p2.numDice===2,"Expected 2, got "+d2d20p2.numDice);
    console.assert(d2d20p2.plus===2,"Expected 2, got "+d2d20p2.plus);
    
    const n1 = parseDice("1");
    console.assert(n1.diceType===1,"Expected 1, got "+n1.diceType);

    console.log("parseSimpleDNotation passed!");
}
if (GLOBAL_DEBUG_INLINE_TESTS)
    testParseSimpleDNotation();