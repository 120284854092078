import { createContext, useMemo, useRef } from "react";
import { JSONFormsObject, useJSONFormsObject, useSaveJSONFormsObject } from "../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject";
import { TypeNotesFindMatchFuncType, useAllTypeNotes, useJSONFormsSchemaComponentsForType, useNote, useSaveOutgoingRelationships } from "../../Notes/Data/NoteDBHooks";
import { useServerConnectedStorage } from "../../ServerConnection/ServerConnectedStorage";
import { Note } from "../../Notes/Data/NoteType";
import { isEqual } from "lodash";
import { changedJsonForm_UpdateRelationships } from "../../Notes/Data/FirestoreJsonFormsClient";
import { getRelationships_OldParameters } from "./JSONSchemaHelpers";
import { useContext } from "react";
import { NotesContext } from "../../Notes/Data/NotesContext";
import { useParams } from "react-router-dom";
import { useStateLSString } from "../../DecisionGraph/Utilities/StateWithLocalCache";
import { ContentBlock, ContentState, EditorState } from "draft-js";
import { ReplicaPitch } from "../../DecisionGraph/Utilities/Sound/ReplicaAPI";
import { Relationship } from "./RelationshipType";

const DEBUG_LOAD_OR_SAVE = false;

export type NoteEditorRef = {
    insertAVoiceLinkIntoNote:(voiceID:string, voiceName:string, pitch:ReplicaPitch)=>void;
    insertAMusicLinkIntoNote:(tags:string[])=>void;
    editorState:EditorState;
    setEditorState:(editorState:EditorState)=>void;
};
export type SoundtrackRef = {
    playBlockquoteMusic: (tags:string[])=>Promise<void>;
    playBlockquoteNarration: (block:ContentBlock, contentState:ContentState)=>Promise<void>;
};

export type JSONFormsObjectContextType = {
    jsonFormsObject:JSONFormsObject
    jsonSchema:any
    jsonSchemaNote:Note | undefined
    jsonFormsUISchema:any
    saveJSONFormsObject:(jsonFormsObject:JSONFormsObject)=>void
    hasNote: boolean;
    isJSONFormsLoaded:boolean
    isSaving:boolean
    note:Note
    setNoteIdForContext?:(note_id:string)=>void
    findMatchFunc_isNoteOfType:TypeNotesFindMatchFuncType
    noteEditorRef:React.MutableRefObject<NoteEditorRef | undefined>
    soundtrackRef:React.MutableRefObject<SoundtrackRef | undefined>
};

function useJSONFormsContext(note_id:string, setNoteIdForContext?:(note_id:string)=>void) {
    const notesContext = useContext(NotesContext);
    const saveJSONFormsObjectCallback_notDebounced = useSaveJSONFormsObject();
    const saveOutgoingRelationships = useSaveOutgoingRelationships();
    const noteEditorRef = useRef<NoteEditorRef | undefined>(undefined);
    const soundtrackRef = useRef<SoundtrackRef | undefined>(undefined);

    // A curious behavior here which could cause bugs if misinterpreted.
    // Sometimes when we call useNote with a new note_id, it returns the old note that's already been loaded. I don't know why.
    // So, don't use note_id until we've confirmed we're getting the new note, or else our server connected storage etc. can get confused and render the wrong thing, even the new combined with the old. Yuck!
    let note = useNote(note_id);
    let noteIdHasChanged = false;
    useMemo(()=>{
        noteIdHasChanged = true;
    },[note_id]);
    
    // // Load the data that goes into the form:
    let jsonFormsObject_loaded = useJSONFormsObject(note_id);
    // Strange workaround for the curious bug where we're getting an old note. We treat this as still loading.
    // We really want a better way to fix this bug.
    if (noteIdHasChanged && jsonFormsObject_loaded?.name !==note?.doc_name) {
        if (DEBUG_LOAD_OR_SAVE) console.log("[JSONFormsObjectContext] note_id changed, but we're still getting the old note. We're treating this as still loading. New = ",note?.doc_name," old = ",jsonFormsObject_loaded?.name,jsonFormsObject_loaded);
        jsonFormsObject_loaded = null;
    }

    const {isLoaded: isJSONFormsLoaded, docRef, saveToServer: saveJSONFormsToServer,  isServerSaving, /*isClientWaitingToSave,*/} = 
        useServerConnectedStorage(note?.id || "",jsonFormsObject_loaded,saveJSONFormsObjectCallback_notDebounced);
    // Always overwrite name so it's in there and can be used by the form or the JSON:
    if (DEBUG_LOAD_OR_SAVE) console.log("[JSONFormsObjectContext] For note named "+note?.doc_name+" jsonFormsObject_loaded=",jsonFormsObject_loaded,"and the serverthing gave us ",docRef.current);
    const jsonFormsObject = {...docRef.current,"name":note?.doc_name} as JSONFormsObject;

    if (DEBUG_LOAD_OR_SAVE && !setNoteIdForContext) {
        console.log("[JSONFormsObjectContext] rendering selected. We loaded ", jsonFormsObject_loaded?.version," the connected server is giving us ", jsonFormsObject.version);
    }

    // TODO Break apart the code for editing schema & UI into another Type.
    // TODO set up the schema & UI both so they are using a server connected storage too, so we can edit them properly.
    // TODO this setup is a bit wierd, in the way we bifurcate between editing the Type itself, and editing an instance. Downstream we get the same thing.
    // TODO BUG A symptom of this setup is that when we save the schema after editing it, we don't overwrite the in-memory object, which means it looks like it didn't save until we refresh the page.

    const type = note?.type==="Type"?note?.doc_name:note?.type;
    let {jsonSchema, jsonSchemaNote, formUiSchemaObj, findMatchFunc_isNoteOfType} = useJSONFormsSchemaComponentsForType(type);
    // if (!setNoteIdForContext && jsonSchema) {
    //     console.log("🌟 useJSONFormsContext (selected) jsonSchema=",jsonSchema);
    //     console.log("🌟 useJSONFormsContext (selected) formUiSchemaObj=",formUiSchemaObj);
    //     console.log("🌟 useJSONFormsContext (selected) findMatchFunc_isNoteOfType=",findMatchFunc_isNoteOfType);
    //     debugger;
    // }
    const typeNotes = useAllTypeNotes();
    let notesOfTypeMap:Map<string,Note> = new Map();
    let relationships:Relationship[] = [];
    if (note) {
        const relationshipsObj = getRelationships_OldParameters(notesContext, jsonFormsObject, note, jsonSchema, findMatchFunc_isNoteOfType);
        notesOfTypeMap = relationshipsObj.notesOfTypeMap;
        relationships = relationshipsObj.relationships;
    }

    function saveJSONFormsObject(newJsonFormsObject:JSONFormsObject) {
        const types = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
        if (isEqual(jsonFormsObject,newJsonFormsObject)) {
            // console.log("[JSONFormsObjectContext]>saveJSONFormsObject got change event, but had no change",jsonFormsObject,newJsonFormsObject);
            return;
        } else {
            if (DEBUG_LOAD_OR_SAVE) console.log("[JSONFormsObjectContext]>saveJSONFormsObject got change event, saving:",newJsonFormsObject.name, newJsonFormsObject.version, newJsonFormsObject);
        }
        //@ts-ignore note.id can never be null by the time we reach this point
        saveJSONFormsToServer(note.id,newJsonFormsObject);
        // TODO have to figure out this:
        
        //@ts-ignore note.id can never be null by the time we reach this point
        changedJsonForm_UpdateRelationships(saveOutgoingRelationships, newJsonFormsObject, types, notesOfTypeMap, relationships, note.id);
    }

    const providerValue = {
        jsonFormsObject,
        jsonSchema,
        jsonSchemaNote,
        jsonFormsUISchema:formUiSchemaObj,
        saveJSONFormsObject,
        isJSONFormsLoaded,
        hasNote: !!note_id,
        isSaving:isServerSaving,
        note,
        setNoteIdForContext,
        findMatchFunc_isNoteOfType,
        noteEditorRef,
        soundtrackRef
    } as JSONFormsObjectContextType;

    return providerValue;
}

export const SelectedJSONFormsContext = createContext({} as JSONFormsObjectContextType);
export function SelectedJSONFormsContextProvider({children}:{children:any}) {
    //@ts-ignore
    const { doc_id }:{doc_id:string} = useParams();
    const providerValue = useJSONFormsContext(doc_id);

    return <SelectedJSONFormsContext.Provider value={providerValue}>
        {children}
    </SelectedJSONFormsContext.Provider>;
}

export const PinnedJSONFormsContext = createContext({} as JSONFormsObjectContextType);
export function PinnedJSONFormsContextProvider({children}:{children:any}) {
    const [note_id_for_context, setNoteIdForContext] = useStateLSString<string>("chat_selectedNoteContext","");
    const providerValue = useJSONFormsContext(note_id_for_context, setNoteIdForContext);

    return <PinnedJSONFormsContext.Provider value={providerValue}>
        {children}
    </PinnedJSONFormsContext.Provider>;
}