import { Editor, EditorState } from "draft-js"
import { useState } from "react";
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from "../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns";

export default function DraftJSTestPage() {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    

    return <div className="editor rdw-editor-main" style={{height:"80vh"}}>
        <Editor
            editorState={editorState}
            onChange={setEditorState}
            blockRendererFn={useBlockRendererFn(setEditorState, editorState, true)}
            blockRenderMap={BLOCK_RENDER_MAP}
            blockStyleFn={blockStyleFn}
        />
    </div>;
}