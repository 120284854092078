import { useFirestore, useFirestoreDocData } from "reactfire";
import { JSONFormsSchema } from "../../NoteType";
import { Firestore, doc, getDoc, setDoc } from "firebase/firestore";
import { saveContentBackup } from "../../NoteDBHooks";
import { SAVE_DELAY } from "../../FirestoreNoteClient";
import { debounce } from "lodash";


export function getRef_JSONFormsSchema(firestore:Firestore, note_id:string) {
    return doc(firestore, "Notes", note_id, "Content", "JSONFormsSchema");;
}

export async function getJSONFormsSchema(firestore:Firestore, note_id:string):Promise<JSONFormsSchema | undefined> {
    const ref = getRef_JSONFormsSchema(firestore, note_id);
    const objectDoc = await getDoc(ref);
    if (!objectDoc.exists()) {
      return undefined;
    } else {
      return objectDoc.data() as JSONFormsSchema;
    }
}

export function useJSONFormsSchema(note_id?:string):JSONFormsSchema | undefined {
    const firestore = useFirestore(); // must be called every time.

    const ref = getRef_JSONFormsSchema(firestore, note_id?note_id:"temporaryIgnoreMeNotARealId");
  
    const { data: jfsUntyped} = useFirestoreDocData(ref, { idField: "id" });
    const jfs = jfsUntyped as JSONFormsSchema;
  
    return jfs;
}

/* For saving types, the schemas */
export function useSaveJSONFormsSchemaCallback() {
    const firestore = useFirestore();
    function save(note_id:string, jsonFormsSchema:JSONFormsSchema) {
        const ref = getRef_JSONFormsSchema(firestore, note_id);
        setDoc(ref, jsonFormsSchema, { merge: false });
        saveContentBackup(jsonFormsSchema, firestore, note_id, "JSONFormsSchema");
    }
    // ⚠ WARNING TODO: debounce does not distinguish between different IDs, which means it's possible to lose edits from quick changes.
    // We need to fix that.
    return debounce(save, SAVE_DELAY);
}
