import { ContentState, Modifier, SelectionState } from "draft-js";
import { createNoteLinkEntity, getSomeLinkEntities } from "../BlockEditing/LinkToNoteBlockUtils";
import { NotesContextType } from "../../../Data/NotesContext";
import { getNoteNameAndEmoji } from "../../../NotesTree/TreeUtilities/CreateTagTreeNodes";
import { getNoteIDFromURL } from "../../../../DecisionGraph/Utilities/NavigateTo";
import { Extension } from "../../../../Extensions/ExtensionsFramework/ExtensionsList";

/****************
 * getFreshUpdatedContentState
 *
 * Execute this on load of a Draft JS note editor: it will update the note links to have the freshest data.
 *
 * Implementation notes
 * - We have to use the ContentState and block.getText(), not the raw data's block because emoji counting is different in the offsets but this version counts offsets the same way as the editor.
 */
export function getFreshUpdatedContentState(originalContentState: ContentState, notesContext: NotesContextType, extensions:Extension[]) {
  let madeAChange = false;
  let contentState = originalContentState;
  const originalBlocks = contentState.getBlocksAsArray();
  for (let blockI = 0; blockI < originalBlocks.length; blockI++) {
    let block = originalBlocks[blockI];
    /******
     * We do one type at a time, in reverse order. This is because we're going to be changing the text, and we want to make sure we're not changing the offsets of the other links.
     * TODO the efficiency could be improved modestly by doing all of them together in reverse order.
     */
    const { noteLinks } = getSomeLinkEntities(block, contentState);
    noteLinks.sort((a, b) => a.offsetStart - b.offsetStart);
    // Check each noteLink in a loop:
    for (let noteLinkI = noteLinks.length - 1; noteLinkI >= 0; noteLinkI--) {
      const noteLink = noteLinks[noteLinkI];
      if (noteLink.doc_id) {
        const innerNote = notesContext.getLoadedNote(noteLink.doc_id, true);
        if (!innerNote) {
          // We can't update the note text yet. Maybe another time.
          // This is deliberately not an async await b/c we want it to finish fast: it's more critical that the plain text isn't showing unfresh data and taking longer to be updated.
          continue;
        }
        const oldNoteName = block.getText().slice(noteLink.offsetStart, noteLink.offsetEnd);
        const newNoteName = getNoteNameAndEmoji(innerNote, notesContext, extensions);
        if (oldNoteName === newNoteName)
          continue; // no update needed.

        // console.log("[getFreshUpdatedContentState] > Found a note link with an out of date name at offset "+noteLink.offsetStart+". Updating from '"+oldNoteName+"' to '"+newNoteName+"'");
        const entityKey = block.getEntityAt(noteLink.offsetStart);
        const selectionState = SelectionState.createEmpty(block.getKey()).merge({
          anchorKey: block.getKey(),
          focusKey: block.getKey(),
          anchorOffset: noteLink.offsetStart,
          focusOffset: noteLink.offsetEnd,
        });
        contentState = Modifier.replaceText(contentState, selectionState, newNoteName, undefined, entityKey);
        // console.log("[getFreshUpdatedContentState] > updated block text: ",block.getText());
        madeAChange = true;
      }
    }
    /* Update regular links
     * These are plain text so we would change the entity type once we recognize them as a Note, Voice, URL.
     */
    const { regularLinks } = getSomeLinkEntities(block, contentState);
    regularLinks.sort((a, b) => a.offsetStart - b.offsetStart);
    for (let regularLinkI = regularLinks.length - 1; regularLinkI >= 0; regularLinkI--) {
      const regularLink = regularLinks[regularLinkI];
      const entityKey = block.getEntityAt(regularLink.offsetStart);
      const entity = contentState.getEntity(entityKey);
      const entityData = entity.getData();
      const url = entityData.url;
      const selectionState = SelectionState.createEmpty(block.getKey()).merge({
        anchorKey: block.getKey(),
        focusKey: block.getKey(),
        anchorOffset: regularLink.offsetStart,
        focusOffset: regularLink.offsetEnd,
      });

      // Try to recognize the URL.
      const note_id = getNoteIDFromURL(url);
      if (note_id) {
        // Yes, it's a note URL. Check if the note ID exists.
        const innerNote = notesContext.getLoadedNote(note_id, true);
        if (!innerNote) {
          // We can't update the note text yet. Maybe another time.
          // This is deliberately not an async await b/c we want it to finish fast: it's more critical that the plain text isn't showing unfresh data and taking longer to be updated.
          continue;
        }

        const newNoteName = getNoteNameAndEmoji(innerNote, notesContext, extensions);

        // First we create a new entity:
        const { entityKey, newContentState } = createNoteLinkEntity(contentState, note_id);
        contentState = newContentState;

        // Then update the text:
        contentState = Modifier.replaceText(contentState, selectionState, newNoteName, undefined, entityKey);
        madeAChange = true;
        continue;
      }
    }
    // TODO Convert voice links & music links from regular URLs into entities.
    // TODO do we have any checks for sythesized lines?
  };
  if (madeAChange)
    return contentState;
  return originalContentState;
}
