import { DiscardableCard, ReusableCard } from "./GameCardTypes";

import PlaceholderImg from "./CardImages/SD/Barricade.png";
import { parseDice } from "./DiceNotation";

export const HealingPotion1:DiscardableCard = {
    name: "Healing Potion",
    actionType: "enhance",
    image: PlaceholderImg,

    goldCost: 3,
    minLevel: 1,

    moveSpaces: 0,
    spacesAffected: 1,
    minRange: 0,
    maxRange: 1,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `You 🎯`,

    commandString: `💨 This does not use up your turn.\n\n🤝 Select an ally 0-1 space away (even you): heal 1d4+1 🧡.\n\n🗑 Discard this card.`,
};

export const AllDiscardableCards = [
    HealingPotion1,
];