/***********************************
 * 
 * 
 * TODO consider whether we can make this generic instead of hard coding these types.
 * 
 */

export function noteTypeIsCharacter(noteType:string):boolean {
    return noteType==="Character" || noteType==="Enemy" || noteType==="NPC"/*NPC is future proofing*/;
}
