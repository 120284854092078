/****************************************
 * Generic server connection that supports:
 *  - loading from the server
 *  - user editing
 *  - saving to the server
 *  - debouncing the save
 *  - merge server side changes when the user isn't editing OR at warn & ignore them
 * 
 * This is intended to be used for Firebase connections, using a react hook, but you can pass in the load/save functions.
 * 
 */

import {useRef, useMemo} from "react";
import { debounce, isEqual } from "lodash";

export type LoadedFromServerWithVersion = {
    version: number;
    // add any other properties here
}

export type SaveToServerFunc = (doc_id: string, objectData: LoadedFromServerWithVersion) => Promise<void>;

/****************
 * One big advantage of this object is that we can also identify errors in the client, work around them,
 * and also debug them effectively.
 */
const DEBUG = false;

export function useServerConnectedStorage(doc_id:string, loadedFromServer:LoadedFromServerWithVersion | null, saveToServer_NotDebounced:SaveToServerFunc) {
    const {version:server_version, ...loadedFromServerWithoutVersion} = {version: 0,...loadedFromServer};
    const lastLoadedFromServer = useRef<LoadedFromServerWithVersion | null>(loadedFromServer);
    lastLoadedFromServer.current = loadedFromServer;

    const lastLoadedDocId = useRef("");
    const localDoc = useRef<LoadedFromServerWithVersion | null>(null);
    
    // const numLoadedProperties = Object.keys(loadedFromServerWithoutVersion).length;
    const isLoaded = useRef(false);
    const isClientWaitingToSave = useRef(false);
    const isServerSaving = useRef(false);
    
    // if there are no values in loadedFromServer, then it hasn't loaded yet
    if (!loadedFromServer) {
        isLoaded.current=false;
        if (lastLoadedDocId.current !== doc_id) {
            // Don't show old data from a different ID:
            localDoc.current = null;
        }
    } else {
        //@ts-ignore
        let {version:local_version, ...localDocWithoutVersion} = {version: 0, ...localDoc.current};
        let updateFromServer = false;
        isLoaded.current=true;
        if (lastLoadedDocId.current !== doc_id) {
            // we've loaded a new doc
            lastLoadedDocId.current = doc_id;
            updateFromServer=true;
        } else if (loadedFromServer.version > local_version) {
            // the server has a newer version than we do
            // check whether the data has actually changed, if it hasn't we can just update the version
            if (!isEqual(loadedFromServerWithoutVersion, localDocWithoutVersion)) {
                updateFromServer=true;
            } else {
                localDoc.current = loadedFromServer;
                updateFromServer=false;
            }
        } else if (loadedFromServer.version <= local_version) {
            // Ignore it. This debounces changes from the server interacting with changes locally.
        }
        if (updateFromServer) {
            //@ts-ignore
            if (DEBUG) console.log("[ServerConnectedStorage] Server sent over new '", loadedFromServer?.name,"' version ",loadedFromServer.version);
            localDoc.current = loadedFromServer;
        } else {
            //@ts-ignore
            if (DEBUG) console.log("[ServerConnectedStorage] No change from server for '", loadedFromServer?.name,"' version ",loadedFromServer.version);
        }
    }

    // From great debouncing advice: https://dmitripavlutin.com/react-throttle-debounce/
    const saveToServer_Debounced = useMemo(() =>{
            // This function is called after debouncing.  It saves to the server and sets isSaving to false.
            async function saveToServer_InsideDebounce (doc_id: string, toSave: LoadedFromServerWithVersion): Promise<void> {
                //@ts-ignore
                if (DEBUG) console.log("[ServerConnectedStorage] Calling server save '", localDoc.current?.name,"' version ",localDoc.current?.version);
                isClientWaitingToSave.current=false;
                isServerSaving.current=true;
                await saveToServer_NotDebounced(doc_id, toSave);
                isServerSaving.current=false;
                // Note that when the above line completes, that doesn't mean the server has saved, just that we've sent the request. It would be cool to have another indicator when that has finished.
            }
            return debounce(saveToServer_InsideDebounce, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [doc_id]);
    
    // This is the version we'll pass back, that includes tracking whether we're saving.
    function saveToServer_WithHandler(doc_id: string, toSave: LoadedFromServerWithVersion) {
        // Check if the data has changed since localdoc's version:
        const {version:editorVersion, ...editorDocWithoutVersion} = toSave;        
        //@ts-ignore
        let {version:local_version, ...localDocWithoutVersion} = localDoc.current;
        if (isNaN(local_version)) {
            local_version=0;
            console.warn("Beware, local_version became NaN. This shouldn't happen.");
        }
        if (local_version===null || local_version===undefined) {
            console.warn("Please check this case. We shouldn't be able to reach a point where there's no local version.");
            debugger;
        }
        if (isEqual(editorDocWithoutVersion, localDocWithoutVersion)) {
            if (DEBUG) console.log("[ServerConnectedStorage] Client asked to save, but we won't, because it's not changed from the local.");
            return;
        }
        //@ts-ignore
        const {version:server_version, ...loadedFromServerWithoutVersion} = lastLoadedFromServer.current;
        if (isEqual(editorDocWithoutVersion, loadedFromServerWithoutVersion)) {
            if (DEBUG) console.log("[ServerConnectedStorage] Client asked to save, but we won't, because it's not changed from the server.");
            return;
        }
        if (editorVersion < local_version) {
            if (isClientWaitingToSave.current) {
                // Bundle this one together! It's a superfast edit from the user, before the save has finished.
                // This should only happen when users type fairly quickly
                if (DEBUG) console.log("[ServerConnectedStorage] Client asked to save and it's a quick change, so we're bundling it together with the last version,",toSave);
            } else {
                console.warn("[ServerConnectedStorage] Client asked to save, but we won't, because it's from an older version. This implies a bug in the client, ",toSave);
                return;
            }
        }


        const toSaveWithNewVersion = {...toSave, version: local_version+1};
        localDoc.current = toSaveWithNewVersion;
        isClientWaitingToSave.current=true;
        //@ts-ignore
        if (DEBUG) console.log("[ServerConnectedStorage] Client asked to save, creating new version '", localDoc.current?.name,"' version ",toSaveWithNewVersion.version,toSaveWithNewVersion);
        saveToServer_Debounced(doc_id, toSaveWithNewVersion);
    }


    //@ts-ignore
    if (DEBUG) console.log("[ServerConnectedStorage] Displaying '", localDoc.current?.name,"' version ",localDoc.current?.version," and isLoaded:",isLoaded.current," and isClientWaitingToSave:",isClientWaitingToSave.current," and isServerSaving:",isServerSaving.current,localDoc.current);
    return {
        isLoaded:isLoaded.current,
        docRef:localDoc,
        isClientWaitingToSave:isClientWaitingToSave.current,
        isServerSaving:isServerSaving.current,
        saveToServer:saveToServer_WithHandler,
    };
}