import _ from 'lodash';
import { createContext, useRef, useState } from 'react';
import { useFirestore } from 'reactfire';
import { useLoadNoteOnceCallback, loadNotesOfType as loadNotesOfTypeSource, getNoteOfTypeNamed, getNotesNamed } from './NoteDBHooks';
import { Note, PartialNote } from './NoteType';
import { Firestore } from 'firebase/firestore';

export type RelatedNoteForAI = {
    note: Note
    type: string
    source: string
};

export type NotesContextType = {
    // Some subset of notes will be fully loaded.
    "loadedNotes": Note[];

    // Requests to load more notes:
    loadNoteID: (note_id:string, waitForLoad?:boolean)=>Promise<Note>;
    getNote:(note_id:string)=>Promise<Note | null>;

    // All in one call -- get the note if it has been loaded, otherwise load it.
    getLoadedNoteOfNameAndType:(doc_name:string, type:string)=>Note|null;
    getLoadedNoteOfName:(doc_name:string)=>Note|null;
    getNotesOfName:(doc_name:string)=>Promise<Note[]>;
    getNoteOfNameAndType:(doc_name:string, type:string)=>Promise<Note|null>;

    getLoadedNote: (note_id:string, loadItAsync?:boolean)=>Note
    getLoadedNotes: (note_ids:string[], loadThemAsync?:boolean)=>{allAreLoaded:boolean, notes:Note[]}
    loadVersion:number
    notesHaveBeenLoaded:(notes:Note[])=>void
    loadNotesOfType:(type:string)=>void
    mergeChangesIntoLoadedNote:(note_id:string, note:PartialNote) => void

    // Updates: remove a note (e.g. deleted)
    removeNote:(note_id:string)=>void

    isLoading:()=>boolean
    getNumNotesBeingLoaded:()=>number
    getNotesBeingLoaded:()=>string[]
    getFirestore:()=>Firestore
};

export const NotesContext = createContext({} as NotesContextType);

interface PromiseIndex {
    [key: string]: Promise<Note | null>;
}

export default function NotesContextProvider({children}:{children:any}) {
    const loadedNotesRef = useRef([] as Note[]);
    // randomDataVersion is a random number. It changes anytime something has changed in loadedNotesRef, so we can update everything that relies on it.
    const [randomDataVersion, setRandomDataVersion] = useState(0);
    const firestore = useFirestore();
    const currentlyLoadingNotePromisesRef = useRef({} as PromiseIndex);
    
    const loadNoteOnce = useLoadNoteOnceCallback();

    /**********
     * Trigger this if you've loaded notes anywhere else.
     * We'll add them to the full list so they can be accessed from anywhere.
     * This will not trigger any changes if it's the same data as before.
     */
    async function notesHaveBeenLoaded(notes:Note[]) {
        let changesMade=0;
        for (const note of notes) {
            // First, we have to remove the note if it's in there already:
            if (!removeNoteFromLoadedNotes(note.id,note)) {
                // Signal that it was found and it's not changed, so don't change anything.
                continue;
            }
            // Now add the fresher version:
            changesMade++;
            loadedNotesRef.current.push(note);
        }
        // Force a refresh if needed
        try {
            if (changesMade>0)
                setRandomDataVersion(Math.random());
        } catch {
            // No problem.
        }
    }
    // TODO break this into two functions. We want one that checks, another than always removes.
    // CompareNote is an optoinal parameter making this function work in two places,
    // as a comparative removeNote and as deleteNote, but this overloaded functionality is confusing and unhelpful.
    function removeNoteFromLoadedNotes(note_id:string, compareNote:Note | null = null):boolean {
        const indexesOfThisId=loadedNotesRef.current.map(
            function findIndex(mightBeNote:Note, index:number){
                if (mightBeNote.id===note_id) return index; return null;
            }).filter(
                function(value){
                    return value!==null;
                });
        if (indexesOfThisId && indexesOfThisId.length>0)
            for (let i=indexesOfThisId.length-1; i>=-1;i--) {
                // for typescript, it won't happen because of the filter:
                const currentIndex = indexesOfThisId[i];
                if (currentIndex===null)
                    continue;
                if (compareNote)
                    // Now, we check if this version is actually different.
                    if (_.isEqual(compareNote,loadedNotesRef.current[currentIndex])) {
                        // Don't change this note at all, it's already up to date.
                        if (indexesOfThisId.length>i+1)
                            // Note -- this early return diminishes the robustness of the multiple indexesOfThisId.
                            // If it ever happens to have a problem, we should fix it.
                            debugger;
                        return false; // Signal not to replace.
                    }
                loadedNotesRef.current.splice(currentIndex,1);
                return true;
            }
        // Not found -- it's okay, counts as a successful "removal"
        return true;
    }
    /* Call this after a save that changes this note, e.g. after a firestore set/update/merge command
    Note must have been loaded first.
    */
    function mergeChangesIntoLoadedNote(note_id:string, note:PartialNote) {
        const noteArr = loadedNotesRef.current.map(function(possibleMatchNote:Note){return possibleMatchNote.id===note_id});
        const indexOfNote = noteArr.indexOf(true);
        if (indexOfNote===-1) {
            // const oldNote = getLoadedNote(note_id);
            debugger;
            // throw "Error, note is not loaded, can't merge it."
            // nothing to update now, I guess...
            return;
        }
        const alreadyLoadedNote = loadedNotesRef.current[indexOfNote];
        loadedNotesRef.current[indexOfNote] = {...alreadyLoadedNote, ...note};
        // Force an update:
        setRandomDataVersion(Math.random());
    }
    async function loadNoteID(note_id:string, waitForLoad:boolean=false) {
        let note = null;
        if (Object.keys(currentlyLoadingNotePromisesRef.current).includes(note_id)) {
            if (!waitForLoad)
                return null; // we won't wait for it. it should be picked up later when it's loaded.

            // Wait for the one that's being loaded:
            note = await currentlyLoadingNotePromisesRef.current[note_id];
        } else {
            const notePromise = loadNoteOnce(note_id);
            currentlyLoadingNotePromisesRef.current[note_id] = notePromise;
            notePromise.then(function(note:Note | null) {
                if (note) {
                    notesHaveBeenLoaded([note]);
                }
                delete currentlyLoadingNotePromisesRef.current[note_id];
            });

            if (waitForLoad)
                note = await notePromise;
        }
        if (!note)
            return null;
        // notesHaveBeenLoaded([note]);
        // Force a refresh. This line should not be necessary, but when we comment it out, things break.e
        // This indicates an issue in notesHaveBeenLoaded.
        // setRandomDataVersion(Math.random());

        // remove note_id from currentlyLoadingNotes, no longer needed.
        // delete currentlyLoadingNotePromisesRef.current[note_id];
        return note;
    }

    function getLoadedNote(note_id:string, loadItAsync:boolean=false):Note | null {
        const filteredNotes = loadedNotesRef.current.filter(function(note:Note){return note.id===note_id});
        if (filteredNotes.length>0)
            return filteredNotes[0];
        if (loadItAsync) {
            loadNoteID(note_id);
        }
        return null;
    }
    function getLoadedNotes(note_ids: string[], loadThemAsync:boolean=false):{allAreLoaded:boolean, notes:Note[]} {
        let allAreLoaded = true;
        const notes:Note[] = [];    
        for (const note_id of note_ids || []) {
            const loadedNote = getLoadedNote(note_id, loadThemAsync);
            if (!loadedNote) {
                allAreLoaded=false;
            } else {
                notes.push(loadedNote);
            }
        }
        return {allAreLoaded, notes};
    }

    
    function loadNotesOfType(type:string) {
        return loadNotesOfTypeSource(type,notesHaveBeenLoaded,firestore);
    }


    /*
        loadNoteID only loads. This always returns the note.
    */
    async function getNote(note_id:string):Promise<Note | null> {
        const loadedNote = getLoadedNote(note_id);
        if (loadedNote!==null)
            return loadedNote;
        // Okay, load it.
        const note = await loadNoteID(note_id, true);
        return note || null;
    }
    async function getNotesOfName(doc_name:string):Promise<Note[]> {
        // It would be faster to grab the one that's been loaded, if it exists... But that might be wrong.
        // const loadedNotes = loadedNotesRef.current.filter(function(note:Note){return note.doc_name===doc_name});
        // if (loadedNotes.length>0)
            // return loadedNotes;
        return await getNotesNamed(firestore, doc_name);
    }

    async function getNoteOfNameAndType(doc_name:string, type:string):Promise<Note | null> {
        const loadedNote = loadedNotesRef.current.filter(function(note:Note){return note.doc_name===doc_name && note.type===type});
        if (loadedNote.length>0)
            return loadedNote[0];
        return await getNoteOfTypeNamed(firestore, type, doc_name);
    }

    function getLoadedNoteOfNameAndType(doc_name:string, type:string):Note | null {
        if (!doc_name || !type) {
            console.error("Error, getLoadedNoteOfNameAndType called with null doc_name or type.");
            debugger;
            throw "Error, getLoadedNoteOfNameAndType called with null doc_name or type.";
        }
        const loadedNote = loadedNotesRef.current.filter(function(note:Note){return note.doc_name===doc_name && note.type===type});
        if (loadedNote.length>0)
            return loadedNote[0];
        // Okay, load it.
        async function loadNote() {
            const note = await getNoteOfTypeNamed(firestore, type, doc_name);
            if (note)
                notesHaveBeenLoaded([note]);
        }
        loadNote();
        return null;
    }
    function getLoadedNoteOfName(doc_name:string):Note | null {
        const loadedNote = loadedNotesRef.current.filter(function(note:Note){return note.doc_name===doc_name});
        if (loadedNote.length>0)
            return loadedNote[0];
        return null;
    }

    function getNumNotesBeingLoaded():number {
        return Object.keys(currentlyLoadingNotePromisesRef.current).length;
    }

    function isLoading():boolean {
        return getNumNotesBeingLoaded()>0;
    }
    function getNotesBeingLoaded():string[] {
        return Object.keys(currentlyLoadingNotePromisesRef.current);
    }
    function getFirestore():Firestore {
        return firestore;
    }

    const providerValue = {
        loadVersion:randomDataVersion,
        loadedNotes:loadedNotesRef.current,
        getLoadedNote,
        getLoadedNotes,
        loadNoteID,
        notesHaveBeenLoaded,
        loadNotesOfType,
        removeNote: removeNoteFromLoadedNotes,
        mergeChangesIntoLoadedNote,
        getNote,
        getNotesOfName,
        getNoteOfNameAndType,
        getLoadedNoteOfNameAndType,
        getLoadedNoteOfName,
        isLoading,
        getNumNotesBeingLoaded,
        getNotesBeingLoaded,
        getFirestore
    } as NotesContextType;

    return <NotesContext.Provider value={providerValue}>
        {children}
    </NotesContext.Provider>
}