import { ContentBlock, ContentState } from "draft-js";
import { ReplicaPitch, ReplicaVoice } from "./ReplicaAPI";
import { Note } from "../../../Notes/Data/NoteType";
import { NotesContextType } from "../../../Notes/Data/NotesContext";
import { getNoteNameAndEmoji } from "../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes";
import { noteTypeIsCharacter } from "../../../Extensions/TTRPG/CharacterEnemyNPCs/CharacterEnemyNPCs";
import { getMusicTagText, getSomeLinkEntities, getUncommentedVoiceLinksInNote } from "../../../Notes/UIs/DraftJSEditor/BlockEditing/LinkToNoteBlockUtils";
import { Extension } from "../../../Extensions/ExtensionsFramework/ExtensionsList";

export const NARRATOR_VOICE_UUID = "bf1be392-4832-40a4-9c61-715ffbdf1176"; // used only to make sure we don't reuse the narrator voice.
export const NARRATOR_SPEAKER_ID = "a49e310a-98d6-45f0-b44d-0027f961c1dd"; // Deckard, Neutral
export const NARRATOR_STYLE = "Neutral";
export const NARRATOR_VOICE_NAME = "Deckard";

const DEBUG=false;

const MERGE_SAME_SPEAKER_LINES = false;

export type NarrationLine = {
    // Initial data:
    character_name: string;
    text: string;

    // Select a voice:
    voice_uuid: string;    
    voice_name: string;
    voice?: ReplicaVoice;

    // Select a style:
    style_name?: string;

    // Select a pitch:
    pitch?: ReplicaPitch;

    // Loaded track:
    path?: string;

    // Music:
    music_tags?: string[];
};

function splitTextIntoLines(text:string):string[] {
    // Split the narration text into lines, using punctuation or line breaks.
    const lines = [] as string[];
    while (text.length>0) {
        const nextPunctuation = text.search(/[\.\?\!]/);
        const nextLineBreak = text.search(/\n/);
        const nextPunctuationOrLineBreak = Math.min(nextPunctuation, nextLineBreak);
        if (nextPunctuation===-1) {
            lines.push(text);
            break;
        }
        const line = text.substring(0,nextPunctuation+1);
        lines.push(line);
        text = text.substring(nextPunctuation+1);
    }
    return lines;
}

export function removeAllEmojis(text:string, replaceWith:string="", replaceWithMultiple:boolean=false):string {
    // Thanks to https://stackoverflow.com/questions/10992921/how-to-remove-emoji-code-using-javascript
    // Doesn't remove everything, but does do most.
    // The ... at the beginning is there because otherwise it gets removed in the process, but we want to keep these as EOL markers.
    // We replace them with single characters, mostly " " because we want to have a consistent character count with Draft-JS, which counts each emoji as one length.
    let replaceWith1 = replaceWith;
    let replaceWith2 = replaceWith;
    let replaceWith3 = replaceWith;
    // let replaceWith4 = replaceWith;
    let replaceWith5 = replaceWith;
    if (replaceWithMultiple) {
        replaceWith2 = replaceWith+replaceWith;
        replaceWith3 = replaceWith+replaceWith+replaceWith;
        // replaceWith4 = replaceWith+replaceWith+replaceWith+replaceWith;
        replaceWith5 = replaceWith+replaceWith+replaceWith+replaceWith+replaceWith;
    }

    return text.replaceAll("…", replaceWith1).replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, replaceWith2).replaceAll("🧙‍♂️",replaceWith5).replaceAll("🪓",replaceWith+replaceWith).replaceAll("5️⃣",replaceWith3).replaceAll("6️⃣",replaceWith3).replaceAll("7️⃣",replaceWith3).replaceAll("9️⃣",replaceWith3).replaceAll("⬅",replaceWith3).replaceAll("4️⃣",replaceWith3).replaceAll("3️⃣",replaceWith3).replaceAll("🔟",replaceWith3).replaceAll("8️⃣",replaceWith3).replaceAll("2️⃣",replaceWith3).replaceAll("1️⃣",replaceWith3).replaceAll("⬛",replaceWith3);
}
// function testRemoveAllEmojis() {
//     console.log("[testRemoveAllEmojis] starting");
//     const testString1 = "Hello🎬💬🔲🗣🎵🔊🔉🔈🤝🐲🎲🎯🧠🎼👊🧡📎✏🆕❌✅🤞📄👾📃⚠📝🐞💡☑⚪💰⚡💪♥🐜✔🗺😁🦶💭📍👂🖊✒⌨🔗✖🤕➡🛢💦🐵🧊❄🔥";
//     const removedWorks = removeAllEmojis(testString1);
//     // console.log("Should equal 'Hello'='"+removedWorks+"'");
//     // console.assert("Hello"===removedWorks);
//     // console.log("Hello length: "+"Hello".length+" removedWorks length: "+removedWorks.length);

//     const testString2 = "Goodbye🪓5️⃣6️⃣7️⃣9️⃣⬅4️⃣3️⃣🔟8️⃣2️⃣1️⃣⬛🧙‍♂️";
//     const removedWorks2 = removeAllEmojis(testString2);
//     // console.log("Should equal 'Goodbye'='"+removedWorks2+"'");

//     console.log("[testRemoveAllEmojis] completed");
// }
// testRemoveAllEmojis();


/***********
 * TODO bug: It uses the embedded doc name in the text of the block, not the new note name. So if you rename a note, and narrate before it's been updated, it will still use the old name.
 */
export async function processBlockToNarrate(block:ContentBlock, contentState:ContentState, defaultQuoteVoice:ReplicaVoice | undefined, notesContext:NotesContextType, extensions:Extension[]) {
    const {noteLinks, musicLinks, synthesizedTextEntities} = getSomeLinkEntities(block, contentState);
    const speechTracks = [] as NarrationLine[];
    const blockText = block.getText();
    // const characterList = block.getCharacterList();
    let lastCharacterFoundNote = undefined as Note | undefined;

    async function processNarrationBlock(narrationStartIndex:number):Promise<void> {
        // It starts with narration.
        const firstQuote = blockText.indexOf("\"",narrationStartIndex);
        const narrationTextToParse = firstQuote===-1?blockText.substring(narrationStartIndex):blockText.substring(narrationStartIndex,firstQuote);
        const lines = splitTextIntoLines(narrationTextToParse);    
        let lineStart = narrationStartIndex;
    
        for (let lineI=0;lineI<lines.length;lineI++) {
            const line = lines[lineI];
            // Find the start of the line, knowing that the shortest start is at lineStart, but there might have been line breaks parsed out via splitTextIntoLines.
            const lineStartStringIndex = blockText.indexOf(line,lineStart);
            if (lineStartStringIndex===-1) {
                console.error("[processNarrationBlock] Bug: Couldn't find the line in the text. There is a bug in processNarration: line=",line,"lineStart=",lineStart);
                debugger;
            } else {
                lineStart = lineStartStringIndex;
            }
            const pureText = removeAllEmojis(line).replaceAll("\n","").trim();
            if (pureText.length>0) {
                // Check for entities within the line:
                for (let noteLinkI=0;noteLinkI<noteLinks.length;noteLinkI++) {
                    const noteLink = noteLinks[noteLinkI];
                    if (noteLink.offsetStart<=lineStart+pureText.length && noteLink.offsetEnd>=lineStart) {
                        // It overlaps at any point.
                        const note = await notesContext.loadNoteID(noteLink.doc_id,true);
                        if (!note) {
                            console.error("[processNarrationBlock] Bug: Couldn't find the note for noteLink=",noteLink);
                            debugger;
                            continue;
                        }
                        if (noteTypeIsCharacter(note.type)) {
                            lastCharacterFoundNote=note;
                            // charactersLinked.push(note);
                        }
                    }
                }
                let music_tags=[] as string[];
                for (let musicLinkI=0;musicLinkI<musicLinks.length;musicLinkI++) {
                    const musicLink = musicLinks[musicLinkI];
                    if (musicLink.offsetStart<=lineStart+pureText.length && musicLink.offsetEnd>=lineStart) {
                        // It overlaps at any point.
                        music_tags=musicLink.musicTags;
                    }
                }

                if (DEBUG) console.log("[processNarrationBlock] block=",block," noteLinks=",noteLinks," line=",pureText," lineStart=",lineStart);
                // Is this purely a character definition? It could be "[note emoji] [note name]:"
                // Remove a colon at the end of the line:
                let narrateThisLine = true;
                let pureText2 = pureText;
                // We don't narrate music tags, so remove them:
                if (music_tags.length>0) {
                    const musicTagText = removeAllEmojis(getMusicTagText(music_tags)).trim();
                    pureText2 = pureText2.replaceAll(musicTagText,"").trim();
                }

                // Check whether the only part of the line line is a character definition:                
                if (lastCharacterFoundNote) {
                    // Remove the character name from the text.
                    // We use removeAllEmojis here because this way we only remove recognized emojis that would also have been removed from the line text above.
                    // This could be done better.
                    const noteNameToRemove = removeAllEmojis(getNoteNameAndEmoji(lastCharacterFoundNote, notesContext,extensions)).trim();
                    let textAfterRemovingTags = pureText2.replaceAll(noteNameToRemove,"").trim();
                    if (textAfterRemovingTags.length===0 || textAfterRemovingTags.length===1) {
                        // It's purely a character definition, so don't narrate it.
                        narrateThisLine = false;
                        pureText2 = "";
                    }
                }
                // TODO we want to be able to include voice entities inline, for single voices. to do that we have to heck whether the voice spec is on the line, and remove it, and use it if it's there.

                if (pureText2.length===0) {
                    // Nothing left to narrate.
                    narrateThisLine = false;
                } else if (pureText2.length===1 && ".?!".indexOf(pureText2)>=0) { // Check if the line is just one of our separators, . ? !
                    narrateThisLine = false;
                }
                if (narrateThisLine)
                    speechTracks.push({speaker_id:NARRATOR_SPEAKER_ID,character_name:"Narrator", style_name:NARRATOR_STYLE,text:pureText2,voice_uuid:NARRATOR_VOICE_UUID,voice_name:NARRATOR_VOICE_NAME,music_tags} as NarrationLine);
            }
            // lineStart = lineStart + pureText.length;
            lineStart = lineStart+line.length;
        }
        if (firstQuote>=0) {
            // There's a quote! There should also be a character name in the last line of lines, unless this Note is a character page.
            let voiceUUID =  undefined as string | undefined;
            let voicePitch = undefined as ReplicaPitch | undefined;
            if (lastCharacterFoundNote) {
                const voiceLinksInCharacterNote = getUncommentedVoiceLinksInNote(lastCharacterFoundNote);
                for (let voiceLink of voiceLinksInCharacterNote) {
                    voiceUUID = voiceLink.uuid;
                    voicePitch = voiceLink.pitch;
                    break;
                }
            }
            // speechTracks[speechTracks.length-1].text===

            // const lastLine = lines[lines.length-1];
    
            // Parse the quote:
            await processBlockToQuote(firstQuote+1, voiceUUID, voicePitch);
        }
    }
    async function processBlockToQuote(quoteStartIndex:number, voiceUUID:string | undefined = defaultQuoteVoice?.uuid, voicePitch:ReplicaPitch | undefined = defaultQuoteVoice?.pitch):Promise<void> {
        // Figure out which voice to use.

        // It starts with a quote.
        let quoteText = blockText.substring(quoteStartIndex);
        // Find the next quote:
        const secondQuote = quoteText.indexOf("\"");
        let quoteTextToParse = "";
        let nextText = "";
        if (secondQuote===-1) {
            // TODO this is a sign of difficult to parse text. It could indicate 6"8' or something else.
            // This is okay, we just go until the end of the narration.
            // console.error("[processBlockToQuote] Bug: Couldn't find the second quote. There is a bug in processNarration: quoteText=",quoteText);
            quoteTextToParse = quoteText;
        } else {
            quoteTextToParse = quoteText.substring(0,secondQuote);
            nextText = quoteText.substring(secondQuote+1);
        }
        const lines = splitTextIntoLines(quoteTextToParse);
        lines.forEach(line => {
            const pureText = removeAllEmojis(line).replaceAll("\n","").trim();
            // Check if the line is just one of our separators, . ? !
            if (pureText.length===1 && ".?!".indexOf(pureText)>=0) {
                return;
            }
            speechTracks.push({text:pureText,voice_uuid:voiceUUID,pitch:voicePitch} as NarrationLine);
        });
        if (nextText.length>0) {
            // There's more text to process. It's narration, since we went until the end of the quote.
            await processNarrationBlock(quoteStartIndex+secondQuote+1);
        }
    }

    await processNarrationBlock(0);

    if (MERGE_SAME_SPEAKER_LINES) {
        // Merge lines with the same speaker.
        const mergedSpeechTracks = [] as NarrationLine[];
        let lastLine = undefined as NarrationLine | undefined;
        for (let speechTrack of speechTracks) {
            if (lastLine && lastLine.voice_uuid===speechTrack.voice_uuid) {
                lastLine.text += " "+speechTrack.text;
            } else {
                if (lastLine) {
                    mergedSpeechTracks.push(lastLine);
                }
                lastLine = speechTrack;
            }
        }
        if (lastLine) {
            mergedSpeechTracks.push(lastLine);
        }
        return mergedSpeechTracks;
    }

    if (DEBUG) console.log("[processBlockToNarrate] Completed processing. Started with: block text=",block.getText(),"\n - Processing notes: noteLinks=",noteLinks,"\n - Output: speechTracks=",speechTracks);
    return speechTracks;
}

// function testProcessNarrationBlock1() {
//     const block = {getText:()=>(
//         "🎬This is a test narration. Noah: \"This is a test quote.\" They looked thoughful. Ally: \"Then what happened?\""),

//     } as ContentBlock;
//     console.log("[testProcessNarrationBlock1] ",processBlockToNarrate(block));

// }

// function testProcessNarration1() {
//     console.log("[testProcessNarration1] ",processNarration("🎬This is a test narration. Noah: \"This is a test quote.\" They looked thoughful. Ally: \"Then what happened?\"",[]));
// }
// testProcessNarration1();
