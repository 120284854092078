import { ReusableCardEnhancement, ReusableCard } from "./GameCardTypes";

export const OldLongbow = {
    name: "Old Longbow",
    applicableToDisciplines: ["Archery"],
    maxRange: 1,
    moveSpaces: 0,
    minLevel: 1,
    goldCost: 10,
} as ReusableCardEnhancement;

export const DecentArrows = {
    name: "Decent Arrows",
    applicableToDisciplines: ["Archery"],
    moveSpaces: 0,
    minLevel: 1,
    dicePerAffectedSpace: {plus:1,numDice:0,diceType:0},
    goldCost: 10,
} as ReusableCardEnhancement;

export const Clogs = {
    name: "Clogs",
    // applicable to all disciplines
    moveSpaces: 1,
    minLevel: 1,
    goldCost: 10,
} as ReusableCardEnhancement;

export const BoxingGloves = {
    name: "Boxing Gloves",
    applicableToDisciplines: ["Fighting"],
    moveSpaces: 0,
    minLevel: 1,
    goldCost: 10,
    dicePerAffectedSpace: {plus:1,numDice:0,diceType:0},
} as ReusableCardEnhancement;

export const TentacleSpikes = {
    name: "Tentacle Spikes",
    applicableToDisciplines: ["Brain In A Jar"],
    moveSpaces: 0,
    minLevel: 1,
    goldCost: 10,
    dicePerAffectedSpace: {plus:1,numDice:0,diceType:0},
} as ReusableCardEnhancement;


export function cardAddEnhancements(card:ReusableCard,enhancements:ReusableCardEnhancement[]):ReusableCard {
    return {
        ...card,
        name: card.name+" & "+enhancements.length,
        enhancements: enhancements,
    };
}

export const AllEnhancements = [OldLongbow, DecentArrows, Clogs, BoxingGloves];
