import { DefenseCard } from "./GameCardTypes";

// TODO redesign this.
// export const Defense_Stealth3_SneakyKnifeThrow3 = {
//     name: "Sneaky Knife Throw 3",
//     minLevel: 2,
//     commandString: "**If you took no actions (not even rest) last round:** "+
//     "Up to **3** times this round, after an enemy finishes an action that ends up to **3 spaces** away, you may:\n\n"+
//     "⚡: 🎯 Target enemy takes ❤️ **1d4** piercing (physical) damage.",
//     xpCost: 2,
//     goldCost: 0,
//     applicableToDisciplines: ["Stealth"],
// } as DefenseCard;

export const Defense_Shield1 = {
    name: "Dinged Up Shield",
    minLevel: 1,
    commandString: "🛡️ **Dinged Up Shield**: 🛡️ When you are attacked **physically** you may take ❤️ **1** less damage.",
    xpCost: 0,
    goldCost: 10,
    applicableToDisciplines: [],
} as DefenseCard;


export const AllDefenseCards = [
    // Defense_Stealth3_SneakyKnifeThrow3,
    Defense_Shield1,
] as DefenseCard[];