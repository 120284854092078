
// React & State
import { useContext, useState } from 'react';
import { useFirestore } from 'reactfire';
import { useStateLSJSON, useStateLSNumber, useStateLSString } from '../../../DecisionGraph/Utilities/StateWithLocalCache';

// Ant Design:
import { Button, InputNumber, Modal, Select } from 'antd';
import { PlusCircleOutlined, StepForwardOutlined, RedoOutlined } from '@ant-design/icons';

import { JSONFormsObject, getJSONFormsObject } from '../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import SvelteJSONEditor from '../../../JSONEditing/RawJSONEditing/SvelteJSONEditor';
import { SelectedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { SearchModal } from '../../../DecisionGraph/AlgoliaSearch/AlgoliaSearchUIs';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { useNavigateToNote } from '../../../DecisionGraph/Utilities/NavigateTo';
import { EnemyAction, Enemy } from '../Enemy';
import { JSONSchemaType } from 'ajv';
import { ExtensionJSONFormsObject } from '../../ExtensionsFramework/ExtensionsList';


const TOKEN_TYPES = {
    color: ["🟥 Red", "🟨 Yellow","🟦 Blue", "⬛ Black","🟩 Green", "🟧 Orange","⬜ White", "Pink"/*, "⚪ Clear"*/],
    letter: ["A", "B", "C", "D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S", "T","U","V","W","X","Y","Z"],
    number: ["1","2","3","4","5","6","7","8","9","10","11","12","13"]
    // number: ["1️⃣", "2️⃣", "3️⃣", "4️⃣","5️⃣", "6️⃣", "7️⃣", "8️⃣","9️⃣","🔟","0️⃣"]
};

const BATTLE_DIFFICULTY_VERY_EASY = "Very easy (e.g. new players)";
const BATTLE_DIFFICULTY_EASY = "Easy (e.g. first in session)";
const BATTLE_DIFFICULTY_NORMAL = "Normal";
const BATTLE_DIFFICULTY_HARD = "Boss";
const BATTLE_DIFFICULTY_DEADLY = "Deadly (warn players they will likely lose if they continue)";
// TODO we'll eventually add multi-wave options, which will allow for a higher number of enemies and higher damage per enemy than if the same number were all in a single wave.
// const BATTLE_STYLE_TWO_WAVES = "Two waves";
// const BATTLE_STYLE_THREE_WAVES = "Three waves";

const BATTLE_DIFFICULTY = [BATTLE_DIFFICULTY_VERY_EASY, BATTLE_DIFFICULTY_EASY,BATTLE_DIFFICULTY_NORMAL,BATTLE_DIFFICULTY_HARD, BATTLE_DIFFICULTY_DEADLY];

/*********
 * This is the schema also coded into our app in firebase. It's a duplicate, so if changed there, it will need to be changed here.
 * TODO reconcile this somehow, potentially make it an unedible type in-app?
 */

type BattleEnemyType = {
    Enemy: string,
    number: number,
    ratioToPlayers: number,
    position: number
};

export type BattleType = ExtensionJSONFormsObject & {
    name: string, // Same as the note name
    id: string,
    version: number,
    map: {
        bookAndPageName: string,
        whyThis: string,
    },
    enemies: BattleEnemyType[],
    battleDifficulty: string
};

export const BattleTypeSchema:JSONSchemaType<BattleType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},
        map: {
            type: "object",
            properties: {
                bookAndPageName: {type: "string", nullable: false},
                whyThis: {type: "string", nullable: false},
            },
            required: []
        },
        enemies: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    Enemy: {type: "string", nullable: false},
                    number: {type: "number", nullable: false, minimum: 0},
                    ratioToPlayers: {type: "number", nullable: false, minimum: 0, maximum: 1},
                    position: {type: "number", nullable: false, minimum: 0},
                },
                required: []
            },
            nullable: false
        },
        battleDifficulty: {type: "string", nullable: false, enum: BATTLE_DIFFICULTY}
    },
    required: []
};


type EnemyBattleState = {
    type:string
    hp:number
    maxHp:number
    token:string
    initialPosition:string
    actions:EnemyAction[]
    roundKilled?:number
    wasKilledOnEnemyTurn?:boolean
    hasCompletedActions?:boolean
    defense:string
    avgDmgPerDmgLevelPerRound:number
};

const BATTLE_TURN_PLAYERS = "Players";
const BATTLE_TURN_ENEMIES = "Enemies";
type BattleTurn = typeof BATTLE_TURN_PLAYERS | typeof BATTLE_TURN_ENEMIES;

type BattleState = {
    numPlayers: number;
    gameLevel: number;
    turn: BattleTurn;
    round: number,
    enemies: EnemyBattleState[];
    enemyTypes: {[key:string]:Enemy};
};
const DEFAULT_BATTLE_STATE = {
    numPlayers: 1,
    gameLevel: 1,
    turn: BATTLE_TURN_PLAYERS,
    round: 1,
    enemies: [],
    enemyTypes: {}
} as BattleState;



function getBattleDifficultyFactor(battleDifficulty:string):number {
    if (battleDifficulty===BATTLE_DIFFICULTY_VERY_EASY) {
        return .4;
    } else if (battleDifficulty===BATTLE_DIFFICULTY_EASY) {
        return .6;
    } else if (battleDifficulty===BATTLE_DIFFICULTY_NORMAL) {
        return .9;
    } else if (battleDifficulty===BATTLE_DIFFICULTY_HARD) {
        return 1.2;
    } else if (battleDifficulty===BATTLE_DIFFICULTY_DEADLY) {
        return 2;
    }
    return 0.9;
}
const BATTLE_DIFFICULTY_OPTIONS = BATTLE_DIFFICULTY.map((battleStyle:string)=>({value:battleStyle,label:battleStyle}));


/********************
 * Player HP
 * Level 1: 8 hp
 * Level 2+: 8+2*(level-1)
 * 
 */
// function getPlayerHPByLevel(gameLevel:number) {
//     return 8+2*(gameLevel-1);
// }


/*
Enemy Damage Cap: the maximum damage regardless of other choices. This is to prevent enemies from one-shotting PCs.
The damage cap is always at least 1 less than the Max HP at that level.
HP increases at 2 per level.
Exception at level 0: the damage cap is 4. People are just learning so we don't want to make it crazy difficult, ever.
Level 1: damage cap is 7.
Level 2: is 9
3 is 11, etc.
*/
function getDamageCapByLevel(gameLevel:number) {
    if (gameLevel===0) return 4;
    return gameLevel*2+5;
}

function getPlayerHPByLevel(gameLevel:number) {
    // Equation for [8,10,12,14,...]
    return 8+2*(gameLevel-1);
}
function getTotalEnemyDamagePerRound(gameLevel:number, numPlayers:number, battleDifficultyFactor:number) {
    const totalPlayerHP = getPlayerHPByLevel(gameLevel)*numPlayers;
    /********
     * With 1-3 players, aim for ~4 rounds of enemies attacking at average damage to kill them at battleDifficultyFactor=1.
     * We have tested at ~5 with hard at player level 3 and it was too easy for a hard battle.
     * 
     * With 5+ players, aim for ~3 rounds of enemies attacking, because each round is slower.
     * 
     * Enemies don't hit every round, because:
     *   - As they get killed, they no longer do damage
     *   - Not every enemy can reach a player every round
     */
    let targetNumberRounds;
    if (numPlayers<=3)
        targetNumberRounds = 4;
    else
        targetNumberRounds = 3;

    return (totalPlayerHP*battleDifficultyFactor)/targetNumberRounds;
}

/* How much HP should we have per PC to make the game balanced? Based on average damage dealt to enemies per round.
Calculations at: https://docs.google.com/spreadsheets/d/1B1O59AU2X_xWlUp2w8fhIk_pMa16yAS4mZLsjvgr_iU/edit#gid=352269094
*/
// For 3 attacks, no bonuses included -- seemed a bit too easy:
// const ENEMY_TOTAL_HP_PER_PC_By_Level =    [6,9,12,17,24,32,49,51,58];
// For 3 attacks, some increasing bonuses included, untested:
const ENEMY_TOTAL_HP_PER_PC_By_Level =    [6,9,14,20,30,40,51,69,82];

function getEnemyTotalHP(gameLevel:number, numPlayers:number, battleDifficultyFactor:number) {
    const totalEnemyHP = ENEMY_TOTAL_HP_PER_PC_By_Level[gameLevel]*numPlayers*battleDifficultyFactor;
    if (numPlayers<=3)
        return totalEnemyHP;
    // With more than 3 players, we decrease the enemy HP by 1/4 to make the game go faster, with the target being 3 rounds of fighting to complete it.
    return totalEnemyHP*3/4;
}

/*
Damage depends on the type of Damage, and the player level. Enemies do less damage than PCs each, but there are more of them.
Note: Right now this is only DAMAGE. But we can exand it to other dynamic things in our audible descriptions.
*/
const ENEMY_DAMAGE_LOOKUP = {
    "%mindamage%": {
        // Function for [1]
        min: (gameLevel:number)=>1,
        // Function for [1,2,3,4,5,6,7,8,9,10,...]
        max: (gameLevel:number)=>gameLevel+1,
        avgFactor:(gameLevel:number)=>-2,
    },
    "%lowdamage%": {
        // Function for [1,1,1,2,2,2,3,3,3,4,4,4...]
        min: (gameLevel:number)=>Math.floor(gameLevel/3)+1,
        // Function for [2,3,4,5,6,7,8,9,10,11,...]
        max: (gameLevel:number)=>gameLevel+2,
        avgFactor:(gameLevel:number)=>-1,
    },
    "%damage%": {
        // Function for [1,2,3,4,5,6,7,8,9,10,...]
        min: (gameLevel:number)=>gameLevel+1,
        // Function for [3,4,5,6,7,8,9,10,11]
        max: (gameLevel:number)=>gameLevel+3,
        avgFactor:(gameLevel:number)=>0,
    },
    "%highdamage%": {
        // Function for [2,4,6,8,10,12,14,16,18,20,...]
        min: (gameLevel:number)=>gameLevel*2+2,
        // Function for [4,6,8,10,12,...
        max: (gameLevel:number)=>gameLevel*2+4,
        avgFactor:(gameLevel:number)=>gameLevel+1,
    },
    "%deadlydamage%": {
        // Function for [6,8,10,12,14,16,18,20,22,24,...]
        min: (gameLevel:number)=>gameLevel*2+6,
        // Function for [8,10,12,14,16,...
        max: (gameLevel:number)=>gameLevel*2+8,
        avgFactor:(gameLevel:number)=>gameLevel+5,
    }
} as {[key:string]:{min:(gameLevel:number)=>number,max:(gameLevel:number)=>number,avgFactor:(gameLevel:number)=>number}};


const GENDER_PRONOUN_LOOKUP: {[key: string]: string[]} = {
    male: ["he","his","him","man","men"],
    female: ["she","her","her","woman","women"],
    person: ["one","one's","one","person","persons"],
    people: ["they","their","them","people","people"],
    it: ["it","its","it","thing","things"],
};

function replacePronounsInString(sentence: string, gender: string) {
    const words = sentence.split(/([^\w])/);
    const replacedWords = words.map((word) => {
      if (word.match(/^\W+$/)) {
        // if the word is only punctuation, don't replace it
        return word;
      }
      for (let i = 0; i < GENDER_PRONOUN_LOOKUP.it.length; i++) {
        // If it's a pronoun, replace it.
        if (word===GENDER_PRONOUN_LOOKUP.male[i])
            return GENDER_PRONOUN_LOOKUP[gender][i];
        if (word===GENDER_PRONOUN_LOOKUP.female[i])
            return GENDER_PRONOUN_LOOKUP[gender][i];

        if (word===GENDER_PRONOUN_LOOKUP.male[i].charAt(0).toUpperCase() +
            GENDER_PRONOUN_LOOKUP.male[i].slice(1))
            return GENDER_PRONOUN_LOOKUP[gender][i].charAt(0).toUpperCase() +
                GENDER_PRONOUN_LOOKUP[gender][i].slice(1);

        if (word===GENDER_PRONOUN_LOOKUP.female[i].charAt(0).toUpperCase() +
            GENDER_PRONOUN_LOOKUP.female[i].slice(1))
            return GENDER_PRONOUN_LOOKUP[gender][i].charAt(0).toUpperCase() +
                GENDER_PRONOUN_LOOKUP[gender][i].slice(1);
      }
      return word;
    });
    console.log(replacedWords);
    return replacedWords.join(''); // join the words back into a sentence
}

export function BattleNoteTypeEditor() {
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const {jsonFormsObject} = selectedJsonFormsContext;
    const battleJson = jsonFormsObject as BattleType;

    const firestore = useFirestore();
    const [isNewBattleModalOpen, setNewBattleModalOpen] = useState(false);
    const [isAddEnemyModalOpen, setAddEnemyModalOpen] = useState(false);
    const [isBattleStateModalOpen, setBattleStateModalOpen] = useState(false);

    const [battleStateFromJSON, setBattleState] = useStateLSJSON("battleState",DEFAULT_BATTLE_STATE);
    const battleState = battleStateFromJSON as BattleState;
    const [numPlayers, setNumPlayers] = useStateLSNumber("numPlayers",2);
    const [gameLevel, setGameLevel] = useStateLSNumber("gameLevel",2);
    const [battleDifficulty, setBattleDifficulty] = useStateLSString<string>("battleStyle",BATTLE_DIFFICULTY_EASY);
    const [tokenTypes, setTokenTypes] = useStateLSJSON("tokenTypes",["color", "number", "letter"]);

    const [hpPerRatioDefault, setHPPerRatioDefault] = useState(1);
    const [avgDmgPerDmgLevelPerRound, setAvgDmgPerDmgLevelPerRound] = useState(1);
    const notesContext = useContext(NotesContext);
    const navigateToNote = useNavigateToNote();


    const battleDifficultyFactor = getBattleDifficultyFactor(battleDifficulty);

    const isEnemiesTurn = battleState?.turn===BATTLE_TURN_ENEMIES;
    //@ts-ignore
    const availableTokenTypes:string[] = tokenTypes.map((tokenType:string)=>TOKEN_TYPES[tokenType]).flat();
    const tokenTypeOptions = availableTokenTypes.map((tokenType) => ({
        label: tokenType,
        value: tokenType
      }));

    function isNewBattleInputValid() {
        if (!numPlayers || numPlayers===0 || Number.isNaN(numPlayers))
            return false;
        if (!gameLevel || gameLevel<0 || Number.isNaN(gameLevel))
            return false;
        return true;
    }

    async function addEnemyToBattle(battleState:BattleState,numEnemies:number,enemyName:string,hpPerRatio:number,avgDmgPerDmgLevelPerRound:number) {
        let enemyObjectData = battleState.enemyTypes[enemyName] as Enemy | null | undefined;
        if (!enemyObjectData) {
            const enemyNote = await notesContext.getNoteOfNameAndType(enemyName,"Enemy");
            if (!enemyNote) {
                console.error("[BattleNoteTypeEditor]>addEnemyToBattle: No note found of type Enemy with name \""+enemyName+"\"");
                return false;
            }
            enemyObjectData = await getJSONFormsObject(firestore, enemyNote.id) as unknown as Enemy | null;
            // let jsonFormsPromptDataInner = "";
            if (!enemyObjectData) {
                console.error("[BattleNoteTypeEditor]>addEnemyToBattle: No enemy data found in the database for ",enemyName);
                return false;
            }
            enemyObjectData = {...enemyObjectData, id: enemyNote.id} as Enemy;
            battleState.enemyTypes[enemyName] = enemyObjectData;
        }
        for (let i=0;i<numEnemies;i++) {
            // Add a bit of randomness to the HP of the enemies. This is to make them seem a bit different, and so that players can't guarantee they know exactly how many HP an enemy has even if it's the same type.
            // TODO The randomness could be scattered around the center of the range, so we don't make the encounter a ton more difficult or easier randomly.
            // It should be based off the HP of the enemy.
            let hp = hpPerRatio * enemyObjectData.maxHpLevel;
            const jitter = ((Math.random()*2)-1) * Math.max(0.5,hp/10);
            hp = Math.round(hp + jitter);

            //const hp = Math.round(hpPerRatio * enemyObjectData.maxHpLevel
                // Add +1, 0, or -1. Adds variety to the HP of our random enemies, which can be more noticeable when there are many of the same one. Makes it a bit harder for players to predict what the number will be.
            //    + Math.round(Math.random()*2-1));
            const token = availableTokenTypes.shift() || ""; // It's very unlikely we run out, but if we do, use a blank string.
            battleState.enemies.push({type:enemyName,hp:hp,maxHp:hp,token,initialPosition:"",actions:[],defense:"",avgDmgPerDmgLevelPerRound: avgDmgPerDmgLevelPerRound});
        }
        return true;        
    }
    async function newBattle() {
        setNewBattleModalOpen(false);
        // TODO it'd be good to add a spinner since it's possible if internet is slow this happens very slowly.

        function getNumEnemies(enemyType: BattleEnemyType) {
            let numEnemies = 0;
            if (enemyType.number>0) {
                numEnemies = enemyType.number;
            } else if (enemyType.ratioToPlayers>0) {
                numEnemies = enemyType.ratioToPlayers * numPlayers;
            }
            return numEnemies;
        }

        const newBattleState = {
            numPlayers,
            gameLevel,
            turn: BATTLE_TURN_PLAYERS,
            round: 1,
            enemies: [],
            enemyTypes: {}
        } as BattleState;

        const totalEnemyHP = getEnemyTotalHP(gameLevel, numPlayers, battleDifficultyFactor);
        const totalEnemyDamagePerRound = getTotalEnemyDamagePerRound(newBattleState.gameLevel,newBattleState.numPlayers,battleDifficultyFactor);        

        // First pass: get the total enemy hp ratio that we'll divide down to get the actual enemy hp.
        // Also cache our enemy types.
        let totalEnemyHPLevels = 0;
        let totalEnemyDamageLevels = 0;
        console.log("jsonFormsObject:",battleJson);
        for (const enemyType of battleJson.enemies as BattleEnemyType[]) {
            const numEnemies = getNumEnemies(enemyType);
            const enemyNote = await notesContext.getNoteOfNameAndType(enemyType.Enemy,"Enemy");
            if (!enemyNote) {
                console.error("[BattleNoteTypeEditor]>newBattle: No note found of type Enemy with name \""+enemyType.Enemy+"\"");
                continue;
            }
            const enemyObjectData = await getJSONFormsObject(firestore, enemyNote.id);
            // let jsonFormsPromptDataInner = "";
            if (enemyObjectData) {
                // console.log("   loaded enemy data: ",enemyObjectData);
                //@ts-ignore
                newBattleState.enemyTypes[enemyType.Enemy as string] = {...enemyObjectData,id:enemyNote.id};
                // If enemyType.maxHP is blank, consider it to be normal:
                // console.log("ratioToAdd for type ",enemyObjectData, ratioToAdd);
                totalEnemyHPLevels += numEnemies*enemyObjectData.maxHpLevel;
                totalEnemyDamageLevels += numEnemies*enemyObjectData.damageLevel;

                // TODO eventually this will be more complex -- there may be strong and weak defenses. But for now we count this as 2 extra HP levels per enemy.
                //@ts-ignore
                if (enemyObjectData.defaultDefense && enemyObjectData.defaultDefense.length>0)
                    totalEnemyHPLevels += 2;
            }
        }

        const hpPerRatio = totalEnemyHP / totalEnemyHPLevels;
        const avgDmgPerDmgLevelPerRound = totalEnemyDamagePerRound / totalEnemyDamageLevels;
        // The only reason to cache these two here is so that the GM can manually add enemies later at the same difficulty level. (Adding enemies mid-fight does increase the difficulty).        
        setHPPerRatioDefault(hpPerRatio);
        setAvgDmgPerDmgLevelPerRound(avgDmgPerDmgLevelPerRound);
        // console.log("availableTokenTypes:",availableTokenTypes);

        // Second pass: actually create the enemies.
        for (const enemyType of battleJson.enemies as BattleEnemyType[]) {
            const numEnemies = getNumEnemies(enemyType);
            await addEnemyToBattle(newBattleState,numEnemies,enemyType.Enemy,hpPerRatio,avgDmgPerDmgLevelPerRound);
        }
        setBattleState(newBattleState);
    }
    function playersAreDone() {
        const newBattleState = {...battleState};
        newBattleState.turn = BATTLE_TURN_ENEMIES;
        setBattleState(newBattleState);
        // console.log("newBattleState:",newBattleState);
        for (const enemy of newBattleState.enemies) {
            if (enemy.hp<=0) {
                console.log("Enemy is dead:",enemy);
                continue;
            }
            // Look up the enemy from enemyTypes:
            const enemyJSONSchema = newBattleState.enemyTypes[enemy.type] as Enemy;
            let actions = [] as EnemyAction[];
            if (enemyJSONSchema.actionsByRoundNum && enemyJSONSchema.actionsByRoundNum.length>=newBattleState.round-1 && enemyJSONSchema.actionsByRoundNum[newBattleState.round-1] && enemyJSONSchema.actionsByRoundNum[newBattleState.round-1].action.length>0) {
                actions = enemyJSONSchema.actionsByRoundNum[newBattleState.round-1].action;
                // console.log("Enemy has actions in round ",newBattleState.round,actions);
            } else if (enemyJSONSchema.randomActions && enemyJSONSchema.randomActions.length>0) {
                // console.log("Enemy has no ROUND-num actions for round so we'll do a RANDOM one.");
                actions = enemyJSONSchema.randomActions[Math.floor(Math.random()*enemyJSONSchema.randomActions.length)].action;
            } else {
                // No actions for this enemy... This is a bit strange.
                // TODO we may want to warn about this or even better, warn before the start of the battle or in the config panel.
            }

            // Now we'll replace %lowdamage% %damage% and %highdamage% with actual random numbers. This is in the action in the array of actions in the "aud"
            enemy.actions = actions.map(function (action:EnemyAction) {
                const newAction = {...action};
                if (newAction.aud && newAction.aud.includes("%")) {
                    if (battleDifficulty===BATTLE_DIFFICULTY_DEADLY) {
                        newAction.aud = newAction.aud.replaceAll("%highdamage%","%deadlydamage%").replaceAll("%damage%","%highdamage%").replaceAll("%lowdamage%","%damage%").replaceAll("%mindamage%","%lowdamage%");
                    }
                    // Using ENEMY_DAMAGE_LOOKUP and getDamageCapByLevel, replace the %lowdamage% %damage% and %highdamage% with actual random numbers:
                    for (const damageType in ENEMY_DAMAGE_LOOKUP) {
                        if (!newAction.aud.includes(damageType))
                            continue;

                        const dmgLookup = ENEMY_DAMAGE_LOOKUP[damageType];
                        const avgDmg = enemy.avgDmgPerDmgLevelPerRound * enemyJSONSchema.damageLevel;
                        let minDmg = dmgLookup.min(newBattleState.gameLevel);
                        let maxDmg = dmgLookup.max(newBattleState.gameLevel);
                        /**
                         * Center the damage for %damage% around avgDmg.
                         * 
                         * TODO this approach so far is not really doing a mathematical centering.
                         * So far all we do is capping the max damage at 2x the average damage. This helps us scale down a bit, but not evenly.
                         */
                        if (maxDmg>avgDmg*2) {
                            maxDmg = Math.round(avgDmg*2);
                        }
                        if (minDmg>=maxDmg) {
                            minDmg = maxDmg-2;
                            if (minDmg<1)
                                minDmg = 1;
                        }

                        // let damage = Math.floor(Math.random()*(maxDmg-minDmg)*battleDifficultyFactor)+minDmg;
                        let damage = Math.floor(Math.random()*(maxDmg-minDmg))+minDmg;
                        // debugger;

                        // console.log("[BattleNoteTypeEditor] enemy damage lookup for "+damageType+" at level "+newBattleState.gameLevel+" is "+minDamage+"-"+maxDamage+" = "+damage);
                        if (battleDifficulty!==BATTLE_DIFFICULTY_DEADLY) {
                            // Check against the damage cap, but only if it's not a deadly battle.                                
                            const damageCap = getDamageCapByLevel(newBattleState.gameLevel);
                            // Cap the damage:
                            if (damage>damageCap) {
                                damage = damageCap;
                            }
                        }
                        newAction.aud = newAction.aud.replace(damageType,damage.toString());
                    }
                }
                return newAction;
            });
            // enemy.actions = actions;
        }
    }
    function enemiesAreDone() {
        const newEnemies = battleState.enemies.map((enemy:EnemyBattleState) => ({
            ...enemy,
            hasCompletedActions: false,
          }));
          for (const enemy of newEnemies) {
            if (enemy.hp===0)
                continue;
            // Look up the enemy from enemyTypes:
            const enemyJSON = battleState.enemyTypes[enemy.type];
            // Select a defense if there is one:
            console.log("[BattleNoteTypeEditor]>playersAreDone> ",enemyJSON.name,"'s default defense is: ",enemyJSON.defaultDefense);
            if (enemyJSON.defaultDefense && enemyJSON.defaultDefense.length>0) {
                enemy.defense = enemyJSON.defaultDefense;
            } else {
                enemy.defense = "";
            }
        }
        setBattleState({
            ...battleState,
            enemies: newEnemies,
            round: battleState.round + 1,
            turn: BATTLE_TURN_PLAYERS
        } as BattleState);
    }
    async function addEnemyFromModal(doc_id:string,doc_name:string) {
        const newBattleState = {...battleState};
        // Bug: only works with existing enemies already added to this battle...
        await addEnemyToBattle(newBattleState,1,doc_name,hpPerRatioDefault,avgDmgPerDmgLevelPerRound);
        setBattleState(newBattleState);
        setAddEnemyModalOpen(false);
    }

    return <div style={{maxHeight:"calc(100vh - 45px)",overflowY:"scroll"}} >
        {/* Buttons **********************************************************/}
        <Button key="newBattleModal" type="default" onClick={()=>setNewBattleModalOpen(true)} icon={<RedoOutlined />}>New</Button>
        &nbsp;&nbsp;&nbsp;
        {battleState?.turn===BATTLE_TURN_PLAYERS && <Button key="playersDone" type="default" onClick={()=>playersAreDone()} icon={<StepForwardOutlined />}>Players are done</Button>}
        {battleState?.turn===BATTLE_TURN_ENEMIES && <Button key="enemiesDone" type="default" onClick={()=>enemiesAreDone()} icon={<StepForwardOutlined />}>Done moving enemies</Button>}
        &nbsp;&nbsp;&nbsp;
        <Button onClick={()=>setAddEnemyModalOpen(true)} icon={<PlusCircleOutlined />}> Add Enemy</Button>
        &nbsp;&nbsp;&nbsp;
        <Button onClick={()=>setBattleStateModalOpen(true)}>⚙ Battle State JSON</Button>

        {/* New Battle Modal ***************************************************/}
        <Modal
            title="New Battle"
            open={isNewBattleModalOpen}
            onOk={()=>setNewBattleModalOpen(false)}
            onCancel={()=>setNewBattleModalOpen(false)}
            width="100vw"
            style={{ top: 20 }}
            maskClosable={false}
            footer={[
                // <Button
                // key="link"
                // type="link"
                // href="https://docs.google.com/document/d/1tC9-O242zeWVzZoXZiHP6f75U565Pykp5oPIas3276c/preview?rm=demo"
                // target="_blank"
                // //@ts-ignore
                // icon={<QuestionCircleOutlined />}
                // >Help</Button>,
                <Button key="submit" type="primary" onClick={newBattle} disabled={!isNewBattleInputValid()}>New</Button>,
            ]}
        >
            Players &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<InputNumber placeholder="Number of players" value={numPlayers} onChange={(value:number|null)=>{if(value!=null)setNumPlayers(value)}}/><br/><br/>
            Level &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<InputNumber placeholder="Avg Player Level" value={gameLevel} onChange={(value:number|null)=>{if(value!=null)setGameLevel(value)}}/><br/><br/>
            Difficulty <Select
                value={battleDifficulty}
                style={{ width: 240 }}
                onChange={setBattleDifficulty}
                options={BATTLE_DIFFICULTY_OPTIONS}
                /><br/>
            Tokens <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                value={tokenTypes}
                onChange={setTokenTypes}
                options={[
                    { value: 'color', label: 'Colors' },
                    { value: 'letter', label: 'Letters' },
                    { value: 'number', label: 'Numbers' }
                ]}
                /><br/>
        </Modal>
            
        {/* Add Enemy Modal ***************************************************/}
        <SearchModal isSearchModalOpen={isAddEnemyModalOpen} setSearchModalOpen={setAddEnemyModalOpen} callback={addEnemyFromModal} filters='type:Enemy' title="Add new enemy"/>

        {/* Battle State Modal *************************************************************/}
        <Modal
            title="Battle State JSON"
            open={isBattleStateModalOpen}
            onOk={()=>setBattleStateModalOpen(false)}
            onCancel={() =>setBattleStateModalOpen(false)}
            width="100vw"
            style={{ top: 20 }}
            footer={[
                // <Button
                // key="link"
                // type="link"
                // href="https://docs.google.com/document/d/1tC9-O242zeWVzZoXZiHP6f75U565Pykp5oPIas3276c/preview?rm=demo"
                // target="_blank"
                // //@ts-ignore
                // icon={<QuestionCircleOutlined />}
                // >Help</Button>,
                <Button key="clear" onClick={()=>setBattleState(DEFAULT_BATTLE_STATE)}>Clear</Button>,
                <Button key="submit" type="primary" onClick={()=>setBattleStateModalOpen(false)}>Close</Button>,
            ]}
        >
            <div className="my-svelte-editor-only-thing-in-modal">
            <SvelteJSONEditor key="svelteJSONEditor"
                content={{text:JSON.stringify(battleState)}}
                onChange={function(obj:any){
                    if (obj.json)
                        setBattleState(obj.json);
                    else if (obj.text)
                        setBattleState(JSON.parse(obj.text));
                }}
            />
            </div>
        </Modal>


        {/* Table that contains the enemies in battleState. */}
        <table className='battleTable'>
            <thead>
                <tr>
                    <th>Token</th>
                    <th>Type</th>
                    <th>HP</th>
                    {!isEnemiesTurn && battleState.round === 1 && <th>Initial Position</th>}
                    {/* TODO we may want to check if there are any defenses to print before printing this header. */}
                    {!isEnemiesTurn && battleState.round > 1 && <th>Defense</th>}
                    {isEnemiesTurn && <th>Actions</th>}
                </tr>
            </thead>
            <tbody>
                {battleState?.enemies?.map((enemy:EnemyBattleState, enemyIndex:number)=>{
                    if (enemy.hp<=0 && (enemy.roundKilled!==undefined && enemy.wasKilledOnEnemyTurn!=undefined && (battleState.round>enemy.roundKilled || enemy.wasKilledOnEnemyTurn!==isEnemiesTurn)))
                        return <></>;
                    const enemyJSONSchema = battleState.enemyTypes[enemy.type];
                    return <tr key={"enemyNumber"+enemyIndex}>
                        <td>
                            {/* Use a select to allow changes to the enemy.token during round 0 */}
                            {battleState.round === 1 && 
                                <Select
                                    showSearch
                                    value={enemy.token}
                                    style={{ width: 120 }}
                                    onChange={(newToken) => {
                                    const newEnemies = [...battleState.enemies];
                                    newEnemies[enemyIndex] = {
                                        ...newEnemies[enemyIndex],
                                        token: newToken
                                    };
                                    setBattleState({...battleState, enemies: newEnemies});
                                    }}
                                    options={tokenTypeOptions}
                                    maxCount={2}
                                    mode="tags"
                                />}
                            {battleState.round > 1 && enemy.token}
                            </td>
                        <td><Button type="link" onClick={()=>navigateToNote(enemyJSONSchema.id)}>{enemy.type}</Button></td>
                        <td><InputNumber className={enemy.hp >= 1000 ? 'battleNumInputWide' : 'battleNumInputNarrow'} value={enemy.hp} onChange={(newHp:number|null) => {
                            if (newHp===null)
                                return;
                            const newEnemies = [...battleState.enemies];
                            newEnemies[enemyIndex].hp = newHp;
                            if (newHp <= 0) {
                                newEnemies[enemyIndex].roundKilled = battleState.round;
                                newEnemies[enemyIndex].wasKilledOnEnemyTurn = isEnemiesTurn;
                            }
                            setBattleState({...battleState, enemies: newEnemies});
                            }}/>/{enemy.maxHp}</td>
                        {!isEnemiesTurn && battleState.round === 1 && <td>{enemy.initialPosition}</td>}
                        {!isEnemiesTurn && battleState.round > 1 && <td><div dangerouslySetInnerHTML={{__html: `${enemy.defense}` }}/></td>}
                        {isEnemiesTurn && <td
                                onClick={() => {
                                    const newEnemies = [...battleState.enemies];
                                    newEnemies[enemyIndex] = {
                                    ...newEnemies[enemyIndex],
                                    hasCompletedActions: !newEnemies[enemyIndex].hasCompletedActions
                                    };
                                    setBattleState({...battleState, enemies: newEnemies});
                                }}
                                style={{ cursor: "pointer" }}
                                >
                                {!enemy.hasCompletedActions && enemy.actions?.map((action:any, actionIndex:number)=><div
                                    key={enemyIndex+"/"+actionIndex}
                                    dangerouslySetInnerHTML={{
                                    __html: `${action.viz}&nbsp;${action.cmd}<br/>
                                        <p style="padding-left:40px;margin:0px"><i>${action.aud}</i></p>`
                                    }}
                                />)}
                                {enemy.hasCompletedActions && "✔"}
                        </td>}
                    </tr>;
                    })}
            </tbody>
        </table>
        {/* {JSON.stringify(battleState)} */}
        
    </div>
}