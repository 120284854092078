import { JSONSchemaType } from "ajv";
import { Archery1Arrow, Athletic3Run, Barbaric1AxeSwing, Barbaric3AxeSwing, Construct2Barricade, Destruct2Explode, Dragonborn3FireBreath, DustDevilsVeil1, Fighting1OneTwoPunch, Fighting3OneTwoPunch, Healing1HealingTouch, Healing3HealingTouch, JarBrain3TentacleAttack, Rest0 as Rest0Breather,  Knives3SneakyKnifeThrow,  Telepath2MindControl, Telepath3MindControl, FireWizardry1FireDart, IceWizardry1IceDart, IceWizardry3IceDart } from "./ReusableCards";
import { BoxingGloves, TentacleSpikes, cardAddEnhancements } from "./ReusableCardEnhancements";
import { DefenseCard, ReusableCard } from "./GameCardTypes";
import { DisguiseSkill, InfluenceSkill, OutdoorsSkill, PhysicalSkill, SensesSkill, StealthSkill } from "./SkillCards";
import { ExtensionJSONFormsObject } from "../../ExtensionsFramework/ExtensionsList";

const TsuiLetourneauSupporters = "7 Ogrukh (4 berserkers, 3 scouts), Alaric Eldridge Thorne (Master Alchemist of Sylvanholme), Elysia (Lensmaker of Sylvanholme)";

type EvenNumber = 0|2|4|6|8|10|12|14|16|18|20|22|24|26|28|30|32|34|36|38|40|42|44|46|48|50;

// Root type includes everything besides cards. When stored in JSON, we store card names. This can be converted to the cards themselves.
export type PlayerCharacterRootType = {
    playerName:string,
    characterName:string,
    role?:string,

    earnedXP:number,
    earnedGold:number,
    
    addedLife: EvenNumber,
    miscItems?:string,
    supporters?:string,

}
export type PlayerCharacterJSONType = PlayerCharacterRootType & ExtensionJSONFormsObject & {
    reusableCardIDs: {
        cardID:string,
        reusableCardEnhancementIDs: string[],
    }[],
    defenseCardIDs:string[],
};
export type PlayerCharacterInMemoryType = PlayerCharacterRootType & {
    reusableCards:ReusableCard[],
    defenseCards:DefenseCard[],
};

export const PlayerCharacterSchema:JSONSchemaType<PlayerCharacterJSONType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},

        playerName: {type: "string", nullable: false},
        characterName: {type: "string", nullable: false},
        role: {type: "string", nullable: true},
        earnedXP: {type: "number", nullable: false, minimum: 0},
        earnedGold: {type: "number", nullable: false, minimum: 0},
        addedLife: {type: "number", nullable: false, minumum: 0},
        reusableCardIDs: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    cardID: {type: "string", nullable: false},
                    reusableCardEnhancementIDs: {
                        type: "array",
                        items: {type: "string"},
                        nullable: false,
                    }
                },
                required: [],
            },
            nullable: false,
        },
        miscItems: {type: "string", nullable: true},
        supporters: {type: "string", nullable: true},
        defenseCardIDs: {
            type: "array",
            items: {type: "string"},
            nullable: false,
        }
    },
    required: [],
};

export const AllPlayerCharacters = [
    {
        // Template empty player
        playerName:"_____________",
        characterName:"____________",
        role: "",
        earnedXP:4, /*Each player starts with 4 XP. Ref PlayerFunctions.ts */
        earnedGold:0,
        addedLife: 0,
        reusableCards:[
            Rest0Breather,
            // Choose no more than 4 of the following:
            Archery1Arrow,
            DustDevilsVeil1,
            FireWizardry1FireDart,
            IceWizardry1IceDart,
            Barbaric1AxeSwing,
            Fighting1OneTwoPunch,
            Healing1HealingTouch,
            SensesSkill(1),
            StealthSkill(1),
        ],
        defenseCards: [],
        miscItems: "",
        supporters: ""
    },
    {
        playerName:"Carl",
        characterName:"Carfaxx Abbey",
        role: "Psionic Monk",        
        earnedXP:13, // Includes 1xp proactively for 6/30 game
        earnedGold:25+4,
        addedLife: 0,
        reusableCards:[
            /*Support*/Construct2Barricade, Athletic3Run, Rest0Breather,
            /*Skills */InfluenceSkill(1),PhysicalSkill(1),StealthSkill(1),
            /*Attacks*/Fighting3OneTwoPunch,Telepath2MindControl,
        ],defenseCards: [],
        miscItems: "1 Horse, 1 unidentified \"Disintegration Marble\", 1 unidentified color-shifting sphere",
        supporters: TsuiLetourneauSupporters
    },{
        playerName:"Noah",
        characterName:"Bronzo",
        role: "Shapechanger",
        earnedXP:13     /*8/5/2024*/ +1,
        earnedGold:25+4 /*8/5/2024*/ +4,
        addedLife: 4,
        reusableCards:[
            /*Support*/ Rest0Breather,
            /*Skills */ DisguiseSkill(3),
            /*Attacks*/ IceWizardry3IceDart,
                cardAddEnhancements(JarBrain3TentacleAttack,[TentacleSpikes]),
            /*Enhance*/ DustDevilsVeil1,
        ],defenseCards: [],
        miscItems: "1 Horse",
        supporters: TsuiLetourneauSupporters
    },{
        playerName: "Brandon",
        characterName: "Rarrr",
        role: "Barbaric Dragonborn",
        earnedXP:13,
        earnedGold: 25+4,
        addedLife: 4,
        reusableCards: [
            /*Support*/ Rest0Breather,
            /*Skills */ SensesSkill(3),
            /*Attacks*/ Barbaric3AxeSwing, Dragonborn3FireBreath, Destruct2Explode,
        ],defenseCards: [],
        miscItems: "1 Horse",
        supporters: TsuiLetourneauSupporters
    },{
        playerName: "Bradley",
        characterName: "Viking Guy",
        role: "Viking",
        earnedXP: 11        /*8/5/2024*/ +1,
        earnedGold: 17+4    /*8/5/2024*/ +4,
        addedLife: /*8/5/2024*/2,
        reusableCards: [
            /*Support*/ Rest0Breather,
            /*Skills */ PhysicalSkill(2), StealthSkill(2), /*8/5/2024*/DisguiseSkill(1),
            /*Attacks*/ Destruct2Explode, Knives3SneakyKnifeThrow,
        ],
        defenseCards: [/*Defense_Stealth3_SneakyKnifeThrow3*/],
        miscItems: "1 Horse",
        supporters: TsuiLetourneauSupporters,
     },{
        playerName: "Jason",
        characterName: "Wolfgang", // full name Wolfgang Von Expo
        role: "Mystic Healer",
        earnedXP: 11        /*8/5/2024*/ +1,
        earnedGold: 17+4    /*8/5/2024*/ +4,
        addedLife: 0,
        reusableCards: [
            /*Support*/ Rest0Breather, Healing3HealingTouch,
            /*Skills */ StealthSkill(2), OutdoorsSkill(2), /*8/5/2024*/InfluenceSkill(1),
            /*Attacks*/ Telepath3MindControl, FireWizardry1FireDart,
        ],defenseCards: [],
        miscItems: "1 Horse",
        supporters: TsuiLetourneauSupporters
     }
] as PlayerCharacterInMemoryType[];

