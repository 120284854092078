import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'antd';
import { FileAddOutlined, UploadOutlined, MenuOutlined, QuestionCircleOutlined, DeleteOutlined} from '@ant-design/icons';
import { useDeleteNoteCallback, useNewNote } from "../../Notes/Data/NoteDBHooks";
import { NotesContext } from "../../Notes/Data/NotesContext";
import { useAddNoteToParent } from "../../Notes/Data/Actions/Tree/ManipulateTree";
import { useNavigateToNote } from "../Utilities/NavigateTo";
import CommandResult from "./CommandResult";
import { getQuery, useQuery } from "../Utilities/URLQueryParams";
import { SelectedJSONFormsContext } from "../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { MenuProps } from "antd/lib";


export default function MainMenuDropdown() {
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);
  // For newNote :
  const newNote = useNewNote();
  // For operations on a single note, we get doc_id:
  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const note = selectedJsonFormsContext.note;
  const doc_id = note?.id;

  const deleteNoteCallback = useDeleteNoteCallback();
  const doc_name = note?.doc_name;
  const notesContext = useContext(NotesContext);
  const navigateToNote = useNavigateToNote();
  const addNoteToParent = useAddNoteToParent();
  // For deleting notes:
  const query = useQuery();
  const navigate = useNavigate();

  // If one note is selected, we can delete it:
  const selectedNoteIDs:string[] = query.getAll("notes");
  const oneOrManyNotesAreSelected = doc_id || selectedNoteIDs.length>0;

  
  async function onNewNoteClicked() {
    // Temporary new names
    const newNoteName = prompt("New note name","Untitled");
    if (!newNoteName) return; // Cancelled
    // TODO since this is now async, we could wait with a progress indicator.
    var newNoteProps:any = {};
    if (doc_id) {
      // If we are in a document, we want to create the new note in the same folder.
      // addNoteToParent handles this case, but adding here saves one update by putting the parent in create time.
      newNoteProps.parent = doc_id;
    }
    const note = await newNote(newNoteName, newNoteProps);
    // Now that we have the ID, we can add it to the parent:
    if (doc_id)
      addNoteToParent(note, doc_id);
    // Preserve Search query, etc., but navigate to the new note:
    navigateToNote(note.id);
    notesContext.notesHaveBeenLoaded([note]); // so it shows in the tree, etc.
  }
  async function onDeleteNoteClicked() {
    // Single document delete:
    const freshQuery = getQuery();
    if (doc_id) {
      if (window.confirm("Are you sure you want to delete "+doc_name+"?")) {
        deleteNoteCallback(doc_id);
        // Make sure the note gets deleted from the search context:
        // notesContext.removeNote(doc_id);
        // Go back to a no selection page, preserve query      
        navigate("/?"+freshQuery.toString(), { replace: true });
      }
    } else if (selectedNoteIDs.length>0) {
      if (window.confirm("Are you sure you want to delete "+selectedNoteIDs.length+" notes?")) {
        for (const doc_id of selectedNoteIDs) {
          deleteNoteCallback(doc_id);
          // Make sure the note gets deleted from the search context:
          // notesContext.removeNote(doc_id);
        }
        freshQuery.set("notes", "");
        navigate("/?"+freshQuery.toString(), { replace: true });
      }
    } else {
      console.error("We should not be able to reach this line!");
      debugger;
    }
  }

  const menuItems:MenuProps['items'] = [
    {
      key: "0",
      onClick: ()=>{onNewNoteClicked()},
      icon: <FileAddOutlined/>,
      label: "New Note"
    },
    {
      key: "BulkUpload",
      onClick: function(){setIsBulkUploadModalOpen(true);},
      icon: <UploadOutlined />,
      label: "Bulk upload"
    }
  ];
  if (oneOrManyNotesAreSelected) {
    menuItems.push({
      key: "Delete",
      icon: <DeleteOutlined/>,
      onClick: onDeleteNoteClicked,
      label: "Delete "+selectedNoteIDs.length+" Note"+(selectedNoteIDs.length>0 ? "s" : "")
    });
  }
  menuItems.push({
    key: "Help",
    icon: <QuestionCircleOutlined />,
    label: <a href="https://drive.google.com/drive/folders/1E4PNY3NYXuqHWxa86tLWdBThOG5W4_sU"  target="_blank" rel="noreferrer">Help</a>
  });

  return <>
    <Dropdown trigger={['click']} menu={{items:menuItems}}>
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()} href="ignoreerrors">
        <MenuOutlined />
      </a>
    </Dropdown>
    {isBulkUploadModalOpen && <CommandResult closeNowFunc={function(){setIsBulkUploadModalOpen(false)}}
      key="BulkUpload" commandType="BulkUpload"/>}
    {/* <BulkUploadModal isModalOpen={isBulkUploadModalOpen} setIsModalOpen={setIsBulkUploadModalOpen}/> */}
  </>
}