import { Firestore } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { howManyNotesNamed, newNote, saveNote } from "../../NoteDBHooks";
import { NotesContextType } from "../../NotesContext";
import { Note, PartialJSONFormsObject, PartialNote } from "../../NoteType";
import { JSONFormsObject, getJSONFormsObject, saveJSONFormsObject } from "./LoadAndSaveJSONFormsObject";


export type JSONFormsObjectDiff = {
  "numberWithName":number;
  "storedData":PartialJSONFormsObject | null;
  "newData":PartialJSONFormsObject;
  "isSame":boolean | null;
  "note_id":string | null;
};

export async function compareJsonFormsObject(firestore:Firestore, data:PartialJSONFormsObject):Promise<JSONFormsObjectDiff> {
  const {numberWithName, noteIds} = await howManyNotesNamed(firestore, data.name);

    const toReturn = {
      numberWithName,
      storedData: null,
      newData:data
    } as JSONFormsObjectDiff;

    if (toReturn.numberWithName>1) {

    } else if (toReturn.numberWithName===1) {
      // The stored data is another path, this is particularly tricky.
      toReturn.note_id=noteIds[0];
      const objectValue = await getJSONFormsObject(firestore, toReturn.note_id);
      if (!objectValue) {
        // It's an empty object! nothing to diff.
        toReturn.isSame=false;
      } else {
        let {id:storedId, name:storedNameUseless, ...storedDataForComparison} = objectValue;
        // Don't compare fields that aren't used at save time:
        let {name, ...newDataForComparison} = data;
        toReturn.storedData={name, ...storedDataForComparison} as PartialJSONFormsObject;
        // Check each property that would be overwritten.
        toReturn.isSame=true;
        for (const property in newDataForComparison) {
          if (!storedDataForComparison.hasOwnProperty(property)) {
            toReturn.isSame=false;
            break;
            //@ts-ignore
          } else if (!_.isEqual(newDataForComparison[property],storedDataForComparison[property])) {
            toReturn.isSame=false;
            break;
          }
        }
      } 
    } else {
      // It's new!
    }
    // Return lots of information.
    return toReturn;
}


export function useCompareJSONFormsObjectCallback() {
    const firestore = useFirestore();
  
    async function compare(data:PartialJSONFormsObject):Promise<JSONFormsObjectDiff> {
        return await compareJsonFormsObject(firestore,data);
    }
    return compare;
}

export type JSON_SAVE_ITEM = {doc_id:string,saveJSONFormsObject:(jsonFormsObject:JSONFormsObject)=>void};
export type JSON_SAVE_FUNCTIONS_ARRAY = JSON_SAVE_ITEM[];

export async function uploadDiffs(firestore:Firestore, 
    parentID:string, diffs:JSONFormsObjectDiff[],
    type:string, notesContext:NotesContextType,
    setIntermediateProgress:any, setUploadDone:any, jsonSaveFunctions?:JSON_SAVE_FUNCTIONS_ARRAY) {
    // Load the parent (so we can save the children under it)
    const parentNote = await notesContext.getNote(parentID);
    if (!parentNote)
      throw new Error("Parent note not found in uploadDiffs. If it was present before, it has already been deleted.");
    let childrenIDs = [] as string[];
    if (parentNote.children && parentNote.children.length>0)
        childrenIDs = [...parentNote.children];

    const newNotesCreated = [];
    const noteIDsChanged = [];
    for (let i=0; i<diffs.length; i++) {
      const diff=diffs[i];
      if (diff.isSame)
        continue;
      if (diff.numberWithName===0) {
        // It's new! Add it. There's quite a few steps here.
        // Create the note:
        const note = await newNote(firestore, diff.newData?.name,{type:type,parent:parentID});
        // We may need to wait for it to be confirmed stored.
        // Set the Content:
        await saveJSONFormsObject(firestore, note.id, diff.newData);
        
        // Warning -- if we break in the middle, we won't save the children, so they won't show in the tree... :(
        childrenIDs.push(note.id);
        newNotesCreated.push(note);
      } else {
         // It's changed! Save the change.
        if (diff.note_id) {
          let found = undefined as JSON_SAVE_ITEM | undefined;
          if (jsonSaveFunctions) {
            found = jsonSaveFunctions.find((item)=>item.doc_id===diff.note_id);
            if (found) {
              // TODO fix this typescript error, we'd prefer to not be using PartialJSONFormsObject here, and instead using the right object.
              //@ts-ignore
              found.saveJSONFormsObject(diff.newData);
            }
          }
          if (!found) 
            await saveJSONFormsObject(firestore, diff.note_id, diff.newData);
        } else {
            // should be impossible to reach here, but typescript disagrees.
            debugger;
        }
        noteIDsChanged.push(diff.note_id);
      }
      setIntermediateProgress(i+1,diffs.length);
    }
    // We have to also add them all as a child to the parent to make sure we can find it in the tree
    const parentToSave = {...parentNote, id:parentID,children:childrenIDs} as Note;
    saveNote(firestore, parentToSave);
    notesContext.notesHaveBeenLoaded(newNotesCreated);
    notesContext.mergeChangesIntoLoadedNote(parentID,{children:childrenIDs} as PartialNote);
    // When completely done:
    setUploadDone(parentID,newNotesCreated, noteIDsChanged);
}