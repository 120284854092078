import React from "react"
import { Route, Routes } from "react-router-dom";

import { Layout, Avatar } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';


import TestGitGraph from "./TestGitGraph";


const { Header, Content } = Layout;

export default function StoriesPage() {
  // const tagTreeRef = useRef();
  // const [siderCollapsed,setSiderCollapsed] = useState(false/*using the default from the slider, it will change this to collapsed if needed*/);
  // const [sider2Collapsed,setSider2Collapsed] = useState(false/*using the default from the slider, it will change this to collapsed if needed*/);

  //@ts-ignore
  // const userId = useSelector((state) => state.firebase.auth.uid);
  /***********
   * Firestore load
   * This needs to be done in one place.
   * We have a bunch of problems if we initialize this in multiple places, including:
   * - corrupted & rapidly changing redux store if some things subscribe to less data in one place -- another may find that only partial data was loaded from the last run.
   */
  // useFirestoreConnect([
  //   { collection: 'Characters'}, // load all characters so we can use them anywhere.
  //   // { collection: 'ByUserData', doc:userId, subcollections: [{ collection: "UIState"}] } // Subscribe to this user's information.
  // ])

  //@ts-ignore
  // const { story_id, character_id } = useParams();
  //@ts-ignore
  // const doc_name = useSelector((state) => GetPropFromState(state,"firestore.data.Notes."+doc_id+".doc_name"));
  // const pageTitle = doc_name; //|| (hashtag_id?("#"+hashtag_id):"Decision Notes App");
  const pageTitle = "Story Editor"


  const tabBarExtraContent=[
    // <NewDropdownMenu/>,<span style={{minWidth:"10px"}}>&nbsp;&nbsp;&nbsp;</span>,
    // <DeleteNoteButton/>,<span style={{minWidth:"10px"}}>&nbsp;&nbsp;&nbsp;</span>,
    <Avatar
      // src={<Image src={photoURL}/>}
      key="avatarInHeader"
    />];

  return <Layout>
    <Header className="OverrideAntBg" style={{height:"75px"}}>
        <PageHeader
        className="site-page-header"
        // onBack={() => null}
        title={pageTitle}
        // subTitle={doc_id}
        extra={tabBarExtraContent}
        />
      </Header>
    <Layout>
      <Content>
      <TestGitGraph/>
      </Content>
    </Layout>
  </Layout>
}
