import {useCallback} from 'react'
import { doc, setDoc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { TagTreeState } from './TagTreeStateType';
import { getAuth } from 'firebase/auth';

export const TagTreeState_doc_name = "TagTreeState";

// When loading is not complete, currentUser is null, which wreaks havoc with hooks.
// This is complicated: https://github.com/FirebaseExtended/reactfire/issues/423
export function useUserTagTreeStateDocRef() {
  const firestore = useFirestore();
  //@ts-ignore it's never null because of FirestoreLogin wrapper.
  const uid = getAuth().currentUser.uid;
  const tagTreeStateRef = doc(firestore, "ByUserData", uid, "UIState", TagTreeState_doc_name);
  return tagTreeStateRef;  
}

export function useUserTagTreeState():[TagTreeState|undefined,{saveTagTreeState:(doc_data: any) => Promise<void>}] {
  const tagTreeStateRef = useUserTagTreeStateDocRef();

  // Create handlers
  async function saveTagTreeState(doc_data:any) {
    if (!tagTreeStateRef)
      return; // should not be reachable.
    const fullDocToSave = {doc_data};
    await setDoc(tagTreeStateRef, fullDocToSave);
  }
  const handlers = {saveTagTreeState:useCallback(saveTagTreeState, [tagTreeStateRef])};

  const {data:tagTreeState} = useFirestoreDocData(tagTreeStateRef, { idField: "id" });
  // When loading is not complete, tagTreeState === undefined
    
  // return array of [data, handlers] to match hooks like useState
  return [tagTreeState as TagTreeState, handlers];
}
export function useUserData() {
  const firestore = useFirestore();
  let uid;
  let isLoggedIn=false;
  if (getAuth().currentUser) {
    //@ts-ignore it's never null
    uid = getAuth().currentUser.uid;
    isLoggedIn=true;
  } else
    uid = "NotLoggedIn";
  // const uid = getAuth().currentUser.uid;
  const ref = doc(firestore, "ByUserData", uid);
  const byUserData = useFirestoreDocData(ref, { idField: "id" });

  const {data:userInfo} = byUserData;

  if (!isLoggedIn)
    return {internetIsDown:false,isMember:false};

  // While i do get successes, there's a bug somewhere that's causing us not to get so far as to get failures. I'm not sure why.
  if (byUserData.status==="success" && !byUserData.isComplete && !userInfo) {
    console.error("useUserData() userInfo is null. The internet may be down causing false negatives here.",userInfo, byUserData);
    return {isLoading: false, internetIsDown:true, isMember:false};
  }
  return {isLoading: !userInfo, internetIsDown:false, isMember: userInfo?.member};
}