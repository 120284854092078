import { ReplicaVoice, ReplicaVoiceStyle } from "./ReplicaAPI";
import { LLMServerContext } from '../../ChatEditor/UI/SelectableLLM';
import { ChatAPIOptions } from "../../../ServerConnection/LLMServer/GenericChatServer";
import { useStateLSJSON } from "../StateWithLocalCache";
import { useContext } from "react";
import { ChatCompletionMessageParam } from "openai/resources";

const DEBUG = false;

type CachedVoiceStyle = {
    uuid: string;
    map: {[text:string]:string};
};

export function useRecommandAVoiceStyle() {
    const {callChatAPI} = useContext(LLMServerContext);
    // At least the same user/system combo won't get different style recommendations each time, it'll pull from the cache if it's the same sentence.
    const [cachedVoiceStyles, setCachedVoiceStyles] = useStateLSJSON("recommendedVoiceStyle",{} as CachedVoiceStyle);

    async function recommendAVoiceStyleCommand(text:string, voice:ReplicaVoice,setSelectedStyle:(style_name:string, speaker_id:string)=>void) {
        if (!callChatAPI) {
            debugger;
            console.error("[useRecommandAVoiceStyle] Error: callChatAPI is not defined, even though it should be in the provider.");
            return;
        }
        const cache = cachedVoiceStyles[voice.uuid] || {};
        const cachedStyleName = cache[text];
        if (cachedStyleName) {
            // Look up the style, confirm it's still there in voices.
            const style = voice.styles.find((style:ReplicaVoiceStyle) => style.name === cachedStyleName);
            if (style) {
                if (DEBUG) console.log("[useRecommandAVoiceStyle] Using cached style for text=",text,"voice_name=",voice.name,"cachedStyle=",cachedStyleName);
                setSelectedStyle(cachedStyleName, style.speaker_id);
                return;
            }
        }

        let styleOptions = voice.styles;
        styleOptions = styleOptions.map((style: ReplicaVoiceStyle) => {
            let {speaker_id, name} = style;
            return {speaker_id, name} as ReplicaVoiceStyle;
        });
        // const styleOptions = voice.styles.map((style: ReplicaVoiceStyle) => {
        //     return style.name;
        // });

        const stylesString = JSON.stringify(styleOptions);
        let systemPrompt = "Recommend the single best style when speaking aloud the following text, based on the available styles. Return a simple JSON object with the style's name and speaker_id, for example: {name:\""+voice.default_style.name+"\", speaker_id:\""+voice.default_style.speaker_id+"\"}. The possible styles are: "+stylesString;
        let userMessage = "Recommend the single best style for: \""+text+"\"";

        const messages = [
            {content:systemPrompt,role:"system"},
            {content:userMessage,role:"user"}
        ] as ChatCompletionMessageParam[];

        await callChatAPI(messages,function(fullOutput:string, newOutput:string, isDone:boolean) {
            if (DEBUG) console.log("[useRecommandAVoiceStyle] fullOutput=",fullOutput,"newOutput=",newOutput,"isDone=",isDone);
            if (isDone) {
                const output = JSON.parse(newOutput) as {name:string, speaker_id:string};
                // Check if the output is one of the styleOptions:
                if (styleOptions.filter((style:ReplicaVoiceStyle) => style.name === output.name).length===0) {
                    // Nope. We're going to give up and go with the default for this voice.
                    console.error("[useRecommandAVoiceStyle] Error: The bot recommended a style, '"+output.name+"' that is not in the list of available styles. Possible choices: , so we'll use the default ("+voice.default_style.name+") , for this voice. text=",text,"systemPrompt=",systemPrompt,"output=",output,"styleOptions=",styleOptions);
                    setSelectedStyle(voice.default_style.name, voice.default_style.speaker_id);
                    cache[text] = voice.default_style.name;
                    setCachedVoiceStyles({...cachedVoiceStyles,[voice.uuid]:cache});
                } else {
                    if (DEBUG) console.log("[useRecommandAVoiceStyle] completed recommendation for ",text,output);
                    if (output.speaker_id) {
                        setSelectedStyle(output.name as string, output.speaker_id as string);
                        cache[text] = voice.default_style.name;
                        setCachedVoiceStyles({...cachedVoiceStyles,[voice.uuid]:cache});
                    }
                }
            }
        },function(error: string) { // Add type annotation for the error parameter
            console.error("[useRecommandAVoiceStyle] Error in recommniding a style: ",error);
        },{jsonMode:true} as ChatAPIOptions);
    }
    return {recommendAVoiceStyleCommand};
}