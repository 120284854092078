import { JSONSchemaType } from "ajv";
import { ExtensionJSONFormsObject } from "../ExtensionsFramework/ExtensionsList";

export type EnemyAction = {
    aud: string
    viz: string
    cmd: string
};

export const EnemyActionSchema:JSONSchemaType<EnemyAction> = {
    type: "object",
    properties: {
        aud: {type: "string", nullable: false},
        viz: {type: "string", nullable: false},
        cmd: {type: "string", nullable: false}
    },
    required: []
};

export type Enemy = ExtensionJSONFormsObject & {
    // Inherited:
    // name: string,
    // id: string,
    // version: number,

    defaultDefense: string,
    maxHpLevel: number,
    damageLevel: number,

    actionsByRoundNum: {roundNum:number,action:EnemyAction[]}[],
    randomActions: {action:EnemyAction[]}[]
    lowHPActions: {action:EnemyAction[]}[]
};

export const DefaultEnemy:Enemy = {
    name: "",
    id: "",
    version: 0,
    defaultDefense: "",
    maxHpLevel: 1,
    damageLevel: 1,
    actionsByRoundNum: [],
    randomActions: [],
    lowHPActions: []
};

export const EnemySchema:JSONSchemaType<Enemy> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},
        defaultDefense: {type: "string", nullable: false},
        maxHpLevel: {type: "number", nullable: false},
        damageLevel: {type: "number", nullable: false},
        actionsByRoundNum: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    roundNum: {type: "number", nullable: false},
                    action: {
                        type: "array",
                        items: EnemyActionSchema,
                        nullable: false
                    }
                },
                required: []
            },
            nullable: false
        },
        randomActions: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    action: {
                        type: "array",
                        items: EnemyActionSchema,
                        nullable: false
                    }
                },
                required: []
            },
            nullable: false
        },
        lowHPActions: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    action: {
                        type: "array",
                        items: EnemyActionSchema,
                        nullable: false
                    }
                },
                required: []
            },
            nullable: false
        }
    },
    required: [],
};

