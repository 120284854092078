import { Layout } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import StoriesPage from "../Stories/StoriesPage";
import TestSvelteJsonEditorPage from "./TestSvelteJsonEditorPage";
import { AlgoliaSearchTestPage } from "./AlgoliaSearchTest";
import DraftJSTestPage from "./DraftJsTestPage";
import { Link } from "react-router-dom";
import DraftJSPluginsTestPage from "./DraftJsPluginsTestPage";
import { FirestoreTestPage } from "./FirestoreTestPage";
import React, { useEffect } from "react";
import SoundTestPage from "./SoundTestPage";
import ReplicaAPITestPage from "./ReplicaAPITestPage";
import { GLOBAL_DEBUG_INLINE_TESTS, GLOBAL_DEBUG_OUTPUT, GLOBAL_DEBUG_USE_LOCAL_SERVER_FUNCTIONS } from "../GlobalDebug";

import { TopicDetectionV7TestPage } from "./TopicDetectionV7TestPage";
import GameCardsTestPage from "./GameCardTestPage";
import ChatWithDraftTestPage from "./ChatWithDraftTestPage";
import { TemplateDetectionV8TestPage } from "./TemplateDetectionV8TestPage";
import ChatWithTemplateDraftTestPage from "./ChatWithTemplateDraftTestPage";

const { Content } = Layout;

export default function TestPages() {
    const navigate = useNavigate();
    const location = useLocation();
    const isOnTestListPage = location.pathname==="/tests";
    const isOnPageWithNoHeader = location.pathname==="/tests/GameCards";

    useEffect(() => {
        if (isOnTestListPage) // only the home page gets its unique title because individual pages may override this
            document.title = 'Tests Page - AI Got This';
      }, []);

    if (isOnPageWithNoHeader)
        return <Routes>
                <Route path="/GameCards" element={<>
                    <GameCardsTestPage/>
                </>}/>
                </Routes>;

    return <Layout className="site-layout">
        <PageHeader
          title="Testing Pages"
          className="site-page-header"
          subTitle={isOnTestListPage?"For developers only":location.pathname.substring(7)}
          onBack={isOnTestListPage?undefined:function(){navigate("/tests")}}
          // tags={<Tag color="purple">not yet signed in</Tag>}
          />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Routes>
                <Route path="/DraftJS" element={<>
                    <DraftJSTestPage/>
                </>}/>
                <Route path="/DraftJSPlugins" element={<>
                    <DraftJSPluginsTestPage/>
                </>}/>
                <Route path="/stories" element={<>
                    <StoriesPage/>
                </>}/>
                <Route path="/jsoneditor" element={<>
                    <TestSvelteJsonEditorPage/>
                </>}/>
                <Route path="/algolia" element={<>
                    <AlgoliaSearchTestPage/>
                </>} />
                <Route path="/firestore" element={<>
                    <FirestoreTestPage/>
                </>} />
                <Route path="/DraftChat" element={<>
                    <ChatWithDraftTestPage/>
                </>} />
                <Route path="/Sound" element={<>
                    <SoundTestPage/>
                </>}/>
                <Route path="/ReplicaAPI" element={<>
                    <ReplicaAPITestPage/>
                </>}/>
                <Route path="/TopicDetectionV7" element={<>
                    <TopicDetectionV7TestPage/>
                </>}/>
                <Route path="/TemplateDetectionV8" element={<>
                    <TemplateDetectionV8TestPage/>
                </>}/>
                <Route path="/ChatWithTemplateDraft" element={<>
                    <ChatWithTemplateDraftTestPage/> 
                </>}/>
                <Route path="/GameCards" element={<>
                    <GameCardsTestPage/>
                </>}/>


                <Route path="*" element={<>
                    <h3>Raw Components</h3>
                    <Link to="/tests/DraftJS">DraftJS</Link><br/>
                    <Link to="/tests/DraftJSPlugins">DraftJS Plugins</Link><br/>
                    <Link to="/tests/stories">Stories & Story Graph</Link><br/>
                    <Link to="/tests/jsoneditor">Svelte JSON Editor (aka "vanilla-jsoneditor" package)</Link><br/>
                    <Link to="/tests/Sound">Sound Player Test</Link><br/>
                    <br/>

                    <h3>Server connected components</h3>
                    <Link to="/tests/algolia">Algolia Search</Link><br/>
                    <Link to="/tests/firestore">Firestore</Link><br/>
                    <Link to="/tests/ReplicaAPI">Replica API</Link><br/>
                    <br/>

                    <h3>Chat Components</h3>
                    <Link to="/tests/DraftChat">Chat with Draft</Link><br/>

                    <h3>AI Components</h3>
                    <Link to="/tests/TopicDetectionV7">Topic Detection V7</Link><br/>
                    <Link to="/tests/TemplateDetectionV8">Template Detection V8</Link><br/>
                    <Link to="/tests/ChatWithTemplateDraft">Chat with Template Draft</Link><br/>
                    <br/>

                    <h3>Game Components</h3>
                    <Link to="/tests/GameCards">Game Cards</Link><br/>
                    <br/>

                    <br/><br/><br/>
                    <div>React version: {React.version}</div>
                    <div>Debugging flags:
                        <li>Global debug output: {JSON.stringify(GLOBAL_DEBUG_OUTPUT)}</li>
                        <li>Global inline tests: {JSON.stringify(GLOBAL_DEBUG_INLINE_TESTS)}</li>
                        <li>Local firebase functions: {JSON.stringify(GLOBAL_DEBUG_USE_LOCAL_SERVER_FUNCTIONS)}</li>
                    </div>
                </>}/>
            </Routes>

          </div>
        </Content>
    </Layout>
}
