import React from 'react';
import './App.css';
import FirebaseLogin from './FirebaseLogin';
import { collection, setDoc, query, getDocs, getDoc, getFirestore, doc, where } from 'firebase/firestore';

import { AuthProvider, FirebaseAppProvider, FirestoreProvider, FunctionsProvider, StorageProvider, useFirebaseApp } from 'reactfire';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { GLOBAL_DEBUG_USE_LOCAL_SERVER_FUNCTIONS } from '../GlobalDebug';

/*************************************
 * Setup Firebase & Firestore
 */
 export const firebaseConfig = {
  apiKey: "AIzaSyBZTq9_zaGHojeFQULCRK5ltQoKI0TJ1nQ",
  authDomain: "lofty-door-320319.firebaseapp.com",
  projectId: "lofty-door-320319",
  storageBucket: "lofty-door-320319.appspot.com",
  messagingSenderId: "412042669578",
  appId: "1:412042669578:web:e7aa5c92038fe72580b9f3",
  measurementId: "G-YK7LP15236"
};

// Initialize firebase instance
// Is it initialized in reactFire?
initializeApp(firebaseConfig);

// // Initialize firestore.
// // Save it to be accessible from the console so we can try things out.
const db = getFirestore();
//@ts-ignore
window.firestore=db;
//@ts-ignore
window.collection=collection;
//@ts-ignore
window.query=query;
// window.addDoc=addDoc;
//@ts-ignore
window.getDocs=getDocs;
//@ts-ignore
window.getDoc=getDoc;
//@ts-ignore
window.setDoc=setDoc;
//@ts-ignore
window.doc=doc;
//@ts-ignore
window.where=where;

// enableIndexedDbPersistence(db).catch((err:any) => {
//   if (err.code === 'failed-precondition') {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//   } else if (err.code === 'unimplemented') {
//     // The current browser does not support all of the
//     // features required to enable persistence
//   }
// });

// This is separate from App because of reactfire's approach for setting up a provider then a hook inside. https://github.com/FirebaseExtended/reactfire
function AppInner() {
  const firestoreApp = useFirebaseApp();
  const firestore = getFirestore(firestoreApp);
  const auth = getAuth(firestoreApp);
  const storage = getStorage(firestoreApp);
  const functions = getFunctions(firestoreApp,"us-east4");
  // TODO the function emulator is not working. We're not sure why, but it's an error in the server side code.
  if (GLOBAL_DEBUG_USE_LOCAL_SERVER_FUNCTIONS) connectFunctionsEmulator(functions, "localhost", 5001);
  return (
    <FirestoreProvider sdk={firestore}>
      <StorageProvider sdk={storage}>
        <AuthProvider sdk={auth}>
          <FunctionsProvider sdk={functions}>
              <div className="App">
                <FirebaseLogin/>
              </div>
          </FunctionsProvider>
        </AuthProvider>
      </StorageProvider>
    </FirestoreProvider>
  );
}


export default function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AppInner/>
    </FirebaseAppProvider>
  );
}