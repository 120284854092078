import { Firestore } from "firebase/firestore";
import { PartialJSONFormsObject } from "../NoteType";
import { compareJsonFormsObject, JSONFormsObjectDiff } from "./JSONFormsObject/DiffJsonFormsObject";


export async function validateBulk(firestore:Firestore, json:PartialJSONFormsObject[], setInfoMessage?:(info:string)=>void ) {
    // Be able to share back info about these objects.
    let numToAdd = 0;
    let numNoChanges = 0;
    let numWithChanges = 0;
    
    const diffs = [] as JSONFormsObjectDiff[];
    for (let i = 0; i < json.length; i++) {
        const data = json[i] as PartialJSONFormsObject;
        // console.log("Checking "+data.name);
        // TODO make into .then() to have incremental loading, e.g.:
        //   compareJsonFormsObject(firestore, data).then(function(diff:JSONFormsObjectDiff){})
        // We'd also need to wait for them all to complete, so combine that with a Promises.all at the end I think?

        const diff = await compareJsonFormsObject(firestore, data);
        if (diff.numberWithName>1) {
            return {hasError:true, message:
                "There are "+diff.numberWithName+" notes named '"+data.name+"'. You can't overwrite those until you rename one of them to something else."};
        }
        if (diff.numberWithName===0) {
            numToAdd++;
            diffs.push(diff);
        } else if (diff.isSame) {
            numNoChanges++;
        } else {
            numWithChanges++;
            diffs.push(diff);
        }
        if (setInfoMessage)
            if ((i+1) % 20 === 0) {
                setInfoMessage("So far, will add "+numToAdd+", change "+numWithChanges+", and "+numNoChanges+" haven't changed so will be ignored.");
            }
        }
        if (diffs.length===0) {
            return {hasError:false, hasContent:false, message:"All "+json.length+" items were the same. Nothing would be changed."};
        } else {
        let infoMessage = "";
        if (numToAdd>0) {
            infoMessage+="Add: "+numToAdd+"\n";
        }
        if (numWithChanges>0) {
            infoMessage+="Change: "+numWithChanges+"\n";
        }
        if (numNoChanges>0) {
            infoMessage+="Stay the same: "+numNoChanges+"\n";
        }
        return {hasError:false, hasContent:true,diffs,message:infoMessage};
    }
}