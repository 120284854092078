import { Hits, InstantSearch, Menu, SearchBox } from 'react-instantsearch';
import { useLinkToNoteId } from '../DecisionGraph/Utilities/NavigateTo';
import { AlgoliaSearchClient } from '../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient';

function Hit({ hit: noteSearchResult }:{hit:any}) {
    const linkToNote = useLinkToNoteId();
    return (
      <article>
        <p>{linkToNote(noteSearchResult.objectID,noteSearchResult.doc_name)}</p>
      </article>
    );
  }

export function AlgoliaSearchTestPage() {

    return (
        <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true} >
        <SearchBox/>
        <Menu attribute="type" />
        <Hits hitComponent={Hit} />
        </InstantSearch>
    );
}