import { EditorState, convertFromRaw, convertToRaw } from "draft-js"
import { useMemo, useState } from "react";
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import "../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/editorStyles.css";
import "../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { InstantSearch } from "react-instantsearch";
import { AlgoliaSearchClient } from "../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient";
import { Layout } from "antd";
import { useStateLSBoolean } from "../DecisionGraph/Utilities/StateWithLocalCache";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from "../Notes/UIs/DraftJSEditor/DraftKeyboardBindings";
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from "../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns";
import { useExtensions } from "../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins";
import { DraftJSOutline } from "../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJSOutlineSidebar";


const DEFAULT_DRAFT_DOCUMENT = {
  "blocks": [
      {
          "key": "3sh82",
          "text": "Document Title (H1)",
          "type": "header-one",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "cjp98",
          "text": "text in section 1",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "futgi",
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "7cgqh",
          "text": "Section 2 (H2)",
          "type": "header-two",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "5bajh",
          "text": "text in section 2",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "7h9na",
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "1f76o",
          "text": "Section 3 (H2)",
          "type": "header-two",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "5p2c6",
          "text": "text in section 3",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "bmeup",
          "text": "",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "5e8bd",
          "text": "Section 3.1 (H3)",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      },
      {
          "key": "97ink",
          "text": "text in section 3.1",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
      }
  ],
  "entityMap": {}
};


export default function DraftJSPluginsTestPage() {
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(DEFAULT_DRAFT_DOCUMENT)));
    const [editable,setEditable] = useStateLSBoolean("draftEditor_isEditable",true);

    const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
    const handleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, editable);

    const {headerComponents, plugins} = useExtensions(setEditorState,editorState, editable, setEditable);

    // Print block map, if needed, e.g. to edit and save
    useMemo(()=>{
      const doc_data_to_save=convertToRaw(editorState.getCurrentContent());
      console.log("[DraftJSPluginsTestPage] Editor state changed to ",doc_data_to_save);
    },
    [editorState]);

    return <Layout>
      <Sider collapsible={true} theme="light">
        <DraftJSOutline editorState={editorState} setEditorState={setEditorState}/>
      </Sider>
      <Content>
        <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true} >
          {headerComponents.map((component,i)=><div key={i}>{component}</div>)}
        <div className="editor rdw2-editor-main" style={{height:"80vh"}}>
        <Editor
            editorState={editorState}
            onChange={setEditorState}
            blockRendererFn={blockRenderFn}
            blockRenderMap={BLOCK_RENDER_MAP}
            blockStyleFn={blockStyleFn}
            plugins={plugins}

            handleKeyCommand={handleKeyCommand}
            // @ts-ignore
            keyBindingFn={basicKeyBindingFn}
            readOnly={!editable}
        />
        </div>
        </InstantSearch>
        </Content>
    </Layout>;
}