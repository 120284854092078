import { callOnAllNodes } from "./GenericTreeUtilities";
import { FolderOpenOutlined, UserOutlined, TagOutlined, TagsOutlined} from '@ant-design/icons';
import { NoteTreeElement, NoteTreeElementWithIcon } from "../../../User/Data/TagTreeStateType";



export function addIconsToTreeNodes(treeNodes:NoteTreeElement[]) {
    // Add the icons -- we don't want to save them, so we keep them in a special object used only for rendering:
    // It must be deep copied like this -- or else the original object gets modified (and is hard to uncorrupt)
    // const treeNodesWithIcons:NoteTreeElementWithIcon[] =JSON.parse(JSON.stringify(treeNodes));
    callOnAllNodes(treeNodes,function(node:NoteTreeElementWithIcon) {
        if (node.type==="AutoParent" && node.key==="Notes")
            node.icon=<FolderOpenOutlined/>;
        else if (node.type==="Note")
            // The minimalism looks better than an icon, and it saves a lot of horizontal space.
            node.icon=<></>;//<FileTextOutlined />;
        else if (node.type==="AutoParent" && node.key==="@MentionsParent")
            node.icon=<UserOutlined />;
        else if (node.type==="@Mention")
            node.icon=<UserOutlined />;

        else if (node.type==="AutoParent" && node.key==="#HashtagsParent")
            node.icon=<TagsOutlined />;
        else if (node.type==="#Hashstag")
            node.icon=<TagOutlined />;
        else
            // Default case so that whatever we loaded accidentally doesn't get rendered by react -- it'll crash react
            node.icon=null;
    });
    return treeNodes;
}