import { RawDraftContentState } from "draft-js";

export const PROMPT_MENTION_TYPE_MENTION = "Mention";
export const PROMPT_MENTION_TYPE_NOT_A_MENTION = "Not Mentioned";
export const PROMPT_MENTION_TYPE_NEW = "New";
export const PROMPT_MENTION_TYPE_EDIT = "Edit";

// Keep these two in sync
export const PROMPT_MENTION_TYPES = [PROMPT_MENTION_TYPE_MENTION, PROMPT_MENTION_TYPE_NOT_A_MENTION, PROMPT_MENTION_TYPE_NEW, PROMPT_MENTION_TYPE_EDIT];
export type TYPES = typeof PROMPT_MENTION_TYPE_MENTION | typeof PROMPT_MENTION_TYPE_NOT_A_MENTION | typeof PROMPT_MENTION_TYPE_NEW | typeof PROMPT_MENTION_TYPE_EDIT;


export type NotePromptMentionExamples = {
    promptExample: string;
    type: TYPES;
    reasoning: string;
};

export type Note = {
    id: string
    doc_name: string
    type: string
    template_doc_id?: string
    emoji?: string // Only possible to fill out if Type=="Type", even then it's optional.
    promptMentionExamples?: NotePromptMentionExamples[]

    // Does this belong here? I think yes, because any other solution would be very slow (queries of subcollections may be tricky/slow)
    parent?: string // ID of the parent node
    children?: string[] // ID of the children

    // Eventually move these into Content/Note:
    doc_data:RawDraftContentState
    version:number
};

// Useful for saving
export type PartialNote = {
    id: string
    doc_name?: string
    type?: string

    parent?: string // ID of the parent node
    children?: string[]

    doc_data?:any
    version?:number
};


export type JSONFormsSchema = {
    "type-schema"?: string
    "type-ui-schema"?: string
};

export type PartialJSONFormsObject = {
    name: string;
}