import { DraftHandleValue, EditorState, KeyBindingUtil, RichUtils, getDefaultKeyBinding } from "draft-js";
//@ts-ignore
import {getSelectedBlock} from  "draftjs-utils";
import { isCheckableItem, isIndentableBlock } from "./BlockEditing/CheckableListItem";
import { addBlockAfter, changeBlockDepth, newBlock, newCheckableBlock, scrollSelectionIntoViewInAMoment } from "./BlockEditing/EditCheckableItemUtils";
import { LINK_TO_NOTE_BLOCK_TYPE } from "./BlockEditing/LinkToNoteBlockUtils";


export const INSERT_HYPERLINK_DIALOG = "insert-hyperlink-dialog";
export const INSERT_FULL_NOTE_LINK_DIALOG = "insert-full-note-link-dialog";
export const SWITCH_TO_SEARCH = "switch-to-search";


export const KEY_COMMAND_NOT_HANDLED = "not-handled";
export const KEY_COMMAND_HANDLED = "handled";


export function useBasicKeyBindingFn(editorState:EditorState) {
    function isSelectedBlockIndentable():boolean {
        const block = getSelectedBlock(editorState);
        // We seem to need to handle this for the WYSYWIG editor, even though it's capable of handling this, once we add this type of keyboard handlers it doesn't.
        return isIndentableBlock(block);
    }
  
    function basicKeyBindingFn(e: React.KeyboardEvent<Element>): string | null {
        // console.log("Pressed ",e.key," while ",isAutocompleteInProgress()?"autocompleting":"not autocompleting");
        if (KeyBindingUtil.hasCommandModifier(e)) {
        if (e.key.toLowerCase()==="s")
            return 'myeditor-save';
        if (e.shiftKey && e.key.toLowerCase()==="k")
            return INSERT_FULL_NOTE_LINK_DIALOG;
        if (e.key.toLowerCase()==="k")
            return INSERT_HYPERLINK_DIALOG;
        if (e.key.toLowerCase()==="b")
            return "BOLD";
        if (e.key.toLowerCase()==="u")
            return "UNDERLINE";
        if (e.key.toLowerCase()==="i")
            return "ITALIC";
        if (e.key.toLowerCase()==="e") {
            e.preventDefault();
            return SWITCH_TO_SEARCH;
        }
        }
        if (e.key==="Tab") {
            // We don't want it to go to another field, ever.
            e.preventDefault();
            // We only handle it if the editor doesn't for our checkable. The editor handles these for bullets well.
            if (isSelectedBlockIndentable()) {
                if (e.shiftKey)
                    return "outdent";
                return "indent";
            }
        }
        return getDefaultKeyBinding(e);
    }
    return basicKeyBindingFn;
}

export function useBasicHandleKeyCommand(editorState:EditorState,onEditorStateChange:(editorState:EditorState)=>void) {

    /*******
     * TODO there is a bug here, also appearing in our changeBlockTo function, that after this event the selection will revert to the previous selection (if using the mouse, not if using the arrow keys)
     */
    function handleIndent(depthDelta:number): DraftHandleValue {
        // console.log("indenting by "+depthDelta);
        // Indent the content, if applicable. We only need to do this if it's a 
        const block = getSelectedBlock(editorState);
        if (isIndentableBlock(block)) {
          // Is it shift-tab, or tab?
          const depth = block.getDepth();
          // TODO we should check the previous block's depth, and make sure this new number is no more than 1 deeper.
          if (depth===0 && depthDelta===-1) {
            // don't do this, it would delete the checkbox. Just ignore it.
            return KEY_COMMAND_NOT_HANDLED;
          } else 
            onEditorStateChange(changeBlockDepth(editorState, block, depthDelta));
        }
        return KEY_COMMAND_HANDLED;
    }
    /* Called from the handleKeyCommand function below. */
    function handleSplitBlock(): DraftHandleValue {
        //@ts-ignore
        let block = getSelectedBlock(editorState);  
        if (isCheckableItem(block)) {
            onEditorStateChange(addBlockAfter(editorState, editorState.getSelection().getFocusKey(), newCheckableBlock()));
            // This works, but causess bugs too.
            scrollSelectionIntoViewInAMoment();
            return KEY_COMMAND_HANDLED;  
        }
        if (block.getType()===LINK_TO_NOTE_BLOCK_TYPE) {
            // After a link, we don't want to create another link.
            onEditorStateChange(addBlockAfter(editorState, editorState.getSelection().getFocusKey(), newBlock()));
                // This works, but causess bugs too.
                scrollSelectionIntoViewInAMoment();
                return KEY_COMMAND_HANDLED;  
        }
        // Let the tool handle it on its own.
        // console.log("letting the editor handle this new line");
        if (isIndentableBlock(block)) {
            return KEY_COMMAND_NOT_HANDLED;
        }
        // Let the editor handle it. We could handle it ourselves like this, but we don't need to:
        // But the editor sometimes doesn't scroll to make this in view. We want to make sure it's always visible to the user.
        // But this causes other bugs -- it will scroll even when it's not needed.
        scrollSelectionIntoViewInAMoment();
        return KEY_COMMAND_NOT_HANDLED;
    }

    function handleKeyCommand(command: string): DraftHandleValue {
        // console.log("handleKeyCommand",command);
        switch (command) {
            case 'myeditor-save':
                return KEY_COMMAND_HANDLED; // ignore because it's autosaved. we just block the dialog from showing.
            case "indent":
                return handleIndent(1);
            case "outdent":
                return handleIndent(-1);
            case "BOLD":
            case "UNDERLINE":
            case "ITALIC":
                onEditorStateChange(RichUtils.toggleInlineStyle(editorState,command));
                return KEY_COMMAND_HANDLED;
            case "split-block":
                return handleSplitBlock();
        }
        return KEY_COMMAND_NOT_HANDLED;
    }
    return handleKeyCommand;
}