import { Select, Tooltip } from "antd";
import { useStateLSString } from "../../Utilities/StateWithLocalCache";
import { MODEL_BEST, MODEL_LIGHT } from "../../../ServerConnection/LLMServer/FirebaseOpenAIChatServer";
import { useVertexViaFirebaseChatCommand } from "../../../ServerConnection/LLMServer/FirebaseVertexAIChatServer";
import { ChatAPICall, FirebaseGroqChatServer, FirebaseOpenAIChatServer, LMServerType, LM_SERVERS_OBJ, LM_SERVER_LOCAL, LM_SERVER_OPENAI_BEST, LM_SERVER_OPENAI_DIRECT, LM_SERVER_OPENAI_LIGHT } from "../../../ServerConnection/LLMServer/GenericChatServer";
import callLMStudio from "../../../ServerConnection/LLMServer/LMStudioChatServer";
import { useOpenAIViaFirebaseChatCommand } from "../../../ServerConnection/LLMServer/FirebaseOpenAIChatServer";
import { createContext, useContext, useEffect } from "react";
import { GLOBAL_DEBUG_OUTPUT } from "../../../GlobalDebug";
import { callOpenAIDirect_Streaming } from "../../../ServerConnection/LLMServer/OpenAIDirectChatServer";

const LM_SERVERS_STRINGS = LM_SERVERS_OBJ.map((server: LMServerType) => server.name) as string[];
const LM_SERVERS_STRINGS_REMOTE = LM_SERVERS_OBJ.filter((server: LMServerType) => !server.isLocal).map((server: LMServerType) => server.name) as string[];

export type LLMServerContextType = {
    callChatAPI?: ChatAPICall;
    serverType: LMServerType;
    lmServers: string[];

    // User facing selection name (it's not the same as the server name)
    lmServerString: string;
    setLMServer: (server: string) => void;
}
export const LLMServerContext = createContext({} as LLMServerContextType);
export function LLMServerContextProvider({children}:{children:any}) {
    const isLocalishhost = (window.location.hostname === 'localhost' || window.location.hostname === "192.168.1.164") && window.location.port === "3000";
    const [lmServerSaved, setLMServer] = useStateLSString<string>("LLM_Select_Server_Type", isLocalishhost ? LM_SERVER_LOCAL : LM_SERVER_OPENAI_BEST);

    let lmServerString = lmServerSaved;
    // Default to OpenAI Best (GPT-4):
    if (!LM_SERVERS_STRINGS.includes(lmServerString))
        lmServerString = LM_SERVER_OPENAI_BEST;
    let openAIModelType = MODEL_BEST;   
    if (lmServerString === LM_SERVER_OPENAI_LIGHT)
        openAIModelType = MODEL_LIGHT;

    let serverType = LM_SERVERS_OBJ.find((server: LMServerType) => server.name === lmServerString) as LMServerType;

    const callLMOpenAI = useOpenAIViaFirebaseChatCommand(serverType.serverType, openAIModelType);
    const callLMVertex = useVertexViaFirebaseChatCommand();

    let lmServers = LM_SERVERS_STRINGS;
    if (!isLocalishhost) {
        lmServers = LM_SERVERS_STRINGS_REMOTE;
        // If we're not on localhost, we can't use the local server:
        if (lmServerString === LM_SERVER_LOCAL) {
            setLMServer(LM_SERVER_OPENAI_BEST);
            lmServerString = LM_SERVER_OPENAI_BEST;
        }
    }

    let callChatAPI;
    if (serverType?.serverType === FirebaseOpenAIChatServer || serverType?.serverType === FirebaseGroqChatServer) {
        callChatAPI = callLMOpenAI;
    } else if (serverType?.serverType === "FirebaseVertexAIChatServer") {
        callChatAPI = callLMVertex;
    } else if (serverType?.serverType === "LMStudioChatServer") {
        callChatAPI = callLMStudio;
    } else if (serverType?.serverType === LM_SERVER_OPENAI_DIRECT) {
        // callChatAPI = callOpenAIDirect_NoStreaming;
        callChatAPI = callOpenAIDirect_Streaming;
    } else {
        console.error("[SelectableServerAntdProChat] Bug in our server list: server not in our hard-coded list despite precheck: ", lmServerString, LM_SERVERS_OBJ);
        debugger;
        callChatAPI = callLMOpenAI;
    }
    useEffect(() => { // If there's no OpenAI API key stored, prompt for it.
        if (serverType?.serverType === LM_SERVER_OPENAI_DIRECT) {
            const openai_api_key = localStorage.getItem("OPENAI_API_KEY");
            if (!openai_api_key) {
                const openai_api_key2 = prompt("Please enter your OpenAI API key to connect directly");
                if (openai_api_key2) {
                    localStorage.setItem("OPENAI_API_KEY", openai_api_key2);
                } else {
                    // Fall back to something else, the default.
                    setLMServer(LM_SERVER_OPENAI_BEST);
                }
            }            
        }
    }, [serverType]);

    const providerValue = { callChatAPI, serverType, lmServers, lmServerString, setLMServer };
    return <LLMServerContext.Provider value={providerValue}>
        {children}
    </LLMServerContext.Provider>;
}

export function SelectableLLMServerDropdown() {
    const { lmServers, lmServerString, setLMServer } = useContext(LLMServerContext);
    if (lmServers.length <= 1) {
        if (GLOBAL_DEBUG_OUTPUT)
            return <>{lmServers.length} servers found so no selection being shown.</>;
        return <></>; // nothing to select. We don't show the message.
    }

    return <>
        <Tooltip title="Groq is the fastest. GPT-4 is the smartest. The others are in-between.">
            <Select value={lmServerString} onChange={function (value: string) {
            setLMServer(value);
        } } style={{ minWidth: '100px' }}>
            {lmServers.map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
            </Select>
        </Tooltip>
        &nbsp;
    </>;
}

