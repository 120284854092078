import { useMemo, useState } from "react";
import 'react-h5-audio-player/lib/styles.css';
import { Select } from "antd";
import { SelectProps } from "antd/lib";
import { CloudPlaylist, CloudPlaylistRef, CurrentTrackData, PLAYER_UI_FANCY } from "./CloudPlaylist";
import { SOUNDTRACK } from "./Soundtrack1";
import { useStateURLString } from "../URLQueryParams";

export const SHOW_TRACK_TAGS = true;

export const SELECTED_MUSIC_TAGS_PARAMETER = "musicTags";

export function getMusicTagsFromURLParameter(tagsStr:string):string[] {
    return JSON.parse(tagsStr) as string[];
}
export function getMusicTagsForURL(tags:string[]) {
    return JSON.stringify(tags);
}

export function useMusicTagsParameters(): [string[], (newTags:string[]) => void] {
    const [tagsStr, setTagsStr] = useStateURLString(SELECTED_MUSIC_TAGS_PARAMETER,"[]",false);
    const tags = useMemo(() => getMusicTagsFromURLParameter(tagsStr), [tagsStr]);
    function setTags(newTags:string[]) {
        // Check if it's the same first:
        if (tags.length===newTags.length && tags.every((tag:string,i:number) => tag===newTags[i])) {
            return;
        }
        setTagsStr(getMusicTagsForURL(newTags));
    }
    // useMemo(() => {
    //     console.log("[useMusicTagsParameters] tags has changed to:",tags);
    // }, [tags]);
    return [tags, setTags];
}

export function FullSoundtrackSelector({playerUI=PLAYER_UI_FANCY,randomizeOrder=true, soundtrack=SOUNDTRACK,autoplay,onDarkBackground,onPlayingChanged, cloudPlaylistRef}:{playerUI?:string,randomizeOrder?:boolean, soundtrack?:{path:string,tags:string[]}[],autoplay?:boolean,onDarkBackground?:boolean,onPlayingChanged?:(isPlaying:boolean)=>void, cloudPlaylistRef?:React.MutableRefObject<CloudPlaylistRef | undefined>}) {   
    const [tags, setTags] = useMusicTagsParameters();
    const [cloudPlaylistTrack, setCloudPlaylistTrack] = useState<CurrentTrackData>({humanFriendlyFileName:"",trackPath:""});
    const {tagOptions,playlist}:{tagOptions: SelectProps['options'],playlist:string[]} = useMemo(() => {
        // Which songs are selected? (Each track must have all selected tags)
        const selectedTracks = soundtrack.filter(track => tags.every(tag => track.tags.includes(tag)));
        // Count the number of songs per tag:
        const tagCountsObj = selectedTracks.reduce((acc, track) => {
            track.tags.forEach(tag => {
                if (tags.includes(tag)) return; // Skip the tag that we already have.
                if (!acc[tag]) {
                    acc[tag]=1;
                } else {
                    acc[tag]++;
                }
            });
            return acc;
        }, {} as {[key:string]:number});

        // TODO We might further reduce the options if there's nothing useful to select (e.g. if all remainging tracks have the same tags)
        
        // Map our tags into an array:
        const tagCountsArr = Object.keys(tagCountsObj).map(tag => {
            return {tag, count: tagCountsObj[tag]};
        });
        // Sort the tagCounts descending by number of songs in that tag:
        tagCountsArr.sort(function(a,b) {
            // Exception: if the tag has all the songs, it should be last, since it's not adding anything to the selection:
            if (b.count===selectedTracks.length || a.count===selectedTracks.length) {
                return a.count-b.count;
            }
            return b.count - a.count;
        });

        // Convert to SelectProps['options'], adding (#) to the end of the label:
        const tagOptions = tagCountsArr.map(tag => ({label:tag.tag+" ("+(tagCountsObj[tag.tag]===selectedTracks.length?"All ":"")+tagCountsObj[tag.tag]+")", value:tag.tag})) as SelectProps['options'];
        // const tagOptions = tagCountsArr.map(tag => ({label:tag.tag, value:tag.tag}));
        // const tagOptions = tagCountsArr.map(tag => ({label:tag.tag, value:tag.tag}));
        const playlist = selectedTracks.map(track => track.path);

        return {tagOptions,playlist};
    }, [tags, soundtrack]);

    const select = <><Select mode="multiple" value={tags} onChange={setTags} options={tagOptions} style={{width: "100%"}} placeholder="Select Tags"/></>;

    return <div>
        {/* {playerUI===PLAYER_UI_FANCY && <Tooltip title="Music Tags">{select}<br/></Tooltip>} */}
        Track: {cloudPlaylistTrack.humanFriendlyFileName}<br/>
        {SHOW_TRACK_TAGS && <>Track's Tags: {soundtrack.find(track => track.path === cloudPlaylistTrack.trackPath)?.tags.join(", ")}<br/></>}
        {select}
        <CloudPlaylist
            playlist={playlist}
            randomizeOrder={randomizeOrder}
            loop={true}
            // playerUI={playerUI}
            playerUI={PLAYER_UI_FANCY}
            autoplay={autoplay}
            onDarkBackground={onDarkBackground}
            tooltipContents={playerUI===PLAYER_UI_FANCY?undefined:select}
            onTrackChange={setCloudPlaylistTrack}
            onPlayingChanged={onPlayingChanged}
            defaultVolume={0.15} // Default for background music is 0.2 so that voices will be louder. Even better would be to dynamically change this to be lower when voices are playing. But changing this doesn't seem to change the volume after the original default.
            cloudPlaylistRef={cloudPlaylistRef}
            />
    </div>;
}

