import { useContext, useEffect, useMemo, useState } from "react";
import { Button, Col, Collapse, Input, InputNumber, Row, Select, Slider, Space, Spin, Tooltip } from "antd";
import { uniq } from "lodash";
import { ReplicaPitch, ReplicaPitch_Max, ReplicaPitch_Min, ReplicaVoice, useReplicaPreSynthesizedText, useReplicaSynthesizeCommand, useReplicaVoices } from "./ReplicaAPI";
import { useStateURLInteger, useStateURLString, useStateURLStringArray } from "../URLQueryParams";
import { CloudPlaylist, CloudPlaylistRef, PLAYER_UI_FANCY } from "./CloudPlaylist";
import { SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { FileAddOutlined } from "@ant-design/icons";
import { useRecommendAVoiceComponent } from "./RecommendAVoice";

const DEBUG = false;
export const SELECTED_VOICE_ID_PARAMETER = "selectedVoiceID";
export const SELECTED_VOICE_TAGS_PARAMETER = "selectedVoiceTags";
export const SELECTED_VOICE_PITCH_PARAMETER = "selectedVoicePitch";

function filterOption(input: string, option?: { label: string; value: string }) {
    if (!option) return false;
    const matches = option.label.toLowerCase().includes(input.toLowerCase());
    return matches;
    //   (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
}

type VoiceTag = {
    name: string;
    count: number;
    type: "Characteristic" | "Gender" | "Accent" | "Age" | "Style";
}

export function SelectReplicaVoiceUnifiedTags({voices, selectedVoice: selectedVoiceName, setSelectedVoice: setSelectedVoiceName, setVoice, setFilteredVoices}:{voices:ReplicaVoice[], selectedVoice:string|undefined, setSelectedVoice:(voice:string)=>void, setVoice?:(voice?:ReplicaVoice)=>void, setFilteredVoices?:(voices:ReplicaVoice[])=>void}) {
    // const [selectedTagsNamesRawStr, setSelectedTagNamesRawStr] = useStateURLString(SELECTED_VOICE_TAGS_PARAMETER,"[]",false);
    // // const [selectedTagNames, setSelectedTagNames] = useStateLSJSON("selectedVoiceTags",[]) as [string[],(tags:string[])=>void];
    // const selectedTagNames = JSON.parse(selectedTagsNamesRawStr) as string[] || [] as string[];
    // function setSelectedTagNames(tags:string[]) {
    //     setSelectedTagNamesRawStr(JSON.stringify(tags));
    // }
    const [selectedTagNames,setSelectedTagNames] = useStateURLStringArray(SELECTED_VOICE_TAGS_PARAMETER,[]);

    const filteredVoices = useMemo(() => {
        // Parse the selectedTagNames to get the name, and the type:
        const selectedTags = selectedTagNames.map(tag => {
            const type = tag.slice(tag.lastIndexOf("[")+1,tag.lastIndexOf("]"));
            const name = tag.slice(0,tag.lastIndexOf("["));
            return {name,type} as VoiceTag;
        });
        let filteredVoices = voices;
        
        // Find the accents:
        const selectedAccent = selectedTags.find(tag => tag.type==="Accent")?.name;
        // const selectedAccent = selectedTagNames.find(tag => voices.some(voice => voice.metadata.accent.toLowerCase()===tag.toLowerCase()));
        if (selectedAccent) {
            filteredVoices = filteredVoices.filter(voice => voice.metadata.accent.toLowerCase()===selectedAccent.toLowerCase());
            // console.log("[SelectReplicaVoiceUnifiedTags] selectedAccent=",selectedAccent,"filteredVoices=",filteredVoices.length);
        }

        const selectedAge = selectedTags.find(tag => tag.type==="Age")?.name;
        // const selectedAge = selectedTagNames.find(tag => voices.some(voice => voice.metadata.voiceAge.toLowerCase()===tag.toLowerCase()));
        if (selectedAge) {
            filteredVoices = filteredVoices.filter(voice => voice.metadata.voiceAge.toLowerCase()===selectedAge.toLowerCase());
            // console.log("[SelectReplicaVoiceUnifiedTags] selectedAge=",selectedAge,"filteredVoices=",filteredVoices.length);
        }

        // Some accents are also characteristics! That's not helpful, though, we don't want to filter characteristics too by the same accent.
        const selectedCharacteristics = selectedTags.filter(tag => tag.type==="Characteristic").map(tag => tag.name);
        // const selectedCharacteristics = selectedTagNames.filter(tag => tag!==selectedAccent && tag!==selectedAge && voices.some(voice => voice.characteristics.map(c=>c.toLowerCase()).includes(tag.toLowerCase())));
        if (selectedCharacteristics.length > 0) {
            filteredVoices = filteredVoices.filter(voice => selectedCharacteristics.every(characteristic => voice.characteristics.map(c=>c.toLowerCase()).includes(characteristic.toLowerCase())));
            // console.log("[SelectReplicaVoiceUnifiedTags] selectedCharacteristics=",selectedCharacteristics,"filteredVoices=",filteredVoices.length)
        }
        const selectedGender = selectedTags.find(tag => tag.type==="Gender")?.name;
        // const selectedGender = selectedTagNames.find(tag => voices.some(voice => voice.metadata.gender.toLowerCase()===tag.toLowerCase()));
        if (selectedGender) {
            filteredVoices = filteredVoices.filter(voice => voice.metadata.gender.toLowerCase()===selectedGender.toLowerCase());
            // console.log("[SelectReplicaVoiceUnifiedTags] selectedGender=",selectedGender,"filteredVoices=",filteredVoices.length);
        }

        const selectedStyles = selectedTags.filter(tag => tag.type==="Style").map(tag => tag.name);
        // const selectedStyles = selectedTagNames.filter(tag => voices.some(voice => voice.styles.map(style => style.name).map(c=>c.toLowerCase()).includes(tag.toLowerCase())));
        if (selectedStyles.length > 0) {
            filteredVoices = filteredVoices.filter(voice => selectedStyles.every(style => voice.styles.map(style => style.name).map(c=>c.toLowerCase()).includes(style.toLowerCase())));
            // console.log("[SelectReplicaVoiceUnifiedTags] selectedStyles=",selectedStyles,"filteredVoices=",filteredVoices.length);
        }
        // console.log("[SelectReplicaVoiceUnifiedTags] selectedTagNames=",selectedTagNames,"filteredVoices=",filteredVoices.length);
        return filteredVoices;
    }, [voices, selectedTagNames]);
    const selectableTags = useMemo(() => {
        let tags = [] as VoiceTag[];
        const uniqueAccents = uniq(filteredVoices.map(voice => voice.metadata.accent.toLowerCase()));
        const uniqueAges = uniq(filteredVoices.map(voice => voice.metadata.voiceAge.toLowerCase()));
        const uniqueCharacteristics = uniq(filteredVoices.map(voice => voice.characteristics).flat().map(c=>c.toLowerCase())).filter(characteristic=>!uniqueAccents.includes(characteristic) && !uniqueAges.includes(characteristic));
        const uniqueGenders = uniq(filteredVoices.map(voice => voice.metadata.gender.toLowerCase()));
        const uniqueStyles = uniq(filteredVoices.map(voice => voice.styles.map(style => style.name)).flat().map(c=>c.toLowerCase()));
        // console.log("[SelectReplicaVoiceUnifiedTags] uniqueCharacteristics=",uniqueCharacteristics,"uniqueGenders=",uniqueGenders,"uniqueAccents=",uniqueAccents,"uniqueStyles=",uniqueStyles);

        uniqueCharacteristics.forEach(characteristic => {
            tags.push({
                name: characteristic[0].toUpperCase() + characteristic.slice(1),
                // Count case insensitively:
                count: filteredVoices.filter(voice => voice.characteristics.map(c=>c.toLowerCase()).includes(characteristic.toLowerCase())).length,
                type: "Characteristic"
            });
        });
        uniqueGenders.forEach(gender => {
            tags.push({
                name: gender[0].toUpperCase()+gender.slice(1),
                count: filteredVoices.filter(voice => voice.metadata.gender.toLowerCase()===gender).length,
                type: "Gender"
            });
        });
        uniqueAccents.forEach(accent => {
            tags.push({
                name: accent[0].toUpperCase()+accent.slice(1),
                count: filteredVoices.filter(voice => voice.metadata.accent.toLowerCase()===accent).length,
                type: "Accent"
            });
        });
        uniqueAges.forEach(age => {
            tags.push({
                name: age[0].toUpperCase()+age.slice(1),
                count: filteredVoices.filter(voice => voice.metadata.voiceAge.toLowerCase()===age).length,
                type: "Age"
            });
        });
        uniqueStyles.forEach(style => {
            tags.push({
                name: style[0].toUpperCase()+style.slice(1),
                count: filteredVoices.filter(voice => voice.styles.map(style => style.name).map(c=>c.toLowerCase()).includes(style.toLowerCase())).length,
                type: "Style"
            });
        });
        tags.sort((a,b)=>b.count-a.count);
        return tags;
    }, [filteredVoices]);
    const voice = useMemo(() => {
        if (selectedVoiceName) {
            const voice = filteredVoices.find(voice => voice.uuid === selectedVoiceName);
            if (DEBUG) console.log("[SelectReplicaVoiceUnifiedTags] selected voice: ",voice);    
            return voice;
        } else {
            return undefined;
        }
    }, [selectedVoiceName, filteredVoices]);
    useEffect(() => {
        if (setVoice)
            setVoice(voice);
    }, [voice, setVoice]);
    useEffect(() => {
        if (setFilteredVoices)
            setFilteredVoices(filteredVoices);
    }, [filteredVoices, setFilteredVoices]);


    return <>
        Tags ({selectableTags.length}) &nbsp;<Select mode="multiple" style={{width: "90%"}} placeholder="Select Tags" value={selectedTagNames} onChange={setSelectedTagNames}>
            {selectableTags.map(tag => <Select.Option key={tag.name} value={tag.name+"["+tag.type+"]"}>{tag.name} [{tag.type}] ({tag.count})</Select.Option>)}
        </Select><br/>
        
        Voice ({filteredVoices.length})&nbsp;<Select showSearch allowClear filterOption={filterOption}  style={{width: "90%"}} placeholder="Select Voice" value={(voice?selectedVoiceName:"")} onChange={setSelectedVoiceName} options={filteredVoices.map(voice=>{return{value:voice.uuid,label:voice.name}})}/><br/>
    </>;
}

export function ReplicaVoiceComponent({showExtraInfo=DEBUG, isInDialog=false,cloudPlaylistRef}:{showExtraInfo?:boolean, isInDialog?:boolean, cloudPlaylistRef?:React.MutableRefObject<CloudPlaylistRef>}) {
    const voices = useReplicaVoices();
    const [selectedStyle, setSelectedStyle] = useState<string|undefined>(undefined);
    const [selectedVoiceID, setSelectedVoiceID] = useStateURLString(SELECTED_VOICE_ID_PARAMETER,"",false);
    const [inputTextToSynthesize, setInputTextToSynthesize] = useState<string>("Hello.");
    const [voice, setVoice] = useState<ReplicaVoice|undefined>(undefined);
    const [pitch, setPitch] = useStateURLInteger(SELECTED_VOICE_PITCH_PARAMETER,0,false);

    const [filteredVoices, setFilteredVoices] = useState<ReplicaVoice[]>(voices);
    const style = useMemo(() => {
        if (selectedStyle && voice) {
            return voice.styles.find(style => style.name === selectedStyle);
        }
    }, [selectedStyle, voice]);
    const filteredStyleNames = useMemo(() => {
        let styles = [] as string[];

        if (voice) {
            styles = uniq(voice.styles.map(style => style.name));
            styles.sort();
        }
        return styles;
    }, [voice]);
    const replicaSynthesizeCommand = useReplicaSynthesizeCommand();
    const [synthesisIsLoading, setSynthesisIsLoading] = useState(false);
    const [synthesisError, setSynthesisError] = useState<string|undefined>(undefined);
    const [filename, setFilename] = useState<string|undefined>(undefined);
    async function synthesizeText() {
        if (!voice || !style) {
            return;
        }
        setSynthesisIsLoading(true);
        replicaSynthesizeCommand(voice.name,style,inputTextToSynthesize,pitch as ReplicaPitch).then((filename:string) => {
            setSynthesisIsLoading(false);
            setFilename(filename);
        }).catch((error) => {
            setSynthesisIsLoading(false);
            setSynthesisError(error);
            setFilename(undefined);
        });
    }
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    function insertLinkIntoNote() {
        if (!voice) {
            console.error("[ReplicaVoiceUI > ReplicaVoiceComponent] voice is null. This should not be possible, so it's a bug: voice=",voice)
            return;
        }
        if (selectedJsonFormsContext && selectedJsonFormsContext.noteEditorRef && selectedJsonFormsContext.noteEditorRef.current) {
            selectedJsonFormsContext.noteEditorRef.current.insertAVoiceLinkIntoNote(selectedVoiceID,voice.name,pitch as ReplicaPitch);
        }
    }
    const {replicaSamples,samplesTitle} = useMemo(() => {
        let replicaSamples = [] as {text:string,url:string}[];
        let samplesTitle = "Sample";
        if (voice) {
            if (style) {
                samplesTitle = "Sample: "+style.name;
                replicaSamples = style.samples;
            } else if (voice.default_style && voice.default_style.samples.length>0) {
                replicaSamples = voice.default_style.samples;
            } else if (voice.hero_sample_url) {
                samplesTitle = "Sample";
                replicaSamples = [{text: "", url: voice.hero_sample_url}];
            }
        }
        if (replicaSamples.length>1)
            samplesTitle += "s";
        return {replicaSamples,samplesTitle};
    }, [voice, style]);
    const {loading, synthesizedText} = useReplicaPreSynthesizedText(voice,style);
    const {recommendAVoiceComponent} = useRecommendAVoiceComponent(voice, setSelectedVoiceID, filteredVoices);

    if (!voices || voices.length===0) return <div><Spin/> Loading voices...</div>;
    if (DEBUG) console.log("[ReplicaAPITestPage] ",selectedVoiceID, voice, style);

    const accordionItems = [] as {key:string,label:string,children:JSX.Element}[];
    if (voice) {
        if (DEBUG) console.log("[ReplicaVoiceComponent] voice=",voice);
        accordionItems.push({
            key: '0',
            label: 'Voice Info',
            children: <>
                <div>{voice.avatar_url && <img src={voice.avatar_url} width="32px"/>}</div>
                <div>Description: {voice.description}</div>
                {voice.characteristics.length>0 && <div>Characteristics: {voice.characteristics.join(", ")}</div>}
                <div>Accent: {voice.metadata.accent}</div>
                <div>Gender: {voice.metadata.gender}</div>
                <div>Age: {voice.metadata.voiceAge}</div>
                {DEBUG && <div>UUID: {voice.uuid}</div>}
            </>
        });
    }
    if (replicaSamples.length>0)
        accordionItems.push({
            key: '1',
            label: samplesTitle,
            children: <>
                {replicaSamples.map(sample => <div key={sample.url}>
                    {sample.text && <><i>{sample.text}</i><br/></>}
                    <audio controls src={sample.url}></audio>
                </div>)}
            </>});
    if (synthesizedText && synthesizedText.length>0)
        accordionItems.push({
        key: '2',
        label: (style?("Synthesized "+style.name):("All Synthesized")),
        children: <>
            <CloudPlaylist
                playlist={synthesizedText.map(text => text.fullPath)}
                playerUI={PLAYER_UI_FANCY}
                autoplay={false}
                onDarkBackground={false}
                defaultVolume={1}
                cloudPlaylistRef={cloudPlaylistRef}
            />
        </>});

    return <>
        <SelectReplicaVoiceUnifiedTags voices={voices} selectedVoice={selectedVoiceID} setSelectedVoice={setSelectedVoiceID} setVoice={setVoice} setFilteredVoices={setFilteredVoices}/>

        Style ({filteredStyleNames.length})&nbsp;<Select showSearch allowClear filterOption={filterOption}  style={{width: "90%"}} placeholder="Select Style" value={style?style.name:""} onChange={setSelectedStyle} options={filteredStyleNames.map(style=>{return{value:style,label:style}})}/><br/>

        <Row>
            <Col span={2}>Pitch</Col>
            <Col span={14}>
                <Slider
                    min={ReplicaPitch_Min}
                    max={ReplicaPitch_Max}
                    onChange={(newValue:number) => {setPitch(newValue as ReplicaPitch);}}
                    value={pitch}
                />
            </Col>
            <Col span={1}>
                <InputNumber
                    min={ReplicaPitch_Min}
                    max={ReplicaPitch_Max}
                    style={{ width: "60px",margin: "0 8px"}}
                    value={pitch}                    
                    // @ts-ignore
                    onChange={(newValue?:number) => {if (newValue!==undefined && newValue!==null) setPitch(newValue as ReplicaPitch);}}
                />
            </Col>
        </Row>

        {voice && <>
            {showExtraInfo && <>
                <h3>About {voice.name}</h3>
                <div>{voice.avatar_url && <img src={voice.avatar_url} width="32px"/>}</div>
                <div>Description: {voice.description}</div>
                <div>Characteristics: {voice.characteristics.join(", ")}</div>
                <div>Accent: {voice.metadata.accent}</div>
                <div>UUID: {voice.uuid}</div>
            </>}
            {style && <>
                {showExtraInfo && <>
                    <b>Style: {style.name}</b><br/>
                    Speaker_id: {style.speaker_id}<br/>
                    Capabilities: {JSON.stringify(style.capabilities)}<br/>
                </>}
                
                {filename && <div>
                    <CloudPlaylist
                        playlist={[filename]}
                        playerUI={PLAYER_UI_FANCY}
                        autoplay={true}
                        onDarkBackground={false}
                        defaultVolume={1}
                        cloudPlaylistRef={cloudPlaylistRef}
                    />
                </div>}
            </>}

            <Space.Compact style={{ width: '100%' }}>
                <Input defaultValue="Combine input and button" value={inputTextToSynthesize} onChange={(e)=>setInputTextToSynthesize(e.target.value)} onPressEnter={()=>synthesizeText()}/>
                <Tooltip title={!voice?"Select a voice.":(!style?"Select a style.":"")}>
                    <Button type="primary" onClick={synthesizeText} loading={synthesisIsLoading} disabled={!voice || !style}>Synth</Button>
                </Tooltip>
            </Space.Compact>
            {synthesisError && <div>Error: {synthesisError}</div>}


            {/* TODO we could collapse this automatically once there's a synthesized voice. */}
            {accordionItems.length>0 && <Collapse accordion defaultActiveKey={['1']} items={accordionItems} />}
            {loading && <div><Spin/> Loading previously synthesized text...</div>}

            {!isInDialog && selectedJsonFormsContext && selectedJsonFormsContext.noteEditorRef && selectedJsonFormsContext.noteEditorRef.current && <>
                    <br/><Button onClick={insertLinkIntoNote} icon={<FileAddOutlined />}>Add to note</Button>
            </>}
        </>}
        {!isInDialog && <><br/>{recommendAVoiceComponent}</>}
    </>;
}

