import { TopLevelNoteHierarchy } from "../../Notes/UIs/NoteInformationComponents/NoteHierarchy";
import { Extension, getInstalledExtensionsList } from "./ExtensionsList";

export function getApplicableExtensions(topLevelNoteHierarchy: TopLevelNoteHierarchy): Extension[]{
    const extensions = getInstalledExtensionsList();
    if (!topLevelNoteHierarchy.noteHierarchiesById)
        return [];
    const applicableExtensions = extensions.filter(extension => {
        const includesAnApplicableNote = extension.included_notes_doc_ids.some(doc_id => {
            return topLevelNoteHierarchy.noteHierarchiesById[doc_id];
        });
        if (includesAnApplicableNote)
            return true;
        return false;
    });
    if (applicableExtensions.length>0) {
        return applicableExtensions;
    }
    // TODO we could check note types to show a warning if there's an uninstalled extension here.
    // The check would look something like the following.
    // const hasApplicableNoteTypes = extension.noteTypes.filter(noteType => {
    //     Object.values(topLevelNoteHierarchy.noteHierarchiesById).some(noteHierarchy => {
    //         if (noteHierarchy.note.type === noteType.name) {
    //             return true;
    //         }
    //     }
    //     )
    //     for (const noteHierarchy of Object.values(topLevelNoteHierarchy.noteHierarchiesById) {
            
    //         if (noteHierarchy.note.type === noteType.doc_id) {
    //             return true;
    //         }
    //     }
    //     topLevelNoteHierarchy.noteHierarchiesById
    //     return topLevelNoteHierarchy.noteHierarchiesById[noteType.doc_id];
    // });
    return [];
}