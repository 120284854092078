import { useEffect, useState } from "react";
import { LLMServerContextProvider, SelectableLLMServerDropdown } from "../DecisionGraph/ChatEditor/UI/SelectableLLM";
import { Note } from "../Notes/Data/NoteType";
import { useSendAnythingToServer } from "../ServerConnection/LLMServer/SendChatToServerHook";
import { isEqual } from "lodash";
import { detectTemplate } from "../ServerConnection/LLMServer/DetectTemplateV8";

const TestingNoteFiller = {
    promptMentionExamples: [],
    type: "Prompt",
    doc_data: {blocks:[],entityMap:{}},
    version: 1,
};

export const TEST_LIST_OF_TEMPLATES:Note[] = [
    {
        doc_name: "NPC Template",
        id: "9883d97c-0269-4692-aaa9-0648dd06d7d7",
        ...TestingNoteFiller,
    },{
        doc_name: "Luminaria Event Template",
        id: "97fb915b-bdbb-4948-bb03-99b0b1ad28e1",
        ...TestingNoteFiller,
    },{
        doc_name: "Faction Template",
        id: "2b40cb10-bdaf-436a-8957-f034ec75bb3e",
        ...TestingNoteFiller,
    },{
        doc_name: "Shop Template",
        id: "6cf816c3-1ede-4ef0-abd8-da9436c0266d",
        ...TestingNoteFiller,
    },{
        doc_name: "Dungeon Room Template",
        id: "f19467ed-491c-4a3b-bae8-9d2f580bd454",
        ...TestingNoteFiller,
    },{
        doc_name: "Luminaria Battle Template",
        id: "50e9a9b2-525f-489b-9d62-113661b53d4d",
        ...TestingNoteFiller,
    }
];

export function TemplateDetectionTest() {
    const [output, setOutput] = useState<string>("");
    const [isStreaming, setIsStreaming] = useState(false);
    function streamingWasCancelled() {
        setIsStreaming(false);
    }
    function streamingHasFinished(succeeded:boolean, completedMessageOrError:string) {
        if (succeeded) {
            
        } else {
            // Failed! TODO pass the error back somehow for display to the user.
        }
        setIsStreaming(false);
    }
    const {sendToServerWithStreaming, cancelStreaming, serverType } = useSendAnythingToServer(streamingWasCancelled, streamingHasFinished);

    useEffect(() => { // On mount, call the template detection.
        if (!serverType.supportsFunctions) {
            return;
        }
        setIsStreaming(true);
        async function callTemplateFunc() {
            const callFunctionSpec1 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "create a new event", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec1,{
                "type": "new",
                "template": "Luminaria Event Template"
            })) {
                console.error("Failed test 1 -- got "+JSON.stringify(callFunctionSpec1));
                setOutput("Failed test 1 -- got "+JSON.stringify(callFunctionSpec1));
                debugger;
                return;
            }
            setOutput("In progress: Test 2...");
            const callFunctionSpec2 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "edit a room", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec2,{
                "type": "edit",
                "template": "Dungeon Room Template"
            })) {
                console.error("Failed test 2 -- got "+JSON.stringify(callFunctionSpec2));
                setOutput("Failed test 2 -- got "+JSON.stringify(callFunctionSpec2));
                debugger;
                return;
            }
            setOutput("In progress: Test 3...");
            const callFunctionSpec3 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "something else", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec3,{
                "type": "none",
                "template": ""
            })) {
                console.error("Failed test 3 -- got "+JSON.stringify(callFunctionSpec3));
                setOutput("Failed test 3 -- got "+JSON.stringify(callFunctionSpec3));
                debugger;
                return;
            }
            setOutput("In progress: Test 4...");
            const callFunctionSpec4 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "create a nw NPC", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec4,{
                "type": "new",
                "template": "NPC Template"
            })) {
                console.error("Failed test 4 -- got "+JSON.stringify(callFunctionSpec4));
                setOutput("Failed test 4 -- got "+JSON.stringify(callFunctionSpec4));
                debugger;
                return;
            }
            setOutput("In progress: Test 5...");
            const callFunctionSpec5 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "edit a faction", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec5,{
                "type": "edit",
                "template": "Faction Template"
            })) {
                console.error("Failed test 5 -- got "+JSON.stringify(callFunctionSpec5));
                setOutput("Failed test 5 -- got "+JSON.stringify(callFunctionSpec5));
                debugger;
                return;
            }
            setOutput("In progress: Test 6...");
            const callFunctionSpec6 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "tell me what you can do", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec6,{
                "type": "none",
                "template": ""
            })) {
                console.error("Failed test 6 -- got "+JSON.stringify(callFunctionSpec6));
                setOutput("Failed test 6 -- got "+JSON.stringify(callFunctionSpec6));
                debugger;
                return;
            }
            // This test sometimes fails, perhaps because the text is a bit ambiguous. So far it's only failed twice out of many runs.
            setOutput("In progress: Test 7...");
            const callFunctionSpec7 = await detectTemplate(TEST_LIST_OF_TEMPLATES, "nxet evnt: the bomb drops just as the door shuts behind us", sendToServerWithStreaming);
            if (!isEqual(callFunctionSpec7,{
                "type": "new",
                "template": "Luminaria Event Template"
            })) {
                console.error("Failed test 7 -- got "+JSON.stringify(callFunctionSpec7));
                setOutput("Failed test 7 -- got "+JSON.stringify(callFunctionSpec7));
                debugger;
                return;
            }

            // The following tests fails. It has nothing in it though, so this is okay. It's only a problem if we get the wrong template when one is needed, not if one is not needed and it thinks one might be relevant.
            // setOutput("In progress: Test 6...");
            // const callFunctionSpec6 = await detectTemplates(TEST_LIST_OF_TEMPLATES, "create me some example JSON, and explain why it might be used as a TTRPG object. be brief. H1 should be \"Test Note 2\" on the first line. Use markdown to explain", sendToServerWithStreaming);
            // if (!isEqual(callFunctionSpec6,{
            //     "type": "none",
            //     "template": ""
            // })) {
            //     console.error("Failed test 6 -- got "+JSON.stringify(callFunctionSpec6));
            //     setOutput("Failed test 6 -- got "+JSON.stringify(callFunctionSpec6));
            //     debugger;
            //     return;
            // }

            setOutput("All 7 tests succeeded!");
            // setOutput("Succeeded!");
            setIsStreaming(false);    
        }
        callTemplateFunc();
    }, []);
    


    return <div>
        {!serverType.supportsFunctions && <div>Template Detection is not supported on this LLM, because it does not support functions.</div>}
        {isStreaming && <div>Streaming...</div>}
        {!isStreaming && <div>
            <pre>{output}</pre>            
        </div>}
    </div>
}

export function TemplateDetectionV8TestPage() {

    useEffect(() => {
        document.title = 'Template Detection Test Page - AI Got This';
    }, []);
      
    return <LLMServerContextProvider>
        <div className="HEADER_ABOVE_TABS">
            <h1>Template Detection V8 Test Page</h1>
            <SelectableLLMServerDropdown/>
            <TemplateDetectionTest/>
        </div>
    </LLMServerContextProvider>;
}