import { ChatCompletionMessageParam } from "openai/resources";
import { ChatAPICallReturnType, ChatStreamErrorCallback, DEFAULT_CHAT_API_OPTIONS } from "./GenericChatServer";
import { ChatAPICall } from "./GenericChatServer";
import { ChatAPIOptions } from "./GenericChatServer";
import { StreamingCallback } from "./GenericChatServer";

export const ERROR_MESSAGE_START_LM_STUDIO = "Could not connect to server. You probably need to launch the LM Studio server.";

const callLMStudio:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS):Promise<ChatAPICallReturnType> {
    // console.log("[callLMStudio]>messages: ",messages, "temperature: ",temperature);
    const {abortController, ...chatAPIOptionsRest} = chatAPIOptions;
    const body = {
        messages,
        temperature: chatAPIOptions.temperature,
        max_tokens: chatAPIOptions.max_tokens,
        stream: true,
        ...chatAPIOptionsRest
    };
    if (!body.temperature || body.temperature<=0 || body.temperature>=1) {
        throw new Error("[callLMStudio]>Invalid temperature: "+body.temperature);
    }
    try {
        const response = await fetch('http://192.168.1.164:1234/v1/chat/completions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            signal: chatAPIOptions.abortController?.signal
        });

        if (!response.ok) {
            if (errorCallback)
                errorCallback("Failed to fetch response: " + response.status);
            return {success:false, error:"Failed to fetch response: " + response.status};
        }
        if (!response.body) {
            if (errorCallback)
                errorCallback("Failed to fetch response: No body.");
            return {success:false, error:"Failed to fetch response: No body."};
        }
        const reader = response.body.getReader();
        let result = '';
        let done = false;
        while (!done) {
            const { value, done: isDone } = await reader.read();
            done = isDone;
            const newJSON = new TextDecoder().decode(value);
            
            const lines = newJSON.split("\n");
            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                if (line.startsWith("data: [DONE]")) {
                    done = true;
                    if (streamingCallback)
                        streamingCallback(result, "", done);
                    reader.cancel();
                } else if (line.startsWith("data: {")) {
                    const data = JSON.parse(line.substring(6));
                    // console.log("data: ", data);
                    let newContent = data.choices[0].delta.content;
                    if (newContent) {                  
                        // Handle LM Studio failing to break. This could come in over multiple tokens while streaming, so we check result for it.      
                        // This is a server side bug, it is supposed to have stopped, but it didn't.
                        // Grab the beginning of the string before the [INST] or [IST] and call it done.
                        if (result.indexOf("[INST]")>-1) {
                            done = true;
                            result = result.substring(0, result.indexOf("[INST]"));
                            reader.cancel();
                            console.log("[LMStudioChatServer] > callLMStudio > [INST] detected, stopping.");
                        }
                        if (result.indexOf("[IST]")>-1) {
                            done = true;
                            result = result.substring(0, result.indexOf("[IST]"));
                            reader.cancel();
                            console.log("[LMStudioChatServer] > callLMStudio > [IST] detected, stopping.");
                        }
                        if (chatAPIOptions.response_format?.type === "json_object") {
                            // This is not right, but it's not bad. We stop if we recieve a } creating a JSON object {}
                            if (newContent.indexOf("}") !== -1) {
                                line = newContent.substring(0, newContent.indexOf("}") + 1);
                                done=true;
                            }
                        }
                        result += newContent;
                        if (streamingCallback)
                            streamingCallback(result, data.choices[0].delta.content, done);
                    }
                }
            }
        }
        return {success:true, message:result};
    } catch (e: any) {
        let errorMessage = "";
        if (e.message === "Failed to fetch" || e.message === "NetworkError when attempting to fetch resource.")
            errorMessage = ERROR_MESSAGE_START_LM_STUDIO;
        else
            errorMessage = e.message;
        if (errorCallback)
            errorCallback(errorMessage);
        return {success:false, error:errorMessage};
    }
};

export default callLMStudio;