import React, { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";

import { Button, Spin, Tooltip, Tree } from 'antd';
import { Key } from "antd/lib/table/interface";
import { LeftSquareOutlined } from '@ant-design/icons';

import { useQuery, querySetAll, useStateURLString, getQuery } from "../../DecisionGraph/Utilities/URLQueryParams";
import { callOnAllNodes, findKeyInTree } from "./TreeUtilities/GenericTreeUtilities";
import { addIconsToTreeNodes } from "./TreeUtilities/TagTreeIcons";
import { useCreateTreeNodes } from "./TreeUtilities/CreateTagTreeNodes";
import { NoteTreeElement } from "../../User/Data/TagTreeStateType";
import { filterNodesToIDMatch, filterNodesToSearchMatches } from "./TreeUtilities/SearchTheTree";
import { NotesContext } from "../Data/NotesContext";
import { EventDataNode } from "antd/es/tree";
import { SELECTED_TAB_IS_NOTE, SELECTED_TAB_PARAMETER } from "../UIs/NotePageAndHigher/NotePage";
import { Note } from "../Data/NoteType";

const DEBUG_AUTO_EXPAND_PARENT=true;

type AntDTreeOnSelectInfoType = {
  // event: 'select';
  selected: boolean;
  node: EventDataNode<any>;
  selectedNodes: any[];
  nativeEvent: MouseEvent;
};

const ALLOW_DIVE_INTO_NODE = true;

// Nice trick, this.
// https://dev.to/alexdrocks/using-lodash-debounce-with-react-hooks-for-an-async-data-fetching-input-2p4g
// TODO refactor into separate function?
function useDebounce(value:any, wait = 500):any {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value);
    }, wait);
    return () => clearTimeout(timer); // cleanup when unmounted
  }, [value, wait]);

  return debounceValue;
}


export default function TagTree() {
  const notesContext = useContext(NotesContext);
  const navigate = useNavigate();
  const query = useQuery();
  const selectedNoteIDs:string[] = query.getAll("notes");
  // This seems like something that works best in local state, or possibly in the URL if we want to persist after refresh (which would mostly benefit in dev)
  // We've tried using a local state, and using the LocalStorage, but both of those introduce a bug where on load we aren't getting the updated tree. I'm not sure why yet.
  const expandedKeys:string[] = query.getAll("expandedKeys");
  const searchValueFromQuery:string=query.get("searchValue") || "";
  const searchValue = useDebounce(searchValueFromQuery, 300);
  const location = useLocation();
  const [lastSelectedNoteID_Expanded, setLastSelectedNoteID_Expanded] = useState<string>("");
  const [diveIntoNodeID, setDiveIntoNodeID] = useStateURLString("diveIntoNodeID","");

  function onExpand(expandedKeysValue: React.Key[]) {
    const freshQuery = getQuery();
    const expandedKeysStrings = expandedKeysValue.map((k:Key)=>k.toString());
    if (querySetAll(freshQuery,"expandedKeys",expandedKeysStrings))
      // Only execute if there was a change:
      navigate(location.pathname+"?"+freshQuery.toString());
  };
  useEffect(function() { // Auto Expand Parents
    if (selectedNoteIDs.length===1 && selectedNoteIDs[0]===lastSelectedNoteID_Expanded)
      return; // we've already expanded for this note, we won't do it again until the selection has changed.
    async function autoExpandParents() {
      const selectedNoteId = selectedNoteIDs[selectedNoteIDs.length-1];
      let expansionChanged = false;
      let newExpandedKeys = [...expandedKeys];
      // Now we traverse up the chain and check whether all the parents are expanded. if not, we expand them.
      let nextCheckNoteId = selectedNoteId;
      let lastNoteChild = null as Note | null;
      let diveNodeIsInclusive = !selectedNoteId || false;
      while (true) {
        if (!nextCheckNoteId)
          break;
        const note = await notesContext.getNote(nextCheckNoteId)
        if (!note) {
          if (nextCheckNoteId===selectedNoteId) {
            if (DEBUG_AUTO_EXPAND_PARENT) console.log("[TagTree]>autoExpandParents The selected note has been deleted.");
          } else {
            if (lastNoteChild)
              console.error("[TagTree]>autoExpandParents It looks like one of our selected notes parents has been deleted, as it could not be loaded. We need to un-corrupt the tree here. The issue is the parent of ",lastNoteChild?.doc_name,lastNoteChild);
            else
              console.error("[TagTree]>autoExpandParents It looks like one selected note itself has been deleted, as it could not be loaded. TBD what to do here.");
            debugger;
          }
          break;
        }
        lastNoteChild=note;
        // We also have to check to make sure we're not deep dived into another place. If we are, we need to undive.        
        if (diveIntoNodeID && nextCheckNoteId===diveIntoNodeID) {
          diveNodeIsInclusive = true;
          // We've reached the top-most-visible parent. Stop here, we don't need to expand further.
          break;
        }        
        if (!note.parent)
          break; // It's top level now. So we're done.
        // Expand the parent:
        if (!newExpandedKeys.includes(note.parent)) {
          newExpandedKeys.push(note.parent);
          expansionChanged = true;
        }
        nextCheckNoteId = note.parent;
      }
      
      setLastSelectedNoteID_Expanded(selectedNoteIDs[selectedNoteIDs.length-1]);
      if (expansionChanged)
        onExpand(newExpandedKeys);
      if (!diveNodeIsInclusive && diveIntoNodeID) {
        // We're not inclusive of the dive node, so we should undive -- IF AND ONLY IF THE USER JUST CHANGED SELECTION.
        setDiveIntoNodeID("");
      }
    }
    autoExpandParents();
  },[selectedNoteIDs]);

  const {treeNodes, onDrop} = useCreateTreeNodes(expandedKeys);

  // Show only the relevant ones, and add icons:
  const treeNodesFiltered = useMemo(function() {
    if (!treeNodes || treeNodes.length===0)
      return treeNodes;

    // Recursive function, called afterwards:
    let nodesWithMatches = treeNodes;
    if (diveIntoNodeID) {
      const matchNode = filterNodesToIDMatch(nodesWithMatches, diveIntoNodeID, notesContext);
      // Just its children:
      if (!matchNode) {
        if (!notesContext.isLoading)
          console.log("[TagTree] diveIntoNodeID="+diveIntoNodeID+" but we didn't find it in the tree. Since we're done loading, this is a bug.");
      } else {
        // console.log("[TagTree] diveIntoNodeID="+diveIntoNodeID+" and we found it in the tree. This is good.");
        if (matchNode.children.length===0) {
          if (!notesContext.isLoading)
            console.log("[TagTree] diveIntoNodeID="+diveIntoNodeID+" but it has no children. Since we're done loading, this must be a bug. ",matchNode);
        } else {
          nodesWithMatches = matchNode.children;
        }
      }
    }
    if (searchValue && searchValue.length>0)
      nodesWithMatches = filterNodesToSearchMatches(nodesWithMatches, searchValue, notesContext);
    
    return addIconsToTreeNodes(nodesWithMatches);
  },
  [notesContext.loadedNotes,treeNodes,searchValue, diveIntoNodeID]);


  /********************
   * Handle drag & drop
   */
  function onDragEnter(dragEnterEvent:any) {
    onExpand(dragEnterEvent.expandedKeys);
  };




  // function selectAll() {
  //   // Put the IDs from treeNodesFiltered into the URL
  //   // Get the Note IDs from treeNodesFiltered:
  //   // const noteIds = treeNodesFiltered.filter((n:any)=>n.type==="Note").map((n:any)=>n.key);
  //   // Get the Note IDs recursively from all the children of treeNodesFiltered:
  //   const noteIds = [] as string[];
  //   callOnAllNodes(treeNodesFiltered, function(n:any){if (n.type==="Note") noteIds.push(n.key)});
  //   const freshQuery = getQuery();
  //   querySetAll(freshQuery,"notes",noteIds);
  //   navigate("/bulkedit/?"+freshQuery.toString());
  // }
  /*******************************************
   * When a tree item is selected
   */
  function onSelect(selectedKeys: Key[], info:AntDTreeOnSelectInfoType) {    
    /***********
     * Which nodes should be selected?
     */
    let selectedNodes = info.selectedNodes;
    // Was it a multiple selection? If not, only select one.
    const requestMultipleSelection = info.nativeEvent && info.nativeEvent.shiftKey;
    const requestChildrenSelection = info.nativeEvent && (info.nativeEvent.ctrlKey || info.nativeEvent.metaKey);
    // console.log("requestMultipleSelection=",requestMultipleSelection,"requestChildrenSelection=",requestChildrenSelection);
    // The last key is the the user just clicked.
    // The node the user clicked:
    if (!expandedKeys.includes(info.node.key)) {
      const hasChildren = findKeyInTree(treeNodes,info.node.key, (item:any)=>item.children && item.children.length>0);
      if (hasChildren)
        //@ts-ignore
        onExpand(expandedKeys.concat([info.node.key]));
    }
    if (!requestMultipleSelection) {
      // User is selecting just one node.
      // Is it selected already?
      if (selectedNoteIDs.includes(info.node.key)) {
        // Are there others? We can deselect them.
        if (selectedNoteIDs.length>1) {
          // Deselect everything else:
          selectedNodes = [findKeyInTree(treeNodes, info.node.key)];
        } else {
          // Nope, but it's selected, so we'd deselect it
          selectedNodes=[];
        }
      } else {
        // It's not already selected. Select it:
        selectedNodes = [findKeyInTree(treeNodes, info.node.key)];
      }
    }
    if (requestChildrenSelection) {
      console.log("requestChildrenSelection of "+info.node.key);
      // User wants to select or deselect children of this node too (usually @'s children)
      // So automaticaly add those to the list, using only the node they clicked on.
      const nodeSelected = findKeyInTree(treeNodes, info.node.key);
      const isSelected = info.selected;
      if (!nodeSelected) {
        console.log("[TagTree]>onSelect Bug: could not find nodeSelected. This should be impossible since it should exist, perhaps data has become corrupted in-memory. For key "+info.node.key);
        return;
      }
      const allNodesToSelect = [nodeSelected];
      callOnAllNodes(nodeSelected.children, function(n:any){allNodesToSelect.push(n)});
      if (isSelected) {
        // Select children nodes
        for (const n of allNodesToSelect)
          // Add all nodes that aren't already in the list
          if (selectedNodes.filter((sn:NoteTreeElement)=>(sn.key===n.key)).length===0) {
            selectedNodes.push(n);
          }
      } else {
        // Deselect children nodes
        for (const n of allNodesToSelect)
          // Remove all nodes that are in the list
          if (selectedNodes.filter((sn:NoteTreeElement)=>(sn.key===n.key)).length>0) {
            selectedNodes.splice(selectedNodes.indexOf(n), 1);
          }
      }
    }
    /**********
     * Change the path based on what's selected, and if needed, create any resources.
     * uniqueSelectedTypes are currently all "Note" because it's pulling from the tag tree nodes, not the note itself.
     */
    const newSelectedNoteIDs = selectedNodes.map((n:any)=>(n.key));
    updateSelectedNoteIDs(newSelectedNoteIDs);
  }
  function updateSelectedNoteIDs(newSelectedNoteIDs:string[]) {
    const freshQuery = getQuery();
    querySetAll(freshQuery,"notes",newSelectedNoteIDs);
    if (newSelectedNoteIDs.length===0) {
      // Nothing selected, so stay on same page.
      // We do have to push the selectedNodeKeyParams which are blank.
      navigate(location.pathname+"?"+freshQuery.toString());
      return;
    }
    if (newSelectedNoteIDs.length===1) {
      // One note selected. Show that note, and the references within it.
      freshQuery.set(SELECTED_TAB_PARAMETER,SELECTED_TAB_IS_NOTE);
      navigate("/note/"+newSelectedNoteIDs[newSelectedNoteIDs.length-1]+"?"+freshQuery.toString());
      return;
    } else {
      // Multiple notes selected
      // Bulk edit operation. Let's show the JSON Table Editor, which is WIP.
      navigate("/bulkedit/?"+freshQuery.toString());  
      return;
    }
  }
  const diveIntoNode = useMemo(function() {
    if (!diveIntoNodeID)
      return null;
    const node = findKeyInTree(treeNodes, diveIntoNodeID);
    if (!node && !notesContext.isLoading)
      console.log("[TagTree] diveIntoNodeID="+diveIntoNodeID+" but we didn't find it in the tree. We're done loading, so this is a bug.");
    return node;
  },[diveIntoNodeID,treeNodes]) as NoteTreeElement;
  function onDoubleClick(info:React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (!ALLOW_DIVE_INTO_NODE)
      return;
    let antTreeNode = undefined;
    //@ts-ignore
    if (info.target.className==="ant-tree-title") {
      //@ts-ignore
      antTreeNode = info.target.childNodes[0] as HTMLSpanElement;
      //@ts-ignore
    } else if (info.target.className.includes("ant-tree-node-content-wrapper")
    ) {
      //@ts-ignore
      antTreeNode = info.target.childNodes[1].childNodes[0] as HTMLSpanElement;
      //@ts-ignore
    } else if (info.target?.getAttribute("data-node-key")) {
      //@ts-ignore
      antTreeNode = info.target as HTMLSpanElement;
    } else {
      console.log("[TagTree]>onDoubleClick Double clicked something not recognizable as a node: ",info.target);
      return;
    }

    const nodeTitle = antTreeNode.innerText;
    const nodeKey = antTreeNode.getAttribute("data-node-key");
    if (!nodeKey) {
      console.log("[TagTree]>onDoubleClick Double clicked a node with no key: ",nodeTitle);
      return;
    }
    // Get the node:
    const node = findKeyInTree(treeNodes, nodeKey);
    if (node===null) {
      console.log("[TagTree]>onDoubleClick Double clicked a node with no matching key. This is a bug, there must be a matching key since we just populated them. ",nodeTitle,"key=",nodeKey);
      return;
    }
    // Check if it has children. Note that the children property can be an empty array because we may not have loaded the data yet.
    if (node.isLeaf) {
      console.log("[TagTree]>onDoubleClick Double clicked a node with no children. We'll ignore this double-click. ",node);
      return;
    }
    // console.log("Double clicked a node: ",node);
    if (!expandedKeys.includes(nodeKey))
      onExpand(expandedKeys.concat([nodeKey]));
    setDiveIntoNodeID(nodeKey);
  }
  function titleRender(nodeData:any) {
    const node = nodeData as NoteTreeElement;
    return <span data-node-key={node.key}>
      {nodeData.title}
    </span>
  }
  function onDiveUpClick() {
    if (diveIntoNodeID) {
      // Also remove the expanded key, they wanted to see more and it's possible it was auto added:
      const index = expandedKeys.indexOf(diveIntoNodeID);
      if (index>=0) {
        const newExpandedKeys = [...expandedKeys];
        newExpandedKeys.splice(index,1);
        onExpand(newExpandedKeys);
      }
      setDiveIntoNodeID("");
    }
  }
  

  return <>
  {/* TODO reimplement select all. Right now it takes over even if the focus is not in the tagtree. We want to be able to select all elsewhere. */}
      {/* <ReactHotkeys
        keyName="control+a,command+a,alt+a"
        onKeyDown={(keyName, e, handle) => {selectAll();console.log(keyName);e.preventDefault();}}
      /> */}
    {treeNodes.length===0 && <div style={{alignItems: "center",width:"100%"}}>
      <Spin size="large" style={{alignItems: "center"}}/>
    </div>}
    {treeNodes.length>0 && <div style={{overflow: "auto",
              height: "100%"}}>
      {diveIntoNode && <div>
        <Tooltip title="Click to show all notes. (Double-clicking a parent will drill down to show just the children of that note.)">
          <Button onClick={onDiveUpClick} type="link" style={{width:"100%", textAlign:"left",borderTop:"0px",borderBottom:"0",paddingTop:"0px",paddingBottom:"0px",height:"25px"}}><LeftSquareOutlined/>Show all</Button></Tooltip>
          <Button type="text" onClick={()=>{updateSelectedNoteIDs([diveIntoNodeID])}} style={{width:"100%", textAlign:"left",borderTop:"0px",borderBottom:"0",paddingTop:"0px",paddingBottom:"0px",height:"25px"}} >{diveIntoNode.title}</Button></div>}
      <Tree
        className="draggable-tree"
        showIcon
        expandedKeys={expandedKeys}
        autoExpandParent={searchValue!==""}
        onExpand={onExpand}
        draggable={!searchValue && !diveIntoNodeID}
        blockNode
        onDragEnter={onDragEnter}
        onDrop={onDrop}
        onSelect={onSelect}
        titleRender={titleRender}
        onDoubleClick={onDoubleClick}
        // May need to update before we can use this, though it seems to say since 4.21.0 https://ant.design/components/tree-select#:~:text=%2D-,treeExpandAction,-Tree%20title%20open
        // treeExpandAction="doubleClick"
        selectedKeys={selectedNoteIDs}
        treeData={treeNodesFiltered}
        multiple
      />
    </div>}
  </>
}