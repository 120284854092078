import './GameCard.css';
import { Button, Segmented, Select, Table } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { Discipline, ReusableCard, getCardsAreSame, getReusableCardCostStr as getReusableCardCostStr, getCardEnhancementsText, AreaEffectOnCard, CardActionType, getCardDistinctName, getReusableCardCommandStringAsReact, getDefenseCardCommandStringReact } from './GameCardTypes';
import { AllReusableCards, getReusableCardEquivalentPoints } from './ReusableCards';
import { PlayerCharacterInMemoryType } from './Players';
import { getGoldUsedByCards, getPlayerLevel, getPlayerLife, getAcquirableThingsStrings, getXPUsed, AcquireableThingsType, getErrors as getErrorsInPlayer } from "./PlayerFunctions";
import { uniq } from 'lodash';
import { ALlSkillCards } from './SkillCards';
import { toJpeg } from 'html-to-image';
import { useStateURLStringArray } from '../../../DecisionGraph/Utilities/URLQueryParams';
import { useStateLSString } from '../../../DecisionGraph/Utilities/StateWithLocalCache';
import { AppstoreOutlined, PrinterOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib';
import { useReactToPrint } from 'react-to-print';

type GameCardTagsType = {name:string,type:string,count:number};
type CardSide = "front"|"back"|"both";

const AllGameCards = [...AllReusableCards, ...ALlSkillCards];

export function GameCardComponent({gameCard, cardSide}:{gameCard:ReusableCard, cardSide:CardSide}) {
    const renderFront = cardSide==="front" || cardSide==="both";
    const renderBack = cardSide==="back" || cardSide==="both";
    return <>
    {renderFront && 
        <div className={"gameCard "+gameCard.actionType+"Action"} style={{backgroundImage:"url("+gameCard.image+")"}} key={"front"}>
            <div className="headerRowLeftAligned">
                <div className="header"><span className="line1">{gameCard.name}</span><span className="line2">{gameCard.discipline}</span></div>
                <div className="headerEmptyColumn"></div>
            </div>
            <div className="gameCardContents">
                <div className="top">
                    {gameCard.commandAreaString && <AreaEffectOnCard card={gameCard}/>}
                    {/* BUG: The next line needs to be included even though it should have no effect.
                    The symptoms show up only when:
                    - printed
                    - NO card being shown has a "gameActionArea larger"
                    - the only cards that show the bug have a null gameCard.commandAreaString
                     */}
                    {!gameCard.commandAreaString && <div className="gameActionArea larger"/>}
                </div>
                <div className="middle"/>
                <div className="bottom">
                    <div className="costRow">
                        <div className="costEmptyColumn"></div>
                        <div className="cost">{getReusableCardCostStr(gameCard)}</div>
                    </div>
                    <div className="directions">
                        {getReusableCardCommandStringAsReact(gameCard)}
                        {gameCard.enhancements && gameCard.enhancements.length>0 && <>
                            <br/><br/>
                            <span className="commandColorText">{getCardEnhancementsText(gameCard)}</span></>}
                    </div>
                </div>
            </div>
        </div>}
    {renderBack && 
        <div className="gameCardBack" style={{backgroundImage:"url("+gameCard.image+")"}} key="back"/>}
    </>;
}
export function PlayerInventoryCardComponent({player}:{player:PlayerCharacterInMemoryType}) {
    const level = getPlayerLevel(player);
    return <div className="gameCard player">
        <div className="headerRowLeftAligned">
            <div className="header">
                <span className="line1">{player.characterName}</span>
                <span className="line2">{player.role?<>{player.role} {level} &nbsp;&nbsp; </>:""}<i>({player.playerName})</i></span>
            </div>
            <div className="headerEmptyColumn"></div>
        </div>
        <div className="bodyStats">
            <div className="miscContent">
                {player.supporters?<div><b>Supporters<br/></b> {player.supporters}</div>:null}
                {player.supporters && player.miscItems?<hr/>:null}
                {player.miscItems?<div><b>Miscellaneous</b><br/>{player.miscItems}</div>:null}
            </div>
        </div>
    </div>;
}
export function PlayerUpgradeCardComponent({player}:{player:PlayerCharacterInMemoryType}) {
    const usedXP = getXPUsed(player);
    const usedGold = getGoldUsedByCards(player);
    const level = getPlayerLevel(player);
    const errors = getErrorsInPlayer(player);
    
    const acquirables = getAcquirableThingsStrings(player);
    // Group them together so we can show them in a shorter list:
    const acquirablesGrouped = acquirables.reduce(function(acc:{[key:string]:string[]},acquirable:AcquireableThingsType) {
        if (!acc[acquirable.costStr])
            acc[acquirable.costStr] = [];
        acc[acquirable.costStr].push(acquirable.name);
        return acc;
    },{});

    return <div className="gameCard playerUpgrades">
        <div className="headerRowLeftAligned">
            <div className="header">
                <span className="line1">&nbsp;{player.characterName} ({player.playerName})</span>
                <span className="line2">&nbsp;&nbsp;Level {level} {player.role}</span>
            </div>
            <div className="headerEmptyColumn"></div>
        </div>
        <div className="bodyStats">
            {errors.length>0?<div className="errors">⚠ {errors.join(", ")}</div>:null}

            <div className="xpStats"><b>Can spend:</b></div>
            <div className="xpStats">&nbsp;&nbsp;{player.earnedXP-usedXP} XP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i>(of {player.earnedXP} earned)</i></div>
            <div className="goldStats">&nbsp;&nbsp;{player.earnedGold-usedGold} Gold &nbsp;&nbsp;&nbsp;&nbsp;<i>(of {player.earnedGold} found)</i></div>
            <div className="miscContent">
                <br/>
                <b>Upgrades available (choose 1 or 2):</b><br/>
                <ul>
                    {Object.keys(acquirablesGrouped).map(function (costStr:string,i:number) {
                        // Join the strings together, prefix the last one with "OR":
                        // const joinedItems = acquirablesGrouped[costStr].map((name,j) => j===acquirablesGrouped[costStr].length-1?"or "+name:name).join(", ");
                        const joinedItems = acquirablesGrouped[costStr].join(", ");
                        return <li key={i}>
                            <b>{joinedItems}</b> for {costStr} {acquirablesGrouped[costStr].length>1?"each":""}
                        </li>})}
                </ul>
            </div>
        </div>
    </div>; 
}
export function PlayerDefenseCardComponent({player}:{player:PlayerCharacterInMemoryType}) {
    const life = getPlayerLife(player);
    const level = getPlayerLevel(player);
    const heartsPerRow = 5;
    return <div className="gameCard player">
        <div className="headerRowRightAligned">
            <div className="headerEmptyColumn"></div>
            <div className="header">
                <span className="line1">{player.characterName}</span>
                <span className="line2">{player.role?<>{player.role} {level} &nbsp;&nbsp; </>:""}<i>({player.playerName})</i></span>
            </div>
        </div>
        <div className="bodyStats">
            <div className="lifeRow">
                <div className="lifeHeader">{life } Life</div>
                <div className="lifeIcons">
                    {/* TODO eventually if we get enough life, we'd put 10 per line */}
                    {Array(Math.floor(life/heartsPerRow)+1).fill(0).map((_,i) => <div key={i}>{Array(Math.min(heartsPerRow,life-i*heartsPerRow)).fill(0).map((_,j) => <span key={j} className="lifeIcon">❤️</span>)}</div>)}
                </div>
            </div>
            <br/>
            <h2>Defense</h2>
            {player.defenseCards.length===0?<div><i>No defense cards</i></div>:<div>
                {player.defenseCards.map((defenseCard,index) => <div key={index}>
                    {getDefenseCardCommandStringReact(defenseCard)}<br/>
                </div>)}
            </div>}
            
        </div>
    </div>;
}


function getPCLabel(pc:PlayerCharacterInMemoryType) {
    return pc.playerName+"'s "+pc.characterName;
}

type GameCardListType = "List" | "Cards";


export default function GameCardList({pcs}:{pcs:PlayerCharacterInMemoryType[]}) {
    const [selectedTagNames,setSelectedTagNames] = useStateURLStringArray("gameCards_selectedTagNames",[]);
    // const {value:selectedTagNames,setValue:setSelectedTagNames} = useStateLSArray("gameCards_selectedTagNames",[]) as {value:string[],setValue:(newValue:string[])=>void};
    // const [selectedTagNames, setSelectedTagNames] = useState<string[]>([]);
    const [cardSide, setCardSide] = useState<CardSide>("front");
    const [listType, setListType] = useStateLSString<GameCardListType>("gameCards_selectedListType","List");

    const {gameCardsReact, gameCardsFiltered, hasGameCardFilter, pcsFiltered} = useMemo(function() {
        let gameCardsFiltered = AllGameCards;
        let pcsFiltered = pcs;
        let hasGameCardFilter=false;
        if (selectedTagNames.length>0) {
            const selectedTags = selectedTagNames.map(tag => {
                const type = tag.slice(tag.lastIndexOf("[")+1,tag.lastIndexOf("]"));
                const name = tag.slice(0,tag.lastIndexOf("["));
                return {name,type} as GameCardTagsType;
            });
            const selectedCardType = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Card Type");
            const selectedPlayerNames = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Player");
            const selectedDisciplines = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Discipline");
            const selectedXP = selectedTags.filter((tag:GameCardTagsType) => tag.type==="XP");
            const selectedCardNames = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Action Card");

            const selectedActionType = selectedTags.filter((tag:GameCardTagsType) => tag.type==="Action Type");

            if (selectedPlayerNames.length>0) {
                // This supports multiple player selection, even other parts of this function only support one player selected.
                pcsFiltered = pcs.filter(player => selectedPlayerNames.some(tag => tag.name===player.playerName));
                pcsFiltered = pcs.filter(player => selectedPlayerNames.some(tag => tag.name===getPCLabel(player)));
                // This version only includes cards if they're in the master list. Won't work well, because cards can be dynamically created from any combo of equipment.
                gameCardsFiltered = uniq(pcsFiltered.reduce(function(acc:ReusableCard[],player:PlayerCharacterInMemoryType) {
                    return acc.concat(player.reusableCards);
                },[]));
            }
            if (selectedCardType.length>0) {
                if (selectedCardType[0].name==="Actions & Skills") {
                    // Keep our actions and skills. No need to filter them further.
                    // But don't show any player cards
                    hasGameCardFilter = true;
                } else if (selectedCardType[0].name==="Players") {
                    // Keep our players. No need to filter them further.
                    // But don't show any game cards.
                    // This must be after the "selectedPlayerNames" above to make sure it goes down to 0 when a player is selected.
                    gameCardsFiltered = [];
                }
            }

            if (selectedActionType.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedActionType.length; i++) {
                        let actionType = selectedActionType[i].name as CardActionType;
                        if (gameCard.actionType===actionType)
                            return true;
                    }
                });
                hasGameCardFilter=true;
            }
            if (selectedDisciplines.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedDisciplines.length; i++) {
                        let discipline = selectedDisciplines[i].name as Discipline;
                        if (gameCard.discipline===discipline)
                            return true;
                    }
                    return false;
                });
                hasGameCardFilter=true;
            }
            if (selectedXP.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedXP.length; i++) {
                        let xp = parseInt(selectedXP[i].name);
                        if (gameCard.xpCost===xp)
                            return true;
                    }
                    return false;
                });
                hasGameCardFilter=true;
            }
            if (selectedCardNames.length>0) {
                gameCardsFiltered = gameCardsFiltered.filter(function(gameCard:ReusableCard) {
                    for (let i=0; i<selectedCardNames.length; i++) {
                        let name = selectedCardNames[i].name;
                        if (gameCard.name===name)
                            return true;
                    }
                    return false;
                });
                hasGameCardFilter=true;
            }
        }
        const gameCardsReact = gameCardsFiltered.map(function(gameCard:ReusableCard,index:number) {
            return <GameCardComponent gameCard={gameCard} cardSide={cardSide} key={"GameCard"+index}/>;
        });
        return {gameCardsReact,gameCardsFiltered, hasGameCardFilter, pcsFiltered};
    },[selectedTagNames,cardSide]);

    
    let gameCardTags:GameCardTagsType[] = gameCardsFiltered.reduce(function(acc:GameCardTagsType[],gameCard:ReusableCard) {
        // Add Action Type tags:
        let foundActionType = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.actionType && acc[i].type==="Action Type") {
                acc[i].count++;
                foundActionType = true;
                break;
            }
        }
        if (!foundActionType)
            acc.push({name:gameCard.actionType, type:"Action Type", count:1})

        // Add Discipline tags:
        let foundDiscipline = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.discipline && acc[i].type==="Discipline") {
                acc[i].count++;
                foundDiscipline = true;
                break;
            }
        }
        if (!foundDiscipline)
            acc.push({name:gameCard.discipline, type:"Discipline", count:1});

        // Add XP tags:
        let foundXP = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.xpCost+" XP" && acc[i].type==="XP") {
                acc[i].count++;
                foundXP = true;
                break;
            }
        }
        if (!foundXP)
            acc.push({name:gameCard.xpCost+" XP", type:"XP", count:1});

        // Add Name tags:
        let foundName = false;
        for (let i=0; i<acc.length; i++) {
            if (acc[i].name===gameCard.name && acc[i].type==="Action Card") {
                acc[i].count++;
                foundName = true;
                break;
            }
        }
        if (!foundName)
            acc.push({name:gameCard.name, type:"Action Card", count:1});

        
        return acc;
    },[]);
    // Add action & skill card tag.
    // These are the types that you can buy with XP.
    if (gameCardTags.length>0) {
        const count = gameCardTags.reduce((acc,tag) => acc+tag.count,0);
        gameCardTags.push({name:"Actions & Skills",type:"Card Type",count});
    }

    const pcTags = pcsFiltered.map(function(pc:PlayerCharacterInMemoryType) {
        // Count the number of cards that the player owns that are in the filtered list:
        const playerCards = pc.reusableCards.filter(playerCard => gameCardsFiltered.some(filteredCard => getCardsAreSame(filteredCard,playerCard)));
        return {name:getPCLabel(pc), type:"Player", count:playerCards.length};
    });
    // Add a tag for all players:
    if (pcsFiltered.length>0) {
        const count = pcsFiltered.reduce((acc,tag) => acc+1,0);
        gameCardTags.push({name:"Players",type:"Card Type",count});
    }
    gameCardTags = gameCardTags.concat(pcTags);
    let playersDiv = [] as JSX.Element[];
    if (pcsFiltered.length>0 && !hasGameCardFilter) 
        playersDiv = pcsFiltered.map(function(player:PlayerCharacterInMemoryType,index:number) {
            return <span key={"player"+index}>
                <PlayerInventoryCardComponent player={player} key={"player"+index}/>
                <PlayerDefenseCardComponent player={player} key={"player"+index+"defense"}/>
                <PlayerUpgradeCardComponent player={player} key={"player"+index+"upgrade"}/>
            </span>;
        });

    function exportGridToJpeg() {
        const cardDeckElement = document.getElementById('cardDeck') as HTMLElement;
        // We want to print to an image without the margin, to be compatible with TableTop Similator's grid, so we remove the margin, then add it back after the image is created.
        cardDeckElement.className="cardDeckNoMargin";
        toJpeg(cardDeckElement, { quality: 1, pixelRatio: 5, style: {margin: "0px"} })
            .then(function (dataUrl) {
            var link = document.createElement('a');
            // Create a custom name based on the filters. Start with the player's name, if there's only one player:
            let fileName = "Powers TTRPG Cards";
            if (pcsFiltered.length===1)
                fileName = pcsFiltered[0].playerName+"'s Deck";
            else if (selectedTagNames.length===0)
                fileName = "Powers TTRPG - All Cards";
            
            link.download = fileName+'.jpeg';
            link.href = dataUrl;
            link.click();
            cardDeckElement.className="cardDeckWithMargin";
        });
    }


    const tableColumns:TableProps<ReusableCard>['columns'] = useMemo(()=>{
        const tableColumnsInner = [{
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (value:any, card:ReusableCard) => {
                    return getCardDistinctName(card);
                },
                width:100,
            },{
                title: "Text",
                key: "text",
                render: (value:any, card:ReusableCard) => {
                    return getReusableCardCommandStringAsReact(card, true)
                },
                width:300,
            },
            /*{
                title: "Type",
                dataIndex: "actionType",
                key: "actionType"
            }, */{
                title: "Points",
                dataIndex: "points",
                key: "points",
                render: (value:any, card:ReusableCard) => {
                    const points = getReusableCardEquivalentPoints(card);
                    return Math.round(points*10)/10;
                },
                width:40,
            }
        ] as TableProps<ReusableCard>['columns'];
        return tableColumnsInner;
    },[]);
    const [selectedCards,setTableRowSelection] = useState([] as ReusableCard[]);
    function onTableRowSelectionChange(selectedRowKeys: React.Key[], selectedRows: ReusableCard[]) {
        setTableRowSelection(selectedRows);
    }
    function getCheckboxPropsForTable(record: ReusableCard) {
        return {
            disabled: false
        };
    }
    const gameCardsFilteredForTable = useMemo(()=>{
        
        return gameCardsFiltered.map(
            (card:ReusableCard,index:number)=>{
                // const points = getReusableCardEquivalentPoints(card);
                return {...card,
                    key:(card.name+card.discipline+index),
                    // points,
                } as ReusableCard/* & {key:string};*/
            });
    },[gameCardsFiltered]);


    const componentToPrint = useRef();
    const handlePrint = useReactToPrint({
      content: function() {
        if (componentToPrint.current)
            return componentToPrint.current;
        return null;
      },
    });


    return <>
        <div className="noprint site-layout-background" style={{ padding: 24, paddingTop:0}}>
            Tags ({gameCardTags.length}) &nbsp;<Select mode="multiple" style={{width: "90%"}} placeholder="Select Tags" value={selectedTagNames} onChange={setSelectedTagNames}>
                {gameCardTags.map(tag => <Select.Option key={tag.name} value={tag.name+"["+tag.type+"]"}>{tag.name} [{tag.type}] ({tag.count})</Select.Option>)}
                </Select><br/>
            <Segmented value={listType} onChange={(s:string)=>setListType(s as GameCardListType)} options={[
                {value:"List", label:"List", icon: <UnorderedListOutlined />},
                {value:"Cards", label: "Cards", icon: <AppstoreOutlined />}]}/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Select style={{width: "200px"}} value={cardSide} onChange={setCardSide}>
                <Select.Option value="front">Front</Select.Option>
                <Select.Option value="back">Back</Select.Option>
                <Select.Option value="both">Both</Select.Option>
            </Select>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Button onClick={handlePrint} icon={<PrinterOutlined/>}>Print cards</Button>
            {/* <Button onClick={exportGridToJpeg}>Export to Tabletop Simulator Card Deck JPG</Button> */}
        </div>
        {listType==="Cards" &&
            // @ts-ignore
            <div id="cardDeck" className="cardDeckWithMargin" ref={componentToPrint}>
                {playersDiv}
                {gameCardsReact}
            </div>
            }
        {listType==="List" && <>
            <div style={selectedCards.length>0?{width:"50%",display:"inline-block",verticalAlign:"top"}:undefined}>
                <Table
                    columns={tableColumns}
                    dataSource={gameCardsFilteredForTable}

                    rowSelection={{
                        type: 'checkbox',
                        onChange: onTableRowSelectionChange,
                        getCheckboxProps:getCheckboxPropsForTable
                    }}

                    scroll={{y:"calc( 100vh - 200px )"}}
                    size="small" pagination={false}/>
            </div>
            
            {selectedCards.length>0 && 
                <div style={{width:"50%",display:"inline-block",verticalAlign:"top"}}>
                    {/* @ts-ignore */}
                    <div ref={componentToPrint} id="cardDeck" className="cardDeckWithMargin">
                    {selectedCards.map(function(gameCard:ReusableCard,index:number) {
                        return <GameCardComponent gameCard={gameCard} cardSide={cardSide} key={"GameCard"+index}/>;
                    })}
                    </div>
                </div>
            }
        </>}
    </>;
}