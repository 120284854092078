
import { NoteTreeElement } from "../../../User/Data/TagTreeStateType";
import { Note } from "../../Data/NoteType";
import { NotesContextType } from "../../Data/NotesContext";

/************************************
 * 
 * BUG 
 * Searches do not search the server, only currently loaded notes on the client.
 * 
 * We need to fix this bug and search from the server.
 * 
 * 
 */

function doesObjectMatchSearch(searchTerms:string[], jsonObject:any) {
    for (const key in jsonObject) {
      const value = jsonObject[key];
      const valType = typeof(value)
      if (valType==="object") {
        // Iterate:
        if (doesObjectMatchSearch(searchTerms,value))
          return true;
      } else if (valType==="string") {
        for (const searchTerm of searchTerms) {
          if (value.includes(searchTerm))
            return true;
          const keyValuePair = (key+"="+value).toLowerCase();
          // console.log("Searching for "+searchTerm+" in "+keyValuePair);
          if (keyValuePair.includes(searchTerm))
            return true;
        }
      }
    }
    return false;
  }
  
  /* Check whether a tree node matches.
  notes is passed in so we can search the notes (finding the one that matches the node) */
  export function doesThisNodeMatchSearch(searchTerms:string[], node:NoteTreeElement, notes:Note[]) {
    for (const searchTerm of searchTerms) {
      if (node.title.toLowerCase().indexOf(searchTerm) > -1) {
        return true;
      }
      // We also accept exact matches for the key:
      if (node.key===searchTerm) {
        return true;
      }
      if (!searchTerm.startsWith("#") && !searchTerm.startsWith("@")) {
        // const lowercase=searchTerm.toLowerCase();
        for (const note of notes) {
          if (node.title===note.doc_name) {
            const {doc_data, id, ...otherProps} = note;
            // Don't search ID
  
            // Search the note text itself here.
            for (const block of doc_data.blocks) {
              if (block.text.toLowerCase().includes(searchTerm)) {
                // console.log("Found '"+searchTerm+"' in "+note.doc_name+"->"+block.text);
                return true;
              }
            }
            
            if (doesObjectMatchSearch(searchTerms,otherProps))
              return true;
            // if (note.project_data && doesObjectMatchSearch(searchTerms,note.project_data))
              // return true;
          }
        }
      }
    }
    return false;
  }
  
  /*******
   * Currently searches for "or" anytime there's a space.
   * 
   */
  // export function getSearchTerms(searchPhrase:string):string[] {

  //   // If it starts with a quote, make it an exact match.
  //   if (searchPhrase.startsWith("\"")) {
  //     if (searchPhrase.endsWith("\"")) {
  //       // TODO do we also need to return some semantic info so it knows there's quotes around this?
  //       // e.g. we want to match "quest" but not "question".
  //       // Or should we just start using Algolia?
  //       return [searchPhrase.substring(1,searchPhrase.length-1).toLowerCase()];
  //     }
  //     return [searchPhrase.substring(1).toLowerCase()];
  //   }
  //   const searchTerms = searchPhrase.split(" ").map((t)=>t.toLowerCase());
  //   return searchTerms;
  // }
  
  export function getSearchTerms(searchPhrase: string): string[] {
    const searchTerms: string[] = [];
    let currentTerm = '';
    let inQuotes = false;
  
    for (let i = 0; i < searchPhrase.length; i++) {
      const char = searchPhrase.charAt(i);
  
      if (char === '"') {
        inQuotes = !inQuotes;
      } else if (char === ' ' && !inQuotes) {
        if (currentTerm.length > 0) {
          searchTerms.push(currentTerm.toLowerCase());
          currentTerm = '';
        }
      } else {
        currentTerm += char;
      }
    }
  
    if (currentTerm.length > 0) {
      searchTerms.push(currentTerm.toLowerCase());
    }
  
    return searchTerms;
  }

  
export function filterNodesToSearchMatches(data:NoteTreeElement[], searchValue:string, notesContext:NotesContextType):NoteTreeElement[] {
  let newNodesWithMatches = [] as NoteTreeElement[];
  const searchTerms = getSearchTerms(searchValue);
    
  data.forEach(function(item:NoteTreeElement):void {
    let childrenNodesWithMatches = [] as NoteTreeElement[];
    // TODO can we still do this up here, but add the items later?
    if (item.children) {
      childrenNodesWithMatches = filterNodesToSearchMatches(item.children, searchValue, notesContext);
    }
    if (!doesThisNodeMatchSearch(searchTerms, item, notesContext.loadedNotes)) {
      newNodesWithMatches = [...newNodesWithMatches, ...childrenNodesWithMatches];
      return;
    }
    const index = item.title.indexOf(searchValue);
    const beforeStr = item.title.substring(0, index);
    const afterStr = item.title.substring(index + searchValue.length);
    const title =
      index > -1 ? (
        <span>
          {beforeStr}
          <span className="site-tree-search-value">{searchValue}</span>
          {afterStr}
        </span>
      ) : (
        <span>{item.title}</span>
      );
    // if (nodesWithMatches.filter(function(node:NoteTreeElement){return node.key===item.key}).length>0)
    //     // It's already in the list, don't add it again.
    //     return;
    //@ts-ignore
    newNodesWithMatches.push({
      ...item,
      title,
      originalTitle:item.title,//so we can use it for utilities. There must be a better way.
      // Whether to include children is a bit tricky. Right now it's very messy if there are multiple matches.
      // We chose to try to make it work by showing children only if none of its children matched. Alternatively it could be if it's the only result.
      children:childrenNodesWithMatches.length>0?[]:item.children, //item.children // it's okay if they want to expand it. But it's a cleaner list if we don't include children.
      isLeaf:childrenNodesWithMatches.length>0?true:item.children.length===0,
    } as NoteTreeElement);
    if (childrenNodesWithMatches.length>0)
      newNodesWithMatches = [...newNodesWithMatches, ...childrenNodesWithMatches];
  });
  return newNodesWithMatches;
}


  
export function filterNodesToIDMatch(data:NoteTreeElement[], key:string, notesContext:NotesContextType):NoteTreeElement|null {
  let match = null;
    
  data.forEach(function(item:NoteTreeElement):void {
    if (item.key===key) {
      // console.log("[filterNodesToIDMatch] Found match in children", item);
      match = item;
      return;
    }

    if (item.children) {
      const possibleMatch = filterNodesToIDMatch(item.children, key, notesContext);
      if (possibleMatch) {
        match = possibleMatch;
        // console.log("[filterNodesToIDMatch] Found match in children 1",match);
        return;
      }
    }
  });
  return match;
}