import { JSONSchemaType } from "ajv";
import { Extension, ExtensionJSONFormsObject, ExtensionNoteTemplateType } from "../ExtensionsFramework/ExtensionsList";
import { JSONFormsObjectContextType } from "../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { Note } from "../../Notes/Data/NoteType";
import { basicSaveOperation, CoreOperation, OPERATION_COMPLETE, OPERATION_SELECT, OperationRenderable } from "../ExtensionsFramework/SaveOperationsBasics";
import { NavigateToNoteFunction } from "../../DecisionGraph/Utilities/NavigateTo";


export type SaveNoteType = ExtensionJSONFormsObject & {
    Complete?: string[],
    Select?: string[],
};

export const SaveNoteSchema:JSONSchemaType<SaveNoteType> = {
    type: "object",
    properties: {
        name: {type: "string", nullable: false},
        id: {type: "string", nullable: false},
        version: {type: "number", nullable: false},
        Complete: {
            type: "array",
            items: {type: "string"},
            nullable: true,
        },
        Select: {
            type: "array",
            items: {type: "string"},
            nullable: true,
        },
    },
    required: [],
};

export const Save_NoteType:ExtensionNoteTemplateType<SaveNoteType> = {
    name: "Save",
    emoji: "💾",
    description: "A note that contains the current relevant global context. It also defines which extensions to use.",
    template_doc_ids: ["832332c1-47b3-45f1-905e-ce32d434b563"], // empty template
    schema: SaveNoteSchema,
};

export const HowTo_NoteType:ExtensionNoteTemplateType<any> = {
    name: "How To",
    emoji: "📖",
    template_doc_ids: ["4fad9983-8bf6-4951-84ba-4591fb8c5859"], // empty template
    description: "A prompt optimized for the LLM (but can also be read by people) that explains how to handle a task or situation.",
};

function getOperationsForNote_DefaultSave(noteContext:JSONFormsObjectContextType, note:Note):OperationRenderable[] {
    let operations = [] as OperationRenderable[];
    const completableOp = OPERATION_COMPLETE;
    const selectableOp = OPERATION_SELECT;
    let isSelected = noteContext.jsonFormsObject[OPERATION_SELECT] && noteContext.jsonFormsObject[OPERATION_SELECT].includes(note.id);
    let isCompleted = noteContext.jsonFormsObject[OPERATION_COMPLETE] && noteContext.jsonFormsObject[OPERATION_COMPLETE].includes(note.id);
    if (selectableOp) {
        if (isSelected) {
            operations.push({operation:OPERATION_SELECT, text:"Undo "+selectableOp, add:false});
        } else {
            // Not selected:
            if (!isCompleted)
                operations.push({operation:OPERATION_SELECT, text:selectableOp, add:true});
        }
    }
    if (completableOp) {
        if (noteContext.jsonFormsObject[OPERATION_COMPLETE] && noteContext.jsonFormsObject[OPERATION_COMPLETE].includes(note.id))
            operations.push({operation:OPERATION_COMPLETE, text:"Undo "+completableOp, add:false});
        else if (isSelected)
            operations.push({operation:OPERATION_COMPLETE, text:completableOp, add:true});
    }
    return operations;
}
function saveOperation_DefaultSave(noteContext:JSONFormsObjectContextType, note:Note, operation:OperationRenderable, navigateToNote:NavigateToNoteFunction) {
    const otherOperations = [] as CoreOperation[];
    if (operation.operation===OPERATION_COMPLETE) {
        otherOperations.push(OPERATION_SELECT);
        if (operation.add)
            // We also want to navigate back to the Save note, because this is completed.
            navigateToNote(noteContext.note.id);
    }
    // The following branch is probably not reachable.
    else if (operation.operation===OPERATION_SELECT)
        otherOperations.push(OPERATION_COMPLETE);
    basicSaveOperation(noteContext, note, operation, otherOperations);
}

export const DefaultNoteTemplateTypes = [
    Save_NoteType,
    HowTo_NoteType,
] as ExtensionNoteTemplateType<any>[];

// export const DefaultExtension:Extension = {
//     name: "Default",
//     description: "Default for all notes",
//     included_notes_doc_ids: [],
//     noteTemplateTypes: [
//         Save_NoteType,
//         HowTo_NoteType,
//     ] as ExtensionNoteTemplateType<any>[],
//     relatedListTypes: [
        
//     ],
//     getOperationsForNote: getOperationsForNote_DefaultSave,
//     saveOperation: saveOperation_DefaultSave
// };
