import { convertFromHTML } from "draft-convert";
import { convertToRaw } from "draft-js";
import { getNoteIDFromURL, getURLForNoteId } from "../../../DecisionGraph/Utilities/NavigateTo";
import { markdownToHTML } from "../../../ServerConnection/LLMServer/MarkdownConverter";

export function convertHTMLToDraftJSRaw(noteHTML: string) {
    const contentState = convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
            if (nodeName === 'span' && node.style.color === 'blue') {
                return currentStyle.add('BLUE');
            } else {
                return currentStyle;
            }
        },
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName !== 'a') {
                return;
            }
            let href = node.href as string;
            // If the URL has a full domain, we update it to a local URL.
            const noteId = getNoteIDFromURL(href);
            if (noteId) {
                href = getURLForNoteId(noteId);
                return createEntity(
                    'LINK',
                    'MUTABLE',
                    {url: node.href}
                )
            }
        },
        textToEntity: (text, createEntity) => {
            const result = [] as any[];
            //@ts-ignore
            text.replace(/\@(\w+)/g, (match, name, offset) => {
                const entityKey = createEntity(
                    'AT-MENTION',
                    'IMMUTABLE',
                    {name}
                );
                result.push({
                    entity: entityKey,
                    offset,
                    length: match.length,
                    result: match
                });
            });
            return result;
        },
        htmlToBlock: (nodeName, node) => {
            if (nodeName === 'blockquote') {
                return {
                    type: 'blockquote',
                    data: {}
                };
            }
        }
    })(noteHTML);
    const doc_data = convertToRaw(contentState);

    return doc_data;
}

export function convertMarkdownToDraftJSRaw(markdown: string) {
    let html = markdownToHTML(markdown);
    // html = html.replaceAll("<blockquote>\n  <p>","<blockquote>").replaceAll("</p>\n</blockquote>","</blockquote>");
    // This removes the additional line at the end of blockquotes. It might only be necessary when going from markdown -> html -> draft-js
    html = html.replaceAll("</p>\n</blockquote>","</p></blockquote>")
    // This removes the additional space before the blockquote starts. Again, it might only be necessary when going from markdown -> html -> draft-js
        .replaceAll("<blockquote>\n  ","<blockquote>");
    const draftJSRaw = convertHTMLToDraftJSRaw(html);
    return draftJSRaw;
}

// Used to debug & test some formatting issues.
// Alternative: we could use this suite for markdown -> draft-js as it doesn't seem to have this problem: https://www.roserobertson.me/markdown-draft-js/
// function testCovertMarkdownToDraftJSRaw() {
//     const markdown =
// `# Hello World

// > This is a blockquote line 1
// > This is a blockquote line 2

// This is not a blockquote.
//     `;
//     let html = markdownToHTML(markdown);
//     html = html.replaceAll("</p>\n</blockquote>","</p></blockquote>")
//     // This removes the additional space before the blockquote starts. Again, it might only be necessary when going from markdown -> html -> draft-js
//         .replaceAll("<blockquote>\n  ","<blockquote>");
//     const draftJSRaw = convertHTMLToDraftJSRaw(html);

//     // debugger;
// }
// if (GLOBAL_DEBUG_INLINE_TESTS)
//     testCovertMarkdownToDraftJSRaw();