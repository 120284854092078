import { Extension } from "./ExtensionsList";
import { Note } from "../../Notes/Data/NoteType";

/******
 * TODO generalize this even more. We want to get passed in the note hierarchy.
 */
export function getSchemaFor(extensions:Extension[], note:Note) {
    for (const extension of extensions) {
        for (const noteType of extension.noteTemplateTypes) {
            if (noteType.name === note.doc_name) {
                // Exact match, using the old format.
                return noteType.schema;
            }
            if (note.template_doc_id && noteType.template_doc_ids && noteType.template_doc_ids.includes(note.template_doc_id)) {
                return noteType.schema;
            }
            // TODO search for the note type in the fully included children of this note. Check for the name in the template.
        }
    }
}

export function getEmojiFor(extensions:Extension[], note:Note) {
    if (!note.template_doc_id)
        return "";
    for (const extension of extensions) {
        for (const noteType of extension.noteTemplateTypes) {
            if (noteType.template_doc_ids?.includes(note.template_doc_id))
                return noteType.emoji;
        }
    }
}