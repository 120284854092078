import { Tabs } from 'antd';

import { JSONFormsSchema} from '../../Notes/Data/NoteType';
import { RawJSONEditor } from '../../JSONEditing/RawJSONEditing/RawJSONEditor';
import JSSchemaEditor from '../../JSONEditing/JSONSchemaBasedEditors/JSSchemaEditor';
import TabPane from 'antd/lib/tabs/TabPane';
import { useSaveJSONFormsSchemaCallback } from '../Data/Actions/JSONFormsObject/LoadAndSaveJSONSchemaObject';
import { ReactElement, useContext } from 'react';
import { SelectedJSONFormsContext } from '../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
// import { rankWith, scopeEndsWith } from '@jsonforms/core';
// import RatingControl from './RatingControl';




/***********
 * type=Type
 * 
 * This is a schema editor for other objects.
 */
 export function TypeEditor({promptContextTab}:{promptContextTab:ReactElement}) {

    // TODO TypeEditor shouldn't be using the SelectedJSONFormsContext, which only allows saving the object itself.
    // Instead, we should have a context for the Schema & UI Schema that uses server connected storage.

    //@ts-ignore doc_id will never be null
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const note = selectedJsonFormsContext.note;
    const doc_id = note?.id;
    const jsonFormsSchema = selectedJsonFormsContext.jsonSchema;
    const jsonFormsUISchema = selectedJsonFormsContext.jsonFormsUISchema;
    
    const saveJSONFormsSchema = useSaveJSONFormsSchemaCallback();
    // const doc_name = note?.doc_name;


    /* Schemas will be stored in the database a strings. They are JSON, but if they're JSON they will not edit correctly with Firestore's "MERGE" command.
        We do want to use firestore's merge command because this is the only property.
        There might be a workaround to store as JSON objects, but we have no reason to want to do that -- except MAYBE a very tiny efficiency improvement
        (partly very small since we'd have to stringify anyways to make a copy).
     */

    function saveTypeSchemaAsString(typeSchemaObj:object) {
        if (doc_id) {
            let changed = {"type-schema":JSON.stringify(typeSchemaObj)} as JSONFormsSchema;
            if (jsonFormsUISchema)
                changed["type-ui-schema"] = JSON.stringify(jsonFormsUISchema);
            saveJSONFormsSchema(doc_id, changed);
        }
    }


    function saveTypeUiSchemaAsString(uiSchemaObj:object) {
        if (doc_id) {
            const changed = {"type-schema":JSON.stringify(jsonFormsSchema),"type-ui-schema":JSON.stringify(uiSchemaObj)} as JSONFormsSchema;
            saveJSONFormsSchema(doc_id, changed);
        }
    }


    return <Tabs defaultActiveKey="1" destroyInactiveTabPane={true}>
        {/* Something is wrong with JsonSchemaEditor or the way we implement it.
        It's saving stale data. Can't figure out why because the doc_name is right, but schema is old. */}
        <TabPane tab="Object Schema Editor"  key="1">
            <JSSchemaEditor formSchemaObj={jsonFormsSchema} saveFunc={saveTypeSchemaAsString}/>
        </TabPane>
        <TabPane tab="Raw Object Schema JSON" key="2">
            {note && jsonFormsSchema && <>
                Warning: After editing, refresh the page to see the changes. There is a bug that while your edits are saved correctly, they disappear from this page.
                <RawJSONEditor jsonStr={JSON.stringify(jsonFormsSchema)} saveFunc={saveTypeSchemaAsString} showSaveButton={false} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://jsonforms-editor.netlify.app/" target="_blank" rel="noreferrer">Open JSON Forms Editor</a>
            </>}
        </TabPane>
        <TabPane tab="Raw UI Schema JSON" key="3">
            Warning: After editing, refresh the page to see the changes. There is a bug that while your edits are saved correctly, they disappear from this page.
            <RawJSONEditor jsonStr={JSON.stringify(jsonFormsUISchema)} saveFunc={saveTypeUiSchemaAsString}  showSaveButton={false}/>
            <br/><br/>
            This is a JSON Forms UI Schema. It can safely be left blank -- you only need to edit it if you want to customize the UI design further.<br/>
            <a href="https://jsonforms-editor.netlify.app/" target="_blank" rel="noreferrer">Open JSON Forms Editor</a>
        </TabPane>
        <TabPane tab="Prompt Context" key="4">
            {promptContextTab}
        </TabPane>
    </Tabs>;
}