import { NoteTreeElement } from "../../../User/Data/TagTreeStateType";



// Callback will be called 1 or 0 times, since there should only be one matching key in any particular tree.
export function findKeyInTree(data:NoteTreeElement[], key:string, callback:any = (n:NoteTreeElement)=>n):NoteTreeElement|null {
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        // Keys should be unique, so return after finding the first one.
        const possibleReturnValue=
          findKeyInTree(data[i].children, key, callback);
        if (possibleReturnValue)
          return possibleReturnValue;
      }
    }
    return null; // not found
};

  
/* Useful, but not used yet
 * Callback will be called multiple times. Will search full tree.
  */
export function findTypeInTree(data:NoteTreeElement[], type:string, callback:(elem:NoteTreeElement,i:number,noteTreeElementList:NoteTreeElement[])=>any) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].type === type) {
        callback(data[i], i, data);
      }
      if (data[i].children) {
        findTypeInTree(data[i].children, type, callback);
      }
    }
};
  
export function callOnAllNodes(nodeList:NoteTreeElement[], callback:(elem:NoteTreeElement,i:number,noteTreeElementList:NoteTreeElement[])=>any) {
    for (let i = 0; i < nodeList.length; i++) {
      callback(nodeList[i], i, nodeList);
      if (nodeList[i].children) {
        callOnAllNodes(nodeList[i].children, callback);
      }
    }
};
  
export function getParentKey(key:string, tree:any[]):any {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item:any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
}

export function isNodeInArray(key:string, list:any[]):boolean {
  if (list.some((item:any) => item.key === key)) {
    return true;
  }
  return false;
}

export function getParentNode(key:string, tree:any[]):any {
    let parentNode;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (isNodeInArray(key, node.children))
          parentNode = node;
        
        // if (node.children.some((item:any) => item.key === key))
          // parentNode = node;
        else if (getParentNode(key, node.children)) {
          parentNode = getParentNode(key, node.children);
        }
      }
    }
    return parentNode;
}

// Create flat list of nodes to search through, to make it easier to find stuff
export function convertTreeToFlatList(treeNodes:any[]):any[] {
  const dataList:any[] = [];
  function generateList(data:any[]) {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      dataList.push({ key, title: title });
      if (node.children) {
        generateList(node.children);
      }
    }
  }
  generateList(treeNodes);
  return dataList;
}
