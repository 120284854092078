import { Button } from "antd";
import { EditorState } from "draft-js";
import { scrollSelectionIntoViewInAMoment } from "../BlockEditing/EditCheckableItemUtils";

export function DraftJSOutline({editorState, setEditorState}:{editorState:EditorState, setEditorState:(editorState:EditorState)=>void}) {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    const blockKeys = blockMap.keySeq().toArray();
    function onClickBlock(blockKey:string) {
      const selection = editorState.getSelection();
      const newSelection = selection.merge({
        anchorKey: blockKey,
        focusKey: blockKey,
        anchorOffset: 0,
        focusOffset: 0,
      });
      setEditorState(EditorState.forceSelection(editorState,newSelection));
      scrollSelectionIntoViewInAMoment();
    }
  
    return <div>
      <ul style={{listStyleType:"none", paddingLeft:10}}>
        {blockKeys.map((blockKey,i)=>{
            const block = blockMap.get(blockKey);
            const blockType = block.getType();
            if (!(blockType==="header-one" || blockType==="header-two" || blockType==="header-three"))
                return null;
            const indent = blockType==="header-one"?0:blockType==="header-two"?1:2;
            
            const blockText = block.getText();
            if (!blockText.trim())
                return null;
            return <li key={i}>
              <Button type="link" onClick={()=>onClickBlock(blockKey)} style={{padding:0, fontSize:(indent===0?"inherit":"small")}}>
                <span className="indentForHeader" style={{paddingLeft:indent*10}}></span>{blockText}</Button></li>;
        })}
      </ul>
    </div>;
}