import { useFunctions } from "reactfire";
import { ChatAPICall, ChatAPICallReturnType, ChatAPIOptions, ChatStreamErrorCallback, DEFAULT_CHAT_API_OPTIONS, StreamingCallback } from "./GenericChatServer";
import { HttpsCallableOptions, httpsCallable } from "firebase/functions";
import { ChatCompletionMessageParam } from "openai/resources";

const SERVER_TIMEOUT_MINS = 4; // 4 minutes. The source of truth is in our server functions in opeaifunctions.js

const DEBUG = true;

export function useVertexViaFirebaseChatCommand() {
    const functions = useFunctions();
    const options: HttpsCallableOptions = {
        timeout: 1000*60*SERVER_TIMEOUT_MINS+1000, // milliseconds
    };

    const remoteCommandChat = httpsCallable(functions, "vertexai_commandChat", options);

    const callFirebaseVertex:ChatAPICall = async function (messages:ChatCompletionMessageParam[], streamingCallback?:StreamingCallback, errorCallback?:ChatStreamErrorCallback, chatAPIOptions:ChatAPIOptions=DEFAULT_CHAT_API_OPTIONS):Promise<ChatAPICallReturnType> {
        if (chatAPIOptions.response_format?.type && chatAPIOptions.response_format?.type!=="text")
            throw new Error("Vertex AI only supports text response format.");
        if (chatAPIOptions.tool_choice)
            throw new Error("Vertex AI does not support tool_choice.");
        if (chatAPIOptions.tools)
            throw new Error("Vertex AI does not support tools.");
        const {temperature} = {...DEFAULT_CHAT_API_OPTIONS, ...chatAPIOptions};
        console.log("[callFirebaseVertexChat]>messages: ",messages, "temperature: ",temperature);
        if (!temperature || temperature<=0 || temperature>=1) {
            throw new Error("[callFirebaseVertexChat]>Invalid temperature: "+temperature);
        }
        const startTime = Date.now();

        async function makeTheCall(retries:number=1, attemptsSoFar:number=0):Promise<ChatAPICallReturnType> {
            try {
                if (DEBUG) console.log("[callFirebaseVertexChat] > sendChatMessage > chat to send: ",messages);
                const chatAPIParameters = {
                    messages,
                    temperature,
                };
                /*if (DEBUG)*/ console.log("[callFirebaseVertexChat] > sendChatMessage > chatAPIParameters: ",chatAPIParameters);
                const commandResponse = await remoteCommandChat(chatAPIParameters) as {data: {succeeded:boolean, error?:string, choices: {text:string}[]}};
                if (DEBUG) console.log("[callFirebaseVertexChat] > sendChatMessage > completed commandResponse: ",commandResponse);
                // console.log(commandResponse);
                if (!commandResponse.data.succeeded || commandResponse.data.choices.length==0) {
                    if (errorCallback)
                        errorCallback("Error: "+commandResponse.data.error);
                    return {success:false, error:commandResponse.data.error};
                } else {
                    const endTime = Date.now();
                    const timeTakenMinutes = (endTime-startTime)/1000/60;
                    console.log("Vertex API completed in "+timeTakenMinutes+" minutes.");
                    const text = commandResponse.data.choices[0].text; // Slightly different from OpenAI API
                    if (streamingCallback)
                        streamingCallback(text,text, true);
                    return {success:true, message:text};
                }
            } catch (error) {
                // Check to see if this is a firebase internal error:
                //@ts-ignore
                if (error.name==="FirebaseError" && error.message==="deadline-exceeded") {
                    // We can retry once. It's likely it'll fail again, though...
                    const endTime = Date.now();
                    const timeTakenMinutes = (endTime-startTime)/1000/60;
                    console.error("Error: Vertex API has taken "+timeTakenMinutes+" minutes (the server timeout is "+SERVER_TIMEOUT_MINS+" minutes). That's "+(attemptsSoFar+1)+" attempts at up to "+SERVER_TIMEOUT_MINS+" mins each...");
                    if (retries>0) {
                        console.log("... Retrying "+(retries)+" more times...");
                        return await makeTheCall(retries-1, attemptsSoFar+1);
                    } else {
                        console.error("... We've exceeded retries. Giving up.");
                        alert("Error: The ChatGPT server is running very slow right now. Please try again later.");
                        if (errorCallback)
                            errorCallback("Error: The ChatGPT server is running very slow right now. Please try again later.");
                        return {success:false, error:"Error: The ChatGPT server is running very slow right now. Please try again later."};
                    }
                }
                // We get them when Vertex takes extra long to reply, though we just set the timeout to now be 2 minutes -- but that's really long.
                if (chatAPIOptions.abortController?.signal.aborted) {
                    // Not a problem!
                } else {
                    // Is this still an unknown error? If so, let's take a look at it in the debugger.
                    console.error(error);
                    debugger;
                    if (errorCallback)
                        errorCallback("Error: "+error);
                }
                return {success:false, error:"Error: "+error};
            }
        }
        return await makeTheCall();
   };
    return callFirebaseVertex;
}