/*******************************
 * All Firestore events for Notes (aka our basic doc) go here
 * 
 * Part of the reason for keeping them together, and separated like this, is that eventually, to scale further,
 * many of these may need to be executed server side.
 * 
 */
import { MentionData } from "@draft-js-plugins/mention";
import { v4 as uuidv4 } from 'uuid';
import { NewEmptyDoc } from "./NoteConstants";
import { Note } from "./NoteType";


export function getNewNoteData(doc_title:string | undefined=undefined):Note {
    if (!doc_title) {
        doc_title = "Untitled "+(Math.random()*100+1).toFixed(0);
    }
    const newId = uuidv4();
    return {id:newId, parent:"", doc_name:doc_title,doc_data:NewEmptyDoc(doc_title)} as Note;
}

/*******
 * Thanks to a great StackOverflow answer https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
 */
export function isAValidNoteID(id:string):boolean {
    // Check whether it ends with a UUID. We have some older IDs that start with "Untitled ##_uuid" that also count as UUIDs:
    // First, trim off the "Untitled ##_" part:
    const uuidStartIndex = id.indexOf("_");
    if (uuidStartIndex>=0) {
        id = id.substring(uuidStartIndex+1);
    }
    // Then check if it's a valid UUID:
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
}

export function fixNoteCorruptedEntities(note:Note):void {
    // Remove any corrupted data that may have come in from a copy/paste.
    // TODO this should be done earlier, not at save time, preferably at paste time.

    if (note.doc_data)
        for (const entityKey in note.doc_data.entityMap) {
        const entity=note.doc_data.entityMap[entityKey];
        // console.log("checking entity",entityKey,"->",entity)
        if (entity.data && entity.data._map) {
            // console.log("\tremoving _map from ",entity);
            delete entity.data._map;
        }
    }
    // return note; // not actually necessary because it's passed
}

export function getTagReferencesInBlock(block: any, tagList:MentionData[],tagReferencesIndex: any={}) {
    for (const tag of tagList) {
        if (block.text.indexOf(tag.name)>-1) {
            tagReferencesIndex[tag.name]=true;
        }
    }
    if (block.type==="checkable-list-item")
        tagReferencesIndex["#TODO"]=true;
    if (block.text.indexOf("💬")>-1) {
        tagReferencesIndex["#Discuss"]=true;
        tagReferencesIndex["#AskInMeeting"]=true;
    }
    if (block.text.indexOf("📅")>-1)
        tagReferencesIndex["#After"]=true;
    if (block.text.indexOf("❓")>-1)
        tagReferencesIndex["#Ask"]=true;
}

// function getTagReferencesIndexFrom(fullDocToSave:any, mentions: MentionData[], hashtags: MentionData[]) {
//     const tagReferencesIndex:any={};
//     // Index the title:
//     if (fullDocToSave.doc_name.startsWith("@")) {
//         tagReferencesIndex[fullDocToSave.doc_name]=true;
//     }
//     // We only index specified tags -- if it's not in the list, it doesn't get index.
//     for (const block of fullDocToSave.doc_data.blocks) {
//         if (!mentions) {
//             console.error("mentions does not seem to be loaded...");
//             tagReferencesIndex["Unknown"]=true;
//         } else
//             getTagReferencesInBlock(block, mentions, tagReferencesIndex);
//         if (!hashtags) {
//             console.error("hashtags does not seem to be loaded...");
//             tagReferencesIndex["Unknown"]=true;
//         } else
//             getTagReferencesInBlock(block, hashtags, tagReferencesIndex);
//     }
//     return tagReferencesIndex;
// }


// According to one source, firestore has a per-document write rate of 1s, which may not cause problems in dev.
// I found 500ms to be okay in limited testing.
// I also know that the debounce doesn't yet consider different IDs -- we need to fix that to be truly robust in debouncing.
// A shorter save delay means less likely to get two changes from different IDs in during the same time period.
// 200 seemed to potentially be causing performance problems (not sure if this was actually caused by this)
export const SAVE_DELAY=400;