import { ReusableCard, getCardMove } from "./GameCardTypes";

import ArrowImg from "./CardImages/SD/Arrow.png";
import BarricadeImg from "./CardImages/SD/Barricade.png";
import FightingPunchImg from "./CardImages/SD/Fighting_Punch.png";
import WizardryFireImg from "./CardImages/SD/Wizardry_Fire.png";
import WizardryIceImg from "./CardImages/SD/Wizardry_Ice.png";
import AthleticRunImg from "./CardImages/SD/Athletic_Run.png";
import TelepathMindControlImg from "./CardImages/SD/Telepath_MindControl.png";
import BarbaricAxeSwingImg from "./CardImages/SD/Barbaric_AxeSwing.jpg";
import DragonbornImg from "./CardImages/SD/Dragonborn.png";
import DisciplineStealthImg from "./CardImages/SD/Discipline_Stealth.png";
import DestructExplodeImg from "./CardImages/SD/Destruct_Explode.png";
import HealingImg from "./CardImages/SD/Healing.jpg";
import RestImg from "./CardImages/SD/Rest.jpg";

import DustDevilsVeilImg from "./CardImages/Copilot/DustDevilsVeil.png";

import PlaceholderImg from "./CardImages/SD/Barricade.png";

import { getAvgRoll, parseDice } from "./DiceNotation";
import { Clogs, DecentArrows, OldLongbow, cardAddEnhancements } from "./ReusableCardEnhancements";

export const Archery1Arrow:ReusableCard = {
    name: "Arrow",
    discipline: "Archery",
    actionType: "attack",
    image: ArrowImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2,
    maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4"),

    commandAreaString: `You ⬜ 🎯`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡ (Spend 1 energy): 🎯 Target **%spacesaffected%** enemy **%range%** spaces away takes **%dice% piercing** (physical) dmg."
};
export const Archery2Arrow:ReusableCard = {
    name: "Arrow",
    discipline: "Archery",
    actionType: "attack",
    image: ArrowImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2,
    maxRange: 3,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `You(⚡) ⬜ 🎯
    OR
    You(⚡⚡) ⬜ ⬜ 🎯`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** enemy **%range%** spaces away takes **%dice% piercing** (physical) dmg.\n\n"+
    "⚡⚡: Same, but **%range+1%** spaces away.",
};
export const Archery3Arrow:ReusableCard = {
    name: "Arrow",
    discipline: "Archery",
    actionType: "attack",
    image: ArrowImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2,
    maxRange: 4,
    dicePerAffectedSpace: parseDice("2d4"),

    commandAreaString: `You(⚡) ⬜ 🎯 OR🎯 OR🎯
    OR
    You(⚡⚡) ⬜ ⬜ ⬜ 🎯`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** enemy **%range%** spaces away takes **%dice% piercing** (physical) dmg.\n\n"+
    "⚡⚡: Same, but **%range+1%** spaces away.",
};
export const Archery4Arrow:ReusableCard = {
    name: "Arrow",
    discipline: "Archery",
    actionType: "attack",
    image: ArrowImg,

    xpCost: 4,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2,
    maxRange: 5,
    dicePerAffectedSpace: parseDice("2d4+1"),

    commandAreaString: `You(⚡) ⬜ 🎯 OR🎯 OR🎯 OR🎯
    OR
    You(⚡⚡) ⬜ ⬜ ⬜ ⬜ ⬜ 🎯`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** enemy **%range%** spaces away takes **%dice% piercing** (physical) dmg.\n\n"+
    "⚡⚡: Same, but **%range+1%** spaces away.",
};

export const Construct1Barricade:ReusableCard = {
    name: "Barricade",
    discipline: "Construct",
    actionType: "enhance",
    image: BarricadeImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 2,
    minRange: 1,
    maxRange: 1,

    commandAreaString: `You X
        ⬜ X`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡ (Spend 1 energy): 🎯 Target an adjacent straight line to create a barricade. Mark it with \"X 3\".\n\n"+
    "\"X\" Spaces: Allows ranged attacks. Destroyed by 3 damage. Physical check ✳✳ for PCs to hurdle. ",
};
export const Construct2Barricade:ReusableCard = {
    name: "Barricade",
    discipline: "Construct",
    actionType: "enhance",
    image: BarricadeImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 1,
    maxRange: 1,

    commandAreaString: `⬜ X
                        You X
                        ⬜ X`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target an adjacent straight line to create a barricade. Mark it with \"X 4\".\n\n"+
    "\"X\" Spaces: Allows ranged attacks. Destroyed by 4 damage. Physical check ✳✳ for PCs to hurdle. ",
};
export const Construct3Barricade:ReusableCard = {
    name: "Barricade",
    discipline: "Construct",
    actionType: "enhance",
    image: BarricadeImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 4,
    minRange: 1,
    maxRange: 1,

    commandAreaString: `⬜ X
                        You X
                        ⬜ X
                        ⬜ X`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target an adjacent straight line to create a barricade. Mark it with \"X 5\".\n\n"+
    "\"X\" Spaces: Allows ranged attacks. Destroyed by 5 damage. Physical check ✳ for PCs to hurdle. ",
};
export const Construct4Barricade:ReusableCard = {
    name: "Barricade",
    discipline: "Construct",
    actionType: "enhance",
    image: BarricadeImg,

    xpCost: 4,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 5,
    minRange: 1,
    maxRange: 1,

    commandAreaString: `⬜ X
                        You X
                        ⬜ X
                        ⬜ X
                        ⬜ X`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target an adjacent straight line to create a barricade. Mark it with \"X 6\".\n\n"+
    "\"X\" Spaces: Allows ranged attacks. Destroyed by 6 damage. Physical check ✳ for PCs to hurdle. ",
};
export const Destruct1Explode:ReusableCard = {
    name: "Explode",
    discipline: "Destruct",
    actionType: "attack",
    image: DestructExplodeImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 2,
    minRange: 1,
    maxRange: 1,
    dicePerAffectedSpace: parseDice("1d4"),
    commandAreaString: `You 🔥
                        ⬜ 🔥`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: Target 2 adjacent spaces: enemies take **%dice%** explosive 🔥 fire and destroy isolated obstacles at GM's discretion (barricades, wooden doors, etc).",
};
export const Destruct2Explode:ReusableCard = {
    name: "Explode",
    discipline: "Destruct",
    actionType: "attack",
    image: DestructExplodeImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 1,
    maxRange: 1,
    dicePerAffectedSpace: parseDice("1d4+1"),
    commandAreaString: `⬜ 🔥
                        You 🔥
                        ⬜ 🔥`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: Target %spacesaffected% adjacent spaces: enemies take **%dice%** explosive 🔥 fire and destroy appropriate obstacles at GM's discretion.\n\n"+
    "⚡⚡: Same, but **%dice+1d4-1%** 🔥 fire damage.",
};
export const Destruct3Explode:ReusableCard = {
    name: "Explode",
    discipline: "Destruct",
    actionType: "attack",
    image: DestructExplodeImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 4,
    minRange: 1,
    maxRange: 1,
    dicePerAffectedSpace: parseDice("2d4"),
    commandAreaString: `⬜ 🔥
                        You 🔥
                        ⬜ 🔥
                        ⬜ 🔥`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: Target %spacesaffected% adjacent spaces: enemies take **%dice%** explosive 🔥 fire and destroy appropriate obstacles at GM's discretion.\n\n"+
    "⚡⚡: Same, but **%dice+1d4%** 🔥 fire damage.",
};

export const FireWizardry1FireDart:ReusableCard = {
    name: "Fire Dart",
    discipline: "Fire Wizardry",
    actionType: "attack",
    image: WizardryFireImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 2,
    maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4"),

    commandAreaString: `⬜ ⬜ ⚡
        You ⬜ ⚡
        ⬜ ⬜ ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** spaces in a line, **%range%** spaces away. Anyone in these spaces (even allies) takes ❤️ **%dice%** 🔥 fire dmg.",
};
export const FireWizardry2FireDart:ReusableCard = {
    name: "Fire Dart",
    discipline: "Fire Wizardry",
    actionType: "attack",
    image: WizardryFireImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 2,
    maxRange: 3,
    dicePerAffectedSpace: parseDice("1d4"),

    commandAreaString: `⬜ ⬜ ⬜ ⚡
        You ⬜ ⬜ ⚡
        ⬜ ⬜ ⬜ ⚡`,
        

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** spaces in a line, **%range%** spaces away. Anyone in these spaces (even allies) takes ❤️ **%dice%** 🔥 fire dmg.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4-1%** 🔥 fire dmg.",
};
export const FireWizardry3FireDart:ReusableCard = {
    name: "Fire Dart",
    discipline: "Fire Wizardry",
    actionType: "attack",
    image: WizardryFireImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 2,
    maxRange: 4,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `⬜ ⬜ ⬜ ⬜ ⚡
        You ⬜ ⬜ ⬜ ⚡
        ⬜ ⬜ ⬜ ⬜ ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** spaces in a line, **%range%** spaces away. Anyone in these spaces (even allies) takes ❤️ **%dice%** 🔥 fire dmg.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4%** 🔥 fire dmg.",
};
export const IceWizardry1IceDart:ReusableCard = {
    name: "Ice Dart",
    discipline: "Ice Wizardry",
    actionType: "attack",
    image: WizardryIceImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 2,
    maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4"),


    commandAreaString: `⬜ ⬜ ⚡
        You ⬜ ⚡
        ⬜ ⬜ ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** spaces in a line, **%range%** spaces away. Anyone in these spaces (even allies) takes ❤️ **%dice%** ❄️ cold dmg.",
};
export const IceWizardry2IceDart:ReusableCard = {
    name: "Ice Dart",
    discipline: "Ice Wizardry",
    actionType: "attack",
    image: WizardryIceImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 2,
    maxRange: 3,
    dicePerAffectedSpace: parseDice("1d4"),

    commandAreaString: `⬜ ⬜ ⬜ ⚡ ⚡⚡
        You ⬜ ⬜ ⚡ ⚡⚡
        ⬜ ⬜ ⬜ ⚡ ⚡⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** spaces in a line, **%range%** spaces away. Anyone in these spaces (even allies) takes ❤️ **%dice%** ❄️ cold dmg.\n\n"+
    "⚡⚡: Same, but **%range+1%** spaces away.",
};
export const IceWizardry3IceDart:ReusableCard = {
    name: "Ice Dart",
    discipline: "Ice Wizardry",
    actionType: "attack",
    image: WizardryIceImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 3,
    minRange: 2,
    maxRange: 4,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `⬜ ⬜ ⬜ ⬜ ⚡ ⚡⚡
        You ⬜ ⬜ ⬜ ⚡ ⚡⚡
        ⬜ ⬜ ⬜ ⬜ ⚡ ⚡⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target **%spacesaffected%** spaces in a line, **%range%** spaces away. Anyone in these spaces (even allies) takes ❤️ **%dice%** ❄️ cold dmg.\n\n"+
    "⚡⚡: Same, but **%range+1%** spaces away.",
};


/*******
 * Compare to Barbaric and Run.
 * Level 1: Same as Barbaric.
 * Level 2: 1 more move than Barbaric, but 1 less damage.
 * Level 3: 1 more move than Barbaric, but 1 less damage.
 * +⚡ adds an additional move, versus Barbaric adding damage.
 */
export const Fighting1OneTwoPunch:ReusableCard = {
    name: "One-Two Punch",
    discipline: "Fighting",
    actionType: "attack",
    image: FightingPunchImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 1, maxRange: 1,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `You ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** bludgeoning (physical) damage.",
};
export const Fighting2OneTwoPunch:ReusableCard = {
    name: "One-Two Punch",
    discipline: "Fighting",
    actionType: "attack",
    image: FightingPunchImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 4,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 1,
    dicePerAffectedSpace: parseDice("2d4-1"),

    commandAreaString: `You ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** bludgeoning (physical) damage.\n\n"+
    "⚡⚡: Same, but 🦶 Move **%move+1%**.",
};
export const Fighting3OneTwoPunch:ReusableCard = {
    name: "One-Two Punch",
    discipline: "Fighting",
    actionType: "attack",
    image: FightingPunchImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 4,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 1,
    dicePerAffectedSpace: parseDice("3d4-2"),

    commandAreaString: `You ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** bludgeoning (physical) damage.\n\n"+
    "⚡⚡: Same, but 🦶 Move **%move+1%**.",
};

export const Athletic1Run:ReusableCard = {
    name: "Run",
    discipline: "Athletic",
    actionType: "enhance",
    image: AthleticRunImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 4,
    spacesAffected: 0,
    minRange: 0,
    maxRange: 0,

    commandString: "🦶 Move **%move%** spaces. (You may move 0-4 spaces, diagonal counts as 1).",
};
export const Athletic2Run:ReusableCard = {
    name: "Run",
    discipline: "Athletic",
    actionType: "enhance",
    image: AthleticRunImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 5,
    spacesAffected: 0,
    minRange: 0,
    maxRange: 0,

    commandString: "🦶 Move **%move%** spaces.\n\n"+
    "⚡: 🦶 Move **%move+3%** spaces.",
};
export const Athletic3Run:ReusableCard = {
    name: "Run",
    discipline: "Athletic",
    actionType: "enhance",
    image: AthleticRunImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 6,
    spacesAffected: 0,
    minRange: 0,
    maxRange: 0,

    commandString: "🦶 Move **%move%** spaces.\n\n"+
    "⚡: 🦶 Move **%move+3%** spaces.\n\n"+
    "⚡⚡: 🦶 Move **%move+6%** spaces.",
};
export const Telepath1MindControl:ReusableCard = {
    name: "Mind Control",
    discipline: "Telepath",
    actionType: "attack",
    image: TelepathMindControlImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 2,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4"),

    commandAreaString: `You ⚡ ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Force target enemy **%range%** spaces away to move **0-1** spaces and attack an adjacent enemy for ❤️ **%dice%** bludgeoning (physical) damage.",
};
export const Telepath2MindControl:ReusableCard = {
    name: "Mind Control",
    discipline: "Telepath",
    actionType: "attack",
    image: TelepathMindControlImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 3,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `You ⚡ ⚡ ⚡ ⚡⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Force target enemy **%range%** spaces away to move **0-2** spaces and attack an adjacent enemy for ❤️ **%dice%** bludgeoning (physical) damage.\n\n"+
    "⚡⚡: Same, but enemy can be **%range+1%** spaces away.",
};
export const Telepath3MindControl:ReusableCard = {
    name: "Mind Control",
    discipline: "Telepath",
    actionType: "attack",
    image: TelepathMindControlImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 4,
    dicePerAffectedSpace: parseDice("1d4+2"),

    commandAreaString: `You ⚡ ⚡ ⚡ ⚡ ⚡⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Force target enemy **%range%** spaces away to move **0-3** spaces and attack an adjacent enemy for ❤️ **%dice%** bludgeoning (physical) damage.\n\n"+
    "⚡⚡: Same, but enemy can be **%range+1%** spaces away.",
};
export const Dragonborn2FireBreath:ReusableCard = {
    name: "Fire Breath",
    discipline: "Dragonborn",
    actionType: "attack",
    image: DragonbornImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `You ⚡ ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target 1 enemy **%range%** spaces away takes ❤️ **%dice%** 🔥 fire damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4-1%** 🔥 fire damage.",
};
export const Dragonborn3FireBreath:ReusableCard = {
    name: "Fire Breath",
    discipline: "Dragonborn",
    actionType: "attack",
    image: DragonbornImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 1,
    maxRange: 2,
    dicePerAffectedSpace: parseDice("3d4-2"),

    commandAreaString: `You ⚡ ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target 1 enemy **%range%** spaces away takes ❤️ **%dice%** 🔥 fire damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4%** 🔥 fire damage.",
};
export const Barbaric1AxeSwing:ReusableCard = {
    name: "Axe Swing",
    discipline: "Barbaric",
    actionType: "attack",
    image: BarbaricAxeSwingImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 1, maxRange: 1,
    dicePerAffectedSpace: parseDice("1d4+1"),

    commandAreaString: `You ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** slicing (physical) damage.",
};
export const Barbaric2AxeSwing:ReusableCard = {
    name: "Axe Swing",
    discipline: "Barbaric",
    actionType: "attack",
    image: BarbaricAxeSwingImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange:1, maxRange: 1,
    dicePerAffectedSpace: parseDice("2d4"),

    commandAreaString: `You ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** slicing (physical) damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4%** slicing (physical) damage.",
};
export const Barbaric3AxeSwing:ReusableCard = {
    name: "Axe Swing",
    discipline: "Barbaric",
    actionType: "attack",
    image: BarbaricAxeSwingImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange:1, maxRange: 1,
    dicePerAffectedSpace: parseDice("3d4-1"),

    commandAreaString: `You ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** slicing (physical) damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4+1%** slicing (physical) damage.",
};
export const JarBrain3TentacleAttack:ReusableCard = {
    name: "Tentacle Lash",
    discipline: "Brain In A Jar",
    actionType: "attack",
    // image: BarbaricAxeSwingImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange:1, maxRange: 1,
    dicePerAffectedSpace: parseDice("3d4-1"),

    commandAreaString: `You ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target adjacent enemy takes ❤️ **%dice%** slicing (physical) damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4+1%** slicing (physical) damage.",
};


/*********
 * Sneaky Knife is positioned between Archery and Barbaric.
 * Level 1: Same as Archery. 1 more range than barbaric, but 1 less damage.
 * Level 2: 1 less range than archery, but 0.5 more damage. 1 more range than barbaric, but 0.5 less damage and a lower additional dmg.
 * Level 3: 1 less range than archery, but 0.5 more damage. 2 more range than barbaric, but 1 less damage and a lower additional dmg.
 * +⚡ Gives more damage (instead of range for archery, and a slightly lower damage boost than barbaric)
 */
export const Knives1SneakyKnifeThrow:ReusableCard = {
    name: "Sneaky Knife Throw",
    discipline: "Knives",
    actionType: "attack",
    image: DisciplineStealthImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2, maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4"),

    commandAreaString: `You ⬜ ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target 1 enemy **%range%** spaces away takes ❤️ **%dice%** piercing (physical) damage.",
};

export const Knives2SneakyKnifeThrow:ReusableCard = {
    name: "Sneaky Knife Throw",
    discipline: "Knives",
    actionType: "attack",
    image: DisciplineStealthImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2, maxRange: 2,
    dicePerAffectedSpace: parseDice("1d4+2"),

    commandAreaString: `You ⬜ ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target 1 enemy **%range%** spaces away takes ❤️ **%dice%** piercing (physical) damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4%** damage.",
    // commandString: "🦶 Move **%move%**.\n\n"+
    // "During the next enemy round, after an enemy takes an action within **%range%**, you may use this twice:\n\n"+
    // "⚡: 🎯 Target enemy takes ❤️ **%dice%** piercing (physical) damage.",
};
export const Knives3SneakyKnifeThrow:ReusableCard = {
    name: "Sneaky Knife Throw",
    discipline: "Knives",
    actionType: "attack",
    image: DisciplineStealthImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 2, maxRange: 3,

    dicePerAffectedSpace: parseDice("3d4-2"),
    commandAreaString: `You ⬜ ⚡ ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target 1 enemy **%range%** spaces away takes ❤️ **%dice%** piercing (physical) damage.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4%** damage.",

    // dicePerAffectedSpace: parseDice("2d4-1"),

    // commandAreaString: `You ⬜ ⬜ ⚡`,

    // commandString: "🦶 Move **%move%**.\n\n"+
    // "During the next enemy round, after an enemy takes an action within **%range%**, you may use this 3x:\n\n"+
    // "⚡: 🎯 Target enemy takes ❤️ **%dice%** piercing (physical) damage.",
};
export const Healing1HealingTouch:ReusableCard = {
    name: "Healing Touch",
    discipline: "Healing",
    actionType: "enhance",
    image: HealingImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 0, maxRange: 1,
    dicePerAffectedSpace: parseDice("1d4+2"),

    commandAreaString: `You ⚡`,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡ (Spend 1 energy): 🎯 You or ally **%range%** space away heals ❤️ **%dice%**.",
};
export const Healing2HealingTouch:ReusableCard = {
    name: "Healing Touch",
    discipline: "Healing",
    actionType: "enhance",
    image: HealingImg,

    xpCost: 2,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 0, maxRange: 1,
    dicePerAffectedSpace: parseDice("2d4+1"),

    commandAreaString: `You ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 You or ally **%range%** space away heals ❤️ **%dice%**.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+1d4%**.",
};
export const Healing3HealingTouch:ReusableCard = {
    name: "Healing Touch",
    discipline: "Healing",
    actionType: "enhance",
    image: HealingImg,

    xpCost: 3,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 1,
    minRange: 0, maxRange: 1,
    dicePerAffectedSpace: parseDice("3d4+1"),

    commandAreaString: `You ⚡`,
    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 You or ally **%range%** space away heals ❤️ **%dice%**.\n\n"+
    "⚡⚡: Same, but ❤️ **%dice+2d4-1%**.",
};


export const Rest0:ReusableCard = {
    name: "Breather",
    discipline: "Rest",
    actionType: "enhance",
    image: RestImg,

    xpCost: 0,
    goldCost: 0,

    moveSpaces: 0,
    spacesAffected: 0,
    minRange:0, maxRange: 0,

    commandString: "Do nothing this round.\n\n"+
    "Gain 1 ⚡ energy or 1 ❤️ (up to your max).",
};

export const DustDevilsVeil1:ReusableCard = {
    name: "Dust Devil's Veil",
    discipline: "Construct",
    actionType: "enhance",
    image: DustDevilsVeilImg,

    xpCost: 1,
    goldCost: 0,

    moveSpaces: 3,
    spacesAffected: 0,
    minRange: 0, maxRange: 4,

    commandString: "🦶 Move **%move%**.\n\n"+
    "⚡: 🎯 Target character **%range%** spaces away does not take damage from poison, vapors, or dust, until the next rest.",
};

export const AllReusableCards:ReusableCard[] = [

    Rest0,

    /*Athletic*/ Athletic1Run,Athletic2Run,Athletic3Run,
    /*Healing*/ Healing1HealingTouch, Healing2HealingTouch, Healing3HealingTouch,


    /*Archery*/ Archery1Arrow, //cardAddEnhancements(Archery1Arrow, [OldLongbow,Clogs,DecentArrows]),
        Archery2Arrow, Archery3Arrow, Archery4Arrow,
    /*Telepath*/ Telepath1MindControl,Telepath2MindControl,Telepath3MindControl,

    /*Barbaric*/ Barbaric1AxeSwing, Barbaric2AxeSwing, Barbaric3AxeSwing,    
    /*Fighting*/ Fighting1OneTwoPunch,Fighting2OneTwoPunch, Fighting3OneTwoPunch, 

    /*Construct*/ Construct1Barricade, Construct2Barricade, Construct3Barricade,
        DustDevilsVeil1,
    /*Destruct*/ Destruct1Explode, Destruct2Explode, Destruct3Explode,

    /*Knives*/ Knives1SneakyKnifeThrow, Knives2SneakyKnifeThrow, Knives3SneakyKnifeThrow,
    
    /*Fire Wizardry*/ FireWizardry1FireDart, FireWizardry2FireDart, FireWizardry3FireDart,
    /*Ice Wizardry*/ IceWizardry1IceDart, IceWizardry2IceDart, IceWizardry3IceDart,

    // For sspecific races:
    /*Dragonborn*/ Dragonborn2FireBreath, Dragonborn3FireBreath,
    /*Brain In A Jar*/ JarBrain3TentacleAttack,
    
];

/*************
 * getCardEquivalentPoints
 * 
 * Use a point system to compare cards.
 * We compare cards at each level (per XP).
 * 
 * Move:
 *      Minimum move is 2 (-1 points)
 *      Each additional move = 1 point
 * 
 * Damage distance:
 *      Base minRange & maxRange of 0 or 1 = 0 points
 *      For each minRange above 1 = -1 point
 *      For each maxRange above minRange = +1 point
 * 
 * Damage:
 *      Calculate damage using getAvgRollD(dice)
 *      Base damage average of 1.5 (average of 1d4 is 2.5. Minimum is 1d4-1 which is 1.5)
 *      Each additional 2.5 = 1 point
 *      for example, a -1 = -1/2.5 = 0.4 point
 * 
 * 
 * Spaces affected:
 *      Base spaces affected is 1
 *      If there are additional spaces, multiply damage points by spaces affected / 2 (because they might not all be full)
 *      
 */
export function getReusableCardEquivalentPoints(card:ReusableCard) {
    let points = 0;
    points += getCardMove(card) - 3;
    if (card.minRange>1)
        points -= (card.minRange - 1)*.5;
    if (card.maxRange && card.maxRange>1) {
        if (card.actionType==="attack")
            // Attacks scale at ?1? .6 point per range
            points += (card.maxRange - 1)*.9;
        else
            // Non-attacks scale at 0.5 points per range
            points += (card.maxRange - 1)/2;
    }
    if (card.dicePerAffectedSpace) {
        let dmgPoints = (getAvgRoll(card.dicePerAffectedSpace) - 1.5);
        if (card.spacesAffected===2)
            dmgPoints *= card.spacesAffected;
        else if (card.spacesAffected>2) {
            // We assume that if it's 2 squares, it'll usually be used for 2. if it's more than 2, it'll be gradually less effective, like 3 squares is often used for just 2 of the 3.
            dmgPoints *= card.spacesAffected/1.3; // 1.5 would make going from 2 spaces to 3 spaces neutral, but I think it's still a bit better to have 3 spaces.
        }
        if (card.discipline==="Telepath")
            // TODO this should be tuned more, I don't think this is quite the right point calculation. But positioning an enemy so he could be hit by more of us is potentially quite powerful, more so than the actual damage.
            dmgPoints = dmgPoints/1.5; // telepath controls enemies, so it's more powerful than a standard attack.
        else if (card.actionType==="attack")
            dmgPoints = dmgPoints/2.5; // convert from damage dice to points.
        else if (card.discipline==="Healing")
            dmgPoints = dmgPoints/4; // healing gets more dice than attacks
        points += dmgPoints;
    }
    if (card.commandString.includes("🔥 fire") || card.commandString.includes("❄️ cold"))
        points+=1; // these are easier to double the damage from.
    return points;
}


/*********************
 * The following are used for debugging & testing card balance.
 */
// function getReusableCardEquivalentPointsString(card:ReusableCard) {
//     const points = getReusableCardEquivalentPoints(card);
//     // console.log("points:",points);
//     const cardNameStr = `${card.name} ${card.xpCost} (${card.discipline})`;
//     // console.log("cardNameStr: ",cardNameStr);
//     const MAX_CARD_NAME_LENGTH=40;
//     if (cardNameStr.length>MAX_CARD_NAME_LENGTH) {
//         throw Error("BUG! Need to increase card length from "+MAX_CARD_NAME_LENGTH+" to "+cardNameStr.length+"!");
//     }
//     const padding = " ".repeat(MAX_CARD_NAME_LENGTH-cardNameStr.length);
//     // console.log("padding: \""+padding+"\"");
//     const str = cardNameStr+":"+padding+points+" points";
//     // console.log("final string: ",str);
//     return str;
// }
// console.log(getCardEquivalentPointsString(DustDevilsVeil1));
// function printPointsForCardsOfXP(xp:number) {
//     console.log(`**Cards of XP ${xp}:**`);
//     for (let index=0; index<AllActionCards.length; index++) {
//         const card = AllActionCards[index];
//         // console.log(index+" "+card.name+card.xpCost,card);
//         if (card.actionType!=="attack")
//             continue; // for now, only print attacks. the others are not equivalents.
//         if (card.xpCost===xp && (!card.enhancements || card.enhancements.length===0)) {
//             console.log("\t"+getReusableCardEquivalentPointsString(card))
//         }
//     }
// }
// printPointsForCardsOfXP(3);
