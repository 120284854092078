import { useContext, useEffect, useMemo, useRef, useState } from "react";
import 'react-h5-audio-player/lib/styles.css';
import { Button, Drawer, Progress, } from "antd";
import { CloudPlaylist, CloudPlaylistRef, CurrentTrackData } from "./CloudPlaylist";
import { FileAddOutlined, MutedOutlined, SoundFilled } from "@ant-design/icons";
import { SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { ContentBlock, ContentState } from "draft-js";
import { FullSoundtrackSelector, useMusicTagsParameters } from "./FullMusicSoundtrack";
import { NarrationLine } from "./ProcessNarration";
import { useStateURLBoolean } from "../URLQueryParams";
import { useGetBlockquoteSynthesis } from "./GetBlockquoteSynthesis";

const DEBUG=false;

export const SOUNDTRACK_DRAWER_OPEN_PARAMETER = "soundtrackDrawerOpen";

export function SoundtrackInDrawer({siderCollapsed}:{siderCollapsed:boolean}) {
    const [isOpen, setIsOpen] = useStateURLBoolean(SOUNDTRACK_DRAWER_OPEN_PARAMETER,false,false);
    const [isPlayingMusic, onIsPlayingMusicChanged] = useState(false);
    const [isPlayingSpeech, setIsPlayingSpeech] = useState(false);
    const [tags, setTags] = useMusicTagsParameters();
    const [speechTracks, setSpeechTracks] = useState<NarrationLine[]>([]);
    const [speechIndex, setSpeechIndex] = useState(0);
    const cloudMusicPlaylistRef = useRef<CloudPlaylistRef>();
    const cloudSpeechPlaylistRef = useRef<CloudPlaylistRef>();
    const [synthesisIsLoading, setSynthesisIsLoading] = useState(false);
    const [synthesisProgress, setSynthesisProgress] = useState(0);
    const [synthesisMaxProgress, setSynthesisMaxProgress] = useState(0);

    const {getBlockquoteSynthesis} = useGetBlockquoteSynthesis();

    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    async function playBlockquoteMusic(tags:string[]) {
        setTags(tags);
        cloudMusicPlaylistRef.current?.setIsPlaying(true);
        setIsOpen(true);
    }
    useEffect(()=> {
        async function playBlockquoteNarration(block:ContentBlock, contentState:ContentState) {
            setSpeechTracks([]);
            setSpeechIndex(0);
            setIsOpen(true); // Required. If we don't open the panel, the sound doesn't start.
            setSynthesisIsLoading(true);
            setSynthesisProgress(0);
            setSynthesisMaxProgress(1);
            
            const {error, speechTracks} = await getBlockquoteSynthesis(block, contentState, setSynthesisProgress, setSynthesisMaxProgress);
            if (error) {
                alert("Error synthesizing narration: "+error);
                setSynthesisIsLoading(false);
                return;
            }

            /*
            We have an optimization to wait for just the first track to be retrieved, and then start playing it immediately, while the others are still loading.
            However, this optimization seems to have several bugs.
              (1) If the user presses pause while playing the first track, once synthesis is complete, it will start playing the next track despite not having finished the first and the user having manually paused.
              (2) Sometimes it's skipping a track (the first?), or parts of tracks, or even playing them out of order (we are assuming this is only related to the first track).

            If we do fix this all up, we might even include a slight delay after the first track request above, to give the servers an additinonal chance to finish first.
            */
            // const START_FIRST_TRACK_EARLY = false; 
            // if (START_FIRST_TRACK_EARLY) {
            //     for (let i=0;i<allPromises.length;i++) {
            //         await allPromises[i];
            //         const firstTrack = speech[i];
            //         if (firstTrack && firstTrack.path) {
            //             setSpeechIndex(0);
            //             setSpeechTracks([firstTrack]);
            //             cloudSpeechPlaylistRef.current?.setIsPlaying(true);
            //             if (firstTrack.music_tags && firstTrack.music_tags.length>0) {
            //                 playBlockquoteMusic(firstTrack.music_tags);
            //             }
            //             // TODO it's also possible that the next one is done next, but not the whole stack... That case could be worth handling in this loop.

            //             // If the follow-up ones are done already, the following await Promis.all would take no time, leaving no time to start on the playlist.
            //             // So by giving the background threads just a bit of time to start this one, we make sure that it's playing when we reach the lines below, whether or not the background threads took time.
            //             await new Promise(resolve => setTimeout(resolve, 500));
            //             break;
            //         }
            //     }
            // }

            setSpeechTracks(speechTracks);
            setSpeechIndex(0);
            cloudSpeechPlaylistRef.current?.setIsPlaying(true);

            // if (speechTracks_new.length>1) {
            //     // Test to see if this continues the same track since the first one won't have changed
            //     if (DEBUG) console.log("[SoundtrackInDrawer] speechTracks_new=",speechTracks_new);
            //     if (DEBUG) console.log("[SoundtrackInDrawer] isPlayingSpeech local state=",isPlayingSpeech," isPlayingSpeech ref=",cloudSpeechPlaylistRef.current?.isPlaying," speechTracks_new=",speechTracks_new);
            //     if (START_FIRST_TRACK_EARLY) {
            //         if (cloudSpeechPlaylistRef.current?.isPlaying) {
            //             // It'll continue, just fine, because there's no interruption:
            //             setSpeechTracks(speechTracks_new);
            //         } else {
            //             // It won't be playing. But we can help this by restarting the playlist, by removing the first item and setting the rest.
            //             speechTracks_new.shift();
            //             setSpeechIndex(0);
            //             setSpeechTracks(speechTracks_new);
            //             cloudSpeechPlaylistRef.current?.setIsPlaying(true);
            //         }
            //     } else {
            //         setSpeechTracks(speechTracks_new);
            //         setSpeechIndex(0);
            //         cloudSpeechPlaylistRef.current?.setIsPlaying(true);
            //     }
            //     // The next line doesn't work to restart the playlist, because it's already stopped. What can we do?
            //     // cloudSpeechPlaylistRef.current?.setIsPlaying(true);
            // }
            setSynthesisIsLoading(false);
        }
        if (selectedJsonFormsContext) {
          selectedJsonFormsContext.soundtrackRef.current = {playBlockquoteMusic, playBlockquoteNarration};
        }
        // On unmount, we have to remove the reference, because we can no longer process changes.
        return function cleanup() {
          if (selectedJsonFormsContext) {
            selectedJsonFormsContext.soundtrackRef.current = undefined;
          }
        }
    },[selectedJsonFormsContext]);

    const hasSpeech = speechTracks!==undefined && speechTracks.length>0;

    const labelIcon = (isPlayingMusic || isPlayingSpeech)?<SoundFilled/>:<MutedOutlined/>;
    let labelText;
    if (hasSpeech) {
        labelText = isPlayingMusic==isPlayingSpeech?"Sound":(isPlayingMusic?"Music":"Speech");
    } else {
        labelText = "Music";
    }

    function onSpeechTrackChange(trackData:CurrentTrackData) {
        if (DEBUG) console.log("[SoundtrackInDrawer] onTrackChange: trackData=",trackData);
        const speechTrack = speechTracks.find(track => track.path===trackData.trackPath);
        if (!speechTrack) {
            console.error("[SoundtrackInDrawer] onTrackChange: Couldn't find speech track for path: ",trackData.trackPath);
            return;
        }
        setSpeechIndex(speechTracks.indexOf(speechTrack));
    }

    const speechPlaylist = useMemo(()=> {
        if (DEBUG) console.log("[SoundtrackInDrawer] isPlayingSpeech=",isPlayingSpeech,"hasSpeech=",hasSpeech,"speechTracks=",speechTracks,"speechIndex=",speechIndex);
        return speechTracks.map((track:NarrationLine) => track.path) as string[];
    },[speechTracks]);
    // Check if the music track should change.
    useEffect(()=>{
        if (hasSpeech && speechIndex<speechTracks.length && isPlayingSpeech) {
            const speechTrack = speechTracks[speechIndex];
            if (speechTrack.music_tags && speechTrack.music_tags.length>0) {
                playBlockquoteMusic(speechTrack.music_tags);
            }
        }
    },[speechIndex,speechTracks,isPlayingSpeech]);

    function insertLinkIntoNote() {
        if (selectedJsonFormsContext && selectedJsonFormsContext.noteEditorRef && selectedJsonFormsContext.noteEditorRef.current) {
            selectedJsonFormsContext.noteEditorRef.current.insertAMusicLinkIntoNote(tags);
        }
    }

    return <>
        {/* Surely we could do better than this funky class styling, perhaps by using the menu item class? */}
        <Button className=".ant-menu-item .antmenu-item-icon+span" type="text" onClick={()=>setIsOpen(true)} style={{alignItems:"center", color:"gray",paddingLeft:siderCollapsed?"10px":"24px", width: "100%",paddingRight:siderCollapsed?"0px":"105px"}}>
            {labelIcon} {!siderCollapsed && labelText}
        </Button>
        <Drawer destroyOnClose={false} onClose={()=>setIsOpen(false)} open={isOpen} placement="left">
            <h2>Music</h2>
            <FullSoundtrackSelector
                onPlayingChanged={onIsPlayingMusicChanged}
                cloudPlaylistRef={cloudMusicPlaylistRef}
                // Bug: When autoplay is false, it never starts playing even after we change the soundtrack. When it's blank, it starts as soon as we open the drawer, playing the last selected tracks. Maybe if we make this dynamic or create a function?
                // autoplay={true}
                />
            {selectedJsonFormsContext?.noteEditorRef?.current && <>
                    <br/><Button onClick={insertLinkIntoNote} icon={<FileAddOutlined />}>Add music to note</Button>
            </>}
            {(hasSpeech || synthesisIsLoading)  && <>
                <h2>Speech</h2>
                {speechTracks.length>0 && <>
                    <i>{speechTracks.length>speechIndex && speechTracks[speechIndex].text}</i>
                    <CloudPlaylist
                        playlist={speechPlaylist}
                        autoplay={true}
                        onPlayingChanged={setIsPlayingSpeech}
                        onTrackChange={onSpeechTrackChange}
                        cloudPlaylistRef={cloudSpeechPlaylistRef}
                        playbackRate={0.87} // Speech is just a tiny bit fast coming back from Replica Studios.
                        />
                </>}
                {synthesisIsLoading && <>                
                <i>Synthesizing...</i>
                <Progress percent={Math.round(synthesisProgress/synthesisMaxProgress*100)}/>
                </>}
            </>}
        </Drawer>
    </>;
}