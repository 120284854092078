import { PlayerCharacterInMemoryType } from "./Players";

import { getCardBaseXPCost, getCardGoldCost, ReusableCard, ReusableCardEnhancement, getReusableCardCostStr, getReusableCardUpgradeCostStr, getEnhancementCostStr, getCardsAreSame, DiscardableCard, getDiscardCardCostStr, DefenseCard, getDefenseCardCostStr, getDefenseCardUpgradeCostStr } from "./GameCardTypes";
import { AllPlayerCharacters } from "./Players";
import { AllReusableCards } from "./ReusableCards";
import { AllEnhancements } from "./ReusableCardEnhancements";
import { ALlSkillCards as AllSkillCards, AllSkillCardFunctions } from "./SkillCards";
import { AllDiscardableCards } from "./DiscardableCards";
import { AllDefenseCards } from "./DefenseCards";

const DEBUG = false;

export function getPlayerLevel(player: PlayerCharacterInMemoryType): number {
    // Level 1 starts with 4.
    // Level 2 is 7 (+3)
    // Level 3 is 10 (+3)
    // Level 4 is 14 (+4)
    // Level 5 is 19 (+5)
    const xp = player.earnedXP;
    const XP_LEVEL = [
        4,  // Start at level 1 with 4 XP
        7,  // Level 2
        10, // Level 3
        14, // Level 4
        19, // Level 5
        25, // Level 6
        32, // Level 7
        40, // Level 8
        49, // Level 9
        59, // Level 10
        70, // Level 11
    ];
    // This works because it matches at index + 1, finding the first index where the level has more XP than the player.
    const level = XP_LEVEL.findIndex(levelXP => xp < levelXP);
    // Previous approach -- XP every 3 levels starting with 4 XP at level 1
    // const level = Math.floor((xp - 4) / 3) + 1;
    return level;
}
export function getXPUsedByCards(player: PlayerCharacterInMemoryType): number {
    return player.reusableCards.reduce((acc, card) => acc + getCardBaseXPCost(card), 0);
}
export function getGoldUsedByCards(player: PlayerCharacterInMemoryType): number {
    return player.reusableCards.reduce((acc, card) => acc + getCardGoldCost(card), 0);
}

export function getXPUsedByLife(player: PlayerCharacterInMemoryType): number {
    // Must be an even number.
    return player.addedLife/2;
}
export function getXPUsed(player: PlayerCharacterInMemoryType): number {
    return getXPUsedByCards(player)
        + getXPUsedByLife(player);
}
export function getPlayerLife(player: PlayerCharacterInMemoryType): number {
    return 6 + getPlayerLevel(player) * 2 + player.addedLife;
}

export function getErrors(player: PlayerCharacterInMemoryType): string[] {
    const errors = [];
    const level = getPlayerLevel(player);
    const cardsAboveLevel = player.reusableCards.filter(card => card.xpCost>level);
    if (cardsAboveLevel.length > 0) {
        errors.push(`Several cards are above character level ${level}: ${cardsAboveLevel.map(card => card.name).join(", ")}`);
    }
    if (player.addedLife/2 > level) {
        errors.push(`Too much added life for level ${level}: Change from ${player.addedLife} to at most ${level*2}`);
    }
    return errors;
}

// function testPrintStatsPerCharacter() {
//     Players.forEach(player => {
//         const usedXP = player.cards.reduce((acc,card) => acc+card.xpCost,0);
//         console.log(
//         `Player: ${player.playerName}:
//             - Cards (${player.cards.length}): ${player.cards.map(card => card.cardName).join(", ")}
//             - XP: Used ${usedXP} / ${player.earnedXP} (${player.earnedXP-usedXP} remaining)
//             - Earned XP: ${player.earnedXP}
//             - Used XP: 
//             - Earned Gold: ${player.earnedGold}
//             - Used Gold: ${player.cards.reduce((acc,card) => acc+card.goldCost,0)}
//         `);
//     });
// }
// testPrintStatsPerCharacter();

// function getJoinedPlayersCardNames(player: PlayerCharactersType) {
//     // Remove the cards everyone by default (Breather, 0XP, Discipline=Rest)
//     const cards = player.cards.filter((card: GameCardType) => card.name !== "Breather");

//     return player.characterName + ": " +
//         cards.map(card => (card.name + " (" + card.discipline + ")")).join(", ");
// }
// console.log(getJoinedPlayersCardNames(AllPlayerCharacters[4]));

export function findOneLowerLevelReusableCard(player: PlayerCharacterInMemoryType, card: ReusableCard): ReusableCard | undefined {
    return player.reusableCards.find(
        function(ownedReusableCard:ReusableCard){
            if (ownedReusableCard.discipline!=card.discipline)
                return false;
            if (getCardBaseXPCost(ownedReusableCard) !== card.xpCost - 1)
                return false;
            if (ownedReusableCard.discipline==="Skill" && ownedReusableCard.name !== card.name)
                return false;
            return true;
        });
}
export function findOneLevelLowerDefenseCard(player: PlayerCharacterInMemoryType, card: DefenseCard): DefenseCard | undefined {
    return player.defenseCards.find(
        function(ownedDefenseCard: DefenseCard){
            if (ownedDefenseCard.xpCost !== card.xpCost - 1)
                return false;
            return true;
        });
}

export function canPlayerAcquireReusableCard(player: PlayerCharacterInMemoryType, card: ReusableCard): boolean {
    const playerUnusedGold = player.earnedGold - getGoldUsedByCards(player);
    const canAffordGold = getCardGoldCost(card) <= playerUnusedGold;

    const playerUnusedXP = player.earnedXP - getXPUsed(player);
    // const isUpgrade = player.cards.some(playerCard => playerCard.discipline === card.discipline && getCardXPCost(playerCard) === card.xpCost - 1);
    // Always checking against 1, because we can only go up 1 at a time.
    const canAffordXP = 1 <= playerUnusedXP;

    // Only allowed to get XP cards up to the level the player is at:
    const playerLevel = getPlayerLevel(player);
    const cardLevel = getCardBaseXPCost(card);
    const meetsLevelRequirement = cardLevel <= playerLevel;

    // If it's a XP <=1 card, it can always be acquired. If it's higher XP than that, they have to trade up, one at a time.
    // First, check whether we have any cards of the same discipline that are XP-1 compared to card we're trying to acquire.
    const has1LowerLevelCard = findOneLowerLevelReusableCard(player, card)!==undefined;
    const alreadyHasHigherLevelCard = player.reusableCards.some(function(playerCard) {
        const isSameDiscipline = playerCard.discipline === card.discipline;
        const isHigherLevel = getCardBaseXPCost(playerCard) === cardLevel + 1;
        if (playerCard.discipline==="Skill") {
            // Check whether this has the same name, too.
            return isSameDiscipline && isHigherLevel && playerCard.name === card.name;
        }
        return isSameDiscipline && isHigherLevel;
    });
    const canTradeUp = cardLevel <= 1 || has1LowerLevelCard;

    // Check if they already have the card.
    const alreadyHasCard = player.reusableCards.some(playerCard => getCardsAreSame(playerCard, card));

    if (DEBUG) {
        let failingReason = "";
        if (!canAffordGold) failingReason += "Can't afford gold. ";
        if (!canAffordXP) failingReason += "Can't afford XP. ";
        if (!meetsLevelRequirement) failingReason += "Doesn't meet level requirement. ";
        if (!canTradeUp) failingReason += "Can't trade up. ";
        if (alreadyHasCard) failingReason += "Already has card. ";
        if (alreadyHasHigherLevelCard) failingReason += "Already has higher level card. ";
        if (failingReason.length > 0) {
            console.log(`*** ${player.characterName} > ${card.name} ${card.xpCost} *** Fails because: ${failingReason}`);
        } else {
            // if (card.name==="Physical")
                // debugger;
            console.log(`*** ${player.characterName} > ${card.name} ${card.xpCost} *** Passes all checks`);
        }

    }
    // console.log(`*** ${player.characterName} > ${card.name} ${card.xpCost} ***
    // Fails because: 
    // canAffordGold: ${canAffordGold}
    // canAffordXP: ${canAffordXP}
    // meetsLevelRequirement: ${meetsLevelRequirement}
    // canTradeUp: ${canTradeUp}
    // alreadyHasCard: ${alreadyHasCard}
    // `);

    return canAffordGold && canAffordXP && meetsLevelRequirement && canTradeUp && !alreadyHasCard && !alreadyHasHigherLevelCard;
}
export function canPlayerAcquireDefenseCard(player: PlayerCharacterInMemoryType, card: DefenseCard): boolean {
    const alreadyHasCard = player.defenseCards.some(playerCard => playerCard.commandString === card.commandString);

    const playerUnusedGold = player.earnedGold - getGoldUsedByCards(player);
    const canAffordGold = card.goldCost <= playerUnusedGold;

    const playerLevel = getPlayerLevel(player);
    const meetsLevelRequirement = card.minLevel <= playerLevel;


    const canAffordXP = 1 <= player.earnedXP - getXPUsed(player);

    const canTradeUp = card.xpCost <= 1 || findOneLevelLowerDefenseCard(player, card)!==undefined;
    // debugger;
    return canAffordGold && meetsLevelRequirement && !alreadyHasCard && canAffordXP && canTradeUp;
}

export function canPlayerAcquireEnhancement(player: PlayerCharacterInMemoryType, enhancement: ReusableCardEnhancement): boolean {
    const playerUnusedGold = player.earnedGold - getGoldUsedByCards(player);
    const canAffordGold = enhancement.goldCost <= playerUnusedGold;

    const playerLevel = getPlayerLevel(player);    
    const meetsLevelRequirement = enhancement.minLevel <= playerLevel;
    const meetsDisciplineRequirement = enhancement.applicableToDisciplines === undefined || 
        enhancement.applicableToDisciplines.some(discipline => player.reusableCards.some(card => discipline===card.discipline && !card.enhancements?.some(enhancement => enhancement.name === enhancement.name)));

    return canAffordGold && meetsLevelRequirement && meetsDisciplineRequirement;
}
export function canPlayerAcquireDiscard(player: PlayerCharacterInMemoryType, discard: DiscardableCard): boolean {
    const playerUnusedGold = player.earnedGold - getGoldUsedByCards(player);
    const canAffordGold = discard.goldCost <= playerUnusedGold;

    const playerLevel = getPlayerLevel(player);    
    const meetsLevelRequirement = discard.minLevel <= playerLevel;

    return canAffordGold && meetsLevelRequirement;
}

export function canPlayerAcquireLife(player: PlayerCharacterInMemoryType): boolean {
    const playerUnusedXP = player.earnedXP - getXPUsed(player);
    const canAffordXP = 1 <= playerUnusedXP;
    const meetsLevelRequirement = player.addedLife < getPlayerLevel(player);

    return canAffordXP && meetsLevelRequirement;
}

export function getAcquireableReusableCards(player: PlayerCharacterInMemoryType, allCards: ReusableCard[]): ReusableCard[] {
    const filtered = allCards.filter(card => canPlayerAcquireReusableCard(player, card));
    return filtered;
}
export function getAcquireableDefenseCards(player: PlayerCharacterInMemoryType, allCards: DefenseCard[]): DefenseCard[] {
    const filtered = allCards.filter(card => canPlayerAcquireDefenseCard(player, card));
    return filtered;
}

export function getAcquireableEnhancements(player: PlayerCharacterInMemoryType, allEnhancements: ReusableCardEnhancement[]): ReusableCardEnhancement[] {
    return allEnhancements.filter(enhancement => canPlayerAcquireEnhancement(player, enhancement));
}
export function getAcquirableDiscardableCards(player: PlayerCharacterInMemoryType, allDiscardableCards: DiscardableCard[]): DiscardableCard[] {
    return allDiscardableCards.filter(card => canPlayerAcquireDiscard(player, card));
}

export type AcquireableThingsType = {
    costStr: string,
    name: string,
};
export function getAcquirableThingsStrings(player: PlayerCharacterInMemoryType): AcquireableThingsType[] {
    const reusableCards = getAcquireableReusableCards(player, AllReusableCards).concat(getAcquireableReusableCards(player, AllSkillCards));
    const enhancements = getAcquireableEnhancements(player, AllEnhancements);
    const discards = getAcquirableDiscardableCards(player, AllDiscardableCards);
    const defenseCards = getAcquireableDefenseCards(player, AllDefenseCards);

    const canLife = canPlayerAcquireLife(player);
    const acqThings = [] as AcquireableThingsType[];
    // const upgrades = [] as string[];
    for (const reusableCard of reusableCards) {
        // If the card costs more than 1xp, it requires a trade-up.
        if (reusableCard.xpCost <= 1) {
            acqThings.push({costStr: getReusableCardCostStr(reusableCard), name: reusableCard.name});
            // upgrades.push(getCardCostStr(card)+" ➡ " + card.name);
        } else {
            const lowerLevelCard = findOneLowerLevelReusableCard(player, reusableCard);
            // const lowerLevelCard = player.cards.find(playerCard => playerCard.discipline === card.discipline && getCardXPCost(playerCard) === card.xpCost - 1);
            if (!lowerLevelCard) {
                console.error("Couldn't find a lower level card to trade up for " + reusableCard.name+". This implies a bug in getAcquireableCards.");
                continue;
            }
            acqThings.push({costStr: lowerLevelCard.name + " " + lowerLevelCard.xpCost + " & "+getReusableCardUpgradeCostStr(lowerLevelCard, reusableCard), name: reusableCard.name+" "+reusableCard.xpCost});
            // upgrades.push("Trade in " + lowerLevelCard.name + " and "+getUpgradeCostStr(lowerLevelCard, card)+" ➡ " + card.name);
        }
    }
    for (const defenseCard of defenseCards) {
        if (defenseCard.xpCost <= 1) {
            acqThings.push({costStr: getDefenseCardCostStr(defenseCard), name: defenseCard.name});
            continue;
        }
        const lowerLevelCard = findOneLevelLowerDefenseCard(player, defenseCard);
        if (!lowerLevelCard) {
            console.error("Couldn't find a lower level card to trade up for " + defenseCard.name+". This implies a bug in getAcquireableCards.");
            continue;
        }
        acqThings.push({costStr: lowerLevelCard.commandString + " & "+getDefenseCardUpgradeCostStr(lowerLevelCard, defenseCard), name: defenseCard.name});
    }

    for (const enhancement of enhancements) {
        acqThings.push({costStr: getEnhancementCostStr(enhancement), name: enhancement.name});
        // upgrades.push(getEnhancementCostStr(enhancement)+" ➡ " + enhancement.name);
    }
    if (canLife) {
        acqThings.push({costStr: "1XP", name: "❤️❤️"});
        // upgrades.push("1XP ➡ ❤️❤️");
    }
    for (const discard of discards) {
        acqThings.push({costStr: getDiscardCardCostStr(discard), name: discard.name});
    }
    // return upgrades;
    return acqThings;
}