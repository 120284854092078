import React, { useContext, useEffect, useRef, useState } from 'react';
import { Configure, Hits, Menu, useInstantSearch, useSearchBox, UseSearchBoxProps } from 'react-instantsearch';
import { useLinkToNoteId } from '../Utilities/NavigateTo';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { NotesContext } from '../../Notes/Data/NotesContext';
import { getNoteNameAndEmoji_multipurpose } from '../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';
import { NoteHierarchyContext } from '../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider';

interface CustomSearchBoxProps extends UseSearchBoxProps {
  searchRef: React.MutableRefObject<HTMLInputElement | undefined>
};

function CustomSearchBox(props: CustomSearchBoxProps) {
  const { query, refine } = useSearchBox(props);
  const { status } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = props.searchRef;

  const isSearchStalled = status === 'stalled';

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div>
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <input
          className="searchInputBox"
          // @ts-ignore
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Search for notes"
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={(event) => {
            setQuery(event.currentTarget.value);
          }}
          autoFocus
        />
        <button type="submit"><SearchOutlined/></button>
        <button type="reset" hidden={inputValue.length === 0 || isSearchStalled}><CloseOutlined /></button>
        <span hidden={!isSearchStalled}>Searching…</span>
      </form>
    </div>
  );
}

export function SearchNotesResult_PrintALink({ hit: noteSearchResult }:{hit:any}) {
    const linkToNote = useLinkToNoteId();
    return (
      <article>
        <p>{linkToNote(noteSearchResult.objectID,noteSearchResult.doc_name)}</p>
      </article>
    );
}

// export function SearchNotesResult_InsertLinkIntoEditor({ hit: noteSearchResult }:{hit:any}) {
//     const linkToNote = useLinkToNoteId();
//     return (
//       <article>
//         <p>{linkToNote(noteSearchResult.objectID,noteSearchResult.doc_name)}</p>
//       </article>
//     );
// }
function useSearchNotesResult_InsertLinkIntoEditor(callback:(doc_id:string,doc_name:string)=>void) {
    const notesContext = useContext(NotesContext);    
    const {extensions} = useContext(NoteHierarchyContext);
    async function onClick(hit:any) {
      const note = await notesContext.getNote(hit.objectID);
      if (!note) {
        console.error("[useSearchNotesResult_InsertLinkIntoEditor] Note not loaded from ID that was in Algolia: ",hit.objectID);
        callback(hit.objectID,hit.doc_name);
      } else {
        const {note_title} = getNoteNameAndEmoji_multipurpose(note, notesContext, extensions);
        callback(note.id,note_title);
      }
    }

    return (props:any) => {
        return (
            <article>
                <p><Button type="dashed" onClick={()=>onClick(props.hit)}>{props.hit.doc_name}</Button></p>
            </article>
        );
    }
}

export default function SearchPanel({searchRef, hitComponent}:{searchRef:React.MutableRefObject<HTMLInputElement | undefined>, hitComponent:any}) {
  const [initialLoad,setInitialLoad] = useState(true);
    // I've tried the reacthotkeys with these two functions, and it doesn't seem to do anything, not even after clicking into this sidebar.
    function focusSearch() {
        // Set focus to the search box:
        //@ts-ignore
        searchRef?.current?.focus();
    }
    useEffect(()=>{
      if (initialLoad) {
        setTimeout(focusSearch, 50);
        setInitialLoad(false);
      }
    },[initialLoad]);
    // // function onShortcutKeyDown(keyName:string, e:any, handle:any) {
    //     // Note -- does not trigger when focus is within the editor
    //     // console.log("onKeyDown", keyName, e, handle);
    //     e.preventDefault();
    //     e.stopPropagation();
    //     focusSearch();
    //     return false;
    // }

    // Also see NotePageContext, which has <InstantSearch/>. This builds on <InstantSearch/>.
    return <div className="RelatedNotes" style={{padding: "10px"}}>
            {/* <ReactHotkeys
                keyName="control+e,command+e" 
                onKeyDown={onShortcutKeyDown}
            /> */}
            {/* Someday we'd like to search other things, not just notes, in this same panel, but until then, make it clear what we can search. (no voices, no music) */}
            <h3>Search Notes</h3>
            {/* We can't add a ref to the item below, ti gives us a warning. */}
            {/* <SearchBox autoFocus={true}/> */}
            <CustomSearchBox searchRef={searchRef}/>
            <Menu attribute="type" />
            <Hits hitComponent={hitComponent} style={{overflowY:"auto", height:"65vh"}}/>
        </div>;
}

export function SearchModal({isSearchModalOpen, setSearchModalOpen, callback,filters,title="Hyperlink to a note"}:{isSearchModalOpen:boolean, setSearchModalOpen:React.Dispatch<React.SetStateAction<boolean>>, callback:(doc_id:string,doc_name:string)=>void,filters?:string,title?:string}) {
    const searchRef = useRef<HTMLInputElement>();
    const searchNotesResult = useSearchNotesResult_InsertLinkIntoEditor(callback);

    return <>
      <Configure filters={filters}/>
      <Modal
            title={title}
            open={isSearchModalOpen}
            onOk={()=>setSearchModalOpen(false)}
            onCancel={()=>setSearchModalOpen(false)}
            width="100vw"
            style={{ top: 20}}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={()=>setSearchModalOpen(false)}>Cancel</Button>
                // <Button key="submit" type="primary" onClick={insertLink} disabled={!isNewBattleInputValid()}>New</Button>,
            ]}
        >
            <SearchPanel searchRef={searchRef} hitComponent={searchNotesResult}/>
        </Modal>
    </>;
}