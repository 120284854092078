import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';

export const Mentions_doc_name = "Mentions";

export function NewEmptyDoc(doc_name:string) {
    //%m/%d/%Y %I:%M%p
    // const current_date_time_string = Moment().format('MMM d YY h:mm a');
    return {
        "blocks": [
            {
                "key": uuidv4(),
                "text": doc_name,
                "type": "header-one",
                "depth": 0,
                "inlineStyleRanges": [
                ],
                "entityRanges": [],
                "data": {}
            },
            // {
            //     "key": uuidv4(),
            //     "text": current_date_time_string,
            //     "type": "header-one",
            //     "depth": 0,
            //     "inlineStyleRanges": [
            //         {
            //             "offset": 0,
            //             "length": current_date_time_string.length,
            //             "style": "CODE"
            //         },
            //         {
            //             "offset": 0,
            //             "length": current_date_time_string.length,
            //             "style": "SMALL"
            //         },
            //         {
            //             "offset": 0,
            //             "length": current_date_time_string.length,
            //             "style": "KEYBOARD"
            //         }
            //     ],
            //     "entityRanges": [],
            //     "data": {}
            // },
            {
                "key": uuidv4(),
                "text": " ",
                "type": "unstyled",
                "depth": 0,
                "inlineStyleRanges": [],
                "entityRanges": [],
                "data": {}
            },
        ] as RawDraftContentBlock[],
        "entityMap": {}
    } as RawDraftContentState
}