// A custom hook that builds on useLocation to parse the URL
import { useLocation, useNavigate } from "react-router-dom";

/*******************
 * Choose between useQuery & getQuery:
 * - useQuery is a hook that returns the query parameters at the time of the render. It's useful for when you want to update the URL with the same parameters as the current URL.
 * - getQuery is a function that returns the query parameters at the time of the call. It's useful for when you want to update the URL with the current parameters.
 */
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export function getQuery() {
    // We can't use useLocation here, because it's too old. Get the search paramas straight form the bar:
    return new URLSearchParams(window.location.search);
}

// Returns whether there was a change made to the parameters.
export function querySetAll(query:URLSearchParams,paramName:string,arrayToSet:string[]):boolean {
    // TODO can we check the query's array before changing it? It could be very slow.
    const currentArray = query.getAll(paramName)
    const arraysAreEqual =
        arrayToSet.length === currentArray.length &&
        arrayToSet.every(function (element) {
            return currentArray.includes(element);
        });
    if (arraysAreEqual)
        // No change, don't incur the cost.
        return false;

    query.delete(paramName);
    for (const val of arrayToSet) {
        if (val==="")
            continue;
        query.append(paramName, val);
    }
    return true;
}


export function useStateURLString(key:string, defaultValue:string, addToHistory:boolean=true): [string, (newValue:string) => void] {
    const query = useQuery();
    const navigate = useNavigate();
    const location = useLocation();

    const value:string=query.get(key) || defaultValue;
    function setValue(newValue:string) {
        // Only execute if there was a change:
        if (newValue === value) {
            // console.log("[useStateURLString] No change to '"+key+"'");
            return;
        }
        const freshQuery = getQuery();
        freshQuery.set(key, newValue);
        navigate(location.pathname+"?"+freshQuery.toString(), {replace: !addToHistory});
        // console.log("[useStateURLString] Set '"+key+"' to '"+newValue+"'")
    }
    return [value, setValue];
}

export function useStateURLStringArray(key:string, defaultValue:string[]=[], addToHistory:boolean=true): [string[], (newValue:string[]) => void] {
    const [valuesRawStr, setValuesRawStr] = useStateURLString(key,JSON.stringify(defaultValue),false);
    const values = JSON.parse(valuesRawStr) as string[] || [] as string[];
    function setValues(tags:string[]) {
        setValuesRawStr(JSON.stringify(tags));
    }
    return [values, setValues];
}

export function useStateURLBoolean(key:string, defaultValue:boolean, addToHistory:boolean=true): [boolean, (newValue:boolean)=>void] {
    const query = useQuery();
    const navigate = useNavigate();
    const location = useLocation();

    const value = query.get(key) === "true" || defaultValue;
    function setValue(newValue:boolean) {
        // Only execute if there was a change:
        if (newValue === value) {
            // console.log("[useStateURLBoolean] No change to '"+key+"'");
            return;
        }
        const freshQuery = getQuery();
        freshQuery.set(key, newValue?"true":"false");
        navigate(location.pathname+"?"+freshQuery.toString(), {replace: !addToHistory});
        // console.log("[useStateURLBoolean] Set '"+key+"' to '"+newValue+"'")
    }
    return [value, setValue];
}

export function useStateURLInteger(key:string, defaultValue:number, addToHistory:boolean=true): [number, (newValue:number)=>void] {
    const query = useQuery();
    const navigate = useNavigate();
    const location = useLocation();

    const value = parseInt(query.get(key) || defaultValue.toString());
    function setValue(newValue:number) {
        // Only execute if there was a change:
        if (newValue === value) {
            // console.log("[useStateURLInteger] No change to '"+key+"'");
            return;
        }
        const freshQuery = getQuery();
        freshQuery.set(key, newValue.toString());
        navigate(location.pathname+"?"+freshQuery.toString(), {replace: !addToHistory});
        // console.log("[useStateURLInteger] Set '"+key+"' to '"+newValue+"'")
    }
    return [value, setValue];
}