import { useContext, useEffect, useState } from "react"
// ant
import DataTabTypeAndEditor from "./DataTabTypeAndEditor";
import { Alert } from "antd";
import { useFirestore } from "reactfire";
import { Note } from "../../Data/NoteType";
import { howManyNotesNamed } from "../../Data/NoteDBHooks";
import { SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";

function CheckForDuplicateNoteNames({note}:{note:Note}) {
  // The note for the page we're on:
  const firestore = useFirestore();
  const [numberWithSameName,setNumberWithSameName] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const {numberWithName} = await howManyNotesNamed(firestore, note.doc_name);
      setNumberWithSameName(numberWithName);
    }
    fetchData().catch(console.error);
}, [note]);

  if (numberWithSameName<=1)
    return <></>
  return <Alert
    message="Warning"
    description={"There are "+(numberWithSameName-1)+" other notes with the same name. We advise changing them to be unique to reduce confusion."}
    type="warning"
    showIcon
    closable
    />

}

export default function NoteDataEditor() {
  // const query = useQuery();
  // const mentionsParams_All:string[] = query.getAll("mentions");
  // const hashtagsParams_All:string[] = query.getAll("hashtags");
  // const notesParams_All:string[] = query.getAll("notes");
  const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
  const note = selectedJsonFormsContext.note;
  // console.log("🌟 NoteDataEditor note=",note);
  
  // Search for all references to selections:
  // const references: any = useMemo(
  //   function() {
  //       return buildReferences(notes,mentionsParams_All, hashtagsParams_All, notesParams_All);
  //     },
  //   [notes, mentionsParams_All, hashtagsParams_All, notesParams_All]);

  // @ts-ignore
  return <div className="Advice">
    {note && <CheckForDuplicateNoteNames note={note}/>}
    {note && <DataTabTypeAndEditor/>}
    {/* {organizeBy==="ByNote" && (references.references.length>0 || references.referencesWithoutMentions.length>0) && <>
      <Title level={4}>{hashtagsParams_All.length>0 && references.referencesWithoutMentions.length>0?"Todos with selected people":"References"}</Title>
      <ReferenceBlock showTitle={false} type={"References"} references={references.references}
        doc_id={doc_id}
        hashtagsParams_All={hashtagsParams_All}
        mentionsParams_All={mentionsParams_All}/>
      <ReferenceBlock showTitle={true} type="Todos without selected @People" references={references.referencesWithoutMentions}
        doc_id={doc_id}
        hashtagsParams_All={hashtagsParams_All}
        mentionsParams_All={mentionsParams_All}/>
    </>} */}
    {/* <PrioritizationComponent references={references} editorRef={editorRef}/> */}
  {/* @ts-ignore */}
  </div>;
}