import {  Input, Select, Spin, Tooltip } from 'antd';
import { useContext, useMemo } from 'react';
import NoteMentionPrompt from './NoteSimplePrompts/NoteMentionPrompt';
import { Note } from '../../Data/NoteType';
import { useAllTypeNotes, useSaveNoteDebounced } from '../../Data/NoteDBHooks';
import { saveRootDocAnswer } from '../../Data/FirestoreJsonFormsClient';
import { SelectedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { TypeEditor } from '../../JSONFormsInNote/TypeEditor';
import OldGenericObjectEditor, { ExtensionSupportedObjectEditor } from '../../JSONFormsInNote/GenericObjectEditor';
import { NoteHierarchyContext } from './NoteHierarchyProvider';
import { ExtensionNoteTemplateType, getTemplateTypes } from '../../../Extensions/ExtensionsFramework/ExtensionsList';
import { NotesContext } from '../../Data/NotesContext';
import { Battle_NoteType, Enemy_NoteType } from '../../../Extensions/TTRPG/TTRPGExtension';


const { Option } = Select;


function MultipleChoiceQuestionPrompt({questionText, questionLabel, answersArray, note}:{questionText:string, questionLabel:string, answersArray:any[],note:Note}) {
    const saveNoteCallback = useSaveNoteDebounced(note.id);
    //@ts-ignore
    const currentValue=note?note[questionLabel]:[];
    // TODO updates are delayed from the debounce below, including firestore. We want updates to be instant.
    // To do that, we may need a temporary local cache managed with a state & memo.
    // That may need to be done at a higher level, e.g. via redux, because otherwise only the dropdown changes.

    // console.log(questionLabel,"->",currentValue);
    return <div>
        {questionText}
        
        <Select placeholder={questionLabel} style={{ minWidth: '200px' }}
            showSearch
            value={currentValue}
            onChange={function(value:string){
                if (currentValue===value) {
                    console.log("Got event, but no change");
                    return;
                }
                saveRootDocAnswer(questionLabel, value, note, saveNoteCallback);
            }} >
            {answersArray.map(function(answer:string) {
                return <Option key={answer} value={answer}>{answer}</Option>
            })}
        </Select>
    </div>;
}

function useNoteTemplateType(note:Note) {
    const {extensions, isLoaded} = useContext(NoteHierarchyContext);
    const newNoteTypes = getTemplateTypes(extensions);
    console.log("newNoteTypes",newNoteTypes);
    const notesContext = useContext(NotesContext);

    const templatesList = [] as Note[];
    let anythingNotLoaded=!isLoaded || false;
    const currentValue=note?note.template_doc_id:"";
    const hasCurrentValue = (currentValue&&currentValue.length>0)?true:false;
    let currentNoteType = undefined as ExtensionNoteTemplateType<any>|undefined;

    for (let i=0;i<newNoteTypes.length;i++) {
        const templateType = newNoteTypes[i];
        if (!templateType.template_doc_ids) continue;
        for (let j=0;j<templateType.template_doc_ids.length;j++) {
            const template_doc_id = templateType.template_doc_ids[j];
            const note = notesContext.getLoadedNote(template_doc_id,true);
            if (currentValue===template_doc_id) {
                currentNoteType = templateType;
            }
            if (note) {
                templatesList.push(note);
            } else {
                anythingNotLoaded=true;
            }
        }
    }
    // Look up current value in the list of newNoteTypes:
    console.log("templatesList",templatesList);


    return {templatesList, anythingNotLoaded, currentValue, hasCurrentValue, currentNoteType};
}

function NoteTemplateTypeQuestionPrompt({note,noteTemplateType}:{note:Note,noteTemplateType:{templatesList:Note[],anythingNotLoaded:boolean,currentValue:string | undefined,hasCurrentValue:boolean}}) {
    const {templatesList, anythingNotLoaded, currentValue} = noteTemplateType;

    const saveNoteCallback = useSaveNoteDebounced(note.id);

    return <div>
        Template:
        {anythingNotLoaded && <Spin size='small'/>}
        {!anythingNotLoaded && <Select placeholder="Template" style={{ minWidth: '200px' }}
            showSearch
            listHeight={400} /*show more than the default*/
            value={currentValue}
            onChange={function(value:string){
                if (currentValue===value) {
                    console.log("Got event, but no change");
                    return;
                }
                saveRootDocAnswer("template_doc_id", value, note, saveNoteCallback);
            }} >
            {templatesList.map(function(template:Note) {
                return <Option key={template.doc_name} value={template.id}>{template.doc_name}</Option>
            })}
            <Option key="" value="">None</Option>
        </Select>}
    </div>;
}

function SingleCharacterQuestionPrompt({questionText, questionLabel, note, tooltipText}:{questionText:string, questionLabel:string, note:Note, tooltipText?:string}) {
    const saveNoteCallback = useSaveNoteDebounced(note.id);
    //@ts-ignore
    const currentValue=note?note[questionLabel]:[];
    function onChange(event:any) {
        let { value }:{value:string} = event.target;

        if (currentValue===value) {
            console.log("Got event, but no change");
            return;
        }
        saveRootDocAnswer(questionLabel, value, note, saveNoteCallback);
    }
    return <div>
        <Tooltip title={tooltipText}>
        {questionText}<Input placeholder={questionLabel} style={{ minWidth: '50px', maxWidth:'50px' }}
            value={currentValue}
            onChange={onChange} >
        </Input>
        </Tooltip>
    </div>;
}

const BasicNoteTypes = ["Note","Type"];



export default function DataTabTypeAndEditor() {
    const typeNotes = useAllTypeNotes();
    const types = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const note = selectedJsonFormsContext.note;
    const doc_name = note?.doc_name;

    const noteTemplateType = useNoteTemplateType(note);

    if (!note)
        return <></>;
    const type = note?.type;

    let noteTypesOld;
    if (types)
        noteTypesOld = [...BasicNoteTypes, ...types]; 
    else
        noteTypesOld = BasicNoteTypes; // still loading the notes.

    const OLD_TYPES_THAT_STILL_NEED_EDITORS = [Battle_NoteType.template_doc_ids?.[0], Enemy_NoteType.template_doc_ids?.[0]];
    const displayOldType = (!noteTemplateType.hasCurrentValue && note.type) || (noteTemplateType.hasCurrentValue && OLD_TYPES_THAT_STILL_NEED_EDITORS.includes(noteTemplateType.currentValue));

    const promptContextTab = <>Examples of prompts that do, or don't, mention this type of note<br/><NoteMentionPrompt note={note}/></>
    let includedEditor;
    if (displayOldType) {
        if (type==="Type")
            includedEditor = <TypeEditor promptContextTab={promptContextTab}/>;
        else if (types && typeNotes && type && type!=="Type" && doc_name && types.includes(type))
            includedEditor = <OldGenericObjectEditor key={note?.id} promptContextTab={promptContextTab}/>;
        else
            // TODO add a title here
            includedEditor = <>{promptContextTab}</>;
    } else {
        if (noteTemplateType.currentNoteType) {
            // TODO we want a new, slimmed down, editor
            // includedEditor = <OldGenericObjectEditor key={note?.id}/>;
            includedEditor = <ExtensionSupportedObjectEditor key={note?.id}/>;
        }
        // includedEditor = <>{promptContextTab}</>;
    }


    return <>
        <div style={{"display":"flex"}}>
            <NoteTemplateTypeQuestionPrompt note={note} noteTemplateType={noteTemplateType}/>&nbsp;&nbsp;&nbsp;
            {displayOldType && <><MultipleChoiceQuestionPrompt questionText="Note Type [old]: " questionLabel="type"
                answersArray={noteTypesOld}
                note={note}/>&nbsp;&nbsp;&nbsp;</>
            }
            {/* Bug: this tooltip does not display. I don't know why not. */}
            <SingleCharacterQuestionPrompt questionText="Emoji: " questionLabel="emoji"
                tooltipText="Optional: select an emoji that visually represents this type."
                note={note}/>
        </div>
        {includedEditor}
    </>;
}