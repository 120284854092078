/*******************************
 * All Firestore events for JSON forms go here.
 * 
 * Part of the reason for keeping them together, and separated like this, is that eventually, to scale further,
 * many of these may need to be executed server side.
 * 
 */
import { Note } from "./NoteType";

/*********
 * Save a change to a single value in the root of our Note, e.g. "type".
 * 
 * currently passed: "type"
 */
export function saveRootDocAnswer(promptLabel:string, values:any, note:Note, saveNoteCallback:_.DebouncedFunc<(note: Note) => void>) {
    const mergeData:Note = {...note, [promptLabel]:values};
    saveNoteCallback(mergeData);
}


export function changedJsonForm_UpdateRelationships(saveOutgoingRelationships:(doc_id: string, relationshipsRootData: any) => any,
    changedObj:any,types:string[], notesOfTypeMap:any,oldRelationships:any[], doc_id:string) {
        
        
    const newTypeRelationships:any[] = [];

    // finding the changes that match recursive through the object.
    function findTypeChanges(valueObj:any) {
        // Search through data for types:
        for (const propertyName in valueObj) { // This works for both arrays & objects!
            const innerValue = valueObj[propertyName];
            if (types.includes(propertyName)) {
                // TODO assert that innerValue is a string.
                const docName = innerValue;
                // Don't add duplicates -- one is enough.
                for (const checkIfAlreadyExistsVal of newTypeRelationships) {
                    if (checkIfAlreadyExistsVal.type===propertyName && checkIfAlreadyExistsVal.name===docName)
                        return;
                }
                // It's unique if we reach this point.
                // Find the ID for the note that was referenced, if we can (we should always be able to):
                if (notesOfTypeMap[propertyName]) { // Make sure there are notes to search through.
                    const matchingNoteIds:string[] = notesOfTypeMap[propertyName].filter((note:Note)=>{
                        return note.doc_name === docName;
                    }).map(
                    function(note:Note){
                        return note.id;
                    });
                    let doc_id_outgoing = null;
                    if (matchingNoteIds.length>0)
                        doc_id_outgoing = matchingNoteIds[0];                
                    newTypeRelationships.push({type:propertyName,name:docName,
                        doc_id:doc_id_outgoing});
                }
            } else if (typeof(innerValue)==="object") {
                // Note: arrays are also typeof "object", so this also triggers for arrays, and it works in the for loop either way.
                findTypeChanges(innerValue);
            }
        }
    }
    findTypeChanges(changedObj);


    // TODO compare to relationships list... We only need to change it if they're different.
    // Not yet implemented:
    // console.log("Relationships to check: (old then new)")
    // console.log(oldRelationships);
    // console.log(newTypeRelationships);

    // Note -- we should only get one change at a time, because users can only click on thing at a time.
    // We're assuming that we don't get 2 changes too fast for a single doc. That makes it a whole
    // lot easier to code the merge back.
    const deletedRelationships = [];
    for (const oldRelationship of oldRelationships) {
        // Check to see if this relationship has been removed:
        let found=false;
        for (const newRelationship of newTypeRelationships) {
            if (oldRelationship.type===newRelationship.type && oldRelationship.name===newRelationship.name) {
                found=true;
                break;
            }
        }
        if (!found) {
            // Yes, it's been removed. Delete it from that note's external references.
            // TODO load that note's references.
            // TODO Remove just that item.
            // TODO save the list without it
            deletedRelationships.push(oldRelationship);
        }
    }
    const addedRelationships = [];
    for (const newRelationship of newTypeRelationships) {
        // TODO Check to see if it's in the old one. If not, we have to add it.
        // const updateDelta = docUpdatesToMake[newDocId] || [];
        let found=false;
        for (const oldRelationship of oldRelationships) {
            if (oldRelationship.type===newRelationship.type && oldRelationship.name===newRelationship.name) {
                found=true;
                break;
            }
        }
        if (!found) {
            // TODO add this one from the new version.
            // debugger;
            addedRelationships.push(newRelationship);
        }
    }

    // Update outgoing relationships too -- this part is easy.
    // This isn't used now but will be when we map out relationships, we don't want them buried.
    // We should also save the JSON Forms object too here, all in one because otherwise we have two doc updates
    // too fast...

    // old way to save?
    // saveRootDocAnswerDebounced("outgoingRelationships",newTypeRelationships, firestore, doc_id);

    // new way to save?
    const mergeData = {relationships:newTypeRelationships,
        deletedRelationships:deletedRelationships,
        addedRelationships:addedRelationships};
    // debugger;
    saveOutgoingRelationships(doc_id,mergeData);
    // TODO can we write the new relationships? I think we have enough information, we just need a structure that won't have any overwriting.
    //     firestore.collection('Notes').doc(newRelationship.doc_id).collection("Relationships").doc(doc_id).set({doc_id}, { merge: false }).then(() => {
    //     }).catch((error:any) => {
    //     console.error("Error writing document to firestore: ", error);
    //     debugger;
    //     });

}