import Showdown from "showdown";
import { GLOBAL_DEBUG_INLINE_TESTS } from "../../GlobalDebug";
//@ts-ignore
// import { TestProperty } from "shared_client_server/TestProperty";
// console.log("The value of TestProperty is:",TestProperty);

const markdownConverter = new Showdown.Converter();

export function htmlToMarkdown(text:string):string {
    let output = markdownConverter.makeMarkdown(text);
    // For some reason, it creates a lot of these useless things.
    output = output.replaceAll("\n<!-- -->\n","");
    output = output.replaceAll("<!-- -->","");
    output = output.replaceAll("<br>\n","\n");
    output = output.replaceAll("<br>","\n");
    output = output.replaceAll("<br/>","\n");    
    if (output.indexOf("<br")!==-1) {
        console.log("htmlToMarkdown output:",output)
        debugger;
    }
    return output;
}

export function markdownToHTML(text:string):string {
    return markdownConverter.makeHtml(text);
}


export function removeJSONFromMarkdown(markdown:string):string {
    const jsonRegex = /```[Jj][Ss][Oo][Nn]\s*([\s\S]*?)\s*```/g;
    return markdown.replace(jsonRegex,"");
}

export function extractJSONFromMarkdown(markdown:string):Object|null {
    const jsonRegex = /```[Jj][Ss][Oo][Nn]\s*([\s\S]*?)\s*```/g;
    const match = jsonRegex.exec(markdown);
    if (!match || match.length!==2) {
        // if (DEBUG_EXTRACT_JSON_FROM_MARKDOWN) {
        //     console.warn("No JSON found in markdown:",markdown, match);
        //     if (markdown.indexOf("```JSON\n")!==-1) {
        //         console.warn("JSON start found, but no end found.");
        //     }
        //     if (markdown.indexOf("\n```")!==-1) {
        //         console.warn("JSON end found, but no start found.");
        //     }
        // }
        return null;
    }
    try {
        return JSON.parse(match[1]);
    } catch (e) {
        console.error("Failed to parse JSON from markdown:",e);
        return null;
    }
}


function test_ExtractJSONFromMarkdown() {
    console.log("Starting tests...");
    let numSuccesses = 0;
    let numFailures = 0;
    const result1 = extractJSONFromMarkdown(`\`\`\`JSON
    {
    "name": "Evil Leprechaun",
    "HP": "Medium",
    "Special Attacks": [
    {
    "name": "Gold Coin Grenade",
    "effect": "Deals damage and temporarily blinds opponents with a shiny explosion."
    },
    {
    "name": "Illusory Double",
    "effect": "Creates an illusion of itself to confuse enemies, effectively giving it an extra turn."
    }
    ],
    "Weaknesses": [
    "Iron",
    "Direct sunlight"
    ],
    "Loot": [
    "Cursed gold coins",
    "Rare magical trinket"
    ],
    "Movement": "Can teleport short distances within its line of sight."
    }
    \`\`\``);
    function check1() {
        if (!result1) {
            return false;
        }
        //@ts-ignore
        if (result1.name !== "Evil Leprechaun") {
            return false;
        }
        return true;
    }
    if (check1()) {
        numSuccesses++;
        // console.log("Test 1 succeeded");
    } else {
        numFailures++;
        console.error("❌ Test1 failed: ",result1);
    }

    

    const result2 = extractJSONFromMarkdown(`\`\`\`JSON
    ["hello","world"]
    \`\`\``);
    function check2() {
        if (!result2 || !Array.isArray(result2) || result2.length!=2 || result2[0]!=="hello" || result2[1]!=="world") {
            return false;
        }
        return true;
    }
    if (check2()) {
        numSuccesses++;
        // console.log("Test 2 succeeded");
    } else {
        numFailures++;
        console.error("❌ Test2 failed: ",result2);
    }

    // Test 3: No JSON
    const result3 = extractJSONFromMarkdown(`This is a test with no JSON.`);
    if (result3===null) {
        numSuccesses++;
        // console.log("Test 3 succeeded");
    } else {
        numFailures++;
        console.error("❌ Test3 failed: ",result3);
    }

    // Test 4: JSON with no end
    const result4 = extractJSONFromMarkdown(`\`\`\`JSON
    {"name":"test"`);
    if (result4===null) {
        numSuccesses++;
        // console.log("Test 4 succeeded");
    } else {
        numFailures++;
        console.error("❌ Test4 failed: ",result4);
    }

    // Test 5: JSON with no start
    const result5 = extractJSONFromMarkdown(`{"name":"test"}`);
    if (result5===null) {
        numSuccesses++;
        // console.log("Test 5 succeeded");
    } else {
        numFailures++;
        console.error("❌ Test5 failed: ",result5);
    }


    /////////////////////////////
    // Wrap up
    if (numFailures===0) {
        console.log("✅ All extractJSONFromMarkdown tests succeeded.");
    } else {
        console.error(numFailures+" tests failed.")
    }
}
if (GLOBAL_DEBUG_INLINE_TESTS)
    test_ExtractJSONFromMarkdown();
