import { Spin } from "antd";
import { useEffect, useState } from "react";
import useTopicDetectionV7Command, { TopicsInputV7 } from "../ServerConnection/LLMServer/TopicDetectionAIV7";


export function TopicDetectionV7TestPage() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [output, setOutput] = useState<React.ReactNode>(<></>);
    const topicDetectionCommand = useTopicDetectionV7Command();
    

    useEffect(()=>{
        console.log("TopicDetectionV7TestPage mounted");
        async function callTopicDetectionV7() {
            setIsLoading(true);
            setOutput(<></>);

            const topicDetectionInput = {
                topics: ["Full list of this bot's capabilities","How to World Build","How to do math","How to do science"],
                userInput: "what is this bot's capabilities?",
            } as TopicsInputV7;
            const response = await topicDetectionCommand(topicDetectionInput);
            // Turn response.logic's line breaks into <br/>:
            const logic = response.logic?.split("\n").map((line:string,i:number)=><>{line}<br/></>);

            if (response.succeeded) {
                setOutput(<>
                <h3>TopicDetectionV7 succeeded!</h3>
                <b>Logic:</b> {logic}<br/>
                <b>Topics:</b> <pre>{JSON.stringify(response.topics,null,2)}</pre>
                </>);
            } else {
                setOutput("TopicDetectionV7 failed: "+response.error);
            }

            setIsLoading(false);
        }
        callTopicDetectionV7();
        return function cleanup() {
            console.log("TopicDetectionV7TestPage unmounted");
        }
    },[]);

    // Now convert output from JSON to HTML:
    

    return <div>
        <h1>Topic Detection V7 Test Page</h1>
        {/* JSON to HTML from output */}
        {isLoading && <Spin/>}
        {output}
    </div>;
}