import { doc, Firestore, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { saveContentBackup } from "../../NoteDBHooks";
import { LoadedFromServerWithVersion } from "../../../../ServerConnection/ServerConnectedStorage";
import { useEffect, useState } from "react";


/*****************************************
 * Constants & Reusable non-hook functions
 */
export const CONTENT_TYPE_JSONFORMS_OBJECT="JSONFormsObject";
export type JSONFormsObject = LoadedFromServerWithVersion & {
  // Can have any structure:
  [key: string]: any;
}


export function getRef_JSONFormsObject(firestore:Firestore, note_id?:string) {
  return doc(firestore, "Notes", note_id?note_id:"temporaryIgnoreMeNotARealId", "Content", "JSONFormsObject");;
}

export async function saveJSONFormsObject(firestore:Firestore,note_id:string, objectData:any) {
  const ref = getRef_JSONFormsObject(firestore, note_id);
  await setDoc(ref, objectData, { merge: false });
  await saveContentBackup(objectData, firestore, note_id, "JSONFormsObject");
}


/*****************************************
 * Hooks
 */

/* 
We've found a strange bug in useFirestoreDocData, where it can return old objects.
So we will start to no longer use useFirestoreDocData, and instead use onSnapshot directly.

https://github.com/FirebaseExtended/reactfire/issues/327

TODO replace all uses of useFirestoreDocData with onSnapshot.
*/
/*export function useJSONFormsObjectOLD(note_id?:string):JSONFormsObject | null {
    const firestore = useFirestore();
    
    const ref = getRef_JSONFormsObject(firestore, note_id);
  
    const { data: jsonFormsObjectData, status} = useFirestoreDocData(ref, { idField: "id" });

    // If we're done loading, and there was no data, it's a new object. We return {} so we know it's successfully loaded an empty object.
    if (status==="success" && !jsonFormsObjectData) {
      // debugger;
      return {version: -1};
    }
    return jsonFormsObjectData as JSONFormsObject;
}*/

const DEBUG_USE_JSONFORMSOBJECT = false;

export function useJSONFormsObject(note_id?:string):JSONFormsObject | null {
  const firestore = useFirestore();
  
  const ref = getRef_JSONFormsObject(firestore, note_id);

  const [jsonFormsObject, setJsonFormsObject] = useState<JSONFormsObject|null>(null);
  useEffect(() => {
      // We're starting a fresh load -- make sure we're not displaying an old one.
      if (jsonFormsObject)
        setJsonFormsObject(null);
      if (!note_id) {
          return;
      }
      if (DEBUG_USE_JSONFORMSOBJECT) console.log("[useJSONFormsObject]>useEffect Starting to load ",note_id," from server, using ", ref);
      const unsub = onSnapshot(ref, (snapshot) => {
        if (DEBUG_USE_JSONFORMSOBJECT) console.log("[useJSONFormsObject]>useEffect got something from server for ",note_id," from server.");
          if (!snapshot.exists()) {
            // It doesn't exist on the server. The server successfully found that it doesn't exist yet, so it's new.
            // console.log("[useJSONFormsObject]>useEffect Error, we tried to load something that does not exist on the server: the JSONFormsObject for note_id ",note_id);
            setJsonFormsObject({version: -1});
            return;
          }
          const loadedNote = snapshot.data() as JSONFormsObject;
          if (DEBUG_USE_JSONFORMSOBJECT) console.log("[useJSONFormsObject]>useEffect Current data: ", snapshot.data());
          setJsonFormsObject({...loadedNote, id:note_id});
      }, (error) => {
          console.log("[useJSONFormsObject]>useEffect Error getting document:", error);
          // TODO which errors can we retry?
      });
      return () => {
          unsub();
      }
  }, [note_id]);
  return jsonFormsObject;
}

export async function getJSONFormsObject(firestore:Firestore, note_id?:string):Promise<JSONFormsObject | null> {
  const ref = getRef_JSONFormsObject(firestore, note_id);
  const objectDoc = await getDoc(ref);
  if (!objectDoc.exists()) {
    return null;
  } else {
    return objectDoc.data() as JSONFormsObject;
  }
}
  

export type SaveJSONFormsFunction = (note_id:string, objectData:LoadedFromServerWithVersion)=>Promise<void>;

/* Save */
export function useSaveJSONFormsObject() {
  const firestore = useFirestore();
  async function save(note_id:string, objectData:LoadedFromServerWithVersion): Promise<void> {
    // console.log("Saving JSONFormsObject:", objectData, "to note_id:", note_id, "in firestore");
    //@ts-ignore
    // console.log("[JSONFormsObject] Official internal hook to save '", objectData?.name,"' version ",objectData.version);
    await saveJSONFormsObject(firestore, note_id, objectData);
  }
  return save;
}
