import { ReusableCard, XPAtLeast1 } from "./GameCardTypes";

import SensesImg from "./CardImages/SD/Skill_Senses.png";
import PhysicalImg from "./CardImages/SD/Skill_Physical.png";
import DisguiseImg from "./CardImages/SD/Skill_Disguise.jpg";
import InfluenceImg from "./CardImages/SD/Skill_Influence.png";
import StealthImg from "./CardImages/SD/Skill_Stealth.png";
import OutdoorsImg from "./CardImages/SD/Skill_Outdoors.png";



export function SensesSkill(plus:XPAtLeast1) {
    return {
        name: "Senses",
        discipline: "Skill",
        actionType: "skill",
        image: SensesImg,
    
        xpCost: plus,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 10,
    
        commandString: "Roll a d20 and add **+%xp%** to check whether you notice something in the environment by using the 5 senses and ability to interpret them:  sight, hearing, smell, taste, or touch.\n\n"+
        "E.g: perceive, spot hidden, search, notice danger, traps, clues, evidence, someone following, patterns, changes, faint sounds, subtle movement, something out of the ordinary.",
    
    } as ReusableCard;
}
export function PhysicalSkill(plus:XPAtLeast1) {
    return {
        name: "Physical",
        discipline: "Skill",
        actionType: "skill",
        image: PhysicalImg,
    
        xpCost: plus,
        goldCost: 0,
    
        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,

        commandString: "⚡: Roll a d20 and add **+%xp%** to check whether you can perform a feat of physical prowess, coordination, or strength.\n\n"+
        "E.g. strength, dexterity, athletics, strength, jump, climb, swim, run, lift, push, pull, catch, balance, dodge, tumble, grapple, throw",
    } as ReusableCard;
}

export function DisguiseSkill(plus:XPAtLeast1):ReusableCard {
    return {
        name: "Disguise",
        discipline: "Skill",
        actionType: "skill",
        image: DisguiseImg,

        xpCost: plus,
        goldCost: plus*5,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 5,

        commandString: "⚡⚡ **Sustained**: Change your appearance to look like someone or something else. This energy is **sustained**: it cannot regenerate until you change your appearance back to normal. Each time your appearance might decieve an observer, roll a d20 and add **+%xp%** to check whether they believe the change.",
    } as ReusableCard;
};
export function InfluenceSkill(plus:XPAtLeast1):ReusableCard {
    return {
        name: "Influence",
        discipline: "Skill",
        actionType: "skill",
        image: InfluenceImg,

        xpCost: plus,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 5,

        commandString: "⚡: Roll a d20 and add **+%xp%** to check whether you can persuade someone to do something they don't already want to do. \n\n"+
        "E.g. convince, sell an idea, negotiate, talk your way out, win over, intimidate, entice, motivate, rally a group, preach, diplomacy, politics, sway someone's opinion",
    } as ReusableCard;
}
export function StealthSkill(plus:XPAtLeast1):ReusableCard {
    return {
        name: "Stealth",
        discipline: "Skill",
        actionType: "skill",
        image: StealthImg,

        xpCost: plus,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,

        commandString: "⚡ **Sustained**: Perform an action stealthily. This energy is **sustained**: it cannot regenerate until you stop using stealth. Each time you could be detected by an observer, roll a d20 and add **+%xp%** to check whether they notice.\n\n"+
        "E.g. sneak, hide, prowl, overhear, avoid detection, blend in, move silently, do an action without being noticed",
    } as ReusableCard;
}
export function OutdoorsSkill(plus:XPAtLeast1):ReusableCard {
    return {
        name: "Outdoors",
        discipline: "Skill",
        actionType: "skill",
        image: OutdoorsImg,

        xpCost: plus,
        goldCost: 0,

        moveSpaces: 0,
        spacesAffected: 1,
        minRange:0, maxRange: 0,

        commandString: "⚡: Roll a d20 and add **+%xp%** to check whether you successfully navigate or interact with outdoor environments.\n\n"+
        "E.g. track, hunt, forage, find water, find shelter, start a fire, navigate with or without a map, make camp, fish, read natural signs",
    } as ReusableCard;
}    

export const ALlSkillCards = [
    SensesSkill(1), SensesSkill(2), SensesSkill(3),
    PhysicalSkill(1), PhysicalSkill(2), PhysicalSkill(3),
    DisguiseSkill(1), DisguiseSkill(2), DisguiseSkill(3),
    InfluenceSkill(1), InfluenceSkill(2), InfluenceSkill(3),
    StealthSkill(1), StealthSkill(2), StealthSkill(3),
    OutdoorsSkill(1), OutdoorsSkill(2), OutdoorsSkill(3),
];

export const AllSkillCardFunctions = [
    SensesSkill, PhysicalSkill, DisguiseSkill, InfluenceSkill, StealthSkill, OutdoorsSkill
];