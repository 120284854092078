import { Select, Spin } from "antd";
import { useState } from "react";
import { useAllTypeNotes } from "./Data/NoteDBHooks";
import { Note } from "./Data/NoteType";

const { Option } = Select;

export default function SelectNoteType({onChange, defaultValue="", includePlainNote=false}:
    {onChange:any, defaultValue?:string,includePlainNote?:boolean}) {
    const [type, setType] = useState(defaultValue);
    const typeNotes = useAllTypeNotes();
    const types = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
    if (types && includePlainNote)
        types.push("Note");
  


    return <>
        {!types && <Spin />}
        {types && <Select placeholder="" style={{ minWidth: '200px' }}
            value={type}
            onChange={function(value:string) {
                if (type===value) {
                    // console.log("Got event, but no change");
                    return;
                }
                setType(value);
                onChange(value);
            }} >
            {types.map(function(answer:string) {
            //@ts-ignore
                return <Option key={answer} value={answer}>{answer}</Option>
            })}
        </Select>
        }
  </>
}
