import { useFunctions, useStorage } from "reactfire";
import { HttpsCallableOptions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useStateLSArray, useStateLSJSON } from "../StateWithLocalCache";
import { ListResult, listAll, ref } from "@firebase/storage";

const SERVER_TIMEOUT_MINS = 4; // 4 minutes. The source of truth is in our server functions

const DEBUG = false;

export type ReplicaVoiceStyle = {
    speaker_id: string;
    // uuid: string; This is here, but not useful.
    name: string;
    samples: [{
        text: string;
        url: string;
    }],
    capabilities: {
        "tts.vox_1_0": boolean;
        "tts.classic": boolean;
    }
};

export const ReplicaPitch_Min = -12;
export const ReplicaPitch_Max = 12;
export type ReplicaPitch = -12 | -11 | -10 | -9 | -8 | -7 | -6 | -5 | -4 | -3 | -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;



export type ReplicaVoice = {
    uuid: string;
    name: string;
    avatar_url: string;
    characteristics: string[];
    description: string;
    hero_sample_url: string;
    default_style: ReplicaVoiceStyle;
    pitch: ReplicaPitch;
    metadata: {
        voiceAge: string;
        gender: string;
        accent: string;
    };
    styles: ReplicaVoiceStyle[];
};


export function useReplicaVoices(): ReplicaVoice[] {
    // We only want to load the voices once per page load -- it's slow! And we need to get it a lot. So we'll store them in a static variable.
    // We also want the voices to be available immediately at load time, so we don't have to wait... But if there's a fresher version, we'll retrieve them from the server and use that instead.
    const [voicesLS, setVoicesLS] = useStateLSJSON("replica_voices",[] as ReplicaVoice[]);
    const functions = useFunctions();
    const options: HttpsCallableOptions = {
        timeout: 1000*60*SERVER_TIMEOUT_MINS+1000, // milliseconds
    };
    function setVoices(voices:ReplicaVoice[]) {
        setVoicesLS(voices);
    }
    
    useEffect(function () {
        async function makeTheCall():Promise<void> {
            const remoteCommand = httpsCallable(functions, "getListOfVoices", options);
            try {
                const commandResponse = await remoteCommand({});
                if (DEBUG) console.log("[useReplicaVoices] > commandResponse: ",commandResponse);
                //@ts-ignore
                const succeeded = commandResponse.data.succeeded as boolean;
                if (!succeeded) {
                    //@ts-ignore
                    console.error("[useReplicaVoices] Error, got this response: "+(commandResponse.error as string));
                    return;
                }
                //@ts-ignore
                const voicesFromServer = commandResponse.data.voices as ReplicaVoice[];
                if (voicesFromServer.length===0) {
                    console.error("[useReplicaVoices] Error, got no voices from the server.");
                    return;
                }
                if (DEBUG) console.log("[useReplicaVoices] > voicesFromServer: ",voicesFromServer);
                setVoices(voicesFromServer);
                return;
            } catch (error) {
                console.error(error);
                debugger;
            }
        }
        // TODO if we've started a call already, we don't want to make another one. However, React doesn't seem to have a good way (without Redux or a high level context provider) to change the return value once it gets set from multiple states.
        if (voicesLS.length===0)
            makeTheCall();
    }, []);

   return voicesLS;
}

/********************
 * Local cache of the path to synthesized text. It's slow to refetch from the server each time.
 * 
 * Weakness of this: if anything gets deleted from the server, this will break and the text would be skipped or stopped in the middle of narration.
 * 
 */
export function useSynthesizedCache() {
    const {value, push } = useStateLSArray("replica_SynthesizedCache",[] as ReplicaPreSynthesizedText[]);

    function getAllVoiceStyleFromCache(voice:string, style:string) {
        return value.filter((item:ReplicaPreSynthesizedText) => item.voice===voice && item.style===style);
    }
    function getFromCache(voice:string,style:string,text:string) {
        const found = value.find((item:ReplicaPreSynthesizedText) => item.voice===voice && item.style===style && item.text===text) as ReplicaPreSynthesizedText;
        if (found) {
            return found;
        }
        return null;
    }
    function addToCachePreSynthesized(preSynthesized:ReplicaPreSynthesizedText) {
        push(preSynthesized);
    }
    function addToCacheRaw(voice:string,style:string,text:string,fullPath:string) {
        addToCachePreSynthesized({voice,style,text,fullPath} as ReplicaPreSynthesizedText);
        if (DEBUG) console.log("[useSynthesizedCache] addToCacheRaw > value: ",value);
    }

    if (DEBUG) console.log("[useSynthesizedCache] > value: ",value);

    return {getFromCache, addToCacheRaw, addToCachePreSynthesized,getAllVoiceStyleFromCache};
}

export function useReplicaSynthesizeCommand() {
    const functions = useFunctions();
    const options: HttpsCallableOptions = {
        timeout: 1000*60*SERVER_TIMEOUT_MINS+1000, // milliseconds
    };
    const remoteCommand = httpsCallable(functions, "getAndStoreSynthesizedText", options);
    const {getFromCache, addToCacheRaw} = useSynthesizedCache();

    async function replicaSynthesizeCommand(voice_name: string, style:ReplicaVoiceStyle, textInput:string, pitch:ReplicaPitch=0):Promise<string> {
        let text = textInput;
        if (pitch) {
            text = `<speak><prosody pitch="${pitch}st">${text}</prosody></speak>`;
        }
        if (DEBUG) console.log("[useReplicaSynthesizeCommand] > Synthesizing: ",voice_name,style,text);
        const synthesizedText = getFromCache(voice_name,style.name,text);
        if (synthesizedText) {
            if (DEBUG) console.log("[useReplicaSynthesizeCommand] > Found in cache: ",synthesizedText.fullPath);
            return synthesizedText.fullPath;
        }

        const cmd = {
            speakerName: voice_name,
            speaker_id: style.speaker_id,
            style: style.name,
            model_chain: style.capabilities["tts.vox_1_0"]?"vox_1_0":"classic",
            text
        };
        // if (DEBUG) console.log("[useReplicaSynthesizeCommand] > Synthesis cmd: ",cmd);
        try {
            const commandResponse = await remoteCommand(cmd);
            if (DEBUG) console.log("[useReplicaSynthesizeCommand] > commandResponse: ",commandResponse);
            //@ts-ignore
            const succeeded = commandResponse.data.succeeded as boolean;
            if (!succeeded) {
                //@ts-ignore
                console.error("[useReplicaSynthesizeCommand] Error, got this response: "+JSON.stringify(commandResponse));
                return "";
            }
            //@ts-ignore
            const filename = commandResponse.data.filename as string;
            if (filename.length===0) {
                console.error("[useReplicaSynthesizeCommand] Error, got no url from the server.");
                return "";
            }
            if (DEBUG) console.log("[useReplicaSynthesizeCommand] > url: ",filename);
            addToCacheRaw(voice_name,style.name,text,filename);
            return filename;
        } catch (error) {
            console.error(error);
            debugger;
            return "";
        }
    }
    return replicaSynthesizeCommand;
}

export type ReplicaPreSynthesizedText = {
    voice: string;
    style: string;
    text: string;
    fullPath: string;
};

export function useReplicaPreSynthesizedText(voice?:ReplicaVoice,style?:ReplicaVoiceStyle) {
    const storage = useStorage();
    // const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [synthesizedText, setSynthesized] = useState<ReplicaPreSynthesizedText[]>([]);
    const {getAllVoiceStyleFromCache, addToCachePreSynthesized} = useSynthesizedCache();
        
    useEffect(function () {
        if (!voice) {
            setSynthesized([]);
            // console.log("[usePreSynthesizedText] > no voice");
            return;
        }
        let path = "Speech/"+voice.name+"/";
        if (style) {
            path += style.name+"/";
        }
        const folderRef = ref(storage,path);
        // console.log("[usePreSynthesizedText] > starting to load from ",folderRef.fullPath);
        const synthed = getAllVoiceStyleFromCache(voice.name,style?style.name:"");
        // const synthed = [] as ReplicaPreSynthesizedText[];
        async function addItem(res:ListResult) {
            const promisesToWaitFor = [] as Promise<ListResult|void>[];
            res.items.forEach(itemRef => {
                // TODO process the itemRef
                // console.log("[usePreSynthesizedText] > found a speech item: ",itemRef.fullPath);
                let text = itemRef.fullPath.split("/").pop();
                if (!text)
                    text = "";
                const existsAlready = synthed.find((item:ReplicaPreSynthesizedText) => item.text===text);
                if (!existsAlready) {
                    const preSynthesized = {text:text,fullPath:itemRef.fullPath,voice:voice?voice.name:"",style:style?style.name:""};
                    synthed.push(preSynthesized);
                    addToCachePreSynthesized(preSynthesized);
                }
            });
            res.prefixes.forEach(folderRef => {
                // console.log("[usePreSynthesizedText] > found a folder: ",folderRef.fullPath);
                promisesToWaitFor.push(listAll(folderRef).then(addItem));
            });
            await Promise.all(promisesToWaitFor);
        }

        async function loadSynthesizedText() {
            setLoading(true);
            await listAll(folderRef).then(addItem);
            setLoading(false);
            setSynthesized(synthed);
        }
        loadSynthesizedText();
    }, [voice,style]);

    return {synthesizedText,loading};
}