import { Button, Modal } from "antd";
import {ReplicaPitch, useReplicaVoices } from "./ReplicaAPI";
import { useStateURLInteger, useStateURLString } from "../URLQueryParams";
import { ReplicaVoiceComponent, SELECTED_VOICE_ID_PARAMETER, SELECTED_VOICE_PITCH_PARAMETER } from "./ReplicaVoiceUI";

export function ReplicaVoiceSidebar() {
    return <div className="RelatedNotes" style={{padding: "10px"}}>
        <h3>Voices</h3>
        <ReplicaVoiceComponent showExtraInfo={false}/>
    </div>;
}

export function SelectVoiceModal({isSearchModalOpen, setSearchModalOpen, callback,title="Select Voice"}:{isSearchModalOpen:boolean, setSearchModalOpen:React.Dispatch<React.SetStateAction<boolean>>, callback:(voice_id:string,voice_name:string, pitch:ReplicaPitch)=>void,title?:string}) {
    const voices = useReplicaVoices();
    // const [selectedVoiceID, setSelectedVoiceID] = useState<string|undefined>(undefined);
    const [selectedVoiceID, setSelectedVoiceID] = useStateURLString(SELECTED_VOICE_ID_PARAMETER,"");
    // const searchNotesResult = useSearchNotesResult_InsertLinkIntoEditor(callback);
    const [pitch, setPitch] = useStateURLInteger(SELECTED_VOICE_PITCH_PARAMETER,0,false);
    const voice = voices.find(voice => voice.uuid === selectedVoiceID);

    function insertLink() {
        if (selectedVoiceID && voice)
            callback(selectedVoiceID,voice.name, pitch as ReplicaPitch);
        else {
            console.error("[ReplicaVoiceUI > SelectVoiceModal] One of the following is null. This should not be possible, so it's a bug: selectedVoiceID=",selectedVoiceID,"voice=",voice)
            alert("Please try again in a moment, there was an issue with loading.");
        }
    }

    return <>
      <Modal
            title={title}
            open={isSearchModalOpen}
            onOk={()=>setSearchModalOpen(false)}
            onCancel={()=>setSearchModalOpen(false)}
            width="100vw"
            style={{ top: 20}}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={()=>setSearchModalOpen(false)}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={insertLink} disabled={!selectedVoiceID}>Select</Button>,
            ]}
        >
            <ReplicaVoiceComponent isInDialog={true}/>
        </Modal>
    </>;
}