import { Note } from "../../Notes/Data/NoteType";
import { getStringFromResponse } from "./SendChatToServerHook";
import { ChatAPIOptions } from "./GenericChatServer";
import { ChatCompletionMessageToolCall } from "openai/resources";


export type TemplateDetectedType = "new" | "edit" | "none";

export type TemplateDetectionReturnType = {
    type: TemplateDetectedType;
    template: string;
};

export async function detectTemplate(templateNotes: Note[], userInput: string, sendToServerWithStreaming: Function): Promise<TemplateDetectionReturnType> {
    const templateNames = templateNotes.map(function (note) {
        if (note.doc_name.includes("Template"))
            return note.doc_name;
        return note.doc_name + " Template";
    });
    const chatAPIOptions = {
        temperature: 0.01, // no creativity
        max_tokens: 1000,
        tool_choice: "required",
        tools: [
            {
                type: "function",
                function: {
                    "name": "new_from_template",
                    "description": "Get the template to create something new.",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "template": {
                                "type": "string",
                                "description": "The name of the template",
                                "enum": templateNames
                            }
                        },
                        "required": [
                            "template"
                        ]
                    }
                }
            }, {
                type: "function",
                function: {
                    "name": "edit_from_template",
                    "description": "Edit something that uses one of these templates.",
                    "parameters": {
                        "type": "object",
                        "properties": {
                            "template": {
                                "type": "string",
                                "description": "The name of the template",
                                "enum": templateNames
                            }
                        },
                        "required": [
                            "template"
                        ]
                    }
                }
            }, {
                type: "function",
                function: {
                    "name": "generate_response",
                    "description": "Continue generating the response. Call when there are no other matching functions.",
                    "parameters": {
                        "type": "object",
                        "properties": {},
                        "required": []
                    }
                }
            }
        ],
    } as ChatAPIOptions;
    const messages = [
        {
            role: "system",
            content: `You are a helpful assistant that detects what the user wants to do.

If the user wants to create something and there's a template for the thing they want to create, call the "new_from_template" function.

If the user wants to edit something, and there's a template for the thing they want to edit, call the "edit_from_template" function.

If the user doesn't want to create or edit something, call the "generate_response" function.

Use the provided functions to return JSON. Always return JSON to call one of the three functions. If none seem to apply, call the "generate_response" function."`
        }, {
            role: "user",
            content: userInput
        }
    ];
    // console.log("[TemplateDetection] Sending messages to server: \""+userInput+"\"");
    let toReturnFunctionSpec: ChatCompletionMessageToolCall.Function[] = [];
    function callFunction(functionSpec: ChatCompletionMessageToolCall.Function[]) {
        // console.log("[TemplateDetection] Got function call: ", functionSpec);
        toReturnFunctionSpec = functionSpec;
    }
    const response = sendToServerWithStreaming(messages, chatAPIOptions, callFunction);
    const output = await getStringFromResponse(response);
    // console.log("[TemplateDetection] Got response: \""+output+"\"");
    // Now parse this down to the function name and the template argument. There should be exactly one function call.
    if (toReturnFunctionSpec.length !== 1) {
        throw new Error("Expected exactly one function call, but got " + toReturnFunctionSpec.length);
    }
    const name = toReturnFunctionSpec[0].name;
    let type = "none" as TemplateDetectedType;
    if (name === "new_from_template") {
        type = "new";
    } else if (name === "edit_from_template") {
        type = "edit";
    }
    const functionSpec = {
        type,
        template: "",
    } as TemplateDetectionReturnType;
    try {
        const argsJSON = JSON.parse(toReturnFunctionSpec[0].arguments);
        if (argsJSON?.template) {
            // Find the one in the list of templateNotes that matches this name -- sometimes we appended " Template" to the name, so it needs to check:
            const templateNote = templateNotes.find(function (note) {
                if (note.doc_name === argsJSON.template || note.doc_name + " Template" === argsJSON.template) {
                    return true;
                }
                return false;
            });
            if (templateNote)
                functionSpec.template = templateNote.doc_name;

            else
                functionSpec.template = argsJSON.template;
        }
    } catch (e) {
        // probably fine.
    }
    return functionSpec;
}
