import { useContext, useState } from "react";
import { Button, Spin } from "antd";
import { ReplicaVoice, ReplicaVoiceStyle } from "./ReplicaAPI";
import { SelectedJSONFormsContext } from "../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { NotesContext } from "../../../Notes/Data/NotesContext";
import { NARRATOR_VOICE_UUID } from "./ProcessNarration";
import { htmlToMarkdown } from "../../../ServerConnection/LLMServer/MarkdownConverter";
import { ChatAPIOptions } from "../../../ServerConnection/LLMServer/GenericChatServer";
import { estimateTokensFromChat, estimateTokensFromString } from "../../../ServerConnection/LLMServer/LLMTokenCounter";
import { noteTypeIsCharacter } from "../../../Extensions/TTRPG/CharacterEnemyNPCs/CharacterEnemyNPCs";
import { LLMServerContext } from "../../ChatEditor/UI/SelectableLLM";
import { ChatCompletionMessageParam } from "openai/resources";

const DEBUG = false;

export function useRecommendAVoiceComponent(voice:ReplicaVoice|undefined, setSelectedVoiceID:(voiceid:string)=>void,filteredVoices:ReplicaVoice[]) {
    const notesContext = useContext(NotesContext);
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const {callChatAPI, serverType} = useContext(LLMServerContext);
    const [isRecommendingAVoice, setIsRecommendingAVoice] = useState(false);
    const canRecommendAVoice = !isRecommendingAVoice && selectedJsonFormsContext?.note?.type && noteTypeIsCharacter(selectedJsonFormsContext?.note?.type);
    let recommendVoiceButtonName = "Recommend a voice";
    const hasASelectedVoiceId = !!voice; // sometimes this becomes "undefined" -- we should root out that cause and eliminate it.
    if (canRecommendAVoice) {
        if (hasASelectedVoiceId) {
            recommendVoiceButtonName = "Recommend another voice for "+selectedJsonFormsContext.note.doc_name;
        } else 
            recommendVoiceButtonName = "Recommend a voice for "+selectedJsonFormsContext.note.doc_name;
    }
    async function recommendAVoice() {
        if (!canRecommendAVoice || !callChatAPI) return;
        setIsRecommendingAVoice(true);
        let otherVoiceOptions = filteredVoices
            .filter(otherVoice => otherVoice.uuid !== voice?.uuid)
            .filter(otherVoice => otherVoice.uuid !== NARRATOR_VOICE_UUID);
        alert("This is not currently implemented. The developers need to work on fixing this to get the information about the character to pick an appropriate voice.");
        throw "Not implemented";
        /*const characterMarkdown = htmlToMarkdown(await getNoteAndIncludedNotesHTML(selectedJsonFormsContext.note, notesContext));

        // Remove useless data from the voice options, to make it smaller. The samples, avatar, are not needed.
        otherVoiceOptions = otherVoiceOptions.map((voice:ReplicaVoice) => {
            let {uuid, name, styles, metadata, characteristics, description} = voice;
            styles = styles.map((style: ReplicaVoiceStyle) => {
                let {speaker_id, name} = style;
                return {speaker_id, name} as ReplicaVoiceStyle;
            });
            return {uuid, name, styles, metadata, characteristics, description} as ReplicaVoice;
        });

        const voicesString = JSON.stringify(otherVoiceOptions);
        const voicesTokens = estimateTokensFromString(voicesString);
        
        // Voice tokens, perhaps because it's JSON, are much larger than usual tokens apparently. We estimate 99k tokens for all voices on 4/7/2024 but it was actually 369k tokens! A huge undercount.

        // So, if there are no tags, we're going to start by getting a recommended set of tags for this voice, to narrow it down.

        console.log("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] voicesTokens=",voicesTokens);
        if (voicesTokens>(serverType.contextLength/4)) {
            // TODO instead of erroring, recommend 2 tags, a gender and one more.
            alert("Please set some initial tags first, before asking to recommend a voice. "+voicesString.length+" voices is too many to start with right now.");
            console.error("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] Error: The voices string is too long for the server to handle. Length = ",voicesString.length,"tokens = ",voicesTokens);
            setIsRecommendingAVoice(false);
            return;
        }
        let systemPrompt = "Recommend the single best voice for the character '"+selectedJsonFormsContext.note.doc_name+"' based on the character's description and the available voices. Return a simple JSON object with the voice's UUID";
        if (DEBUG) {
            // Add name
            systemPrompt+=" and the voice's name: {uuid:'"+NARRATOR_VOICE_UUID+"', name:'Deckard'}";
        } else {
            systemPrompt+=": {uuid:'"+NARRATOR_VOICE_UUID+"'}";
        }
        systemPrompt+=". The voices you can select from are: "+voicesString+". About this character: "+characterMarkdown;

        if (DEBUG) console.log("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] systemPrompt=",systemPrompt);

        const messages = [{content:systemPrompt,role:"system"}] as ChatCompletionMessageParam[];
        const estTokens = estimateTokensFromChat(messages);
        // console.log("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] estTokens=",estTokens);

        callChatAPI(messages,function(fullOutput:string, newOutput:string, isDone:boolean) {
            if (DEBUG) console.log("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] fullOutput=",characterMarkdown,"newOutput=",newOutput,"isDone=",isDone);
            if (isDone) {
                const output = JSON.parse(newOutput) as {uuid:string};
                if (DEBUG) console.log("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] completed recommendation for ",selectedJsonFormsContext.note.doc_name,output);
                if (output.uuid) {
                    setSelectedVoiceID(output.uuid as string);
                }
                setIsRecommendingAVoice(false);
            }
        },function(error: string) { // Add type annotation for the error parameter
            console.error("[ReplicaVoiceUI > ReplicaVoiceComponent > recommendAVoice] Error in recommendAVoice: ",error);
            setIsRecommendingAVoice(false);
        },{jsonMode:true} as ChatAPIOptions);*/
    }

    const recommendAVoiceComponent = <>
        {isRecommendingAVoice && <Spin/>}
        {canRecommendAVoice && <Button type="default" onClick={recommendAVoice} loading={isRecommendingAVoice}>{recommendVoiceButtonName}</Button>}
    </>;

    return {recommendAVoiceComponent};
}