import { useState } from "react";
import 'react-h5-audio-player/lib/styles.css';
import { Select } from "antd";
import { PLAYER_UIS, PLAYER_UI_FANCY } from "../DecisionGraph/Utilities/Sound/CloudPlaylist";
import { FullSoundtrackSelector } from "../DecisionGraph/Utilities/Sound/FullMusicSoundtrack";


export default function SoundTestPage() {
    const [playerUI, setPlayerUI] = useState(PLAYER_UI_FANCY);
    const [randomizeOrder, setRandomizeOrder] = useState(true);

    return <div>
        <h1>Sound Player Test Page</h1>
        <p>For autoplay to work, you must first interact with the page in any way. Then we can play it programmitically or with autoplay. So if you leave this page and browse back, it will start automatically, but if you refresh this page, it will not autoplay.</p>
        UI Version: <Select value={playerUI} onChange={setPlayerUI}>
            {PLAYER_UIS.map(option => <Select.Option key={option} value={option}>{option}</Select.Option>)}
        </Select> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Track Order: <Select value={randomizeOrder} onChange={setRandomizeOrder}>
            <Select.Option value={true}>Randomize Order</Select.Option>
            <Select.Option value={false}>In Order</Select.Option>
        </Select> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;        
        <br/>
        <FullSoundtrackSelector playerUI={playerUI} randomizeOrder={randomizeOrder}/>
    </div>;
}