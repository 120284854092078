import { DocumentData, Transaction, runTransaction } from 'firebase/firestore';
import { DocumentReference } from 'firebase/firestore';
import { collection, deleteDoc, doc, Firestore, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { debounce } from 'lodash';
import { useFirestoreCollectionData, useFirestore, useFirestoreDocData } from 'reactfire';
import { fixNoteCorruptedEntities, getNewNoteData, SAVE_DELAY } from './FirestoreNoteClient';
import { JSONFormsSchema, Note } from './NoteType';
import { getJSONFormsSchema, useJSONFormsSchema } from './Actions/JSONFormsObject/LoadAndSaveJSONSchemaObject';
import { useContext, useMemo } from 'react';
import { NotesContext } from './NotesContext';


export async function newNote(firestore:Firestore, name:string|undefined=undefined, mergeData:object={}):Promise<Note> {
  // TODO we should really accept a parent node so it can be organized by default.
  // That would probably require a refactor of the way TagTree data is stored.
  const newNoteData = getNewNoteData(name);
  const {id, ...newNoteDataToSave} = newNoteData;
  const newNoteRef = doc(firestore, "Notes",id);
  await setDoc(newNoteRef, {...newNoteDataToSave,...mergeData}, { merge: true });
  return {id, ...newNoteDataToSave, ...mergeData} as Note;
}

export function useNewNote():(
    name?:string,
    mergeData?:object) => Promise<Note> {
  const firestore = useFirestore();
  return async function newNote_Wrapper(name:string|undefined=undefined, mergeData:object={}):Promise<Note> {
    return await newNote(firestore, name, mergeData);
  }
}

async function finishLoadingNotes(noteListRef:any,notesHaveBeenLoaded:(notes:Note[])=>void) {
  const querySnapshot = await getDocs(noteListRef);
  const notes = [] as Note[];
  querySnapshot.forEach(async function(noteWrapper:any) {
    const note = {...noteWrapper.data(), id:noteWrapper.id} as Note;
    notes.push(note);
  });
  // FYI, we do push these all in one batch, this does slow the load down, with the tradeoff of fewer updates.
  notesHaveBeenLoaded(notes);
}

const typesLoadingInfo={} as any;

export function isCurrentlyLoadingAnyTypes() {
  for (const type in typesLoadingInfo) {
    if (typesLoadingInfo[type].loading) {
      return true;
    }
  }
  return false;
}

export async function loadNotesOfType(type:string,notesHaveBeenLoaded:(notes:Note[])=>any, firestore:Firestore) {
  let loadingInfo;
  if (typesLoadingInfo.hasOwnProperty(type)) {
    loadingInfo = typesLoadingInfo[type];
    if (loadingInfo.loading) {
      return;
    } else {
      if (loadingInfo.lastLoad && loadingInfo.lastLoad>Date.now()-(60*1000))
        return;
      loadingInfo.loading=true;
    }
  } else {
    loadingInfo = {loading:true};
    typesLoadingInfo[type]=loadingInfo;
  }
  const notesRef = collection(firestore, "Notes");
  const typeValuesRef = query(notesRef,where("type","==",type));

  await finishLoadingNotes(typeValuesRef,notesHaveBeenLoaded);
  loadingInfo.loading=false;
  loadingInfo.lastLoad = Date.now();
}


export function useRootParentNotes():Note[] {
  const allNotesRef = collection(useFirestore(), "Notes");
  const parentNotesRef = query(allNotesRef, where("parent", "==", ""));
  const { data: notesList } = useFirestoreCollectionData(parentNotesRef, { idField: "id" });
  return notesList as Note[];
}

function getRef_NotesOfType(firestore:Firestore,type:string) {
  const allNotesRef = collection(firestore, "Notes");
  // Create a query against the collection.
  const typeNotesRef = query(allNotesRef, where("type", "==", type));
  return typeNotesRef;
}

/*************
 * Most of the time, this should return just one note. However, we have to return an array because it's theoretically possible to get multiple matches.
 */
function getRef_NotesOfTypeNamed(firestore:Firestore,type:string,name:string) {
  const allNotesRef = collection(firestore, "Notes");
  // Create a query against the collection.
  const typeNotesRef = query(allNotesRef, where("type", "==", type), where("doc_name", "==", name));
  return typeNotesRef;
}

export async function getNoteOfTypeNamed(firestore:Firestore,type:string,name:string):Promise<Note | null> {
  const typeNotesRef = getRef_NotesOfTypeNamed(firestore,type,name);
  const querySnapshot = await getDocs(typeNotesRef);
  const notes = [] as Note[];
  querySnapshot.forEach(async function(noteWrapper:any) {
    const note = {...noteWrapper.data(), id:noteWrapper.id} as Note;
    notes.push(note);
  });
  if (notes.length>0) {
    return notes[0];
  }
  return null;
}

export function useNoteOfTypeNamed(type:string,name:string):Note | undefined {
  const firestore = useFirestore();
  const typeNotesRef = getRef_NotesOfTypeNamed(firestore,type,name);
  const { data: notesList } = useFirestoreCollectionData(typeNotesRef, { idField: "id" });
  if (notesList && notesList.length>0) {
    return notesList[0] as Note;
  }
  return undefined;
}



export function getRef_NoteID(firestore:Firestore,id:string):DocumentReference<DocumentData> {
  if (!id)
    throw new Error("getRef_NoteID called with no ID");
  const noteRef = doc(firestore, "Notes",id);
  return noteRef;
}


export function useAllTypeNotes():Note[] {
  const firestore = useFirestore();
  const typeNotesRef = getRef_NotesOfType(firestore,"Type");  

  // subscribe to the do throws a Promise for Suspense to catch,
  // and then streams live updates
  //@ts-ignore cast to Note
  const { data: notesList }:{data:Note[]} = useFirestoreCollectionData(typeNotesRef, { idField: "id" });
  
  // return array of [data, handlers] to match hooks like useState
  return notesList;
}

export function useNote(id?:string):Note | undefined {
  const firestore = useFirestore(); // must be called every time.

  const noteRef = getRef_NoteID(firestore, id?id:"temporaryIgnoreMeNotARealId");
  
  const { data: noteUntyped} = useFirestoreDocData(noteRef, { idField: "id" });
  const note = noteUntyped as Note;

  // Bug workaround:
  // This is a workaround to the very curious incorrect loading of old data.
  // If the data loaded is not what we requested, return undefined. This seems to correct itself after a call or two, but there may be a corrupted cache of some sort.
  if (note?.id!==id) {
    return undefined;
  }

  return note;
}

async function loadOneNoteOnce(firestore:Firestore,id:string) {
  const noteRef = getRef_NoteID(firestore, id);
  const noteWrapper = await getDoc(noteRef); 
  if (!noteWrapper.exists()) {
    return null;
  }
  // Add the ID back in (it's not automatic):
  return {...noteWrapper.data(),id} as Note;  
}

/**
 * Used for loading lots of notes, without subscribing to the contents.
 * Useful for the tree or search results -- not useful for the other stuff.
 * 
 * @param ids 
 */
export function useLoadNoteOnceCallback() {
  const firestore = useFirestore();

  async function loadNoteOnce(id:string):Promise<Note | null> {
    return await loadOneNoteOnce(firestore, id);
  }
  return loadNoteOnce;
}

async function deleteSubcollection(noteRef:DocumentReference<DocumentData>,subcollectionName:string, transaction?:Transaction) {
  const subcollectionRef = collection(noteRef,subcollectionName);
  const subcollectionDocs = await getDocs(subcollectionRef);
  subcollectionDocs.forEach(async (subcollectionDoc) => {
    const subcollectionDocRef = doc(subcollectionRef,subcollectionDoc.id);
    if (transaction)
      transaction.delete(subcollectionDocRef);
    else
      deleteDoc(subcollectionDocRef);
  });
}

export function useDeleteNoteCallback() {
    const firestore = useFirestore();
    const notesContext = useContext(NotesContext);
    const saveNote = useSaveNote();

    async function deleteNote(noteIdToDelete:string) {
      const noteRef = getRef_NoteID(firestore, noteIdToDelete);
      const note = await loadOneNoteOnce(firestore, noteIdToDelete);
      if (!note) {
        // Should we error silently since it's already deleted?
        console.error("[deleteNote] The note to be deleted was not found -- it may have already been deleted.");
        return;
      }
      const parentId = note.parent;
      let parentNote = null as Note | null;
      let newParentId = "";
      if (parentId) {
        parentNote = await loadOneNoteOnce(firestore, parentId);
        if (parentNote) {
          newParentId = parentNote.parent || "";
        }
      }
      await runTransaction(firestore, async (transaction) => {
        // Check for children, and repartent them:
        if (note?.children?.length && note?.children?.length>0) {
          // We have to update these parents to the parent's parent, otherwise they won't show in the list at all.
          note.children.forEach(async (child_id:string) => {
            // const childRef = getRef_NoteID(firestore, child_id);
            const child = await loadOneNoteOnce(firestore, child_id);
            if (child) {
              saveNote({...child, parent:newParentId}, transaction);
            }
          });
        }
        // Check for parent, and remove the child:
        if (parentNote && parentNote.children && parentNote.children.length>0) {
          const newChildren = parentNote.children.filter((child_id:string)=>child_id!==noteIdToDelete);
          saveNote({...parentNote, children:newChildren}, transaction);
        }

        transaction.delete(noteRef);

        // Also delete the subcollections, which include the Content (JSONFormsObject & schema), Relationships, and Backups:
        await deleteSubcollection(noteRef,"Content", transaction);
        await deleteSubcollection(noteRef,"Relationships", transaction);
        await deleteSubcollection(noteRef,"Backups", transaction);
      });
      notesContext.removeNote(noteIdToDelete);
    }
    return deleteNote;
}

function saveNoteBackup(change:Note,firestore:any) {
  // Add to backups.
  // Since it's debounced, millisecond resolution is a decent way to track changes.
  // toISOString adds milliseconds and sets the time zone to UTC -- perfect.
  // We do one backup per 10 minutes -- that seems like enough.
  // TODO separately, we need a process that runs occasionally (monthly?) to compress backups.
  // That process will start from an original document. When there are too many backups, or too old, we want to combine them.
  
  // TODO we also need to figure out the process for instantiating undos.
  // We do have the changes, but we may have to start from the oldest document and build up, or trace backwards
  // to the last time each object was changed in order to get the original. We have the change, but we didn't store the original
  // so e.g. if the note was changed, we might hvae to go back 10 backups to find the previous note object.

  // TODO older documents created before we started this, and that don't have edits, may be slightly trickier?
  // e.g. if there have been no edits to the notes section, it might not appear in the backups. So the manual process is to edit before undoing...?
  // Note that my personal older files don't have this so we can't actually undo. Only new files will be able to be rebuilt.
  // TODO so for these older files we want to create that too
  // So that there can be a starting place to rebuild from (e.g. to get a backup out of history, you need an original then apply all of the changes to it)

  const backup_date = new Date();
  backup_date.setMilliseconds(0);
  backup_date.setSeconds(0);
  backup_date.setMinutes(Math.floor(backup_date.getMinutes()/10)*10);
  const backup_name = backup_date.toISOString();

  const {id, ...noteToSave} = change;

  // console.log("Saving Backup for "+backup_name+": "+ JSON.stringify(noteToSave));
  const backupRef = doc(firestore, "Notes",id,"Backups",backup_name);
  setDoc(backupRef, noteToSave, { merge: true });
}

export async function saveContentBackup(change:any,firestore:any,doc_id:string, contentDocName:string) {
  const backup_date = new Date();
  backup_date.setMilliseconds(0);
  backup_date.setSeconds(0);
  backup_date.setMinutes(Math.floor(backup_date.getMinutes()/10)*10);
  const backup_name = backup_date.toISOString();

  // console.log("Saving Backup for "+backup_name+": "+ JSON.stringify(change));
  const backupRef = doc(firestore, "Notes",doc_id,"Content",contentDocName,"Backups",backup_name);
  await setDoc(backupRef, change, { merge: true });
}

export function useContentBackup(note_id:string,contentDocName:string):any[] {
  const backupsRef = collection(useFirestore(), "Notes",note_id,"Content",contentDocName,"Backups");
  // id will be the date
  const { data: backupsList }:{data:any[]} = useFirestoreCollectionData(backupsRef, { idField: "id" });
  return backupsList;
}

export function useSaveOutgoingRelationships() {
  const firestore = useFirestore();

  return function saveOutgoingRelationships(doc_id:string,relationships:any) {
    const outgoingRelationshipsRef = doc(firestore, "Notes", doc_id, "Relationships","Outgoing");
    setDoc(outgoingRelationshipsRef, relationships, { merge: true });
  }
}

export function saveNote(firestore:Firestore, note:Note, transaction?:Transaction) {
  const noteRef = getRef_NoteID(firestore, note.id);
  // TODO update index of references so we can efficiently search for these later
  // TODO this is not working correctly. It does not get icons
  // fullDocToSave.tagReferencesIndex=getTagReferencesIndexFrom(fullDocToSave, mentions, hashtags);

  fixNoteCorruptedEntities(note);
  const {id, ...noteToSave} = note;

  // We merge today, because a bunch of our code changes just one variable at a time.
  // However, more data would be consistent if we remove the merge, but we'd have to rewrite most calls to this function.
  // TODO we could update this to only write the part that's changed, which would be even safer to multiple editors.
  if (transaction)
    transaction.set(noteRef, noteToSave, { merge: true });
  else
    setDoc(noteRef, noteToSave, { merge: true });

  saveNoteBackup(note, firestore);
}

export function useSaveNote() {
  const firestore = useFirestore();
  const notesContext = useContext(NotesContext);
  return function saveNote_wrapper(note:Note, transaction?:Transaction) {
    const toReturn = saveNote(firestore, note, transaction);
    notesContext.notesHaveBeenLoaded([note]);
    return toReturn;
  }
}

export function useSaveNoteDebounced(note_id:string) {
  const saveNote = useSaveNote();
  const debouncedSaveNote = useMemo(() => debounce(saveNote, SAVE_DELAY), [note_id]);
  return debouncedSaveNote;
}



export function getRef_NotesNamed(firestore:Firestore,name:string) {
  const allNotesRef = collection(firestore, "Notes");
  // Create a query against the collection.
  const sameNameNotesRef = query(allNotesRef, where("doc_name", "==", name));
  return sameNameNotesRef;
}

export async function howManyNotesNamed(firestore:Firestore,name:string) {
  const sameNameNotesRef = getRef_NotesNamed(firestore,name);
  const querySnapshot = await getDocs(sameNameNotesRef);

  let numberWithName=0;

  const noteIds = [] as string[];
  querySnapshot.forEach(function(doc:any) {
      numberWithName++;
      noteIds.push(doc.id);
  });

  return {numberWithName, noteIds};
}
export async function getNotesNamed(firestore:Firestore,name:string) {
  const sameNameNotesRef = getRef_NotesNamed(firestore,name);
  const querySnapshot = await getDocs(sameNameNotesRef);

  const notes = [] as Note[];
  querySnapshot.forEach(function(doc:any) {
      const note = {...doc.data(), id:doc.id} as Note;
      notes.push(note);
  });

  return notes;
}


function getSchemaAndUIFromSchemaObject(jsonFormsSchema:JSONFormsSchema | undefined) {
  if (!jsonFormsSchema || !jsonFormsSchema["type-schema"]) {
    // User needs to go to the note "{type}" to create fields for this.
    return {};
  }

  const formSchemaStr = jsonFormsSchema["type-schema"];
  const formUiSchemaStr = jsonFormsSchema["type-ui-schema"];
  /* Load & Parse data.
      TODO Temporary code. We will eventually convert to all strings in database & delete most of the following.
      Check whether the code is object or String.
      Parse the object into a string if it's not there already.
      We'll only use schemaStr below.
  */
  let jsonSchema = undefined;
  if (formSchemaStr && typeof(formSchemaStr)=="string") {
      // New version!
      jsonSchema = JSON.parse(formSchemaStr);
  } else if (formSchemaStr && typeof(formSchemaStr)=="object") {
      // OLD code -- this branch will be deleted.
      jsonSchema = formSchemaStr;
  }
  let formUiSchemaObj = undefined; // Should be autopopulatable as a vertical layout?
  if (formUiSchemaStr && typeof(formUiSchemaStr)=="string") {
      // New version!
      if (formUiSchemaStr.length>3)
          // Only parse if there's something, else leave as undefined so it can autopopulate.
          formUiSchemaObj=JSON.parse(formUiSchemaStr);
  } else if (formUiSchemaStr && typeof(formUiSchemaStr)=="object") {
      // OLD code -- this branch will be deleted.
      formUiSchemaObj = formUiSchemaStr;
  }

  return {jsonSchema,formUiSchemaObj};
}


export async function getJSONFormsSchemaForType(firestore:Firestore, type:string) {
  const note = await getNoteOfTypeNamed(firestore,"Type",type);
  if (!note) {
    return {};
  }
  const noteId = note.id;

  const jsonFormsSchema = await getJSONFormsSchema(firestore, noteId);
  const {jsonSchema,formUiSchemaObj} = getSchemaAndUIFromSchemaObject(jsonFormsSchema);
  
  return {jsonSchema,formUiSchemaObj};
}

/****************
 * The difference between the "use..." and the "get..." is that get is async, use is a hook which must return instantly even if the data has not yet been loaded.
 * 
 */
export function useJSONFormsSchemaComponentsForType(type?:string) {
  // TODO Currently this has to load all notes because the match func below requires all types to be loaded... There's got to be a better way.
  const typeNotes = useAllTypeNotes();
  const typeNames = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
  const typeNote = (typeNames && type)?typeNotes[typeNames.indexOf(type)]:undefined;
  const jsonFormsSchema = useJSONFormsSchema(typeNote && typeNote.id);
  // console.log("useJSONFormsSchemaComponentsForType: ",type,jsonFormsSchema)

  const {jsonSchema,formUiSchemaObj} = getSchemaAndUIFromSchemaObject(jsonFormsSchema);

  return {
    jsonSchema,
    formUiSchemaObj,
    jsonSchemaNote: typeNote,
    findMatchFunc_isNoteOfType:getTypeNotesFindMatchFunc(typeNames),
    types: typeNames
  };
}

export type TypeNotesFindMatchFuncType = (propertyName:string, path:string|null)=>boolean;

export function getTypeNotesFindMatchFunc(types:string[]) {
  function findMatchFunc_isNoteOfType(propertyName:string, path:string|null=null):boolean {
    return types?.includes(propertyName);
  }
  return findMatchFunc_isNoteOfType;
}

export function useTypeNotesFindMatchFunc():TypeNotesFindMatchFuncType {
  const typeNotes = useAllTypeNotes();
  const types = typeNotes && typeNotes.map(function(note:Note){return note.doc_name});
  return getTypeNotesFindMatchFunc(types);
}