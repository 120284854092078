import { Alert, Button } from "antd";
import { useMemo, useState } from "react";
import { SaveOutlined } from '@ant-design/icons';
import { JTDDataType } from "ajv/dist/core";
import Ajv from "ajv";
import SvelteJSONEditor from "./SvelteJSONEditor";
import { createAjvValidator } from 'vanilla-jsoneditor';
import ValidationError from "ajv/dist/runtime/validation_error";


export function RawJSONEditor({jsonStr,
        isSchema=false, // TODO implement
        saveFunc=null,schema=null,showSaveButton=true, saveButtonText="Save Changes",saveButtonIsPrimary=false}:
    {jsonStr:string,isSchema?:boolean,
        saveFunc?: null | ((jsonObject: object) => void),
        schema?:object | null,showSaveButton:boolean, saveButtonText?:string,saveButtonIsPrimary?:boolean}) {

    const [tempJsonStr,setTempJsonStr] = useState(jsonStr);
    const [errorStr, setErrorStr] = useState<string | null>(null);
    const [warningStr, setWarningStr] = useState<string|null>(null);


    // Validate the initial one
    useMemo(()=>{
        function validateSchema(schemaToValidate:object,objectToValidate:object) {
            const ajv = new Ajv({"strict":true,strictSchema:false,strictTypes:false,verbose:true});
            type MyData = JTDDataType<typeof schema>;
            // Validate it as a schema.
            try {
                const validate = ajv.compile<MyData>({...schemaToValidate,"$schema":undefined});
                if (isSchema) {
                    // No object to validate.
                    setErrorStr(null);
                } else if (validate(objectToValidate)) {
                    // data is MyData here
                    // console.log(validData.foo);
                } else {
                    console.log(validate.errors)
                    // alert("Parse error. Does not match schema -- not saved. Errors: "+validate.errors);
                    // Don't save:
                    setTempJsonStr(tempJsonStr);
                    if (validate && validate.errors) {
                        setErrorStr(validate.errors.map(function(errObj){return errObj.message+"\n"}).join());
                        // setErrorStr(validate.errors.toString());
                    } else {
                        // TODO put some error in
                        setErrorStr("Error in parse, no messages available, sorry.");
                    }
                    return false;
                }
                return true;
            } catch (error:any) {
                // Probably a JSON parse error.
                // Reject, let the user know...
                setErrorStr("Not valid schema: "+error.toString());
            }
        }

        try {
            if (isSchema)
                validateSchema(JSON.parse(tempJsonStr),{});
            else if (schema)
                validateSchema(schema,JSON.parse(tempJsonStr));
        } catch (error:any) {
            // Won't parse, it'll get caught as invalid below too.
            setErrorStr("Not valid JSON: "+error.toString());
        }
    },[isSchema,tempJsonStr,schema]);

    function setNewTempValueWithValidation(newString:string) {
        // console.log("Changed the object schema from JSON:\n"+newString);
        if (tempJsonStr===newString)
            return;
        try {
            const parsedNew = JSON.parse(newString);
            // TODO more validation!

            // if (isSchema) {
            //     if (!validateSchema(parsedNew,{}))
            //         return; // don't save
            //     else
            //         setTempJsonStr(newString);
            // }
            // if (schema) {
            //     if (!validateSchema(schema,parsedNew))
            //         return; // don't save
            //     else
            //         setTempJsonStr(newString);
            // }
            setErrorStr(null);
            if (showSaveButton) {
                //@ts-ignore
                setWarningStr("Unsaved changes");
                setTempJsonStr(newString);
            } else if (saveFunc) {
                saveFunc(parsedNew);
                setWarningStr(null);
            }
            else
                setTempJsonStr(newString);
        } catch (error) {
            // Probably a JSON parse error.
            // Reject, let the user know...
            //@ts-ignore
            setErrorStr("Not valid JSON: "+error.toString());
            setTempJsonStr(newString);
        }
    }
    return <>
        {/* Note -- if you upgrade to react-scripts 5, which includes webpack 5, this won't work (at this time) Seems to be a common issue but the easy solutions don't seem to solve it
        https://github.com/microsoft/PowerBI-visuals-tools/issues/365
        https://github.com/SheetJS/sheetjs/issues/2527 */}
        {/* @ts-ignore */}
        <div className={(errorStr || warningStr)?"my-svelte-editor-in-raw-json-tab-with-message":"my-svelte-editor-in-raw-json-tab-no-message"}>
        <SvelteJSONEditor
            content={{text:tempJsonStr}}
            onChange={function(obj:any){
                if (obj.json)
                    setNewTempValueWithValidation(JSON.stringify(obj.json))
                else if (obj.text)
                    setNewTempValueWithValidation(obj.text);
            }}
            validator={function (json: any): ValidationError[]{
                if (!schema)
                    return [];
                //@ts-ignore
                const validator = createAjvValidator({schema:schema});
                //@ts-ignore
                return validator(JSON.parse(tempJsonStr));
            }}
        />
        {/* @ts-ignore */}
        </div>

        {errorStr && <Alert
            message="Error"
            description={errorStr}
            type="error"
            showIcon
            closable
            />}
        {!errorStr && warningStr && <Alert
            message="Warning"
            description={warningStr}
            type="warning"
            showIcon
            closable
            />}
            {/* @ts-ignore */}
        {showSaveButton && <Button type={saveButtonIsPrimary?"primary":"default"} icon={<SaveOutlined />} disabled={errorStr!==null} onClick={function(){
            const parsedNew = JSON.parse(tempJsonStr);
            // console.log("Saving "+tempJsonStr)
            if (saveFunc) {
                saveFunc(parsedNew);
                setWarningStr(null);
            }
        }}>{saveButtonText}</Button>}
        {/* @ts-ignore */}
        {/* We don't need the raw text anymore. Keeping it around for a bit in case something changes, all functionality is still enabled above. */}
        {/* <TextArea rows={10} value={tempJsonStr} onChange={function(e:any) {
            const newString = e.target.value;
            setNewTempValueWithValidation(newString);}} /> */}
        {!isSchema && schema &&
            <>
            {/* @ts-ignore */}
                <a target="_blank" rel="noreferrer" href={"https://jsoneditoronline.org/#left=json."+
                        encodeURIComponent(JSON.stringify(schema))+"&right=json."+encodeURIComponent(tempJsonStr)}
                >Edit with schema on jsoneditoronline.org
                {/* @ts-ignore */}
                </a>
                {/* <br/>💡 Tip: To show errors in the object: In right panel 🠮 Gear 🠮 JSON Schema 🠮 Panel 🠮 OK */}
            </>}
        {!isSchema && !schema &&
        // @ts-ignore
            <a target="_blank" rel="noreferrer" href={"https://jsoneditoronline.org/#left=json."+
                encodeURIComponent(tempJsonStr)}
            >Edit on jsoneditoronline.org
            {/* @ts-ignore */}
            </a>}
        
    </>
}