import { ChatCompletionMessageParam } from "openai/resources";
import { ChatCompletionCreateParams, ChatCompletionMessageToolCall, ChatCompletionTool, ChatCompletionToolChoiceOption } from "openai/resources/chat/completions";

export type StreamingCallback = (fullOutput: string, newOutput: string, isDone: boolean) => void;
export type ChatStreamErrorCallback = (error: string) => void;
export type ChatAPIOptions = /*ChatCompletionCreateParamsBase &*/ {
    // from the ChatCompletionCreateParamsBase:
    temperature?: number;
    max_tokens?: number;
    tool_choice?: ChatCompletionToolChoiceOption;
    tools?: Array<ChatCompletionTool>;
    response_format?: ChatCompletionCreateParams.ResponseFormat,

    // Just for us:
    abortController?: AbortController;
};
export const DEFAULT_CHAT_API_OPTIONS: ChatAPIOptions = {
    // Defaults:
    temperature: 0.3,
    response_format: {type: "text"} as ChatCompletionCreateParams.ResponseFormat,
    max_tokens: -1,
};

export type ChatAPICallReturnType = {success:boolean, message?:string, error?:string};

export type ChatAPICall = (messages: ChatCompletionMessageParam[], streamingCallback?: StreamingCallback, errorCallback?: ChatStreamErrorCallback, options?: ChatAPIOptions, callFunction?:(functionSpec:ChatCompletionMessageToolCall.Function[])=>void) => Promise<ChatAPICallReturnType>;


export const LM_SERVER_LOCAL = "Local LM";
export const LM_SERVER_OPENAI_BEST = "GPT-4o";
export const LM_SERVER_OPENAI_LIGHT = "GPT-3.5";
export const LM_SERVER_GEMINI = "Gemini";
export const LM_SERVER_GROQ = "Groq";

export const LM_SERVER_OPENAI_DIRECT = "GPT-4o Direct";

export const FirebaseOpenAIChatServer = "FirebaseOpenAIChatServer";
export const FirebaseGroqChatServer="FirebaseGroqChatServer";

export type ServerEndpointString = typeof FirebaseOpenAIChatServer | "FirebaseVertexAIChatServer" | "LMStudioChatServer" | "FirebaseGroqChatServer" | typeof LM_SERVER_OPENAI_DIRECT;

export type LMServerType = {
    name: string;
    contextLength: number; // In Tokens
    isLocal: boolean;
    serverType: ServerEndpointString;
    defaultTemperature: number;
    supportsStreaming: boolean; // to support streaming, it can't use Firebase Functions, which have a single batch output.
    supportsFunctions: boolean;
    // modelTypeForTokenization?: TiktokenModel; // Best model type for tokenization. Currently only supporting OpenAI tokenization counts in LLMTokenCounter
}

export const LM_SERVERS_OBJ = [
    {name: LM_SERVER_LOCAL,
        contextLength: 32768,
        isLocal: true,
        serverType: "LMStudioChatServer",
        defaultTemperature: 0.3,
        supportsStreaming: true,
        supportsFunctions: false,
        /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/},
    {name: LM_SERVER_OPENAI_BEST,
        contextLength: 131072/*128k*/,
        isLocal: false,
        serverType: "FirebaseOpenAIChatServer",
        defaultTemperature: 0.35, // Hypothesis: Because GPT-4 is a bit smarter than the others, a higher temperature has more variety without as much downside. But at 0.4 I do see some hallucinations.
        supportsStreaming: false,
        supportsFunctions: true,
        /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/},
    {name: LM_SERVER_OPENAI_LIGHT,
        contextLength: 16384/*16k*/,
        isLocal: false,
        serverType: "FirebaseOpenAIChatServer",
        defaultTemperature: 0.3,
        supportsStreaming: false,
        supportsFunctions: true,
        /*modelTypeForTokenization: "gpt-3.5-turbo-0125" as TiktokenModel*/},
    {name: LM_SERVER_GEMINI,
        contextLength: 32768,
        isLocal: false,
        defaultTemperature: 0.3,
        supportsStreaming: false,
        supportsFunctions: false,
        serverType: "FirebaseVertexAIChatServer",
    },
    {name: LM_SERVER_GROQ,
        contextLength: 32768,
        isLocal: false,
        defaultTemperature: 0.3,
        supportsStreaming: false,
        supportsFunctions: true,
        serverType: "FirebaseGroqChatServer",
    },
    {name: LM_SERVER_OPENAI_DIRECT,
        contextLength: 131072/*128k*/,
        isLocal: false,
        serverType: LM_SERVER_OPENAI_DIRECT,
        defaultTemperature: 0.35,
        supportsStreaming: true,
        supportsFunctions: true,
        /*modelTypeForTokenization: "gpt-4-0125-preview" as TiktokenModel*/
    }
] as LMServerType[];


export function cleanChatMessagesBeforeSendToServer(messages:ChatCompletionMessageParam[]) {
    return messages.map((message:ChatCompletionMessageParam)=>{
        const newMessage = {role: message.role, content: message.content};
        return newMessage;
    });
}