import { isEqual } from "lodash";
import { Dice, DiceSchema, addDice, getDiceStr, parseDice } from "./DiceNotation";
import { JSONSchemaType } from "ajv";
import { markdownToHTML } from "../../../ServerConnection/LLMServer/MarkdownConverter";

export type CardActionType = "attack" | "enhance" | "skill";
export type Discipline = "Archery" | "Construct" | "Rest" | "Fire Wizardry" | "Ice Wizardry" | "Fighting" | "Skill" | "Athletic" | "Telepath" | "Barbaric" | "Destruct" | "Knives" | "Healing" | "Item"
// Special disciplines limited to specific creature -- these are adjusted for a particular type of attack
    | "Dragonborn" | "Brain In A Jar";
// Range is 0 to affect only yourself. 1 for adjacent, 2 for 2 spaces away (one space between player & target), etc.
export type PositiveNumberTo20 = 1|2|3|4|5|6|7|8|9|10|11|12|13|14|15|16|17|18|19|20;

export type Range = 0|PositiveNumberTo20;
export type XPAtLeast1 = PositiveNumberTo20;
export type XPAtLeast0 = 0|XPAtLeast1;

export type ActionBasics = {
    name: string;
    moveSpaces?: number;
    maxRange?: Range;
    dicePerAffectedSpace?: Dice;
    goldCost: number;
};

export type ReusableCardEnhancement = ActionBasics & {
    applicableToDisciplines?: Discipline[];
    minLevel: XPAtLeast1;
};
export const ReusableCardEnhancementSchema:JSONSchemaType<ReusableCardEnhancement> = {
    type: "object",
    properties: {
        name: {type: "string"},
        moveSpaces: {type: "number", nullable: true},
        maxRange: {type: "number", nullable: true},
        dicePerAffectedSpace: {...DiceSchema, nullable: true},
        goldCost: {type: "number"},
        applicableToDisciplines: {type: "array", items: {type: "string"}, nullable: true},
        minLevel: {type: "number"},
    },
    required: ["name","goldCost","minLevel"],
};

export type ActionCard = ActionBasics & {
    actionType: CardActionType;
    image?: string;
    commandAreaString?: string;
    commandString: string;
    // Power of the card
    minRange: Range;
    spacesAffected: Range;
};

/*********
 * Reusable cards require using XP to gain, and they get permanantly added to the player's hand. They have a discipline to distinguish them.
 */
export type ReusableCard = ActionCard & {
    discipline: Discipline;
    xpCost: XPAtLeast0;
    moveSpaces: Range;
    enhancements?: ReusableCardEnhancement[];
};


/**********
 * Discardable cards get used just once, then discarded.
 */
export type DiscardableCard = ActionCard & {
    minLevel: XPAtLeast1;
};

export type DefenseCard = {
    name: string;
    applicableToDisciplines?: Discipline[];
    minLevel: XPAtLeast1;
    xpCost: XPAtLeast0;
    goldCost: number;
    commandString: string;
};

export function getCardMove(card:ReusableCard,bonus?:number):number {
    return card.moveSpaces + (bonus?bonus:0) + (card.enhancements?card.enhancements.reduce(
        function(acc,enhancement){
            if (!enhancement.moveSpaces)
                return acc;
            return acc+enhancement.moveSpaces;
        },0):0);
}

export function getMaxRange(card:ReusableCard,bonus?:number):Range {
    return (card.maxRange?card.maxRange:0) + (bonus?bonus:0) + (card.enhancements?card.enhancements.reduce(
        function(acc,enhancement){
            if (!enhancement.maxRange)
                return acc;
            return acc+enhancement.maxRange;
        },0):0) as Range;
}
export function getMinRange(card:ReusableCard):Range {
    return card.minRange;
}

export function getRangeStr(card:ReusableCard,bonus?:number):string {
    const minRange = getMinRange(card);
    const maxRange = getMaxRange(card)+(bonus?bonus:0);
    if (minRange===maxRange)
        return minRange+"";//+" spaces";
    return minRange+"-"+maxRange;//+" spaces";
}
export function getBonusRangeStr(card:ReusableCard,bonus?:number):string {
    const maxRange = getMaxRange(card)+(bonus?bonus:0);
    return maxRange+"";//+" spaces";
}


export function getCardDice(card:ReusableCard):Dice|undefined {
    let dice = card.dicePerAffectedSpace;
    for (const enhancement of card.enhancements||[]) {
        if (enhancement.dicePerAffectedSpace) {
            if (!dice)
                dice = enhancement.dicePerAffectedSpace;
            else
                dice = addDice(dice,enhancement.dicePerAffectedSpace);
        }
    }
    return dice as Dice;
}
export function getCardDiceStr(card:ReusableCard,bonus?:string):string {
    let dice = getCardDice(card);
    if (!dice)
        return "[ERROR! No dice in this card.]";
    if (bonus) {
        const bonusDice = parseDice(bonus);
        dice = addDice(dice,bonusDice);
    }

    return getDiceStr(dice);
}

export function getEnhancementCostStr(enhancement:ReusableCardEnhancement):string {
    return getCostStrRaw(0,enhancement.goldCost);
}

export function getCardBaseXPCost(gameCard:ReusableCard):number {
    return gameCard.xpCost;// + (gameCard.enhancements?gameCard.enhancements.reduce((acc,enhancement)=>acc+(enhancement.xpCost||0),0):0);
}
export function getCardGoldCost(gameCard:ReusableCard):number {
    return gameCard.goldCost + (gameCard.enhancements?gameCard.enhancements.reduce((acc,enhancement)=>acc+(enhancement.goldCost||0),0):0);
}

function getCostStrRaw(xpCost:number,goldCost:number) {
    let costStr = "";
    if (goldCost>0 && xpCost===0) {
        // Special case: gold only card.
        costStr += goldCost+" Gold";
    } else {
        // Always print XP if it's a 0 XP card.
        // We might change this later if there's other card types. This prints 0 XP for "breather".
        if (xpCost>-1)
            costStr += xpCost+" XP";
        if (goldCost>0)
            costStr += (costStr.length>0?" + ":"")+goldCost+" Gold";
    }
    // if (costStr.length===0)
        // costStr = "Free";
    return costStr;
}

export function getReusableCardCostStr(gameCard:ReusableCard) {
    const xpCost = getCardBaseXPCost(gameCard);
    const goldCost = getCardGoldCost(gameCard);

    return getCostStrRaw(xpCost,goldCost);
}
export function getDefenseCardCostStr(defenseCard:DefenseCard) {
    return getCostStrRaw(defenseCard.xpCost,defenseCard.goldCost);
}
export function getDiscardCardCostStr(discardCard:DiscardableCard) {
    return getCostStrRaw(0,discardCard.goldCost);
}

export function getReusableCardUpgradeCostStr(tradeInCard:ReusableCard,upgradeCard:ReusableCard) {
    const xpCost = getCardBaseXPCost(upgradeCard) - getCardBaseXPCost(tradeInCard);
    const goldCost = getCardGoldCost(upgradeCard) - getCardGoldCost(tradeInCard);
    return getCostStrRaw(xpCost,goldCost);
}
export function getDefenseCardUpgradeCostStr(tradeInCard:DefenseCard,upgradeCard:DefenseCard) {
    const xpCost = upgradeCard.xpCost - tradeInCard.xpCost;
    const goldCost = upgradeCard.goldCost - tradeInCard.goldCost;
    return getCostStrRaw(xpCost,goldCost);
}


export function getCardEnhancementsText(card:ReusableCard):string {
    if (!card.enhancements || card.enhancements.length===0)
        return "";
    let text = "Includes ";
    let bonuses = "";
    let rangeBoost = 0;
    let diceBoost = null as null | Dice;
    // let dmgBoost = "";
    let moveBoost = 0;
    // const enhancementNames = card.enhancements.map(enhancement=>enhancement.name).join(", ");
    const usedEnhancements = [] as ReusableCardEnhancement[];
    for (const enhancement of card.enhancements) {
        let usedEnhancement = false;
        if (enhancement.moveSpaces) {
            moveBoost += enhancement.moveSpaces;
            usedEnhancement = true;
        }
        if (enhancement.maxRange) {
            rangeBoost += enhancement.maxRange;
            usedEnhancement = true;
        }
        if (enhancement.dicePerAffectedSpace) {
            if (diceBoost===null)
                diceBoost = enhancement.dicePerAffectedSpace;
            else
                diceBoost = addDice(diceBoost,enhancement.dicePerAffectedSpace);
            usedEnhancement = true;
        }
        if (usedEnhancement)
            usedEnhancements.push(enhancement);
    }
    if (moveBoost>0)
        bonuses += " move +"+ moveBoost;
    if (rangeBoost>0)
        bonuses = (bonuses.length>0?bonuses+", ":"")+" range +"+ rangeBoost;
    if (diceBoost)
        bonuses = (bonuses.length>0?bonuses+", ":"")+" dmg +"+getDiceStr(diceBoost);
    text += bonuses;
    if (usedEnhancements.length<4) {
        text += " from "+usedEnhancements.map(enhancement=>enhancement.name).join(", ");
    } else {
        text += " from "+card.enhancements.length+" enhancements";
    }
    return text;
}


export function AreaEffectOfCommandAreaString({commandAreaString}:{commandAreaString:string}):JSX.Element {
    const STRINGS_OUTSIDE_CELLS=["TO","OR"];
    const STRINGS_TARGET=["❌","🎯","⚡","⚡⚡","X","🔥"];
    // Render the areaString
    let rows = commandAreaString.split("\n");
    rows = rows.map(row=>row.trim());
    rows = rows.filter(row=>row.length>0);
    let size = "";
    if (rows.length===1)
        size=" larger";
    else if (rows.length>4)
        size=" muchSmaller";
    else if (rows.length>=3)
        size=" smaller";

    let rowsReact = rows.map(function(row:string, rowIndex:number) {
        let cells = row.split(" ");
        let cellsReact = cells.map(function(cell:string, cellIndex:number) {
            if (cell==="⬜")
                return <div className={"gameActionAreaCell empty"+size} key={rowIndex+","+cellIndex}></div>;
            else if (STRINGS_TARGET.includes(cell))
                return <div className={"gameActionAreaCell target"+size} key={rowIndex+","+cellIndex}>{cell}</div>;
            else if (cell.startsWith("OR") && STRINGS_TARGET.some(target => cell.endsWith(target)))
                return <div className={"gameActionAreaCell target"+size} key={rowIndex+","+cellIndex}>OR<br/>{cell.substring(2)}</div>;
            else if (STRINGS_TARGET.some(target => cell.startsWith(target))) {
                const target = STRINGS_TARGET.find(target => cell.startsWith(target)) as string;
                return <div className={"gameActionAreaCell you"+size} key={rowIndex+","+cellIndex}>{target}<br/>{cell.substring(target.length)}</div>;
            }
            else if (cell==="You")
                return <div className={"gameActionAreaCell you"+size} key={rowIndex+","+cellIndex}>You</div>;
            else if (cell.startsWith("You("))
                return <div className={"gameActionAreaCell you"+size} key={rowIndex+","+cellIndex}>You<br/>{"("+cell.substring(4)}</div>;
            else if (STRINGS_OUTSIDE_CELLS.includes(cell))
                return <div key={rowIndex+","+cellIndex}><b>&nbsp;&nbsp;{cell}</b></div>;
            else
                return <div className={"gameActionAreaCell"+size} key={rowIndex+","+cellIndex}>{cell}</div>;
        });
        return <div className={"gameActionArea"+size} key={rowIndex}>{cellsReact}</div>;
    });
    return <>{rowsReact}</>;
}

/*********
 * Uses space as the delimiter between cells, and newline as the delimiter between rows.
 * That means your areas cannot include spaces or newlines.
 */
export function AreaEffectOnActionCard({card}:{card:ActionCard}):JSX.Element {
    if (!card.commandAreaString)
        throw "Do not call AreaEffectOnCard if there is no commandAreaString.";
    return <AreaEffectOfCommandAreaString commandAreaString={card.commandAreaString}/>;
}

export function AreaEffectOnCard({card}:{card:ReusableCard}):JSX.Element {
    if (!card.commandAreaString)
        throw "Do not call AreaEffectOnCard if there is no commandAreaString.";

    let areaString = card.commandAreaString;
    if (card.enhancements)
        for (const enhancement of card.enhancements) {
            // TODO adjust the area string based on the enhancement
            if (enhancement.maxRange) {
                // Add to the range
                let rows = areaString.split("\n");
                rows = rows.map(row=>row.trim());
                rows = rows.filter(row=>row.length>0);
                let newRows = [] as string[];
                for (let row of rows) {
                    let cells = row.split(" ");
                    let newCells = [] as string[];
                    for (let cell of cells) {
                        if (cell==="⬜" || cell==="You")
                            newCells.push(cell);
                        else if (cell==="TO")
                            newCells.push(cell);
                        else {
                            for (let i=0;i<enhancement.maxRange;i++)
                                newCells.push("⬜");
                            newCells.push(cell);
                        }
                    }
                    newRows.push(newCells.join(" "));
                }
                areaString = newRows.join("\n");
            }
        }

    return <AreaEffectOfCommandAreaString commandAreaString={areaString}/>;
}

export function getCardsAreSame(card1:ReusableCard, card2:ReusableCard):boolean {
    /* don't need to check:
        - image
    */
    return card1.name===card2.name &&
        card1.discipline===card2.discipline &&
        card1.actionType===card2.actionType &&
        card1.xpCost===card2.xpCost &&
        card1.goldCost===card2.goldCost &&
        card1.commandAreaString===card2.commandAreaString &&
        card1.moveSpaces===card2.moveSpaces &&
        card1.spacesAffected===card2.spacesAffected &&
        card1.maxRange===card2.maxRange &&
        isEqual(card1.dicePerAffectedSpace,card2.dicePerAffectedSpace) && 
        isEqual(card1.enhancements,card2.enhancements) &&
        card1.commandString===card2.commandString;        
}

/******
 * 
 */
export function fillInReusableCardCommandString(card:ReusableCard):string {
    if (!card.commandString)
        throw "Don't call fillInCardCommandString when there's no command string.";
    let commandString2 = card.commandString.
        replaceAll("%xp%",getCardBaseXPCost(card)+"").

        replaceAll("%move%",getCardMove(card)+"").
        replaceAll("%move+1%",getCardMove(card,1)+"").
        replaceAll("%move+2%",getCardMove(card,2)+"").
        replaceAll("%move+3%",getCardMove(card,3)+"").
        replaceAll("%move+4%",getCardMove(card,4)+"").
        replaceAll("%move+5%",getCardMove(card,5)+"").
        replaceAll("%move+6%",getCardMove(card,6)+"").
        replaceAll("%move+7%",getCardMove(card,7)+"").

        replaceAll("%range%",getRangeStr(card)).
        // The following are only used in the extra energy situations, so it's usually phrased as "but 6 away"
        replaceAll("%range+1%",getBonusRangeStr(card,1)).
        replaceAll("%range+2%",getBonusRangeStr(card,2)).
        replaceAll("%range+3%",getBonusRangeStr(card,3)).
        replaceAll("%range+4%",getBonusRangeStr(card,4)).
        replaceAll("%range+5%",getBonusRangeStr(card,5)).
        replaceAll("%range+6%",getBonusRangeStr(card,6)).
        replaceAll("%range+7%",getBonusRangeStr(card,7)).

        replaceAll("%spacesaffected%",card.spacesAffected+"").

        replaceAll("%dice%",getCardDiceStr(card));

    // Handle "%dice+SOMETHING%" e.g. "%dice+1d4+1%" etc for any value of that something (it can't have a % in it)
    const dicePlusRegex = /%dice\+([^\%]+)%/g;
    let match = dicePlusRegex.exec(commandString2);
    while (match) {
        const bonus = match[1];
        const bonusStr = getCardDiceStr(card,bonus);
        commandString2 = commandString2.replace(match[0],bonusStr);
        match = dicePlusRegex.exec(commandString2);
    }

    // Count the # of %'s remaining. If there are any, there's probably an error, so we'll warn:
    const numPercentSigns = commandString2.split("%").length-1;
    if (numPercentSigns>0) {
        console.warn("There are "+numPercentSigns+" % signs remaining in the card command string. Did you forget to replace something in "+getCardDistinctName(card),card,commandString2);
    }

    return commandString2;
}
export function fillInDefenseCardCommandString(card:DefenseCard):string {
    return card.commandString.
        replaceAll("%xp%",card.xpCost+"");
}
export function getDefenseCardCommandStringReact(card:DefenseCard):JSX.Element {
    const filledIn = fillInDefenseCardCommandString(card);
    const html = markdownToHTML(filledIn);
    return <div dangerouslySetInnerHTML={{__html: html }}/>;
}

export function getCardDistinctName(card:ReusableCard):string {
    return card.discipline+" "+card.name+" ("+card.xpCost+" XP)";
}

export function getReusableCardCommandStringReactFromFilledIn(markdownCommandString:string, compressedLineBreaks:boolean) {
    let html = markdownToHTML(markdownCommandString);
    if (compressedLineBreaks)
        html = html.replaceAll("<p>","<p style='margin-block-start: 0;margin-block-end: 0;'>");
    return <div dangerouslySetInnerHTML={{__html: html }}/>;
}

export function getReusableCardCommandStringAsReact(card:ReusableCard, compressedLineBreaks:boolean=false):JSX.Element {
    let markdownCommandString = fillInReusableCardCommandString(card);
    if (compressedLineBreaks)
        markdownCommandString.replaceAll("\n\n","\n").trim();
    return getReusableCardCommandStringReactFromFilledIn(markdownCommandString,compressedLineBreaks);
}

// function testAreaStringToReact() {
//     // Arrow xp 3 example:
//     getCardAreaStringToReact(
//         `You ⬜ ❌
//         TO
//         You ⬜ ⬜ ⬜ ❌`);
//     // Wizardry xp 1 example:
//     getCardAreaStringToReact(
//         `⬜ ⬜ ❌
//         You ⬜ ❌
//         ⬜ ⬜ ❌`);
// }
// if (GLOBAL_DEBUG_INLINE_TESTS)
//     testAreaStringToReact();