import { Alert, Button } from "antd";
import { useEffect, useState } from "react";
import { InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
// import JsonSchemaEditor from "@optum/json-schema-editor";

export default function JSSchemaEditor({formSchemaObj,saveFunc=null}:{formSchemaObj:object,saveFunc?: null | ((jsonObject: object) => void)}) {
    const [errorStr, setErrorStr] = useState("");
    const [formSchemaStr, setFormSchemaStr] = useState(JSON.stringify(formSchemaObj));
    const [childKey, setChildKey] = useState(1);
    const [canSave, setCanSave] = useState(false);

    // https://optum.github.io/jsonschema-editor-react/?path=/story/example-schemaeditor--new-json-schema
    // https://github.com/Optum/jsonschema-editor-react
    // https://stackoverflow.com/questions/52260258/reactjs-destroy-old-component-instance-and-create-new
    // Each render, we change the key. Because the optum schema editor does not have a controlled state -- "data" is only initial data(!).
    useEffect(() => {
       setChildKey(prev => prev + 1);
    },[formSchemaObj]);

    return <>
    {!saveFunc && canSave && <Alert
            message="Warning"
            description="Save is not yet implemented."
            type="warning"
            showIcon
            closable
            />}
    {errorStr && <Alert
        message="Error"
        description={errorStr}
        type="error"
        showIcon
        closable
        />}
    <Button icon={<InfoCircleOutlined />}  href="https://docs.google.com/document/d/1KY9unv4DrlN4G3dKAPmtDohSwKU5HvAq8CZIHE8DrYw/preview"  target="_blank" rel="noopener noreferrer">Help</Button>
    {saveFunc && <Button icon={<SaveOutlined />} disabled={!canSave} onClick={function(){
        // Assume parseable because canSave=true
        saveFunc(JSON.parse(formSchemaStr));
        }}>Save Changes</Button>}
    {/* This might be fixable now that we have our key setting, but we're not willing to risk it on auto-saves quite yet. We probably want some sort of on-firestore backup system first. */}
    {/* <JsonSchemaEditor key={childKey} data={_.cloneDeep(formSchemaObj)} onSchemaChange={(schemaStr:string)=>{
        try {
            const schemaObj = JSON.parse(schemaStr);
            setCanSave(!_.isEqual(schemaObj,formSchemaObj));
            // console.log("💾 Saving in doc "+doc_name+"    schema title="+schemaObj.title);
            setFormSchemaStr(schemaStr);
            setErrorStr("");
        } catch (error) {
            setErrorStr("This JSON does not parse.");
        }
    }} /> */}
    {/* <Alert
            message="Warning"
            description="The following text box is not being update from above."
            type="warning"
            showIcon
            closable
            />
    <RawJSONEditor jsonStr={formSchemaStr} isSchema={true} showSaveButton={false} /> */}
    </>
}