// import "./styles.css";

import { Gitgraph, Orientation, templateExtend } from "@gitgraph/react";
import React, { useState } from "react";
// import { isCommaListExpression } from "typescript";


export default function TestGitGraph() {
  const [printCharacterNames/*, setPrintCharacterNames*/] = useState(false);

  function onSelected(subjectId:String) {
    console.log("Selected "+subjectId);
  }

  function onDotClickHandler(commit: any) {
    onSelected(commit.subject);
    // console.log("Clicked on v4: "+commit.subject);
    // console.log(commit);
  }

  var dynamicRenderMessage = function (passage: any) {
    return function /*renderMessage*/(commit: any) {
      let printText = "";
      if (printCharacterNames)
        for (const c of passage.characters) {
          printText += "[" + c + "] ";
        }
      printText += passage.summary;

      // We can put regular React HTML in here -- we will just need to get the styling right.
      // return (<foreignObject width={300} x={8}>
      //   <div>
      //       {passage.summary}
      //   </div>
      // </foreignObject>);

      return React.createElement(
        "text",
        {
          y: commit.style.dot.size,
          alignmentBaseline: "central",
          fill: commit.style.dot.color,
          cursor: "pointer",
          onClick: function () {
            onSelected(passage.id);
            // console.log("clicked text for element: " + commit.subject);
            // console.log("Characters:");
            // console.log(passage.characters);
          }
        },
        printText
      );
    };
  };

  return (
    <div className="git-graph">
      <br/>
      <Gitgraph
        options={{
          author: "Author",
          orientation: Orientation.VerticalReverse,
          //@ts-ignore
          template: templateExtend("metro", {
            colors: [
              // "#E44900",
              "#383F8F",
              "#7AB439",
              "#F739D8",
              "#21988C",
              "#8D64FF",
              "#21C5D9",
              "#FFD30D",
              "#F17F21",
              "#000000",
              "#B5535C",
              "#F939D8",
              "#F139D1"
            ],
            commit: {
              message: {
                font: "13px Roboto",
                color: "black",
                displayAuthor: false,
                displayHash: false
                // display: false
              },
              dot: {
                size: 4
              },
              spacing: /*spacingY*/20,
              widthExtension: 50
            },
            branch: {
              lineWidth: 2,
              spacing: /*spacingX*/10,
              label: {
                display: false
              },
              widthExtension: 10
            }
          }),
          // mode: Mode.Compact
        }
      }
      >
        {(gitgraph) => {
          const main = gitgraph.branch("main");
          const release = gitgraph.branch("release/v2.0.0");
          const feature = gitgraph.branch("feat/react-native/v0.65.1");


          const passage1 = {
            id: "p1_id",
            summary: "You met Olivia & Brandon on the beach.",
            characters: ["Olivia", "Brandon"]
          };
          const passage2 = {
            id: "p2_id",
            summary: "You started travelling with Olivia & Brandon.",
            characters: ["Olivia", "Brandon"]
          };
          //@ts-ignore
          main
            .commit({
              subject: passage1.id,
              onClick: onDotClickHandler,
              renderMessage: dynamicRenderMessage(passage1)
            })
            .commit({
              subject: passage2.id,
              onClick: onDotClickHandler,
              renderMessage: dynamicRenderMessage(passage2)
            });

          release
            .merge(main, "start feat. sync release w/ main")
            .commit("feat: card view")
            .commit("fix: home refactor");

          feature
            .merge(main, "start feat. sync feature w/ main2")
            .commit("chore: upgrade rn 0.61.5 to 0.65.1")
            .commit("fix: solve jest unit test");

          feature.merge(release, "sync w/ feature");
          main.merge(release, "Physical Card");

          // feature.merge(main, "sync feature w/ main");
          // main.merge(feature, "React-native upgrade");
        }}
      </Gitgraph>
    </div>
  );
}
