import { INCLUSION_TYPE_EDIT_CONTEXT, INCLUSION_TYPE_LINK, INCLUSION_TYPE_MENTIONABLE, LINK_TO_NOTE_BLOCK_TYPE, NOTE_LINK_ENTITY_TYPE } from "../DraftJSEditor/BlockEditing/LinkToNoteBlockUtils";
import { Note } from "../../Data/NoteType";
import { getNoteIDFromURL } from "../../../DecisionGraph/Utilities/NavigateTo";
import { JSONFormsObject, getJSONFormsObject } from "../../Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject";
import { COMMENT_BLOCK } from "../DraftJSEditor/BlockEditing/CommentBlock";
import { OPERATION_COMPLETE, OPERATION_SELECT } from "../../../Extensions/ExtensionsFramework/SaveOperationsBasics";
import { NotesContextType } from "../../Data/NotesContext";

/****
 * The note, plus any notes referred to inside it. This is a tree structure.
 */

export type TopLevelNoteHierarchy = {
    completedNoteIDs: string[];
    topNoteHierarchies: NoteHierarchy[];

    isLoaded: boolean;

    noteHierarchiesById:{[key:string]:NoteHierarchy};


    // Internal use only:
    internal?: {
        allPromises: Promise<NoteHierarchy>[];
        topNoteHierarchyPromises: Promise<NoteHierarchy>[];    
    };
};

export type NoteHierarchy = {
    exists: boolean;

    note: Note;
    jsonFormsObject: JSONFormsObject | null;

    // All items should be in one of these:
    fullyIncludedNoteIDs: string[];
    linkedNoteIDs: string[];
    editContextNotes: string[];

    // They may also be in one of these:
    selectedNoteIDs: string[];
    completedNoteIDs: string[];
};

export type NotifyProgressLoadingNoteHierarchy = (percentComplete:number,isDone:boolean)=>void;

export function startLoadingNoteHierarchy(doc_ids: string[], notesContext: NotesContextType, notifyProgressLoadingNoteHierarchy:NotifyProgressLoadingNoteHierarchy): TopLevelNoteHierarchy {
    const topLevelNoteHierarchy = {
        completedNoteIDs: [],
        isLoaded: false,
        topNoteHierarchies: [],
        noteHierarchiesById: {},
        internal: {
            allPromises: [],
            topNoteHierarchyPromises: [],
        },
    } as TopLevelNoteHierarchy;
    for (const doc_id of doc_ids) {
        const topNoteHierarchyPromise = loadNoteHierarchy(doc_id, notesContext, topLevelNoteHierarchy);
        topLevelNoteHierarchy.internal?.topNoteHierarchyPromises.push(topNoteHierarchyPromise);
        topLevelNoteHierarchy.internal?.allPromises.push(topNoteHierarchyPromise);
    }
    async function finishLoadingNoteHierarchy() {
        if (!topLevelNoteHierarchy.internal) {
            console.error("[finishLoadingNoteHierarchy] The internal data has been deleted too soon.");
            debugger;
            return;
        }
            
        let numPromises = topLevelNoteHierarchy.internal.allPromises.length;
        notifyProgressLoadingNoteHierarchy(0,false);
        while (true) {
            await Promise.all(topLevelNoteHierarchy.internal.allPromises);
            const numCurrentPromises = topLevelNoteHierarchy.internal.allPromises.length;
            if (numCurrentPromises===numPromises) {
                // console.log("[finishLoadingNoteHierarchy] Done loading promises, still processing...");
                notifyProgressLoadingNoteHierarchy(0.7,false);
                break;
            }
            notifyProgressLoadingNoteHierarchy(numPromises/(numCurrentPromises*1.5),false);
            const numNewPromises = numCurrentPromises-numPromises;
            // console.log("[finishLoadingNoteHierarchy] More notes to load, there are "+(numNewPromises)+" new ones");
            numPromises=numCurrentPromises;
        }
        // Cleanup of the hierarchy

        // First, convert from promises to actuals:
        topLevelNoteHierarchy.topNoteHierarchies = await Promise.all(topLevelNoteHierarchy.internal.topNoteHierarchyPromises);

        function removeCompletedAndDeletedChildrenFromNode(noteIDs:string[]):string[] {
            return noteIDs.filter((noteID:string) => !topLevelNoteHierarchy.completedNoteIDs.includes(noteID)).filter((noteID:string) => topLevelNoteHierarchy.noteHierarchiesById[noteID]);
        }

        // Remove completed children from the list of notes:
        // topLevelNoteHierarchy.completedNoteIDs.forEach((noteID:string) => {
        //     // If this note has been completed, remove it from the list.
        //     if (topLevelNoteHierarchy.completedNoteIDs.includes(noteID)) {
        //         delete topLevelNoteHierarchy.noteHierarchiesById[noteID];
        //         return;
        //     }
        // });
        for (const noteID in topLevelNoteHierarchy.noteHierarchiesById) {
            // Check the children for completed notes:
            const noteHierarchy = topLevelNoteHierarchy.noteHierarchiesById[noteID];
            noteHierarchy.fullyIncludedNoteIDs = removeCompletedAndDeletedChildrenFromNode(noteHierarchy.fullyIncludedNoteIDs);
            noteHierarchy.linkedNoteIDs = removeCompletedAndDeletedChildrenFromNode(noteHierarchy.linkedNoteIDs).filter((noteID:string) => !noteHierarchy.fullyIncludedNoteIDs.includes(noteID));
            noteHierarchy.editContextNotes = removeCompletedAndDeletedChildrenFromNode(noteHierarchy.editContextNotes).filter((noteID:string) => !noteHierarchy.fullyIncludedNoteIDs.includes(noteID));
        }

        // Save memery once complete:
        delete topLevelNoteHierarchy.internal;
    
        topLevelNoteHierarchy.isLoaded = true;
        // console.log("[finishLoadingNoteHierarchy]: Finished loading all "+numPromises+" notes.");
        notifyProgressLoadingNoteHierarchy(1,true);
    }
    finishLoadingNoteHierarchy();
    return topLevelNoteHierarchy;
}
 

async function loadNoteHierarchy(doc_id: string, notesContext: NotesContextType, topLevelNoteHierarchy: TopLevelNoteHierarchy): Promise<NoteHierarchy> {
    // Start loading:
    const notePromise = notesContext.getNote(doc_id);
    const noteJSONPromise = getJSONFormsObject(notesContext.getFirestore(), doc_id);
    // Wait for the loads to finish:
    const note = await notePromise;
    const jsonFormsObject = await noteJSONPromise;
    const noteHierarchy = getNoteHierarchy(note, jsonFormsObject, notesContext, topLevelNoteHierarchy);
    // if (!topLevelNoteHierarchy.internal?.noteHierarchiesById)
        // throw new Error("TopLevelNoteHierarchy's internal data has been deleted too soon.");
    if (noteHierarchy.exists && !topLevelNoteHierarchy.noteHierarchiesById[doc_id])
        topLevelNoteHierarchy.noteHierarchiesById[doc_id] = noteHierarchy;
    return noteHierarchy;
}
/*****************
 * This is specifically designed for AI chat, not just any render to HTML.
 */

export function getNoteHierarchy(note: Note | null, jsonFormsObject: JSONFormsObject | null, notesContext: NotesContextType, topLevelNoteHierarchy: TopLevelNoteHierarchy): NoteHierarchy {
    const noteHierarchy = {
        note,
        jsonFormsObject,
        exists: !!note,

        fullyIncludedNoteIDs: [],
        linkedNoteIDs: [],
        editContextNotes: [],

        selectedNoteIDs: [],
        completedNoteIDs: [],
    } as NoteHierarchy;
    if (!note) // = noteHierarchy.exists but TypeScript doesn't recognize that.
        return noteHierarchy;

    function loadIfNotLoading(noteId:string) {
        if (topLevelNoteHierarchy.noteHierarchiesById[noteId])
            return; // Load has already started!
        const newPromise = loadNoteHierarchy(noteId, notesContext, topLevelNoteHierarchy);
        topLevelNoteHierarchy.internal?.allPromises.push(newPromise);
    }
    function addChild(arrayOfIDs:string[], noteId:string) {
        const alreadyCompleted = topLevelNoteHierarchy.completedNoteIDs.includes(noteId);
        // if (alreadyCompleted)
            // return; // don't even try to load it, it would just be removed later.
        if (arrayOfIDs.includes(noteId))
            return; // already added and loading
        if (noteHierarchy.fullyIncludedNoteIDs.includes(noteId))
            return; // already added and loading. We won't add it to this sub-list.
        if (!alreadyCompleted)
            arrayOfIDs.push(noteId); // don't add it to the list, since it would be removed later.
        loadIfNotLoading(noteId);
    }

    // Completion and selected should go first, because we don't want to traverse down the tree for completed Notes.
    if (jsonFormsObject) {
        if (jsonFormsObject[OPERATION_COMPLETE]) {
            for (const noteID of jsonFormsObject[OPERATION_COMPLETE]) {
                topLevelNoteHierarchy.completedNoteIDs.push(noteID);
                noteHierarchy.completedNoteIDs.push(noteID);
                // We do have to load it, so we can see it later.
            }
        }
        if (jsonFormsObject[OPERATION_SELECT]) {
            for (const noteID of jsonFormsObject[OPERATION_SELECT]) {
                addChild(noteHierarchy.fullyIncludedNoteIDs, noteID);
                noteHierarchy.selectedNoteIDs.push(noteID);
            }
        }
    }
    if (note.doc_data && note.doc_data.blocks && note.doc_data.blocks.length > 0)
        for (const block of note.doc_data.blocks) {
            if (block.type === COMMENT_BLOCK) {
                // we don't print comments, they're just for the editor.
                continue;
            }
            if (block.type === LINK_TO_NOTE_BLOCK_TYPE) {
                if (!block.data || !block.data.checked) {
                    // We will not include this one in the prompt.
                    continue;
                }
                if (block.data.inclusion === INCLUSION_TYPE_LINK || block.data.inclusion === INCLUSION_TYPE_MENTIONABLE) {
                    addChild(noteHierarchy.linkedNoteIDs, block.data.doc_id);
                } else if (block.data.inclusion === INCLUSION_TYPE_EDIT_CONTEXT) {
                    addChild(noteHierarchy.editContextNotes, block.data.doc_id);
                } else {
                    addChild(noteHierarchy.fullyIncludedNoteIDs, block.data.doc_id);
                }
            }
            if (block.entityRanges)
                for (const entityRange of block.entityRanges) {
                    // These links could also be to a note. Find the link:
                    const entity = note.doc_data.entityMap[entityRange.key];
                    if ((entity.type === NOTE_LINK_ENTITY_TYPE || entity.type === "LINK") && entity.data.url) {
                        const noteId = getNoteIDFromURL(entity.data.url);
                        if (noteId)
                            addChild(noteHierarchy.linkedNoteIDs, noteId);
                    }
                }
        }
    if (note.children)
        for (const childId of note.children) {
            addChild(noteHierarchy.linkedNoteIDs, childId);
        }

    function getExistingIDs(notes:NoteHierarchy[]) {
        return notes.map((noteHierarchy:NoteHierarchy) => {
            if (noteHierarchy.exists) return noteHierarchy.note.id;
            return "";
        }).filter((noteID:string) => noteID!=="");
    }

    return noteHierarchy;
}
