// React & state:
import { useEffect, useRef } from 'react';
// Chat AI:
import { ChatEditorRefFunctions } from '../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatLogContextProvider } from '../DecisionGraph/ChatEditor/UI/ChatLog';
import { LLMServerContextProvider } from '../DecisionGraph/ChatEditor/UI/SelectableLLM';
import "../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { ChatWithDraft } from '../DecisionGraph/ChatEditor/UI/ChatWithDraft';
import { InstantSearch } from 'react-instantsearch';
import { AlgoliaSearchClient } from '../DecisionGraph/AlgoliaSearch/AlgoliaSearchClient';


export default function ChatWithDraftTestPage() {
  const chatEditorRef = useRef<ChatEditorRefFunctions>({} as ChatEditorRefFunctions);

  useEffect(() => {
    document.title = 'Chat with Draft Test Page - AI Got This';
  }, []);
  
  return <InstantSearch searchClient={AlgoliaSearchClient} indexName="AIGotThisNotes" future={{preserveSharedStateOnUnmount: true}} routing={true}>
        <ChatLogContextProvider>
          <LLMServerContextProvider>
            <div className="HEADER_ABOVE_TABS">
              <ChatWithDraft chatEditorRef={chatEditorRef}/>
            </div>
          </LLMServerContextProvider>
        </ChatLogContextProvider>
      </InstantSearch>;
}