import { JSONSchemaType } from "ajv";
import { TTRPGExtension } from "../TTRPG/TTRPGExtension";
import { JSONFormsObjectContextType } from "../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext";
import { Note } from "../../Notes/Data/NoteType";
import { OperationRenderable } from "./SaveOperationsBasics";
import { NavigateToNoteFunction } from "../../DecisionGraph/Utilities/NavigateTo";

export type Extension = {
    name: string;
    description: string;

    /*******
     * When any of these notes are included, the extension is available.
     */
    included_notes_doc_ids: string[];

    /********
     * When any of these note types are included, the extension is available.
     */
    noteTemplateTypes: ExtensionNoteTemplateType<any>[];

    definitiveNoteTemplateType: ExtensionNoteTemplateType<any>; // This is also in the noteTempleteTypes, but it's the one that's available if no extensions are selected. This is the way someone starts using this extension, by selecting this note type.

    relatedListTypes: RelatedListType[];

    getOperationsForNote: (noteContext:JSONFormsObjectContextType, note:Note)=>OperationRenderable[];
    saveOperation: (noteContext:JSONFormsObjectContextType, note:Note, operation:OperationRenderable, navigateToNote:NavigateToNoteFunction)=>void;
}

export type RelatedListType = {
    name: string;
    description: string;
    emoji: string;

    listComponent: ()=>JSX.Element;
}

export type ExtensionNoteTemplateType<T extends ExtensionJSONFormsObject> = {
    name: string;
    description: string;
    emoji: string;

    // Draft JS info -- including type of the note (draft portion). If any of these are true, there should be a doc_id associated with the note.
    template_doc_ids?: string[]; // the doc_id of templates that create this note type.

    schema?: JSONSchemaType<T>;//<any>; // if left blank, no javascript will be allowed, it will be only a note.
};


// TypeScript puzzle: We seem to need to be unable to refer to JsonFormsObject for this, even though it's the same properties. We only get validation errors on the downstream Schema, not in the types. Perhaps it's because JSONFormsObject allows arbitrary additional properties?
export type ExtensionJSONFormsObject = /*JSONFormsObject &*/ {
    name: string,
    id: string,
    version: number
};


export function getTemplateTypes(extensions:Extension[]) {
    if (extensions.length===0) {
        // Special case! We want one special note type at the top level for each installed extension.
        return getInstalledExtensionsList().map((extension:Extension) => {
            return extension.definitiveNoteTemplateType;
        });
    }

    return extensions.reduce((acc:ExtensionNoteTemplateType<any>[], extension:Extension) => {
        return acc.concat(extension.noteTemplateTypes);
    }, []);
}

export function getInstalledExtensionsList():Extension[] {
    return [TTRPGExtension];
}
