// React & state:
import { useContext, useEffect, useMemo, useState } from 'react';
import { EditorState, RawDraftContentBlock, RawDraftContentState, convertFromRaw, convertToRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import "../../../Notes/UIs/DraftJSEditor/WYSIWYGEditor.css";
import { getAuth } from 'firebase/auth';
import { Avatar, Button, Collapse, Image, Modal, Spin, Tooltip } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DeleteOutlined, EditOutlined, MergeCellsOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { ChatEditorRefFunctions, useSendToServerFromChatEditor } from '../../../ServerConnection/LLMServer/SendChatToServerHook';
import { ChatListDropdown, ChatLogContext, ChatMessage2 } from './ChatLog';
import { SelectableLLMServerDropdown } from './SelectableLLM';
import { findLastIndex, isEqual, uniq } from 'lodash';
import { extractJSONFromMarkdown, removeJSONFromMarkdown } from '../../../ServerConnection/LLMServer/MarkdownConverter';
import { JSONFormsObject, getJSONFormsObject, saveJSONFormsObject } from '../../../Notes/Data/Actions/JSONFormsObject/LoadAndSaveJSONFormsObject';
import { NotesContext } from '../../../Notes/Data/NotesContext';
import { useFirestore } from 'reactfire';
import { useNavigateToNote } from '../../Utilities/NavigateTo';
import { Note } from '../../../Notes/Data/NoteType';
import { SelectedJSONFormsContext } from '../../../JSONEditing/JSONSchemaBasedEditors/JSONFormsObjectContext';
import { newNote, useSaveNote } from '../../../Notes/Data/NoteDBHooks';
import { addNoteToParent } from '../../../Notes/Data/Actions/Tree/ManipulateTree';
//@ts-ignore
import ReactJsonViewCompare from 'react-json-view-compare';
import { getNoteNameAndEmoji } from '../../../Notes/NotesTree/TreeUtilities/CreateTagTreeNodes';
import { convertMarkdownToDraftJSRaw } from '../../../Notes/UIs/NoteInformationComponents/DraftJSFromHTML';
import { useBasicHandleKeyCommand, useBasicKeyBindingFn } from '../../../Notes/UIs/DraftJSEditor/DraftKeyboardBindings';
import { BLOCK_RENDER_MAP, blockStyleFn, useBlockRendererFn } from '../../../Notes/UIs/DraftJSEditor/BlockEditing/BlockRendererAndStyleFns';
import { useExtensions } from '../../../Notes/UIs/DraftJSEditor/DraftJSPluginsEditor/DraftJsToolbarAndPlugins';
import { SELECTED_TAB_IS_DATA, SELECTED_TAB_IS_NOTE } from '../../../Notes/UIs/NotePageAndHigher/NotePage';
import { NoteHierarchyContext } from '../../../Notes/UIs/NoteInformationComponents/NoteHierarchyProvider';

const DEBUG = false;
const DEBUG_SHOW_SYSTEM_PROMPT = true;
const DEBUG_SEND_TO_SERVER_WITH_STREAMING = false;

const DELAY_BETWEEN_CLIENT_CHAT_UPDATES = 1000; // 500 was flickering a lot and we did get some errors where the message was randomly invisible at the end.

function SystemMessageViewer({message}:{message:ChatMessage2}) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // const [editable,setEditable] = useStateLSBoolean("draftEditor_isEditable",true);
  // This will have only the assistant & system messages, nothing from the regular. 
  useEffect(() => {
    if (message.role !== 'system') {
      console.error("SystemMessageViewer only accepts system messages. Got:",message.role);
      debugger;
      return;
    }
    const draftJSMessage = convertMarkdownToDraftJSRaw(message.content as string);
    const editorStateNew = EditorState.createWithContent(convertFromRaw(draftJSMessage));
    setEditorState(editorStateNew);
  },[message]);
  const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
  const handleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
  const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
  const {plugins} = useExtensions(setEditorState,editorState, false, (a:boolean)=>{});


  return <Collapse accordion={true} items={[
        {
            key: "System Prompt",
            label: "System Prompt",
            children: <div className="editor">
                <Button type='default' onClick={()=>navigator.clipboard.writeText(message.content as string)}>Copy system prompt</Button>
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    blockRendererFn={blockRenderFn}
                    blockRenderMap={BLOCK_RENDER_MAP}
                    blockStyleFn={blockStyleFn}
                    plugins={plugins}
            
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={basicKeyBindingFn}
                    readOnly={true}
                />
            </div>
      }]}/>
}

function InlineDraftJSViewer({draftJSMessage}:{draftJSMessage:RawDraftContentState}) {
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(draftJSMessage)));
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
    const {plugins} = useExtensions(setEditorState,editorState, false, (a:boolean)=>{});
  
    return <div className="editor inline-editor-main">
      <Editor
        editorState={editorState}
        onChange={setEditorState}
        blockRendererFn={blockRenderFn}
        blockRenderMap={BLOCK_RENDER_MAP}
        blockStyleFn={blockStyleFn}
        plugins={plugins}
        readOnly={true}
    />
    </div>;
}
function InlineDraftJSEditor({draftRawContent, setDraftJSMessage}:{draftRawContent:RawDraftContentState, setDraftJSMessage:(message:RawDraftContentState)=>void}) {
    const [editorState, setEditorStateLocal] = useState(() => EditorState.createWithContent(convertFromRaw(draftRawContent)));
    function setEditorState(newEditorState:EditorState) {
        setEditorStateLocal(newEditorState);
        setDraftJSMessage(convertToRaw(newEditorState.getCurrentContent()));
    }
    const basicKeyBindingFn = useBasicKeyBindingFn(editorState);
    const handleKeyCommand = useBasicHandleKeyCommand(editorState, setEditorState);
    const blockRenderFn = useBlockRendererFn(setEditorState, editorState, false);
    const {headerComponents, plugins} = useExtensions(setEditorState,editorState, true);
  
    return <div className="editor inline-editor-main">
        {headerComponents}
        <Editor
            editorState={editorState}
            onChange={setEditorState}
            blockRendererFn={blockRenderFn}
            blockRenderMap={BLOCK_RENDER_MAP}
            blockStyleFn={blockStyleFn}
            plugins={plugins}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={basicKeyBindingFn}
            readOnly={false}
        />
    </div>;
}

function OverwriteNoteModal({loadedNote, loadedJSON, isModalOpen, setModalIsOpen, message, setMessage}:{loadedNote:Note, loadedJSON:JSONFormsObject|null, isModalOpen:boolean, setModalIsOpen:(open:boolean)=>void, message:ChatMessage2, setMessage:(message:ChatMessage2)=>void}) {
    const firestore = useFirestore();
    const navigateToNote = useNavigateToNote();
    const saveNote = useSaveNote();

    const {draftDiffers, jsonDiffers, newNote, newMessage} = useMemo(()=>{
        const {newNote, newMessage, hasDraftJSContent} = getSaveableNoteFrom(loadedNote.doc_name, message);

        const draftIsSame = isEqual(loadedNote.doc_data, newNote.doc_data);
        const draftDiffers = hasDraftJSContent && !draftIsSame;
        const jsonDiffers = !isEqual(loadedJSON, message.jsonContent);
        // console.log("[OverwriteNoteModal]>useMemo> hasDraftJSContent: ",hasDraftJSContent, "  draftDiffers:",draftDiffers);
        return {draftDiffers, jsonDiffers, newNote:{...newNote, id:loadedNote.id} as Note, newMessage};
    },[loadedNote, loadedJSON, message]);

    async function submitChanges() {
        // TODO keep it open but replace it with a spinner and remove the buttons. (Or just close it and show a spinner on the main page).
        setModalIsOpen(false);
        if (jsonDiffers) {
            await saveJSONFormsObject(firestore, loadedNote.id, message.jsonContent);
            // Bug: If (and only if) the note was the current editor open, this does not refresh the JSON editor. We need to do that manually somehow, perhaps via the current note context.
        }
        if (draftDiffers) {
            saveNote(newNote);
            if (newMessage)
                setMessage(newMessage);
        }
        // Sleep for a moment before going to that page... Giving it a moment to catch up seems to allow it to become fresh.
        await new Promise(r => setTimeout(r, 100));
        if (jsonDiffers && !draftDiffers)
            navigateToNote(newNote.id, SELECTED_TAB_IS_DATA);
        else 
            navigateToNote(newNote.id, SELECTED_TAB_IS_NOTE);
        if (jsonDiffers && (!loadedNote.type || loadedNote.type==="Note")) {
            alert("Please select a type for this note manually. (Until then, you won't be able to see the JSON).");
        }
    }


    // TODO Use a visual diff for the draftjs instead of a JSON viewer.

    return <Modal
        title="Overwrite Note?"
        closable={true}
        open={isModalOpen}
        onOk={submitChanges}
        onCancel={()=>{setModalIsOpen(false)}}
        okText="Overwrite"
        cancelText="Cancel"
        width="100vw" style={{ top: 20, minHeight: "100vh"}}
        footer={[
            <Button key="back" type="default" onClick={()=>{setModalIsOpen(false)}}>Cancel</Button>,
            <Button key="submit" type="primary" onClick={submitChanges}>Overwrite</Button>,
        ]}
        >
            {draftDiffers && <>
                <h3>Note Text</h3>
                <ReactJsonViewCompare oldData={loadedNote?.doc_data} newData={message.draftRawContent}/>
            </>}
            {jsonDiffers && <>
                <h3>Data</h3>
                <ReactJsonViewCompare oldData={loadedJSON} newData={message.jsonContent}/>
            </>}
       <p>Are you sure you want to overwrite the note?</p>
       
    </Modal>;
}

const HEADER_TYPES = ["header-one","header-two","header-three"];

function getFirstHeader(doc_data:RawDraftContentState) {
    // Remove any lines before the first H1 (sometimes the bot creates a description saying what it's creating)
    // And mark whether there are additional contents below the H1.
    let indexOfFirstBlockWithHeader = null as number|null;
    for (let i=0;i<doc_data.blocks.length;i++) {
        // Sometimes it generats the first header as being another type besides H1.
        if (HEADER_TYPES.includes(doc_data.blocks[i].type)) {
            indexOfFirstBlockWithHeader = i;
            doc_data.blocks[i].type="header-one"; // make it header-one if it was a lower level header.
            return {found: true, doc_name: doc_data.blocks[i].text, index:i};
        }
    }
    return {found:false, index: null};
}

function getSaveableNoteFrom(doc_name:string, message:ChatMessage2) {
    // Create a new note
    const newNote = {doc_name:doc_name} as Note;
    let newMessage = null as ChatMessage2|null;
    let hasDraftJSContent = false;
    let needsAType = false;
    if (message.draftRawContent) {
        let doc_data = message.draftRawContent;
        // Remove any lines before the first H1 (sometimes the bot creates a description saying what it's creating)
        // And mark whether there are additional contents below the H1.
        const {index: indexOfFirstBlockWithHeader} = getFirstHeader(doc_data);
        // let indexOfFirstBlockWithHeader = null as number|null;
        // for (let i=0;i<doc_data.blocks.length;i++) {
        //     // Sometimes it generats the first header as being another type besides H1.
        //     if (HEADER_TYPES.includes(doc_data.blocks[i].type)) {
        //         indexOfFirstBlockWithHeader = i;
        //         doc_data.blocks[i].type="header-one"; // make it header-one if it was a lower level header.
        //         break;
        //     }
        // }
        if (indexOfFirstBlockWithHeader===null) {
            // Add the title block in as the first block.
            const titleBlock = {text:doc_name,type:"header-one",key:"HeaderBlock"+Math.random()} as unknown as RawDraftContentBlock;
            doc_data.blocks = [titleBlock, ...doc_data.blocks];
        } else if (indexOfFirstBlockWithHeader>0) {
            // Trim blocks before the header.
            doc_data.blocks = doc_data.blocks.slice(indexOfFirstBlockWithHeader);                
        }
        if (doc_data.blocks.length>1)
            hasDraftJSContent = true;
        newNote.doc_data=doc_data;
        newMessage = {...message, draftRawContent: doc_data} as ChatMessage2;
    }
    if (message.jsonContent) {
        // We also need to know the note type. Figure it out from the JSON.
        // TODO JSONFormsObject should have a type field, and we should use that in other places.
        if (message.jsonContent.type) {
            newNote.type=message.jsonContent.type;
        } else {
            // TODO pass this back
            needsAType = true;
            // alert("Please select the type for this note manually, it was not inclueded in the JSON.");
        }
    }
    return {newNote, newMessage, hasDraftJSContent, needsAType};
}

function AssistantMessage({message, deleteSelectedMessage, setMessage, editing, setEditing}:{message:ChatMessage2, deleteSelectedMessage?:()=>void, setMessage:(message:ChatMessage2)=>void, editing: boolean, setEditing:(editing:boolean)=>void}) {
    const notesContext = useContext(NotesContext);
    const firestore = useFirestore();
    const navigateToNote = useNavigateToNote();
    const selectedJsonFormsContext = useContext(SelectedJSONFormsContext);
    const [isOverwriteModalOpen, setOverwriteModalOpen] = useState(false);
    useEffect(() => { // First time convert from markdown to draftjs & json
        if (message.role!== 'assistant' && message.role !== 'system') {
          console.error("StaticDraftJSViewer only accepts assistant and system messages. Got:",message.role);
          debugger;
          return;
        }
        if (message.draftRawContent || message.jsonContent)
            return; // already converted.
        // TODO check whether there's draft content and whether there's JSON.
        let markdownText = (message.content as string).trim();
        const jsonContent = extractJSONFromMarkdown(markdownText) as JSONFormsObject | null;
        if (jsonContent) {
            markdownText = removeJSONFromMarkdown(markdownText).trim();
        }
        const draftJSMessage = convertMarkdownToDraftJSRaw(markdownText);
        setMessage({...message, draftRawContent: draftJSMessage, jsonContent} as ChatMessage2);
    },[message]);
    const doc_name = useMemo(() => {
        // let doc_name = null as string|null;
        // let blockWithH1 = null as number|null;
        if (!message.draftRawContent)
            return null;
        const {doc_name, index: blockWithH1} = getFirstHeader(message.draftRawContent as RawDraftContentState);
        // Get the title by finding the first H1 of the note. It can be on the first line, sometimes the second line and we'll have to drop the first line when saving as a note.
        // for (let i=0;i<message.draftRawContent.blocks.length;i++) {
        //     if (message.draftRawContent.blocks[i].type === 'header-one') {
        //         blockWithH1 = i;
        //         break;
        //     }
        // }
        if (blockWithH1 !== null) {
            return doc_name;
        }
        // console.log("[AssistantMessage]>useMemo> doc_name:",doc_name," hasNoteTitle:",hasNoteTitle," draftRawContent:",message.draftRawContent);
        return null;
    },[message.draftRawContent]);
    const [loadedNote, setLoadedNote] = useState(null as Note|null);
    const [loadedJson, setLoadedJson] = useState(null as JSONFormsObject|null);
    const [noteIsDifferent, setNoteIsDifferent] = useState(false);
    const [errorNotesWithSameName, setErrorNotesWithSameName] = useState([] as Note[]);
    useEffect(() => { // Load note by this name
        if (!doc_name) {
            setLoadedNote(null);
            return;
        }
        async function checkIfNoteExists() {
            if (DEBUG) console.log("[AssistantMessage]>useEffect> Checking if note exists for doc_name:",doc_name);
            const notes = await notesContext.getNotesOfName(doc_name as string);
            if (notes.length===0) {
                setLoadedNote(null);
                setLoadedJson(null);
                setErrorNotesWithSameName([]);
                return;
            } else if (notes.length>1) {
                setLoadedNote(null);
                setLoadedJson(null);
                setErrorNotesWithSameName(notes);
                return;
            } else if (notes.length===1) {
                setLoadedNote(notes[0]);
                const jsonFormsObject = await getJSONFormsObject(firestore, notes[0].id);
                setLoadedJson(jsonFormsObject);
                setErrorNotesWithSameName([]);
            }
        }
        checkIfNoteExists();
    },[doc_name]);
    useEffect(()=> { // Check for changes vs the note.
        if (!loadedNote || !message.draftRawContent) {
            setNoteIsDifferent(false);
            return;
        }
        const draftIsSame = isEqual(loadedNote.doc_data, message.draftRawContent);
        const jsonIsSame = isEqual(loadedJson, message.jsonContent);
        setNoteIsDifferent(!draftIsSame || !jsonIsSame);
    },[loadedNote,message.draftRawContent]);
    async function saveAsNewNote(doc_name:string) {
        if (!doc_name) {
            alert("Unfortunately, there was a bug. The system is having trouble processing the doc name.");
            if (DEBUG) console.error("[AssistantMessage]>saveAsNewNote> doc_name is blank. saveAsNewNote should not be callable in this case.");
            debugger;
            return;
        }
        // Create a new note
        const {newNote: newNoteDoc, newMessage, hasDraftJSContent} = getSaveableNoteFrom(doc_name, message);
        if (newMessage)
            setMessage(newMessage);
        const parentNote = selectedJsonFormsContext.note;
        if (parentNote)
            newNoteDoc.parent=parentNote.id;
        const createdNote = await newNote(firestore, doc_name, newNoteDoc);
        notesContext.notesHaveBeenLoaded([createdNote]);
        if (message.jsonContent)
            await saveJSONFormsObject(firestore, createdNote.id, message.jsonContent);
        if (parentNote) {
            await addNoteToParent(firestore, createdNote, parentNote.id, null, notesContext);
        }
        if (message.jsonContent && !hasDraftJSContent)
            navigateToNote(createdNote.id, SELECTED_TAB_IS_DATA);
        else 
            navigateToNote(createdNote.id, SELECTED_TAB_IS_NOTE);
        setLoadedNote(createdNote);
        setNoteIsDifferent(false);
        return;
    }
    function saveAsAskForName() {
        const doc_name = prompt("Please enter a name for this note.");
        if (!doc_name) {            
            return;
        }
        saveAsNewNote(doc_name);
    }
    
    let noteAction = <></>;
    if (!doc_name) {
        noteAction = <Tooltip title="Choose a name and save as a new note">
            <Button type="default" onClick={saveAsAskForName} icon={<SaveOutlined />}>Save as...</Button>
        </Tooltip>;
    } else { // doc_name exists: there is a note title.
        if (errorNotesWithSameName.length>1) {
            noteAction = <div style={{color:"red"}}>There are multiple notes with the same name. Please delete one of them before trying to save this: {errorNotesWithSameName.map((note:Note,index:number) => <div key={note.id}>
                <Button type="link" onClick={()=>{navigateToNote(note.id)}} key={note.id}>"{note.doc_name}" ({index+1})</Button>
                </div>)}
            </div>;
        } else if (loadedNote!==null) {
            if (noteIsDifferent) {
                noteAction = <Tooltip title="Update the existing note. This will save over it">
                    <Button type="link" onClick={()=>{navigateToNote(loadedNote.id)}}>Go to (current) "{doc_name}"</Button>
                    <Button type="default" onClick={()=>{setOverwriteModalOpen(true)}} icon={<MergeCellsOutlined />}>Overwrite "{doc_name}"</Button>
                </Tooltip>;
            } else {
                noteAction = <Tooltip title="Note exists and is the same.">
                    <Button type="link" onClick={()=>{navigateToNote(loadedNote.id)}}>Go to "{doc_name}"</Button>
                </Tooltip>;
            }
        } else {
            // Note doesn't exist yet
            noteAction = <Tooltip title={"Will create a new note named \""+doc_name+"\""}>
                <Button type="default" onClick={()=>saveAsNewNote(doc_name)} icon={<PlusCircleOutlined />}>Create "{doc_name}"</Button>
            </Tooltip>;
        }
    }
    
    // console.log("[AssistantMessage]>render> loadedNote:",loadedNote," loadedJson:",loadedJson," errorNotesWithSameName:",errorNotesWithSameName," noteIsDifferent:",noteIsDifferent)

    return <div>
        {/* {message.extra && <div>There is some extra on the assistant message. It's not being rendered.</div>} */}
        {noteIsDifferent && loadedNote && errorNotesWithSameName.length===0 &&
            <OverwriteNoteModal
                loadedNote={loadedNote}
                loadedJSON={loadedJson}
                isModalOpen={isOverwriteModalOpen} setModalIsOpen={setOverwriteModalOpen} message={message} setMessage={setMessage}/>
        }
        {!editing && message.draftRawContent && <>
            <InlineDraftJSViewer draftJSMessage={message.draftRawContent}/>
            {message.jsonContent && <div>JSON: {JSON.stringify(message.jsonContent)}</div>}
            <Tooltip title="Edit this response">
                <Button type="default" onClick={()=>{setEditing(true)}} icon={<EditOutlined/>}>Edit</Button>
            </Tooltip>&nbsp;&nbsp;
            <Tooltip title="Delete this response">
                <Button type="default" onClick={deleteSelectedMessage} icon={<DeleteOutlined />}/>
            </Tooltip>&nbsp;&nbsp;
            {noteAction}
        </>}
        {editing && message.draftRawContent && <>
            <InlineDraftJSEditor
                draftRawContent={message.draftRawContent}
                setDraftJSMessage={(draftRawContent)=>{setMessage({...message, draftRawContent: draftRawContent} as ChatMessage2)}}
                />
            {message.jsonContent && <div>JSON: {JSON.stringify(message.jsonContent)}</div>}
            <Tooltip title="Stop editing">
                <Button type="default" onClick={()=>{setEditing(false)}}>Done</Button>
            </Tooltip>&nbsp;&nbsp;
            {noteAction}
        </>}
        <br/>
    </div>;
}

export function ChatWithDraft({chatEditorRef}:{chatEditorRef:React.MutableRefObject<ChatEditorRefFunctions>}) {
    const { allChats, selectedChatIndex, setSelectedChatMessages } = useContext(ChatLogContext);
    const selectedChatMessages = allChats[selectedChatIndex]?.messages || [] as ChatMessage2[];
    const [editingMessageIndex, setEditingMessageIndexState] = useState(-1);
    const [currentMessageHasChanged, setCurrentMessageHasChanged] = useState(false);
    const {sendToServerWithStreaming,cancelStreaming} = useSendToServerFromChatEditor(chatEditorRef);
    const isWaitingForChat = chatEditorRef.current?.isWaitingForChat || false;
    const [uneditedMessage, setUneditedMessage] = useState("");
    const notesContext = useContext(NotesContext);
    const {extensions} = useContext(NoteHierarchyContext);
    const navigateToNote = useNavigateToNote();


    function deleteMessage(index:number) {
        // TODO someday we'd like to implement undo. (We won't ask the user for confirmation because this is usually a lightweight operation).
        const newMessages = selectedChatMessages;
        let numToDelete = 1;
        // If this was an assistant message, we also check to see whether there was an empty user message after it.
        if (newMessages[index].role === 'assistant' && index+1<newMessages.length && newMessages[index+1].role === 'user' && newMessages[index+1].content === "")
            numToDelete = 2;
        newMessages.splice(index,numToDelete);
        setSelectedChatMessages(newMessages);
        // Update the editing message to make it the last user message.
        const lastUserMessageIndex = findLastIndex(newMessages,(message)=>message.role === 'user');
        if (lastUserMessageIndex>-1)
            setEditingMessageIndex(lastUserMessageIndex);
    }
    function setMessage(index:number, message:ChatMessage2) {
        const newMessages = selectedChatMessages;
        newMessages[index] = message;
        setSelectedChatMessages(newMessages);
        if (index === editingMessageIndex)
            setCurrentMessageHasChanged(true);
    }
    function setCurrentUserMessageTo(message:string) {
        const newMessages = selectedChatMessages;
        const newMessage = {role:'user',content:message, createAt: new Date().getTime()} as ChatMessage2;
        newMessages[editingMessageIndex] = newMessage;
        setSelectedChatMessages(newMessages);
        setCurrentMessageHasChanged(true);
    }

    useEffect(() => { // Anytime someone selects a different chat, we cancel any edits.
        setEditingMessageIndex(-1);
    },[selectedChatIndex]);
    useEffect(() => { // Adjust messages & selection to match
        if (DEBUG) console.log("[ChatWithDraft]>useEffect> Starting checks:");
        // Check if there's no user chat at the end, and we're not editing a message, in which case, we should add a user message at the end.
        if (chatEditorRef.current.isWaitingForChat)
            return;
        // Check if editingMessageIndex is now invalid, if so, we need to change it to a valid one. This happens when the chat is changed, or we create a new chat.
        if (editingMessageIndex>-1) {
            if (editingMessageIndex<selectedChatMessages.length) {
                // it already exists, so don't change anything.
                // However, if we just deleted a message, it won't be focused by default.
                // TODO enhance this so we can get autofocus.
                if (DEBUG) console.log("[ChatWithDraft]>useEffect> Editing message index is already valid, not changing.");
                return;
            }
            // The message doesn't exist yet:
            //if (editingMessageIndex>=selectedChatMessages.length) {
            if (DEBUG) console.log("[ChatWithDraft]>useEffect> Editing message index is invalid, changing to -1.");
            setEditingMessageIndex(-1);
            // This useEffect will be called again with the new editingMessageIndex, so, still return:
            // }
            return;
        }
        if (selectedChatMessages.length===0 || selectedChatMessages[selectedChatMessages.length-1].role !== 'user') {
            // We need to add a user message at the end.
            if (DEBUG) console.log("[ChatWithDraft]>useEffect> Automatically adding a user message to the end.");
            const newMessages = selectedChatMessages;
            newMessages.push({role:'user',content:"", createAt: new Date().getTime()} as ChatMessage2);
            setSelectedChatMessages(newMessages);
        }
        if (DEBUG) console.log("[ChatWithDraft]>useEffect> Automatically editing the last message.");
        setEditingMessageIndex(selectedChatMessages.length-1);
    },[selectedChatMessages, editingMessageIndex, isWaitingForChat]);

    function setEditingMessageIndex(index:number) {
        if (index>-1) {
            DEBUG && console.log("[ChatWithDraft]>setEditingMessageIndex> Going to start editing message index:",index);
            setUneditedMessage(selectedChatMessages[index].content as string);
        }
        setEditingMessageIndexState(index);
    }
    async function submitUserMessage(doneEditingIndex:number) {
        if (!currentMessageHasChanged && selectedChatMessages.length>doneEditingIndex && selectedChatMessages[doneEditingIndex+1]?.role === 'assistant') {
            // Nothing has changed, and there's already an answer, so just stop editing.
            //   cancelEditingUserMessage(doneEditingIndex);
            setEditingMessageIndex(-1);
            return;
        }
        // User has submitted a message to process. Clean up, by removing any messages below this index, and send the message to the server.
        const messagesToSendToServer = selectedChatMessages.slice(0,editingMessageIndex+1);
        setSelectedChatMessages(messagesToSendToServer);
        chatEditorRef.current.isWaitingForChat=true;
        setEditingMessageIndex(-1); // Do this before the await, so that it will display as submitted.
        if (DEBUG_SEND_TO_SERVER_WITH_STREAMING) console.log("[ChatWithDraft]>submitUserMessage> Sending to server with streaming.");
        const {response, newChatMessages} = await sendToServerWithStreaming(messagesToSendToServer);
        // Confirm that newChatMessages ends with a user message:
        if (newChatMessages.length>0 && newChatMessages[newChatMessages.length-1].role !== 'user') {
            console.error("[ChatWithDraft]>submitUserMessage> The processed messages did not end with a user message.");
            debugger;
        }
        // Read and incrementally add the messages to the chat, until it's done:
        if (!response.body) {
            console.error("[ChatWithDraft]>submitUserMessage> Response has no body.");
            return;
        }
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        // Create the new assistant message:
        const newAssistantMessage = {role: "assistant", content:""} as ChatMessage2;
        let lastAddition = new Date().getTime();
        while (true) {
            const {done, value} = await reader.read();
            if (done) {
                break;
            }
            const newChunk = decoder.decode(value);
            // Add the message to the chat.
            newAssistantMessage.content += newChunk;
            if (DEBUG_SEND_TO_SERVER_WITH_STREAMING) console.log("[ChatWithDraft]>submitUserMessage> Message so far:",newAssistantMessage.content);
            let latestAddition = new Date().getTime();
            if (latestAddition - lastAddition > DELAY_BETWEEN_CLIENT_CHAT_UPDATES) {
                // Add the message to the chat, but only once per second, to avoid overloading react. (There might be another way)
                const newChatMessages2 = [...newChatMessages, newAssistantMessage];
                setSelectedChatMessages(newChatMessages2);
                lastAddition = latestAddition;
            }
        }
        const newChatMessages2 = [...newChatMessages, newAssistantMessage];
        setSelectedChatMessages(newChatMessages2);
        setCurrentMessageHasChanged(false);
        chatEditorRef.current.isWaitingForChat=false;
    }
    //   function cancelEditingUserMessage(cancelEditingIndex:number) {
    //     // Just stop editing. Revert the message to its original state.
    //     // Special case -- if it's the last message, we don't stop editing (until the user has selected another message)
    //     // This special case is required in order to make sure we get the events to start editing the next message -- I think the order is not onBlur then click or something like that, because we don't get the start editing message.
    //     if (cancelEditingIndex === selectedChatMessages.length-1) {
    //       if (DEBUG) console.log("[ChatWithDraft]>cancelEditingUserMessage> Ignoring onBlur to stop editing the last message.");
    //       return;
    //     }
    //     if (DEBUG) console.log("[ChatWithDraft]>cancelEditingUserMessage> User cancelled editing message at index:",editingMessageIndex);
    //     const newMessages = [...selectedChatMessages];
    //     newMessages[editingMessageIndex].content = uneditedMessage;
    //     setSelectedChatMessages(newMessages);
    //     setEditingMessageIndex(-1);
    //   }

    const auth = getAuth();
    const photoURL = auth.currentUser?.photoURL;
    const avatar = <Avatar
            src={photoURL && <Image src={photoURL}/>}
        />;

    // useMemo(()=>{
    //     console.log("[ChatWithDraft]> selectedChatMessages changed:",selectedChatMessages);
    // },[selectedChatMessages]);
    return <>
        <SelectableLLMServerDropdown/>
        <ChatListDropdown/><br/><br/>

        <Spin size='large' spinning={isWaitingForChat}>
        <div style={{overflowY:"scroll"}}>
        {selectedChatMessages.map((message:ChatMessage2, index) => {
            const isEditingThis = index === editingMessageIndex;
            if (message.role === 'user') {
                if (isEditingThis) {
                    return <div key={index}>{avatar}&nbsp;&nbsp;&nbsp;
                        <div style={{display:'inline-block', width:'calc(100% - 80px)',paddingTop:"10px"}}>
                            <TextArea
                                autoFocus
                                autoSize
                                value={message.content as string}
                                style={{width:'100%',verticalAlign:"middle"}}
                                onChange={(e)=>{setCurrentUserMessageTo(e.target.value)}}
                                onPressEnter={()=>submitUserMessage(index)}
                                //   onBlur={()=>cancelEditingUserMessage(index)}
                                onFocus={(e)=>{
                                    // Set the cursor to the end of the text area.
                                    // if (DEBUG) console.log("[ChatWithDraft]>onFocus> Setting cursor to end of text area, using ",element);
                                    const element = e.currentTarget as HTMLTextAreaElement;
                                    element.setSelectionRange(element.value.length,element.value.length);
                                }}
                            />
                        </div>
                    </div>;
                } else {
                    return <div key={index}>
                        <Button type="text" key={index} className="chat-with-draft-user-message"
                            onClick={()=>{setEditingMessageIndex(index)}}>
                                {avatar}&nbsp;&nbsp;&nbsp;
                                <div style={{display:"inline-block", width: 'calc(100% - 100px)', verticalAlign: "top"}}>{message.content as string}<br/><br/></div>
                        </Button>
                        {message.extra && message.extra.noteIDs && message.extra.noteIDs.length>0 && <div className="chat-item-title-ref2">References:
                            {/* It's an array of strings that are note names used in the prompt. */}
                            {(uniq(message.extra.noteIDs) as string[]).map((note_id:string,index:number)=>{
                                const note = notesContext.getLoadedNote(note_id,true);
                                if (!note) // it may still be loading.
                                    return <div key={note_id}></div>;
                                return <Button className="chat-item-title-ref-button" key={note_id} type="link" onClick={()=>{navigateToNote(note_id)}}>{getNoteNameAndEmoji(note, notesContext, extensions)}</Button>;
                            })}
                            </div>}
                    </div>
                }
            }
            if (message.role === 'system' && DEBUG_SHOW_SYSTEM_PROMPT) {
                // Do we want to show these?
                // return <div key={index}>{message.content}<br/><br/></div>;
                return <div key={index}><SystemMessageViewer message={message}/><br/><br/></div>;
                // return <div key={index}></div>;
            }
            if (message.role === 'assistant') {
            return <AssistantMessage key={index} message={message}
                deleteSelectedMessage={()=>deleteMessage(index)}
                setMessage={(message:ChatMessage2)=>setMessage(index,message)}
                editing={isEditingThis}
                setEditing={(editing:boolean)=>setEditingMessageIndex(editing?index:-1)}
                />;
            }
            // console.error("Corrupted message role: ", message.role);
            return <div key={index}>Loading? Unknown message role: {message.role}<br/><br/></div>;
        })}
        </div>
        </Spin>
    </>;
}